import { useCookies } from "react-cookie"
import { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"

import Header from "../../components/Header"
import PlansItems from "../../components/Plans/Items"
import PlansStatus from "../../components/Plans/Status"

import { getPlanItems } from "../../redux/action/plans/action"
import PlansHeaderAction from "../../components/Plans/HeaderAction"
import PlansDescription from "../../components/Plans/Description"

const Plans = () => {

    const dispatch = useDispatch()
    const plansLoaded = useSelector(state => state.plans.loaded)
    const plansLoading = useSelector(state => state.plans.loading)
    
    const [cookies] = useCookies(['authToken'])

    useEffect(() => {

        if(!plansLoaded && !plansLoading){
            getPlansData()
        }

        window.scrollTo({top: 0})

    }, [])

    const getPlansData = async () => {

        const data = await dispatch( await getPlanItems(cookies.authToken) )    

        if(data){
            return true
        }
    }

    return (
        <>
            <Header 
                title={'اشتراک‌ها'}
                backBtn={true}
            >

                <PlansHeaderAction 
                    onGetPlansData={getPlansData}
                />
                

            </Header>

            <main className="main-content">
                
                <PlansStatus />

                <PlansDescription />

                <PlansItems />

            </main>

        </>
    )

}

export default Plans