import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux"
import Input from "../../../../UI/Input";
import { SET_LOADING_BASEDATA } from "../../../../../redux/action/baseData/baseType";

const NoteAddFormSubjectSearch = (props) => {

    const dispatch = useDispatch()
    const subjects = useSelector(state => state.baseData?.info?.misc?.note_subject)

    const [search, setSearch] = useState('')

    useEffect(() => {

        const setValue = setTimeout(() => {
            // come update the redux --> for access into all of the app
            findItemsFromSearch()

        }, 500)

        return () => {
            if(setValue)
                clearTimeout(setValue)
        }

    }, [search])

    const updateSearch = (event) => {
        setSearch(event.target.value)

        dispatch({
            type: SET_LOADING_BASEDATA,
            payload: true
        })
    }

    const findItemsFromSearch = () => {
        
        if(!subjects || subjects?.length <= 0) {
            
            dispatch({
                type: SET_LOADING_BASEDATA,
                payload: false
            })
            
            return

        }

        const list = subjects?.filter(item => item.includes(search))
        
        props.onSetFilteredList({
            searched: search.length >= 1 ? true : false,
            list
        })

        dispatch({
            type: SET_LOADING_BASEDATA,
            payload: false
        })
    }

    return (
        <div className="mb-4">
            
            <Input 
                id='search-cases-field'
                placeholder={'جستجو دسته...'}
                className={'shadow-custom-lg border-gray-300/60 focus:shadow-primary/5'}
                value={search}
                onChange={updateSearch}
            />

        </div>
    )

}

export default NoteAddFormSubjectSearch