import { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { json, useNavigate, useOutletContext } from "react-router-dom"
import { useCookies } from "react-cookie"

import { SET_MOBILE_AUTH, SET_TOKEN_AUTH } from "../../../redux/action/auth/authType"
import { verifyRequest } from "../../../redux/action/auth/authAction"

import VerifyTitle from "../../../components/Auth/SubSection/Verify/Title"
import VerifyForm from "../../../components/Auth/SubSection/Verify/Form"

import { checkExistAuthStep1, removeAuthStep1, time } from '../../../utils/auth'
import { checkIsNewUser } from '../../../utils/welcomeRegister'
import { add } from "date-fns-jalali"

const Verify = () => {
    
    const dispatch = useDispatch()
    const mobile = useSelector(state => state.auth.mobile)

    const navigate = useNavigate()
    const [ showSuccessMessage ] = useOutletContext()

    const [cookies, setCookies] = useCookies(['diviceId'])

    useEffect(() => {

        // get info of localStorage
        const result = checkExistAuthStep1()
        checkPassedAuthStep1(result)

    }, [])

    const checkPassedAuthStep1 = (result) => {

        if(result && result?.mobile){

            dispatch({
                type: SET_MOBILE_AUTH,
                payload: result.mobile
            })

        }
        else {
            navigate('/auth/login', {replace: true})
        }

    }

    const doVerify = async (code) => {

        // get user platform
        const platform = getUserPlatform()

        // get device id of user
        const deviceId = getDeviceId()

        const data = {
            mobile: mobile,
            code,
            app_type: 'client',
            platform,
            app_version: '1.0',
            device_id: deviceId
        }

        const token = await dispatch( await verifyRequest(data) )
        
        if(token){
            showSuccessMessage('با موفقیت وارد شدید')

            // remove localstorage
            removeAuthStep1()

            localStorage.removeItem('base_data')
            
            dispatch({
                type: SET_MOBILE_AUTH,
                payload: data.mobile
            })

            dispatch({
                type: SET_TOKEN_AUTH,
                payload: token
            })

            // set exipre for auth cookie
            const expireDate = add( new Date(), {months: 3} )
            setCookies('authToken', token, {path: '/',  expires: expireDate, sameSite: "lax"})
            
            setTimeout(() => {
                
                // if is new-user come do
                const isNewUser = checkIsNewUser()
                
                if(isNewUser){
                    navigate('/welcome', {replace: true, state: {token}})
                }
                else {
                    navigate('/', {replace: true, state: {token}})
                }

            }, 600)

        }

    }

    const getUserPlatform = () => {
        const platform = navigator.userAgent.toLocaleLowerCase()
        
        if(platform.includes('android')){
            return 'android'
        }
        else if(platform.includes('window')){
            return 'windows'
        }
        else if(platform.includes('iphone') || platform.includes('ipad') || platform.includes('ipod') ){
            return 'ios'
        }
        else if(platform.includes('mac os')){
            return 'mac'
        }
        else {
            return 'unknow'
        }

    }

    const getDeviceId = () => {
        
        if(cookies.diviceId && typeof cookies.diviceId === 'string'){
            return cookies.diviceId
        }

        else {
            const generateDeviceId = 'DEVICE_ID_' + Math.random().toString(36).substring(2, 10)
    
            const time = new Date()
            time.setMonth( time.getMonth() + 3 )

            setCookies('diviceId', generateDeviceId, { path: '/', expires: time})
    
            return generateDeviceId
        }

    }

    // --- render the app --
    return (
        <>

            <VerifyTitle />

            <VerifyForm 
                onSubmit={doVerify}
            />

        </>
    )

}

export default Verify