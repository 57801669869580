import { useEffect } from "react"
import { useLocation, useNavigate } from "react-router-dom"

import Header from "../../../components/Header"
import PaymentGetewayDescription from "../../../components/PaymentGeteway/Description"
import PaymentGetewayItems from "../../../components/PaymentGeteway/Items"

const PaymentGeteway = () => {

    const location = useLocation()
    const navigate = useNavigate()

    useEffect(() => {

        if(!location.state?.urls || (location.state?.urls && location.state?.urls?.length <= 0) ){
            navigate(location.key !== 'default' ? -1 : '/', {replace: true})
        }

    }, [])

    return (
        <>
            <Header 
                title={'انتخاب درگاه پرداخت'}
                backBtn={true}
            >
            </Header>

            <main className="main-content">

                <PaymentGetewayDescription />

                <PaymentGetewayItems />

            </main>

        </>
    )

}

export default PaymentGeteway