import { applyMiddleware, legacy_createStore as createStore } from 'redux'
import thunk from 'redux-thunk'

import rootReducer from './reducer/rootReducer'

const store = createStore(rootReducer, applyMiddleware(thunk))

export default store

// something
export const apiKey = 'https://api.vakilsun.com/api/v1/'