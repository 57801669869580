import { ReactSVG } from "react-svg"

const BuyPlansTitle = () => {

    return (
        <div className="flex flex-wrap items-center justify-between gap-8  bg-gradient-to-br outline outline-[6px] mx-1 outline-red-600/20 from-red-400 to-red-600 py-6 px-4 rounded-2xl mb-14">
                 
                
            <div className="flex items-start gap-2">

                <ReactSVG 
                    className="xm:w-6 w-5 flex-shrink-0 xm:translate-y-0.5 text-white"
                    src={`${process.env.PUBLIC_URL}/assets/images/icons/plans-box.svg`}
                />

                <h3 className="bg-clip-text bg-gradient-to-r from-white to-cyan-50 text-transparent xm:text-lg text-base font-medium"> برای استفاده از نرم افزار وکیل‌سان باید اشتراک تهیه کنید </h3>
                
            </div>

            <div className="flex-shrink-0 sm:mx-0 mx-auto">
                <ReactSVG 
                    className="w-8 rotate-90 text-white"
                    src={`${process.env.PUBLIC_URL}/assets/images/icons/hand-right.svg`}
                />
            </div>

        </div>
    )

}

export default BuyPlansTitle