import { useSelector } from "react-redux"
import ToastCustom from "../../../UI/ToastCustom"
import { ReactSVG } from "react-svg"
import LoadingDot from "../../../UI/LoadingDot"

const DeleteChatOption = () => {

    const deleteError = useSelector(state => state.messages.list.deleteError)
    const deleteLoading = useSelector(state => state.messages.list.deleteLoading)

    return (
        <>
            { deleteError &&
                
                <ToastCustom className="lg:-translate-x-2/3">
                    
                    <div className="flex items-center gap-2">
                        <ReactSVG
                            className="text-rose-500/90 4xm:w-8 w-6"
                            src={`${process.env.PUBLIC_URL}/assets/images/icons/info-box.svg`}
                        />

                        {deleteError}

                    </div>

                </ToastCustom>

            }

            { deleteLoading && <LoadingDot />  }

        </>
    )

}

export default DeleteChatOption