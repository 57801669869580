import axios from 'axios'
import { apiKey } from '../../store'
import { ADD_TO_CONTACTS, DELETE_USER_FROM_LIST, EDIT_ITEM_FROM_PROFILE_LIST, REPLACE_CONTACT_INFO, SET_CONTACTS, SET_CONTACT_INFO, SET_FAILURE_CONTACTS, SET_LOADING_CONTACTS, SET_UPDATE_FAILURE_CONTACTS, SET_UPDATE_LOADING_CONTACTS, UNSET_LOADING_CONTACTS, UNSET_UPDATE_LOADING_CONTACTS } from './typeContacts'
import { UPDATE_USERS_FROM_CASES } from '../cases/casesType'

const apiKeyContacts = apiKey + 'lawyer/contacts'

export const getContactsList = async (authToken) => {

    return async (dispatch) => {
        
        dispatch({
            type: SET_LOADING_CONTACTS
        })
    
        const config = {
            headers: {
                'Auth-Token': authToken,
                'X-Auth-Token': authToken,
                'header_app_type': 'client',
            },
            timeout: 15000
        }

        const data = await axios.get(`${apiKeyContacts}`, config)
        .catch(error => {
            
            dispatch({
                type: SET_FAILURE_CONTACTS,
                payload: 'عدم برقراری ارتباط با سرور'
            })

        })

        if(data){

            if(data.data.success){

                dispatch({
                    type: SET_CONTACTS,
                    payload: data.data.data
                })

                return data.data.data

            }

            else if(!data.data.success && data.data.errors){

                dispatch({
                    type: SET_FAILURE_CONTACTS,
                    payload: data.data.errors[0]?.message
                })

            }

        }

    }

}

// export const searchInContacts = async (searchValue, authToken) => {

//     return async (dispatch) => {

//         dispatch({
//             type: SET_LOADING_CONTACTS
//         })
    
//         const config = {
//             headers: {
//                 'Auth-Token': authToken,
//                 'X-Auth-Token': authToken,
//                 'header_app_type': 'client',
//             }
//         }

//         // check search by base mobile or name
//         let params = ''
//         if(isNaN(searchValue)){
//             params = 'name=' + searchValue
//         }
//         else{
//             params = 'mobile=' + searchValue
//         }

//         const data = await axios.get(`${apiKeyContacts}?${params}`, config)
//         .catch(error => {
            
//             dispatch({
//                 type: UNSET_LOADING_CONTACTS
//             })

//         })

//         if(data){

//             if(data.data.success){

//                 dispatch({
//                     type: SET_FILTER_CONTACTS,
//                     payload: {
//                         value: searchValue,
//                         list: data.data.data
//                     }
//                 })

//             }

//             else if(!data.data.success && data.data.errors){

//                 dispatch({
//                     type: UNSET_LOADING_CONTACTS,
//                 })

//             }

//         }

//     }

// }

export const deleteContact = async (payload, authToken) => {

    return async (dispatch) => {

        dispatch({
            type: SET_UPDATE_LOADING_CONTACTS,
        })

        const config = {
            headers: {
                'Auth-Token': authToken,
                'X-Auth-Token': authToken,
                'header_app_type': 'client',
            },
            timeout: 15000
        }

        const data = await axios.post(`${apiKeyContacts}/${payload}/delete`, null, config)
        .catch(error => {
            
            dispatch({
                type: SET_UPDATE_FAILURE_CONTACTS,
                payload: 'عدم برقراری ارتباط با سرور'
            })

        })

        if(data){

            if(data.data.success){

                dispatch({
                    type: UNSET_UPDATE_LOADING_CONTACTS
                })

                dispatch({
                    type: DELETE_USER_FROM_LIST,
                    payload: payload
                })

                return true
            }

            else if(!data.data.success && data.data.errors){

                dispatch({
                    type: SET_UPDATE_FAILURE_CONTACTS,
                    payload: data.data.errors[0].message
                })

            }

        }

    }

}

export const AddContact = async (payload, authToken) => {

    return async (dispatch) => {

        dispatch({
            type: SET_UPDATE_LOADING_CONTACTS
        })

        const config = {
            headers: {
                'Auth-Token': authToken,
                'X-Auth-Token': authToken,
                'header_app_type': 'client',
            },
            timeout: 15000
        }

        const data = await axios.post(`${apiKeyContacts}`, payload, config)
        .catch(error => {
            
            dispatch({
                type: SET_UPDATE_FAILURE_CONTACTS,
                payload: 'عدم برقراری ارتباط با سرور'
            })

        })

        if(data){

            if(data.data.success){
                
                // add user to contacts
                dispatch({
                    type: ADD_TO_CONTACTS,
                    payload: data.data.data
                })
                
                return data
            }

            else if(!data.data.success && data.data.errors){

                dispatch({
                    type: SET_UPDATE_FAILURE_CONTACTS,
                    payload: data.data.errors[0].message
                })

            }

        }

    }

}

export const getContactInfo = (payload, authToken) => {

    return async (dispatch) => {

        dispatch({
            type: SET_LOADING_CONTACTS
        })

        const config = {
            headers: {
                'Auth-Token': authToken,
                'X-Auth-Token': authToken,
                'header_app_type': 'client',
            },
            timeout: 15000
        }

        const data = await axios.get(`${apiKeyContacts}/${payload}`, config)
        .catch(error => {
            
            dispatch({
                type: SET_UPDATE_FAILURE_CONTACTS,
                payload: 'عدم برقراری ارتباط با سرور'
            })

            dispatch({
                type: UNSET_LOADING_CONTACTS
            })

        })

        if(data){

            if(data.data.success){

                dispatch({
                    type: SET_CONTACT_INFO,
                    payload: data.data.data
                })

                dispatch({
                    type: UNSET_LOADING_CONTACTS
                })

                return data.data.data
            }

            else if(!data.data.success && data.data.errors){

                dispatch({
                    type: SET_UPDATE_FAILURE_CONTACTS,
                    payload: 'نتونستم اطلاعات مخاطب رو بگیرم'
                })

                dispatch({
                    type: UNSET_LOADING_CONTACTS
                })

            }

        }

    }

}

export const editContact = async (id, payload , authToken) => {

    return async (dispatch) => {

        dispatch({
            type: SET_UPDATE_LOADING_CONTACTS
        })

        const config = {
            headers: {
                'Auth-Token': authToken,
                'X-Auth-Token': authToken,
                'header_app_type': 'client',
            },
            timeout: 15000
        }

        const data = await axios.post(`${apiKeyContacts}/${id}`, payload, config)
        .catch(error => {
            
            dispatch({
                type: SET_UPDATE_FAILURE_CONTACTS,
                payload: 'عدم برقراری ارتباط با سرور'
            })

        })

        if(data){

            if(data.data.success){

                // set new content to contactInfo
                dispatch({
                    type: REPLACE_CONTACT_INFO,
                    payload: {
                        id,
                        ...payload,
                    }
                })

                dispatch({
                    type: SET_CONTACT_INFO,
                    payload: {
                        city: payload.location.city,
                        province: payload.location.province,
                    }
                })

                dispatch({
                    type: UNSET_UPDATE_LOADING_CONTACTS
                })

                // edit-user into the contact-detial loaded
                dispatch({
                    type: EDIT_ITEM_FROM_PROFILE_LIST,
                    payload: {
                        id,
                        data: payload,
                    }
                })
                
                // edit-user into the cases-sections :)
                dispatch({
                    type: UPDATE_USERS_FROM_CASES,
                    payload: {
                        id,
                        data: payload
                    }
                })

                return payload
                // return true
            }

            else if(!data.data.success && data.data.errors){

                dispatch({
                    type: SET_UPDATE_FAILURE_CONTACTS,
                    payload: data.data.errors[0].message
                })

            }

        }

    }

}

// get a item by base mobile
export const getContactInfoByBaseMobile = async (phone, authToken) => {

    const config = {
            headers: {
                'Auth-Token': authToken,
                'X-Auth-Token': authToken,
                'header_app_type': 'client',
            },
            timeout: 15000
    }

    const data = await axios.get(`${apiKeyContacts}?mobile=${phone}`, config)
    .catch(error => {
    })

    if(data){

        if(data.data.success){
            return data.data.data
        }

    }

}