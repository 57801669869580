import { useSelector } from "react-redux"
import { ReactSVG } from "react-svg"
import HomeCaseItem from "./Item"

const HomeCases = ({items}) => {

    const loading = useSelector(state => state.home.loading)

    return (
        <section className="mb-10">

            <h2 className="text-lg font-medium mb-6"> پرونده‌های من</h2>

            { ( (!items || items?.length <= 0) && !loading ) &&
                <div className="flex items-center gap-2">

                    <ReactSVG 
                        src={`${process.env.PUBLIC_URL}/assets/images/icons/cases.svg`}
                        className="w-4 text-gray-700"
                    />

                    <span> هنوز پرونده ای به ثبت نرسیده </span>
                
                </div>
            }

            { items &&
                <ul className="grid sm:grid-cols-2 gap-4">

                    {items.map(item => 
                        
                        <HomeCaseItem 
                            key={item.id + '-home-case'}
                            id={item.id}
                            title={item.title}
                        />
                        
                    )}

                </ul>
            }

            { loading &&
                
                <div className="w-full h-20 flex items-center justify-center">

                    <ReactSVG 
                        src={`${process.env.PUBLIC_URL}/assets/images/icons/loading-dot.svg`}
                        className="text-gray-500 w-10"
                    />
                    
                </div>

            }

        </section>
    )

}

export default HomeCases