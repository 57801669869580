import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import SideMenuItem from "../Item"

import { SET_SIDE_MENU_ITEMS } from "../../../../redux/action/menu/side/sideType"

const SideMenuItems = () => {

    const dispatch = useDispatch()
    const items = useSelector(state => state.menu.side.menuItems)
    const activePlan = useSelector(state => state.profile.profile.active_user_plan)

    useEffect(() => {

        if(activePlan && activePlan[0])
            setMenuItems(true)
        else 
            setMenuItems(false)

    }, [activePlan])

    const setMenuItems = (loaded) => {

        if(!loaded)
            dispatch({
                type: SET_SIDE_MENU_ITEMS,
                payload: [
                    {   
                        path: '/profile',
                        icon: 'user',
                        text: 'پروفایل'
                    },
                    {   
                        path: '/contacts',
                        icon: 'contact',
                        text: 'مخاطبین'
                    },
                    {   
                        path: '/wallet',
                        icon: 'wallet',
                        text: 'کیف پول'
                    },
                    {   
                        path: '/plans',
                        icon: 'plans-box',
                        text: 'خرید اشتراک'
                    },
                    {   
                        path: '/tariffs',
                        icon: 'tariff',
                        text: 'تعرفه اشتراک‌ها'
                    }
                ],
            })

        else 
            dispatch({
                type: SET_SIDE_MENU_ITEMS,
                payload: [
                    {   
                        path: '/profile',
                        icon: 'user',
                        text: 'پروفایل'
                    },
                    {   
                        path: '/contacts',
                        icon: 'contact',
                        text: 'مخاطبین'
                    },
                    {   
                        path: '/wallet',
                        icon: 'wallet',
                        text: 'کیف پول'
                    },
                    {   
                        path: '/plans',
                        icon: 'plans-box',
                        text: (activePlan && activePlan[0] && activePlan[0]?.id) ? 'مدیریت اشتراک' : 'خرید اشتراک'
                    },
                    {   
                        path: '/tariffs',
                        icon: 'tariff',
                        text: 'تعرفه اشتراک‌ها'
                    }
                ],
            })

    }

    return (
        <ul className="flex flex-col items-start gap-4 overflow-y-auto lg:px-0 px-4">
            
            { items.map(item => 

                <SideMenuItem 
                    key={item.path}
                    icon={item.icon}
                    text={item.text}
                    path={item.path}
                />

            )}
        
        </ul>
    )

}

export default SideMenuItems