import { useDispatch, useSelector } from "react-redux"
import PlanItemPaymentItem from "./Item"
import { paymentToWallet } from "../../../../../../redux/action/wallet/action"
import { useCookies } from "react-cookie"
import { useState } from "react"
import { useNavigate } from "react-router-dom"
import MessageForPaymentWallet from "./Message"

const PlanItemPaymentItems = ({id, title, price, onToggleActivePaymentItem = () => {} }) => {

    const dispatch = useDispatch()
    const walletAmount = useSelector(state => state?.wallet?.wallet?.balance?.total)

    const [clickedButton, setClickedButton] = useState()

    const [paymentWithWalletStatusBalance, setPaymentWithWalletStatusBalance] = useState()
    const [showMessage, setShowMessage] = useState(false)


    const navigate = useNavigate()

    const [cookies] = useCookies(['authToken'])

    const payment = async () => {

        setClickedButton('direct')

        // paymentToWallet = async (amount, authToken, type = 0, planId = 0)
        const data = await dispatch( await paymentToWallet(price, cookies.authToken, 1, id) )

        if(data){
            
            setClickedButton(null)

            navigate('/payment-geteway', {
                state: {
                    urls: data?.urls,
                    plan: {
                        title,
                        price
                    }
                }
            })
            // window.open(data.urls[0].url, '_blank')

        }

    }

    const paymentWithWallet = async () => {

        if(!walletAmount || !price)
            return

        setShowMessage(true)
        document.body.classList.add('modal-opened')


        if(walletAmount >= price){
            // have enogth balance ==> show message for buy this plan
            setPaymentWithWalletStatusBalance(true)
        }

        else {
            // not have money ==> show message for increase balance
            setPaymentWithWalletStatusBalance(false)
        }

    }

    return (
        <>
            
            <div className="flex flex-row items-start 5xm:flex-nowrap flex-wrap justify-around gap-y-3">
            
                <PlanItemPaymentItem 
                    icon='peyment-card'
                    text= {clickedButton !== 'direct' ? 'مستقیم' : 'loading'}
                    click={payment}
                    clickedButton={clickedButton}
                />

                <PlanItemPaymentItem 
                    icon='wallet-2'
                    text='کیف پول'

                    click={paymentWithWallet}
                    clickedButton={clickedButton}
                />


            </div>

            <MessageForPaymentWallet 
                status={paymentWithWalletStatusBalance}

                showMessage={showMessage}
                onSetShowMessage={setShowMessage}
                onToggleActivePaymentItem={onToggleActivePaymentItem}

                planId={id}
                planPrice={price}

                balance={walletAmount}
            />

        </>
    )

}

export default PlanItemPaymentItems