import { useEffect, useState } from "react"

const HomeInstall = () => {

    const [notInstalled, setNotInstalled] = useState()
    const [isMobile, setIsMobile] = useState(false)

    useEffect(() => {

        if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Windows Phone|Opera Mini/i.test(navigator.userAgent)){
            window.addEventListener('beforeinstallprompt', checkInstallOrNo)
        }

        return () => {
            window.removeEventListener('beforeinstallprompt', checkInstallOrNo)
        }

    }, [])

    const checkInstallOrNo = (event) => {

        event.preventDefault()

        if(event){
            setIsMobile(true)   
            setNotInstalled(event)
        }

    }

    const install = () => {
        
        if(notInstalled){
            notInstalled.prompt()
        }

    }
    
    if(isMobile && notInstalled){

        return (
            <section className="mb-8">
    
                <div className=" bg-gradient-to-br outline outline-[6px] mx-1 outline-third/20 from-third to-four rounded-2xl p-4 flex items-center justify-between gap-4 flex-wrap">
                    
                    <div className="flex flex-col gap-3">
                        
                        <div className="flex items-center gap-2">

                            <div className="bg-white rounded-md w-6 h-6 flex items-center justify-center">
                                <img className="w-4 translate-y-[1px] drop-shadow-2xl" src={`${process.env.PUBLIC_URL}/assets/images/logo/logo.png`} alt="" />
                            </div>
    
                            <h4 className="font-medium text-white"> نصب وکیل‌سان </h4>
                        
                        </div>
    
                        <p className="text-gray-100">  آیا تمایل به نصب اپلیکیشن <span className="text-white">وکیل‌سان</span> دارید؟ </p>
    
                    </div>
    
                    <button onClick={install} className="btn mr-auto py-2 px-5 font-medium rounded-lg bg-white text-four"> نصب </button>
    
                </div>
    
            </section>
        )
        
    }

}

export default HomeInstall