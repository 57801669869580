import { useState } from "react"
import { ReactSVG } from "react-svg"

import ConfirmModalDelete from "../../ConfirmModal/Delete"
import { Link, useParams } from "react-router-dom"
import { deleteMessageFromChat } from "../../../../redux/action/messages/chat/chatAction"
import { useDispatch } from "react-redux"
import { useCookies } from "react-cookie"

const ModalImageHeader = (props) => {
    
    const dispatch = useDispatch()

    const [showModal, setShowModal] = useState(false)
    const [deleteBoth, setDeleteBoth] = useState(true)

    const params = useParams()

    const [cookies] = useCookies(['authToken'])

    const showDeleteDropdown = () => {
        setShowModal(true)
    }

    const deleteMessage = async () => {
        setShowModal(false)

        // do a work
        const scope = deleteBoth ? 'both' : 'self'
        await dispatch( await deleteMessageFromChat(props.id, params.id, scope, cookies.authToken) )
    }

    return (
        <>
            <div className="flex items-center justify-between flex-wrap gap-3 h-full w-full">

                <div onClick={props.onHide} className="flex items-center gap-4 text-gray-400 h-full">

                    <button className="lg:w-12 w-11 hover:text-gray-200 duration-300 h-full flex items-center justify-center lg:pr-4 pr-3">
                        <ReactSVG 
                            src={`${process.env.PUBLIC_URL}/assets/images/icons/back.svg`}
                            className="w-5 rotate-180"
                        />
                    </button>

                    <div className="flex flex-col">

                        <span> {props.name} </span>

                        <span className="text-xs text-gray-500 5xm:block hidden"> {new Date(props.time)?.toLocaleTimeString('fa', {hour: '2-digit', minute: '2-digit', timeZone: '+0330'})} </span>

                    </div>

                </div>

                <div className="text-gray-400 mr-auto relative">

                    <div className="flex items-center flex-row-reverse h-full">

                        <button onClick={showDeleteDropdown} className="lg:w-12 w-11 h-12 text-gray-400 duration-300 hover:text-gray-100 outline-none border-none flex items-center justify-center lg:pl-4 pl-3">

                            <ReactSVG 
                                src={`${process.env.PUBLIC_URL}/assets/images/icons/delete.svg`}
                                className="w-6 "
                            />

                        </button>

                        <Link to={props.src} target="_blank" download={true} className="w-11 h-12 text-gray-400 duration-300 hover:text-gray-100 outline-none border-none flex items-center justify-center">

                            <ReactSVG 
                                src={`${process.env.PUBLIC_URL}/assets/images/icons/download-2.svg`}
                                className="w-6"
                            />

                        </Link>

                    </div>

                    {showModal && 
                    
                        <ConfirmModalDelete
                            title="حذف پیام"
                            description="آیا از حذف این پیام اطمینان دارید؟"
                            show={showModal}
                            onSetShow={setShowModal}
                            onConfirm={deleteMessage}
                            selectCount={1}
                        >   

                            <div className="flex justify-center text-gray-600">
                            
                                <input type="checkbox" id="deleteBothInput" className="accent-primary rounded-lg w-5 cursor-pointer" onChange={() => setDeleteBoth(!deleteBoth) } checked={deleteBoth} />

                                <label htmlFor="deleteBothInput" className="select-none cursor-pointer pr-2.5">حذف همچنین برای {props.name}</label>

                            </div>

                        </ConfirmModalDelete>

                    }

                </div>

            </div>
        </>
    )

}

export default ModalImageHeader