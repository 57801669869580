import validator from 'validator'

export const validateField = (field) => {

    const { length, min, required, email } = field?.validate
    
    if(!required && field.value === '')
        return true      
    
    if(!email){

        if(length && field.value.length !== length){
            return false
        }
    
        if(min && field.value.length < min){
            return false
        }

    }
    else {
        if(validator.isEmail(field.value))
            return true
        else
            return false
    }

    return true
}