import { useEffect, useState } from "react"
import { Link } from "react-router-dom"
import { ReactSVG } from "react-svg"
import { useCookies } from "react-cookie"
import { useDispatch, useSelector } from "react-redux"
import { getContactsList } from "../../../../../redux/action/contacts/contactsAction"

const ContactProfileHeaderButtons = (props) => {

    const dispatch = useDispatch()
    const contacts = useSelector(state => state.contacts.contacts)
    const loaded = useSelector(state => state.contacts.getedContacts)

    const loading = useSelector(state => state.contacts.loading)

    const [contactId, setContactId] = useState()

    const [cookies] = useCookies(['authToken'])

    useEffect(() => {

        if(contacts.length <= 0 && !loading && !loaded){
            getContacts()
        }
        else
            findContactId()

    }, [])

    useEffect(() => {

        if(loaded)
            findContactId()

    }, [contacts, props?.mobile])

    const getContacts = async () => {
        const data = await dispatch( await getContactsList(cookies.authToken) )
    }

    const findContactId = () => {
    
        const finded = contacts.find(item => Number(item.mobile) === Number(props.mobile) )

        if(finded && finded?.user?.id && finded?.user?.last_login){
            setContactId(finded?.user?.id)
        }
        else if(!finded || !finded?.user?.id)
            setContactId('')
    
    }

    return (
        <div className="flex items-center gap-4 absolute top-full left-4 -translate-y-1/2">

            { contactId &&
                <Link to={`/messages/${contactId}/${props.mobile}`} className="w-12 h-12 flex items-center justify-center rounded-2xl bg-sky-500 shadow-custom-xl outline outline-4 outline-sky-500/20">
                
                    <ReactSVG 
                        src="../assets/images/icons/message.svg"
                        className="w-6 text-white"
                    />

                </Link>
            }

            <a href={`tel:${props.mobile}`} className="w-12 h-12 flex items-center justify-center rounded-2xl bg-green-500 shadow-custom-xl outline outline-4 outline-green-500/20">
                <ReactSVG 
                    src="../assets/images/icons/mobile.svg"
                    className="w-6 text-white"
                />
            </a>

        </div>
    )

}

export default ContactProfileHeaderButtons