import { useEffect, useState } from "react"
import { ReactSVG } from "react-svg"
import ModalBottom from "../../../../UI/ModalBottom"
import AddEventFormCustomTimeList from "./List"

const AddEventFormCustomTimeField = (props) => {

    const [showModal, setShowModal] = useState(false)
    const [showingModal, setShowingModal] = useState(false)
    const [customState, setCustomState] = useState(false)
    
    useEffect(() => {

        return () => {
            if(document.body.classList.contains('modal-opened'))
                document.body.classList.remove('modal-opened')
        }

    }, [])

    const openModal = () => {
        setShowModal(true)

        document.body.classList.add('modal-opened')
    }

    const hideModal= () => {
        setShowingModal(false)

        setTimeout(() => {
            setShowModal(false)
            setCustomState(false)
        }, 300);

        document.body.classList.remove('modal-opened')
    }

    const resetDataAndHideModal = () => {
        hideModal()

        props.onResetAlarmData()
    }

    const restoreDataAndHideModal = (event) => {
        event.preventDefault()

        hideModal()

        props.onUpdateAlaramDate(props.mainAlarm.date, props.mainAlarm.clock, props.mainAlarm.label, props.mainAlarm.englishDate)
    }

    return (
        <>
            <div className="flex flex-col gap-2.5">

                <span className="text-gray-500 text-sm"> تنظیم هشدار  : </span>

                <button onClick={ openModal } type="button" className="flex items-center justify-between gap-4 flex-wrap bg-white p-3 text-right text-gray-800 rounded-xl shadow-custom-md">

                    <div className="flex items-center gap-3">
                        
                        <ReactSVG src={`${process.env.PUBLIC_URL}/assets/images/icons/calculate.svg`} className={`w-5 text-primary ${(props.loading) && '!text-gray-400' }`} />

                        { !props.loading ? (props.alaram_at.clock || props.alaram_at.date) ? props.alaram_at.label ? props.alaram_at.label : (props.alaram_at.date !== props.persianDate) ? `${Number(props.alaram_at.date.split('/')[0]).toLocaleString('fa').replace('٬', '')}/${Number(props.alaram_at.date.split('/')[1]).toLocaleString('fa')}/${Number(props.alaram_at.date.split('/')[2]).toLocaleString('fa')} - ${Number( props.alaram_at.clock.split(':')[0]) <= 9 ? '۰' + Number( props.alaram_at.clock.split(':')[0]).toLocaleString('fa') : Number( props.alaram_at.clock.split(':')[0]).toLocaleString('fa')}:${ Number( props.alaram_at.clock.split(':')[1]) <= 9 ? '۰' + Number( props.alaram_at.clock.split(':')[1]).toLocaleString('fa') : Number( props.alaram_at.clock.split(':')[1]).toLocaleString('fa')}` : `${Number( props.alaram_at.clock.split(':')[0]) <= 9 ? '۰' + Number( props.alaram_at.clock.split(':')[0]).toLocaleString('fa') : Number( props.alaram_at.clock.split(':')[0]).toLocaleString('fa')}:${ Number( props.alaram_at.clock.split(':')[1]) <= 9 ? '۰' + Number( props.alaram_at.clock.split(':')[1]).toLocaleString('fa') : Number( props.alaram_at.clock.split(':')[1]).toLocaleString('fa')}` : <span className="text-gray-500">انتخاب نشده</span>
                            :
                            <ReactSVG src={`${process.env.PUBLIC_URL}/assets/images/icons/loading-dot.svg`} className="w-10 py-[7px] text-gray-400" /> 
                        }
                    
                    </div>

                    <div>
                        <ReactSVG src={`${process.env.PUBLIC_URL}/assets/images/icons/down.svg`} className="w-5" />
                    </div>

                </button>

            </div>
            
            { showModal &&
                
                <ModalBottom
                    show={showModal}
                    onSetShow={setShowModal}
                    className="overflow-y-auto no-scrollbar"
                    showing={showingModal}
                    onSetShowing={setShowingModal}
                    onHided={() => setCustomState(false)}
                >
                    
                    <div className="sticky top-0 left-0 flex flex-col bg-white w-full z-[2] border-b border-b-slate-200">

                        <div className="py-2 bg-white z-[1] mb-2">
                            <div className="mx-auto w-20 h-2 bg-gray-200 rounded-2xl"></div>
                        </div>

                        <div className="flex items-center justify-between gap-4 mb-4">

                            <button onClick={!customState ? hideModal : () => setCustomState(false)} type="button" className="w-10 group">
                                
                                <ReactSVG src={`${process.env.PUBLIC_URL}/assets/images/icons/back.svg`} className="w-5 text-gray-600 hover:text-primary duration-300 rotate-180 mr-1.5 group-disabled:text-gray-400 group-disabled:opacity-70" />

                            </button>

                            <div className="flex items-center gap-0.5">

                                <span className="font-medium flex items-center gap-1.5">
                                    <ReactSVG src={`${process.env.PUBLIC_URL}/assets/images/icons/calculate.svg`} className="w-[18px] text-gray-500" />

                                    { (props.alaram_at.clock || props.alaram_at.date) ? props.alaram_at.label ? props.alaram_at.label : (props.alaram_at.date !== props.persianDate) ? `${Number(props.alaram_at.date.split('/')[0]).toLocaleString('fa').replace('٬', '')}/${Number(props.alaram_at.date.split('/')[1]).toLocaleString('fa')}/${Number(props.alaram_at.date.split('/')[2]).toLocaleString('fa')} - ${Number( props.alaram_at.clock.split(':')[0]) <= 9 ? '۰' + Number( props.alaram_at.clock.split(':')[0]).toLocaleString('fa') : Number( props.alaram_at.clock.split(':')[0]).toLocaleString('fa')}:${ Number( props.alaram_at.clock.split(':')[1]) <= 9 ? '۰' + Number( props.alaram_at.clock.split(':')[1]).toLocaleString('fa') : Number( props.alaram_at.clock.split(':')[1]).toLocaleString('fa')}` : `${Number( props.alaram_at.clock.split(':')[0]) <= 9 ? '۰' + Number( props.alaram_at.clock.split(':')[0]).toLocaleString('fa') : Number( props.alaram_at.clock.split(':')[0]).toLocaleString('fa')}:${ Number( props.alaram_at.clock.split(':')[1]) <= 9 ? '۰' + Number( props.alaram_at.clock.split(':')[1]).toLocaleString('fa') : Number( props.alaram_at.clock.split(':')[1]).toLocaleString('fa')}` : 'انتخاب نشده' }
                                </span>

                                {/* mainAlarm */}

                                { props.alaram_at.clock && ((props.mainAlarm && props.alaram_at.clock === props.mainAlarm.clock && props.alaram_at.label === props.mainAlarm.label && props.alaram_at.date === props.mainAlarm.date) || !props.mainAlarm) ?
                                    <button onClick={resetDataAndHideModal} className="h-full focus:outline-none outline-none w-8 flex justify-end">
                                        <ReactSVG 
                                            src={`${process.env.PUBLIC_URL}/assets/images/icons/delete.svg`}
                                            className="w-5 text-rose-600"
                                        />
                                    </button>
                                    :
                                    (props.mainAlarm && (props.alaram_at.clock !== props.mainAlarm.clock && props.alaram_at.date === props.mainAlarm.date && props.alaram_at.label === props.mainAlarm.label)) &&
                                        <button onClick={restoreDataAndHideModal} type="button" className="h-full focus:outline-none outline-none w-8 flex justify-end">
                                            <ReactSVG 
                                                src={`${process.env.PUBLIC_URL}/assets/images/icons/turn-back.svg`}
                                                className="w-4 text-primary"
                                            />
                                        </button>
                                }

                            </div>

                        </div>

                    </div>

                    <AddEventFormCustomTimeList 
                        persianDate={props.persianDate}
                        customState={customState}
                        onSetCustomState={setCustomState}
                        clock={props.clock}
                        alaram_at={props.alaram_at}
                        OnUpdateAlaramClock={props.OnUpdateAlaramClock}
                        onUpdateAlaramDate={props.onUpdateAlaramDate}
                        onHideModal={hideModal}
                    />

                </ModalBottom>
            }
        </>
    )

}

export default AddEventFormCustomTimeField