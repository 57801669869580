import { useSelector } from "react-redux";
import { NavLink } from "react-router-dom"
import { ReactSVG } from "react-svg"

const MainMenuListItem = (props) => {

    return (
        <li className="main-menu-item relative order-2 w-full" id={props.id}>
    
            <NavLink to={props.path} className="main-menu-link group flex lg:flex-row flex-col items-center lg:gap-3 gap-2 w-full rounded-xl cursor-pointer lg:hover:text-gray-900 hover:text-primary duration-300 lg:bg-gray-100 lg:py-3 lg:px-4 border-dashed lg:border border-gray-200 lg:hover:bg-gray-200">

                <ReactSVG src={props.icon} className="main-menu-link-icon md:w-6 3xm:w-5 w-[18px] text-gray-600 lg:group-hover:text-gray-900 group-hover:text-primary duration-300" />

                <span className="md:text-base 3xm:text-sm text-xs"> {props.text} </span>

                { (( props?.countMessage && Number(props.countMessage) >= 1 && props.id === 'messages-menu-item') ? true : false ) && 
                    <div className="absolute bg-red-500 lg:top-1/2 lg:-translate-y-1/2 lg:left-4 text-xs lg:w-5 lg:h-5 w-4 h-4 2xm:left-2.5 3xm:left-1 5xm:left-0 -left-1 rounded-full flex items-center justify-center text-white">
                        <span className={`${props.countMessage !== 0 ? 'translate-y-[0.75px]' : ''} lg:scale-100 scale-90`}>
                            { Number(props.countMessage)?.toLocaleString('fa') }
                        </span>
                    </div>
                }

            </NavLink>

        </li>
    )

}

export default MainMenuListItem