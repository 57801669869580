import { useDispatch, useSelector } from "react-redux"
import SideMenuItem from "../Item"
import { useEffect } from "react"
import { getBaseDataInfoFromLocaleStorage, saveBaseDataInfoToLocaleStorage } from "../../../../utils/base-data"
import { getBaseDataLawyer } from "../../../../redux/action/baseData/baseAction"
import { useCookies } from "react-cookie"
import { SET_SUCCESS_BASEDATA } from "../../../../redux/action/baseData/baseType"
import { SET_SIDE_MENU_LINKS } from "../../../../redux/action/menu/side/sideType"
import { ReactSVG } from "react-svg"

const SideMenuLinks = () => {

    const dispatch = useDispatch()
    const links = useSelector(state => state.menu.side.menuLinks)

    const baseDataConfig = useSelector(state => state.baseData?.info?.config)
    const baseDataLoading = useSelector(state => state.baseData.loading)
    const baseDataError = useSelector(state => state.baseData.error)
    const baseDataLoaded = useSelector(state => state.baseData.loaded)

    const [cookies] = useCookies(['authToken'])

    useEffect(() => {

        getLinksDataFromBaseData()

    }, [])

    useEffect(() => {

        if(baseDataConfig)
            setLinkForLinks()

    }, [baseDataConfig])

    const getLinksDataFromBaseData = async () => {

        if(baseDataLoaded || baseDataLoading) return

        const baseData = getBaseDataInfoFromLocaleStorage()

        if(!baseData){
            const data = await dispatch( await getBaseDataLawyer(cookies.authToken) )

            if(data){
                saveBaseDataInfoToLocaleStorage(data)
            }

        }
        else {

            dispatch({
                type: SET_SUCCESS_BASEDATA,
                payload: baseData
            })

        }

    }

    const setLinkForLinks = () => {
        const links = [
            {   
                path: 'https://blog.vakilsun.com',
                icon: 'world',
                text: 'پیوندهای کاربردی'
            },
            {   
                path: baseDataConfig?.support_url ? baseDataConfig?.support_url : 'https://blog.vakilsun.com/contactus',
                icon: 'message',
                text: 'ارتباط با پشتیبانی'
            },
            {   
                path: baseDataConfig?.help_url,
                icon: 'info-box',
                text: 'راهنما'
            }
        ]

        // set side menu links
        dispatch({
            type: SET_SIDE_MENU_LINKS,
            payload: links
        })
    }

    if(baseDataLoading){
        return (
            <div className="flex items-center justify-center h-20">  
                <ReactSVG src={`${process.env.PUBLIC_URL}/assets/images/icons/loading-dot.svg`} className="w-10 text-gray-500" />
            </div>
        )
    }

    else if(baseDataError){
        return (
            <div className="flex items-center justify-center h-20 text-gray-600">
                {baseDataError}
            </div>
        )
    }

    return (
        <ul className="flex flex-col items-start gap-4 overflow-y-auto lg:px-0 px-4">
            
            { links.map(item => 

                <SideMenuItem 
                    key={item.path+item.text}
                    icon={item.icon}
                    text={item.text}
                    path={item.path}
                    link={true}
                />

            )}
        
        </ul>
    )

}

export default SideMenuLinks