import { useEffect, useState } from "react"
import NoteAddFooterColor from "./Footer"
import { useDispatch, useSelector } from "react-redux"
import { RESET_NOTE_INFO, SET_NOTE_INFO_BODY, SET_NOTE_INFO_LAW_SUIT, SET_NOTE_INFO_SUBJECT, SET_NOTE_INFO_TITLE } from "../../../redux/action/notes/notesType"
import { useLocation } from "react-router-dom"
import { ReactSVG } from "react-svg"
import NoteAddFormCases from "./Cases"
import NoteAddFormSubject from "./Subject"

const NoteAddForm = () => {

    const dispatch = useDispatch()
    const noteInfo = useSelector(state => state.notes.noteInfo)

    const [showCases, setShowCases] = useState(false)
    const [showSubject, setShowSubject] = useState(false)

    const location = useLocation()

    useEffect(() => {

        // come empty, becuase is new
        if(noteInfo.id !== null){
            
            dispatch({
                type: RESET_NOTE_INFO
            })

        }

    }, [])

    const updateBody = (event) => {
        
        if(event.target.value.length > 2000) return

        dispatch({
            type: SET_NOTE_INFO_BODY,
            payload: event.target.value
        })

    }

    const showCasesList = () => {

        setShowCases(true)

        document.body.classList.add('modal-opened')

    }

    const showSubjectList = () => {

        setShowSubject(true)

        document.body.classList.add('modal-opened')

    }

    const updateCaseInfoField = (id, title) => {
        dispatch({
            type: SET_NOTE_INFO_LAW_SUIT,
            payload: {
                id,
                title
            }
        })
    }

    const updateSubjectField = (subject) => {
        dispatch({
            type: SET_NOTE_INFO_SUBJECT,
            payload: subject
        })
    }

    // ---- Render ----

    const selectColor = () => {
        if(noteInfo.color === 1)
            return 'bg-pink-200'

        else if(noteInfo.color === 2)
            return 'bg-blue-200'
        
        else if(noteInfo.color === 3)
            return 'bg-orange-200'

        else if(noteInfo.color === 4)
            return 'bg-green-200'
        
        else if(noteInfo.color === 5)  
            return 'bg-purple-200'

        else 
            return 'bg-pink-200'
    }

    return (
        <>
            <div className={`w-full 2xm:min-h-[calc(100vh-90px-56px-20px)] mb-16 ${selectColor()} rounded-xl`}>

                <form>

                    {
                        location?.state?.law_suit?.title ?
                            <div className="p-4 pb-1">
                                        
                                <span className="text-xs-12px font-medium flex items-center gap-1.5 border-b border-b-gray-700 pb-2">
                        
                                    <ReactSVG
                                        src={`${process.env.PUBLIC_URL}/assets/images/icons/cases.svg`}
                                        className="w-3.5 h-3.5 mb-0.5 mt-1"
                                    />

                                    { location?.state?.law_suit?.title }
                                    
                                </span>
                                
                            </div>
                            :
                            <div className="pb-1 px-4 w-full">
                                        
                                <button onClick={ showCasesList } type="button" className={`cursor-pointer pt-4 text-xs-12px font-medium flex items-center gap-1.5 border-b border-b-gray-700 pb-2 ${noteInfo?.law_suit?.title ? 'text-gray-800' : 'text-gray-500 '} w-full`}>
                        
                                    <ReactSVG
                                        src={`${process.env.PUBLIC_URL}/assets/images/icons/cases.svg`}
                                        className="w-3.5 h-3.5 mb-0.5"
                                    />

                                    { noteInfo?.law_suit?.title ? noteInfo?.law_suit?.title : 'انتخاب پرونده' }
                                    
                                </button>
                                
                            </div>
                    }

                    <div className="px-4 pt-3">
                        
                        <button onClick={showSubjectList} type="button" className={`bg-white flex items-center gap-2 rounded-md ${noteInfo?.subject ? 'pl-3 pr-2' : 'px-2'} py-1.5 text-sm`}>
                            <ReactSVG
                                src={`${process.env.PUBLIC_URL}/assets/images/icons/file.svg`}
                                className="w-4 h-4 mb-0.5"
                            />
                            { noteInfo?.subject ? noteInfo.subject :
                                
                                <div className="flex items-center gap-2">
                                    دسته‌بندی
                                    <ReactSVG
                                        src={`${process.env.PUBLIC_URL}/assets/images/icons/add.svg`}
                                        className="w-4 h-4"
                                    />

                                </div>
                            }
                        </button>

                    </div>

                    <textarea 
                        className={`w-full no-scrollbar h-screen 2xm:max-h-[calc(100vh-90px-56px-20px-70px-46px)] max-h-[calc(100vh-90px-56px-20px-70px-80px)] bg-transparent resize-none outline-none focus:outline-none border-0 px-4 placeholder-gray-400 text-gray-800 pt-4`}
                        placeholder="تایپ کردن را آغاز کنید..."
                        autoFocus
                        onChange={updateBody}
                        value={noteInfo.body}
                        maxLength={'2000'}
                    >

                    </textarea>

                </form>

                { showCases && 
                    <NoteAddFormCases 
                        showCases={showCases}
                        onSetShowCases={setShowCases}

                        caseInfo={noteInfo.law_suit}
                        onUpdateCaseInfoField={updateCaseInfoField}                        
                    />
                }

                { showSubject && 
                    <NoteAddFormSubject
                        showSubject={showSubject}
                        onSetShowSubject={setShowSubject}

                        subject={noteInfo.subject}
                        onUpdateSubjectField={updateSubjectField}                        
                    />
                }

            </div>

            <NoteAddFooterColor 
                color={noteInfo.color}
            />
        </>
    )

}

export default NoteAddForm