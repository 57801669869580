import { ReactSVG } from "react-svg"

import './Emoji.css'

const SendMessageEmoji = (props) => {

    const handleShowEmoji = () => {
        props.onHandleShowEmoji()

        if(props.showEmoji)
            document.body.classList.remove('modal-opened')
        else
            document.body.classList.add('modal-opened')
    }

    return (
        <div className="absolute bottom-[0.8px] left-2">

            <button onClick={handleShowEmoji} className="text-gray-500 3xm:w-10 w-8 h-12 flex items-center justify-center" type="button">
                
                { !props.showEmoji ? 
                    <ReactSVG src={`${process.env.PUBLIC_URL}/assets/images/icons/emoji.svg`} className="3xm:w-7 3xm:h-7 w-5 h-5" />
                    :
                    <ReactSVG src={`${process.env.PUBLIC_URL}/assets/images/icons/times.svg`} className="3xm:w-7 3xm:h-7 w-5 h-5" />
                }
            </button>

        </div>
    )

}

export default SendMessageEmoji