import { useEffect, useState } from "react"
import EventDateItem from "./Item"

import DeleteHeader from "./DeleteHeader"
import { useDispatch, useSelector } from "react-redux";
import { useCookies } from "react-cookie";
import { getEventsListByDate, getMoreEventsList, getMoreEventsListByDate } from "../../../redux/action/events/eventsAction";
import { ReactSVG } from "react-svg";
import { SET_FAILURE_CALENDAR_DATE_EVENTS, SET_HAVE_CHANGE_IN_STORAGE } from "../../../redux/action/events/eventsType";
import InfiniteScroll from "react-infinite-scroller";
import { getBaseDataInfoFromLocaleStorage, saveBaseDataInfoToLocaleStorage } from "../../../utils/base-data";
import { getBaseDataLawyer } from "../../../redux/action/baseData/baseAction";
import { SET_SUCCESS_BASEDATA } from "../../../redux/action/baseData/baseType";

const EventsDateItems = (props) => {

    const dispatch = useDispatch()
    const eventsDay = useSelector(state => state.events.eventsDay)
    const eventsDayLoading = useSelector(state => state.events.eventsDayLoading)
    const eventsDayError = useSelector(state => state.events.eventsDayError)
    
    const filtered = useSelector(state => state.events.filtered)
    const filterError = useSelector(state => state.events.filterError)
    const haveAChangeInStorage = useSelector(state => state.events.haveAChangeInStorage)
    const loadMoreLoading = useSelector(state => state.events.loadMoreLoading)

    const baseDataUserId = useSelector(state => state.baseData.info?.profile?.user_id)
    const baseDataLoading = useSelector(state => state.baseData.loading)
    const baseDataLoaded = useSelector(state => state.baseData.loaded)

    const [selectedItemDelete, setSelectedItemDelete] = useState(null)
    const [loadingMore, setLoadingMore] = useState(false)

    const [cookies] = useCookies(['authToken'])

    useEffect(() => {

        if(eventsDay && eventsDay[props.date] && eventsDay[props.date]?.length >= 1)
            getUserIdFromBaseData()

    }, [eventsDay])

    useEffect(() => {

        if(props.date){
                        
            // if not exist this events in list come get
            if( !eventsDay[props.date] ){
                getEvents()
            }
            else {
                
                if(eventsDayError){
                    
                    dispatch({
                        type: SET_FAILURE_CALENDAR_DATE_EVENTS,
                        payload: null
                    })

                }
                
                else if(haveAChangeInStorage.eventDate){
                    getEvents()
                }

            }

        }

    }, [props.date])


    const getUserIdFromBaseData = async () => {
        
        if(baseDataLoaded) return

        if(baseDataLoading) return

        const baseData = getBaseDataInfoFromLocaleStorage()

        if(!baseData){
            const data = await dispatch( await getBaseDataLawyer(cookies.authToken) )

            if(data){
                saveBaseDataInfoToLocaleStorage(data)
            }

        }
        else {

            dispatch({
                type: SET_SUCCESS_BASEDATA,
                payload: baseData
            })

        }

    }

    const getEvents = async () => {

        const date = props.date.split('/')

        const endDay = Number(date[2]) + 1
        const endDate = `${date[0]}/${date[1]}/${endDay}`

        if(!eventsDayLoading)
            await dispatch( await getEventsListByDate(props.date, endDate, cookies.authToken) )
        
            dispatch({
                type: SET_HAVE_CHANGE_IN_STORAGE,
                payload: {
                    list: haveAChangeInStorage.list,
                    calendar: haveAChangeInStorage.calendar,
                    eventDate: false,
                }
            })
    }

    const loadMore = async () => {
        
        if( filtered.list && filtered.list.length >= 12 && !loadMoreLoading && !loadingMore && window.scrollY >= window.screenTop ) { 

            setLoadingMore(true)

            const date = props.date.split('/')
            const endDay = Number(date[2]) + 1
            const endDate = `${date[0]}/${date[1]}/${endDay}`

            const data = await dispatch( await getMoreEventsList(filtered?.pagination?.current_page ? filtered?.pagination?.current_page : 1, filtered.searchedValue ? filtered.searchedValue : '', cookies.authToken, props.date, endDate) )
            
            if(data){

                setLoadingMore(false)
                
            }

        }
        else if( eventsDay[props.date] && eventsDay[props.date].length >= 12 && !loadMoreLoading && !loadingMore && window.scrollY >= window.screenTop ){
            setLoadingMore(true)

            const date = props.date.split('/')
            const endDay = Number(date[2]) + 1
            const endDate = `${date[0]}/${date[1]}/${endDay}`

            const data = await dispatch( await getMoreEventsListByDate(eventsDay?.pagination?.has_more_pages, props.date, endDate, cookies.authToken) )
            
            if(data){
                setLoadingMore(false)
            }

        }

    }

    if(eventsDayLoading){

        return (
            <section className="flex items-center justify-center h-20">
                <ReactSVG 
                    src={`${process.env.PUBLIC_URL}/assets/images/icons/loading-dot.svg`}
                    className="w-10 text-second"
                />
            </section>
        )

    }

    if(eventsDayError || filterError) {
        return (
            <section className="flex items-center gap-2">

                <ReactSVG src={`${process.env.PUBLIC_URL}/assets/images/icons/info-box.svg`} className="w-6 text-rose-500" />

                <p className="text-gray-700 leading-7"> {eventsDayError || filterError} </p>

            </section>
        )
    }

    if(filtered.searchedValue){

        return (
            <>

                { selectedItemDelete &&
                    <DeleteHeader 
                        selectedItemId={selectedItemDelete}
                        onSetSelectedItemDelete={setSelectedItemDelete}
                        date={props.date}
                        events={filtered?.list}
                    />
                }

                {filtered?.list?.length === 0 && 
                    <p className="text-gray-600 flex items-center gap-2"> 
                        هیچ رویدادی یافت نشد
                    </p>
                }

                { props.date && eventsDay[props.date] && eventsDay[props.date].length >= 1  && filtered?.list?.length >= 1 &&

                    <div> 
                                        
                        <InfiniteScroll
                            pageStart={1}
                            loadMore={loadMore}
                            threshold={0}
                            hasMore={filtered.pagination?.has_more_pages}
                            loader={ loadMoreLoading &&
                                <ReactSVG
                                    key={0}
                                    className="w-10 text-primary mx-auto block mt-10"
                                    src={`${process.env.PUBLIC_URL}/assets/images/icons/loading-dot.svg`}
                                />
                            }
                        >

                            <ul className="grid md:grid-cols-2 grid-cols-1 gap-6">

                                { filtered?.list?.map((item, index) => 

                                    <EventDateItem 
                                        key={item.id + '-filtered'}
                                        type={item.type}
                                        title={item.title}
                                        body={item.description}
                                        time={item.starts_at}
                                        case={item.law_suit ? item.law_suit.title : ''}
                                        count={index + 1}
                                        id={`${item.id}`}
                                        date={props.date}
                                        item={item}
                                        selectedItemDelete={selectedItemDelete}
                                        onSetSelectedItemDelete={setSelectedItemDelete}
                                        isStarted={item?.is_started}
                                        creator={item.creator}
                                        myId={baseDataUserId}
                                        send_sms={item?.send_sms}
                                    />

                                )}

                            </ul>   

                        </InfiniteScroll>

                    </div>
                   
                }  

            </>
        )

    }

    return (
        <>

            { selectedItemDelete &&
                <DeleteHeader 
                    selectedItemId={selectedItemDelete}
                    onSetSelectedItemDelete={setSelectedItemDelete}
                    date={props.date}
                    events={eventsDay[props.date]}
                />
            }

            {  props.date && eventsDay[props.date] && eventsDay[props.date].length >= 1  ?
                
                <div> 
                                        
                    <InfiniteScroll
                        pageStart={1}
                        loadMore={loadMore}
                        threshold={0}
                        hasMore={eventsDay?.pagination?.has_more_pages}
                        loader={ loadMoreLoading &&
                            <ReactSVG
                                key={0}
                                className="w-10 text-primary mx-auto block mt-10"
                                src={`${process.env.PUBLIC_URL}/assets/images/icons/loading-dot.svg`}
                            />
                        }
                    >

                        <ul className="grid md:grid-cols-2 grid-cols-1 gap-6">

                            { eventsDay[props.date].map((item, index) => 

                                <EventDateItem 
                                    key={item.id}
                                    type={item.type}
                                    title={item.title}
                                    body={item.description}
                                    time={item.starts_at}
                                    case={item.law_suit ? item.law_suit.title : ''}
                                    count={index + 1}
                                    id={`${item.id}`}
                                    date={props.date}
                                    item={item}
                                    selectedItemDelete={selectedItemDelete}
                                    onSetSelectedItemDelete={setSelectedItemDelete}
                                    isStarted={item?.is_started}
                                    creator={item.creator}
                                    myId={baseDataUserId}
                                    send_sms={item?.send_sms}
                                />

                            )}

                        </ul>

                    </InfiniteScroll>

                </div>

                :
                <p className="text-gray-600 text-center h-20 flex items-center justify-center  rounded-2xl shadow-custom-md border-[3px] border-white p-4"> هنوز رویدادی ثبت نشده است </p>
            }
        
        </>
    )

}

export default EventsDateItems