import { useEffect, useRef, useState } from "react"
import { useDispatch } from "react-redux"
import { ReactSVG } from "react-svg"
import { updateImageProfile } from "../../../redux/action/profile/profileAction"
import { useCookies } from "react-cookie"

const PicPicker = (props) => {

    const dispatch = useDispatch()

    const [image, setImage] = useState('')
    const [current, setCurrent] = useState('')

    const fileRef = useRef()

    const [cookies] = useCookies(['authToken'])

    useEffect(() => {

        if(props.image){
            setImage(props.image)
            setCurrent(props.image)
        }

    }, [props.image])

    useEffect(() => {

        if(props.onImageChanged){
            sendInfoOfUpdated()
        }

    }, [current])

    const updateImageField = () => {
        let pic = fileRef.current.files[0]

        // if get a pic come set
        if(pic){
            const imageUrl = URL.createObjectURL(pic)
            setCurrent(imageUrl)
        }

    }

    const removeCurrentImage = (event) => {
        event.preventDefault()
        
        setCurrent(image)
    }

    const emptyImage = (event) => {
        event.preventDefault()

        setCurrent('')
        setImage('')

        fileRef.current.value = ''
    }

    const turnImage = (event) => {
        event.preventDefault()

        setImage(props.image)
        setCurrent(props.image)
    }

    const sendInfoOfUpdated = () => {

        if(current === props.image || (!props.image && current === '') ){
            props.onImageChanged(null)
        }
        
        else {
            if(!fileRef.current.files[0]){
                props.onImageChanged(true)
                return
            }

            props.onImageChanged(fileRef.current.files[0])
        }

    }

    const saveImage = async () => {

        await dispatch( await updateImageProfile(fileRef.current.files[0] ? fileRef.current.files[0] : '', cookies.authToken) )

    }

    // ------  render -------

    const handleActionImage = () => {

        if( (current === image) && props.image && image === ''){
            return (
                <button onClick={turnImage} className="absolute bottom-0 left-0 w-8 h-8 translate-y-1/3 flex items-center justify-center -translate-x-1/3 bg-white border border-gray-300 shadow-lg rounded-md hover:text-primary duration-300">
                    <ReactSVG src="../assets/images/icons/turn-back.svg" className="w-6/12 rotate-180" />
                </button>
            )
        }

        else if(current === image){
            return
        }

        return (
            <button onClick={removeCurrentImage} className="absolute bottom-0 left-0 w-8 h-8 translate-y-1/3 flex items-center justify-center -translate-x-1/3 bg-white border border-gray-300 shadow-lg rounded-md hover:text-rose-500 duration-300">
                <ReactSVG src="../assets/images/icons/times.svg" className="w-8/12" />
            </button>
        )

    }

    const handleImage = () => {

        // if exist image
        if(current || image){
            return (
                <>

                    {/* maybe remove this section */}
                    { (image === current) && 
                        <button onClick={emptyImage} className="absolute bottom-0 right-0 w-8 h-8 translate-y-1/3 flex items-center justify-center translate-x-1/3 bg-white border border-rose-300 shadow-lg shadow-rose-200/10 rounded-md text-rose-500 hover:border-rose-400 hover:text-rose-600 hover:shadow hover:shadow-3xl duration-300">
                            <ReactSVG src="../assets/images/icons/delete.svg" className="w-3/5" />
                        </button>
                    }

                    <img className="object-cover w-full h-full rounded-xl shadow-custom-md" src={current || image} alt={props.label} /> 

                    <ReactSVG src="../assets/images/icons/camera.svg" className="w-1/5 absolute text-gray-600 bg-gray-300/50 opacity-90 shadow-custom-md rounded-md" />

                </>
            )
        }

        // if not exist image
        return (
            <>
                { props.loading ? <ReactSVG src="../assets/images/icons/loading-dot.svg" className={` w-12 text-gray-400 `} /> 
                : <ReactSVG src="../assets/images/icons/add-pic.svg" className="w-1/3" /> }
            </>
        )

    }

    return (
        <>
            <label htmlFor={props.id} className={`relative cursor-pointer flex flex-col gap-2.5 w-fit ${props.className}`}>

                { props.label && <span className={`text-gray-500 mb-2 ${props.className}`}> {props.label} : </span> }

                <div className={`relative flex items-center text-gray-500 justify-center bg-white border-4 border-gray-200 shadow-custom-md w-32 h-32 rounded-2xl`}>
                    { handleImage() }   
                    { handleActionImage() } 
                    
                    { (image != current || props.image && !current ) && 
                        <button onClick={saveImage} className="absolute top-0 right-0 w-8 h-8 -translate-y-1/3 flex items-center justify-center translate-x-1/3 bg-white border border-purple-300 shadow-lg shadow-purple-200/10 rounded-md text-purple-500 hover:border-purple-400 hover:text-purple-600 hover:shadow hover:shadow-3xl duration-300">
                            <ReactSVG src="../assets/images/icons/save-3.svg" className="w-3/5" />
                        </button>
                    }

                </div>
                
                <input 
                    id={props.id} 
                    ref={fileRef}
                    accept="image/png, image/jpeg, image/jpg" 
                    type="file" 
                    className="hidden" 
                    onChange={updateImageField}
                />        

            </label>
        </>
    )

}

export default PicPicker