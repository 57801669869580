import { ReactSVG } from "react-svg"

const WalletActionButtonAddBalanceModalTitle = ({props}) => {

    const hide = () => {
        props.onSetShowingPayModal(false)

        setTimeout(() => {
            props.onSetShowPayModal(false)
        }, 300);
        
        document.body.classList.remove('modal-opened')
    }

    return (
        <div className="sticky top-0 left-0 flex flex-col bg-white w-full z-[1] border-b border-b-slate-200">

            <div className="py-2 bg-white z-[1] mb-2">
                <div className="mx-auto w-20 h-2 bg-gray-200 rounded-2xl"></div>
            </div>

            <div className="flex items-center justify-between gap-4 mb-4">

                <div className="flex items-center gap-1.5">
                    
                    <ReactSVG src={`${process.env.PUBLIC_URL}/assets/images/icons/wallet-add.svg`} className="w-5 text-primary  duration-300  group-disabled:text-gray-400 group-disabled:opacity-70" />
                    
                    <span className="font-medium text-lg"> افزایش موجودی </span>

                </div>

                <button onClick={hide} type="button" className="w-10 group flex justify-end">
                    
                    <ReactSVG src={`${process.env.PUBLIC_URL}/assets/images/icons/times.svg`} className="w-6 h-6 text-gray-600 hover:text-primary duration-300 rotate-180 group-disabled:text-gray-400 group-disabled:opacity-70" />

                </button>

            </div>

        </div>
    )

}

export default WalletActionButtonAddBalanceModalTitle