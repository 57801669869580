import { useEffect, useState } from "react"
import { Link, useLocation, useNavigate, useParams } from "react-router-dom"
import { ReactSVG } from "react-svg"
import { deleteContact } from "../../../../../redux/action/contacts/contactsAction"
import { useCookies } from "react-cookie"
import { useDispatch } from "react-redux"

import DropdownModal from '../../../../UI/DropdownModal'

const ContactProfileHeaderActions = (props) => {

    const dispatch = useDispatch()

    const [dropdown, setDropdown] = useState(false)

    const location = useLocation()
    const params = useParams()
    const navigate = useNavigate()

    const [cookies] = useCookies(['authToken'])

    useEffect(() => {

        return () => {
            document.body.classList.remove('modal-opened')
        }

    }, [])

    const showMoreOption = () => {
        setDropdown(true)
    
        document.body.classList.add('modal-opened')
    }

    const doDelete = async () => {
        // close the dropdown
        setDropdown(false)
        document.body.classList.remove('modal-opened')

        // delete the contact
        const success = await dispatch( await deleteContact(params.id, cookies.authToken) )
        
        if(success){
            // go to contacts page
            navigate(location.key !== 'default' ? -1 : '/contacts', {replace: true})
        }
    }

    return (
        <div className="flex items-center justify-between flex-wrap gap-5 mb-4 relative">

            <Link to={location.key !== 'default' ? -1 : '/'} className="w-10 h-7 flex items-start select-none focus:outline-none outline-none">

                <ReactSVG 
                    src={`${process.env.PUBLIC_URL}/assets/images/icons/back.svg`}
                    className="w-5 text-white rotate-180"
                />

            </Link>

            <button onClick={showMoreOption} className="w-10 h-7 flex items-start justify-end select-none focus:outline-none outline-none">

                <ReactSVG 
                    src={`${process.env.PUBLIC_URL}/assets/images/icons/more.svg`}
                    className="w-5 text-white rotate-180"
                />

            </button>
        
            { dropdown && 
                
                <DropdownModal 
                    onSetShow={setDropdown}
                    title={props.name}
                >
                    
                    <Link to={`/edit-contact/${params.id}`} className="p-4 md:text-base flex items-center justify-between gap-4 flex-wrap border-b border-b-gray-200 last:border-b-0 w-full text-second duration-300 relative">
                        ویرایش

                        <ReactSVG 
                            src={`${process.env.PUBLIC_URL}/assets/images/icons/edit-2.svg`}
                            className="w-7 absolute left-[15px]"
                        />

                    </Link>

                    <button onClick={doDelete} className="p-4 md:text-base flex items-center justify-between gap-4 flex-wrap border-b border-b-gray-200 last:border-b-0 w-full hover:text-rose-600 text-rose-500 duration-300">
                        حذف

                        <ReactSVG 
                            src={`${process.env.PUBLIC_URL}/assets/images/icons/delete-2.svg`}
                            className="w-6"
                        />

                    </button>
                    
                </DropdownModal>

            }

        </div>
    )

}

export default ContactProfileHeaderActions