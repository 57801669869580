import { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { HIDE_SEARCH, SET_MESSAGES_SEARCH, SHOW_SEARCH } from "../../../redux/action/search/searchType"
import { SET_FILTER_MESSEAGES, SET_LOADING_MESSAGES, UNSET_LOADING_MESSAGES } from '../../../redux/action/messages/list/listType'

import SearchBox from "../../Header/Search"
import { searchInList } from "../../../utils/search"

const MessagesSearch = () => {

    const dispatch = useDispatch()
    const showSearch = useSelector(state => state.search.showSearch)
    const searchValue = useSelector(state => state.search.messagesSearch)

    const messages = useSelector(state => state.messages.list.messages)
    const filtered = useSelector(state => state.messages.list.filtered)

    useEffect(() => {

        if(!searchValue && showSearch){
            // close the search state
            hideSearch()
        }   
        else if(searchValue && !showSearch){
            dispatch({
                type: SHOW_SEARCH
            })
        }

    }, [])

    useEffect(() => {

        // dont send request to server when not in search state
        if(searchValue === '' || !showSearch ||  filtered.searchedValue === searchValue) return
        // if(messages.length <= 0) return
        
        // fake loading - for watching better user.
        dispatch({
            type: SET_LOADING_MESSAGES
        })

        const timeout = setTimeout(async () => {

            doSearch()

        }, 700)

        return () => {
            if(timeout)
                clearTimeout(timeout)
        }

    }, [searchValue])

    const updateSearchField = (event) => {

        if(event.target.value === ''){
            clearSearchValue()
            return
        }

        dispatch({
            type: SET_MESSAGES_SEARCH,
            payload: event.target.value
        })

    }

    const clearSearchValue = () => {
        
        dispatch({
            type: SET_MESSAGES_SEARCH,
            payload: ''
        })
        
        // if exist search-value come show fake loading
        if(searchValue){
            dispatch({
                type: SET_FILTER_MESSEAGES,
                payload: {
                    value: '',
                    list: []
                }
            })

            // set fake loading
            dispatch({
                type: SET_LOADING_MESSAGES
            })
    
            setTimeout(() => {
                
                dispatch({
                    type: UNSET_LOADING_MESSAGES
                })
    
            }, 250)
        }

    }

    const hideSearch = () => {
        
        // hide search
        dispatch({
            type: HIDE_SEARCH
        })

        clearSearchValue()

    }

    const doSearch = () => {
        const filtered = searchInList(messages, searchValue, ['name', 'mobile'])

        dispatch({
            type: SET_FILTER_MESSEAGES,
            payload: {
                value: searchValue,
                list: filtered
            }
        })        
    }

    return (
        <SearchBox 
            value={searchValue}
            onUpdate={ updateSearchField }
            onClear={clearSearchValue}
        />
    )

}

export default MessagesSearch