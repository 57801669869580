import { fromUnixTime } from "date-fns-jalali";
import moment from "jalali-moment";
import { ReactSVG } from "react-svg";

const CaseContentInfoDetail = (props) => {

    const getPersianDate = () => {
        if(!props.created_at) return '-'

        const jalaliDates = Date.parse(props.created_at) / 1000;
        const jalaliDate = fromUnixTime(jalaliDates);
        const date = moment(jalaliDate).utcOffset('-0330').format('jYYYY/jMM/jDD').split('/')

        const year = Number(date[0]).toLocaleString('fa').split('٬').join('')
        const month = Number(date[1])
        const day = Number(date[2])

        return `${year}/${month <= 9 ? '۰' + month.toLocaleString('fa') : month.toLocaleString('fa')}/${day <= 9 ? '۰' + day.toLocaleString('fa') : day.toLocaleString('fa')}`
    }

    return (
        <div className="md:grid md:grid-cols-2 flex flex-col justify-center gap-x-4 bg-white shadow-custom-md rounded-xl w-full relative">
    
            <div className="grid 5xm:grid-cols-2 gap-x-4 text-sm border-b border-b-gray-200 last:border-b-0 px-4 relative">

                <div className="5xm:pt-0 pt-4 flex items-center gap-2 text-gray-600">
          
                    <ReactSVG 
                        src={`${process.env.PUBLIC_URL}/assets/images/icons/ticket.svg`}
                        className="w-5 text-primary text-opacity-80"
                    />

                    <h4 className="text-second"> شماره پرونده </h4>

                </div>

                <span className="text-gray-600 font-medium py-4"> {props.code ? props.code : '-'} </span>

            </div>

            <div className="grid grid-cols-2 gap-4 text-sm border-b border-b-gray-200 last:border-b-0 px-4 relative">

                <div className="flex items-center gap-2 text-gray-600">
                    
                    <ReactSVG 
                        src={`${process.env.PUBLIC_URL}/assets/images/icons/archive_code.svg`}
                        className="w-5 text-primary text-opacity-80"
                    />
  
                    <h4 className="text-second"> شماره بایگانی </h4>

                </div>

                <span className="text-gray-600 font-medium py-4"> {props.archive_code ? props.archive_code : '-'} </span>

            </div>

            <div className="grid grid-cols-2 gap-4 text-sm border-b border-b-gray-200 last:border-b-0 px-4 relative">

                <div className="flex items-center gap-2 text-gray-600">
                    
                    <ReactSVG 
                        src={`${process.env.PUBLIC_URL}/assets/images/icons/folder.svg`}
                        className="w-5 text-primary text-opacity-80"
                    />

                    <h4 className="text-second"> نوع دعوی </h4>

                </div>

                <span className="text-gray-600 font-medium py-4"> { props?.category?.name } </span>

            </div>

            <div className="grid grid-cols-2 gap-4 text-sm border-b border-b-gray-200 last:border-b-0 px-4 relative">

                <div className="flex items-center gap-2 text-gray-600">
                    
                    <svg className="w-5 h-5 text-primary text-opacity-[0.85]" fill="currentColor" viewBox="0 0 20 20"><path d="M5.516 14.224c-2.262-2.432-2.222-6.244.128-8.611a6.074 6.074 0 0 1 3.414-1.736L8.989 1.8a8.112 8.112 0 0 0-4.797 2.351c-3.149 3.17-3.187 8.289-.123 11.531l-1.741 1.752 5.51.301-.015-5.834-2.307 2.323zm6.647-11.959l.015 5.834 2.307-2.322c2.262 2.434 2.222 6.246-.128 8.611a6.07 6.07 0 0 1-3.414 1.736l.069 2.076a8.122 8.122 0 0 0 4.798-2.35c3.148-3.172 3.186-8.291.122-11.531l1.741-1.754-5.51-.3z"></path></svg>

                    <h4 className="text-second"> مرحله رسیدگی </h4>

                </div>

                <span className="text-gray-600 font-medium py-4"> {props?.step} </span>

            </div>

            <div className="grid grid-cols-2 gap-4 text-sm border-b border-b-gray-200 last:border-b-0 px-4 relative">

                <div className="flex items-center gap-2 text-gray-600">
                    
                    <ReactSVG 
                        src={`${process.env.PUBLIC_URL}/assets/images/icons/court-2.svg`}
                        className="w-5 text-primary text-opacity-80"
                    />

                    <h4 className="text-second"> شعبه رسیدگی </h4>

                </div>

                <span className="text-gray-600 font-medium py-4 text-ellipsis overflow-hidden"> { props?.branch } </span>

            </div>

            <div className="grid grid-cols-2 gap-4 text-sm border-b border-b-gray-200 last:border-b-0 px-4 relative">

                <div className="flex items-center gap-2 text-gray-600">

                    <ReactSVG 
                        src={`${process.env.PUBLIC_URL}/assets/images/icons/location.svg`}
                        className="w-5 text-primary text-opacity-80"
                    />

                    <h4 className="text-second"> استان و شهر </h4>

                </div>

                <span className="text-gray-600 font-medium py-4 text-ellipsis overflow-hidden"> { props?.data?.city?.name } ، { props?.data?.province?.name } </span>

            </div>

            <div className={`grid grid-cols-2 gap-4 text-sm border-b border-b-gray-200 last:border-b-0 px-4 ${props?.agreed_fee && 'md:border-b-0 md:pb-0'}`}>

                <div className="flex items-center gap-2 text-gray-600">
                    
                    <ReactSVG 
                        src={`${process.env.PUBLIC_URL}/assets/images/icons/calculate.svg`}
                        className="w-5 text-primary text-opacity-80"
                    />

                    <h4 className="text-second"> تاریخ ایجاد </h4>

                </div>

                <span className="text-gray-600 font-medium py-4 text-ellipsis overflow-hidden"> { getPersianDate() } </span>

            </div>

            {props?.firstData?.agreed_fee && 
            
                <div className="grid grid-cols-2 gap-4 text-sm border-b border-b-gray-200 last:border-b-0 px-4 relative">

                    <div className="flex items-center gap-2 text-gray-600">
                        
                        <ReactSVG 
                            src={`${process.env.PUBLIC_URL}/assets/images/icons/money.svg`}
                            className="w-5 text-primary text-opacity-80"
                        />

                        <h4 className="text-second"> حق الوکاله </h4>

                    </div>

                    <span className="text-gray-600 font-medium py-4 text-ellipsis overflow-hidden"> {  props.agreed_fee ? Number(props.agreed_fee).toLocaleString('en') : ''  } </span>

                </div>

            }

        </div>
    )

}

export default CaseContentInfoDetail