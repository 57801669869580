import { useEffect, useState } from "react"
import ConfirmModal from "../../../../../../UI/ConfirmModal"
import { ReactSVG } from "react-svg"
import { useDispatch, useSelector } from "react-redux"
import { withdrawFromWallet } from "../../../../../../../redux/action/wallet/action"
import { useCookies } from "react-cookie"
import { SET_BALANCE_TOTAL_WALLET } from "../../../../../../../redux/action/wallet/type"
import { getProfileInfo } from "../../../../../../../redux/action/profile/profileAction"
import { useNavigate } from "react-router-dom"

const MessageForPaymentWallet = ({status, planId, planPrice, balance, showMessage, onSetShowMessage, onToggleActivePaymentItem}) => {

    const dispatch = useDispatch()
    const loading = useSelector(state => state.wallet.paymentLoading)

    const [cookies] = useCookies(['authToken'])

    const navigate = useNavigate()

    useEffect(() => {

        return () => {
            document.body.classList.remove('modal-opened')
        }

    }, [])

    const buyFromWallet = async () => {

        const data = await dispatch( await withdrawFromWallet(planPrice, cookies.authToken, 1, planId) )

        if(data){

            // update wallet data
            dispatch({
                type: SET_BALANCE_TOTAL_WALLET,
                payload: Number(balance) - Number(planPrice)
            })

            // update plan status data
            await dispatch( await getProfileInfo(cookies.authToken) )

            // hide message
            onSetShowMessage(false)
            document.body.classList.remove('modal-opened')
            
            onToggleActivePaymentItem(null)

            window.scrollTo({top: 0, behavior: 'smooth'})
        }


    }

    const goToWalletForIncrease = () => {
        // hide message
        onSetShowMessage(false)
        document.body.classList.remove('modal-opened')

        navigate('/wallet', {
            state: {
                increaseToWallet: true,
                price: Math.abs( Number(planPrice) - Number(balance) )
            }
        })
    }

    if(status){

        return (
            <ConfirmModal
                title="تاییدیه پرداخت"
                description="آیا از خرید خود مطمئن هستید؟"
                show={showMessage}
                setShow={onSetShowMessage}
                onConfirm={buyFromWallet}

                confirmLoading={loading}
            >  
                
                <div className="w-14 h-14 bg-white shadow-custom-lg flex items-center justify-center rounded-2xl text-green-500">
                    <ReactSVG src={`${process.env.PUBLIC_URL}/assets/images/icons/success-card.svg`} className="w-10 h-10" />
                </div>

            </ConfirmModal>
        )

    }

    else {

        return (
            <ConfirmModal
                title={`${Math.abs( Number(planPrice) - Number(balance) ).toLocaleString('fa')} تومان کمبود اعتبار دارید`} 
                description="آیا تمایل به شارژ کیف پول خود دارید؟"
                show={showMessage}
                setShow={onSetShowMessage}
                onConfirm={goToWalletForIncrease}

                buttonConfirmText={'شارژ کیف‌پول'}
            >  
                
                <div className="w-14 h-14 bg-white shadow-custom-lg flex items-center justify-center rounded-2xl text-red-500">
                    <ReactSVG src={`${process.env.PUBLIC_URL}/assets/images/icons/fail-card.svg`} className="w-10 h-10" />
                </div>

            </ConfirmModal>
        )

    }

}

export default MessageForPaymentWallet