import { RESTE_ALL_PLANS_DATA_APP, SET_ERROR_PLANS, SET_FREE_PLAN_ERROR, SET_FREE_PLAN_LOADING, SET_LOADING_PLANS, SET_PLANS_DESCRIPTION, SET_PLANS_ITEMS } from "../../action/plans/type"

const initialState = {
    items: [],
    loading: false,
    error: null,
    loaded: false,
    description: '',

    freePlanLoading: false,
    freePlanError: null
}

const plansReducer = (state = initialState, action) => {

    switch(action.type){
        
        // set base profile
        case SET_LOADING_PLANS :
            return {
                ...state,
                loading: action.payload,
                error: null
            }

        case SET_ERROR_PLANS :
            return {
                ...state,
                loading: false,
                error: action.payload,
            }

        case SET_PLANS_ITEMS :
            return {
                ...state,
                loading: false,
                error: false,
                items: action.payload,
                loaded: true
            }

        case SET_PLANS_DESCRIPTION :
            return {
                ...state,
                description: action.payload
            }

        case SET_FREE_PLAN_LOADING :
            return {
                ...state,
                freePlanLoading: action.payload,
                freePlanError: null
            }

        case SET_FREE_PLAN_ERROR :
            return {
                ...state,
                freePlanLoading: false,
                freePlanError: action.payload
            }



        case RESTE_ALL_PLANS_DATA_APP :
            return {
                ...state,
                ...initialState
            }

        default : 
            return state

    }

}

export default plansReducer