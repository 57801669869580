import { useEffect, useState } from "react"
import ContactMainForm from "./MainForm"
import ContactMoreInfoForm from "./MoreInfoForm"
import { useSelector } from "react-redux"

const ContactAddSection = (props) => {

    const [invalid, setInvalid] = useState({})
    const [mainComplete, setMainComplete] = useState(false)
    const updateLoading = useSelector(state => state.contacts.updateLoading)

    useEffect(() => {

        if(updateLoading)
            setMainComplete(false)

    }, [updateLoading])

    return (
        <section className="flex flex-col gap-8">
            
            <ContactMainForm 
                invalid={invalid}
                setInvalid={setInvalid}
                setMainComplete={setMainComplete}
            />

            <ContactMoreInfoForm 
                invalid={invalid}
                setInvalid={setInvalid}
                mainComplete={mainComplete}
            />

        </section>
    )

}

export default ContactAddSection