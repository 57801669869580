import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useOutletContext } from 'react-router-dom'

import { loginRequest } from '../../../redux/action/auth/authAction'

import LoginTitle from "../../../components/Auth/SubSection/Login/Title"
import LoginForm from "../../../components/Auth/SubSection/Login/Form"

import { removeAuthStep1, setAuthStep1 } from '../../../utils/auth'
import { removeIsNewUser, setIsNewUser } from '../../../utils/welcomeRegister'
import { useEffect } from 'react'
import { RESTE_ALL_PROFILE_DATA_APP } from '../../../redux/action/profile/profileType'
import { RESTE_ALL_NOTES_DATA_APP } from '../../../redux/action/notes/notesType'
import { RESTE_ALL_MESSAGES_LIST_DATA_APP } from '../../../redux/action/messages/list/listType'
import { RESTE_ALL_MESSAGES_CHAT_DATA_APP } from '../../../redux/action/messages/chat/chatType'
import { RESTE_ALL_HOME_DATA_APP } from '../../../redux/action/home/homeType'
import { RESTE_ALL_EVENTS_DATA_APP } from '../../../redux/action/events/eventsType'
import { RESTE_ALL_CASES_DATA_APP, RESTE_ALL_CONTACTS_DATA_APP } from '../../../redux/action/cases/casesType'
import { SET_MOBILE_AUTH, SET_TOKEN_AUTH } from '../../../redux/action/auth/authType'

const Login = () => {

    const dispatch = useDispatch()
    const phone = useSelector(state => state.auth.mobile)

    const navigate = useNavigate()
    const [ showSuccessMessage ] = useOutletContext()

    useEffect(() => {

        resetDataApp()

    }, [])

    const resetDataApp = () => {

        // remove info from localStorage
        localStorage.removeItem('base_data')
        localStorage.removeItem('eventsCalenarState')
        localStorage.removeItem('last_load_messages')

        // reset data...
        dispatch({
            type: RESTE_ALL_PROFILE_DATA_APP
        })

        dispatch({
            type: RESTE_ALL_NOTES_DATA_APP
        })

        dispatch({
            type: RESTE_ALL_MESSAGES_LIST_DATA_APP
        })

        dispatch({
            type: RESTE_ALL_MESSAGES_CHAT_DATA_APP
        })

        dispatch({
            type: RESTE_ALL_HOME_DATA_APP
        })

        dispatch({
            type: RESTE_ALL_EVENTS_DATA_APP
        })

        dispatch({
            type: RESTE_ALL_CONTACTS_DATA_APP
        })


        dispatch({
            type: RESTE_ALL_CASES_DATA_APP
        })
        
    }

    const doLogin = async (mobile, existLocalStorage, finishTime) => {

        // if phone not change come go to verify page
        if(phone && mobile === phone && existLocalStorage){

            if(finishTime - new Date() >= 0){
                navigate('/auth/verify')
                return
            }
        }

        // make data for sending to server
        const data = {
            mobile,
            app_type: 'client'
        }

        // send request to api for login
        const response = await dispatch(await loginRequest(data) )

        // go to next step - verify
        if(response?.success){
            const date = new Date()
            const time = date.getTime()
            
            removeAuthStep1()
            
            // set time in localStorage
            setAuthStep1({
                mobile,
                time
            })

            dispatch({
                type: SET_MOBILE_AUTH,
                payload: mobile
            })

            dispatch({
                type: SET_TOKEN_AUTH,
                payload: ''
            })

            // check is-new-user
            if(response.isNewUser){
                setIsNewUser(true)
            }
            else {
                removeIsNewUser()
            }

            // show success message
            showSuccessMessage()

            navigate('/auth/verify', {state: mobile})
        }

    }

    // --- render the app --
    return (
        <>  

            <LoginTitle />

            <LoginForm 
                onSubmit={doLogin}
            />

        </>
    )

}

export default Login