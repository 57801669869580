import { ReactSVG } from "react-svg"

import Button from "../../../UI/Button"
import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useCookies } from "react-cookie"
import { deleteChatMessages } from "../../../../redux/action/messages/list/listAction"
import ConfirmModalDelete from "../../../UI/ConfirmModal/Delete"
import { DELETE_ITEM_LOADEDCHATLIST } from "../../../../redux/action/messages/chat/chatType"

const DeleteHeader = (props) => {

    const dispatch = useDispatch()
    const deleteLoading = useSelector(state => state.messages.list.deleteLoading)
    const messages = useSelector(state => state.messages.list.messages)
    const loadedChatList = useSelector(state => state.messages.chat.loadedChatList)

    const [userInfo, setUserInfo] = useState({})
    const [showDeleteModal, setShowDeleteModal] = useState(false)
    const [deleteBoth, setDeleteBoth] = useState(true)

    const [cookies] = useCookies(['authToken'])

    useEffect(() => {

        getUserInfo()

    }, [props.selectedItemId])

    const getUserInfo = () => {
        const info = messages.filter(item => item.id == props.selectedItemId)
        
        if(info.length >= 1){
            setUserInfo({
                name: info[0].name
            })
        }
    }

    const destroyDeleteMode = () => {
        props.onSetSelectedItemDelete(null)
    }

    const doDelete = async () => {
        setShowDeleteModal(false)

        document.body.classList.remove('modal-opened')

        if(deleteLoading) return

        const scope = deleteBoth ? 'both' : 'self'

        const data = await dispatch( await deleteChatMessages(props.selectedItemId, scope, cookies.authToken) )
        
        if(data){
            destroyDeleteMode()

            // if exist into the loadedChat come delete
            if(loadedChatList[props.selectedItemId]){
                
                dispatch({
                    type: DELETE_ITEM_LOADEDCHATLIST,
                    payload: props.selectedItemId
                })

            }

        }
    }

    const handleShowDeleteMessage = (event) => {
        event.stopPropagation()

        setShowDeleteModal(true)
    }

    return (
        <>
            <header className="fixed lg:pt-4 bg-custom-gray py-3 top-0 left-0 lg:w-3/4 lg:px-4 px-3 w-full z-[2]">

                <div className="bg-white border border-gray-200 flex items-center flex-wrap justify-between rounded-2xl shadow-custom-lg p-4">

                    <button onClick={destroyDeleteMode} className="text-gray-600 h-full">
                        <ReactSVG 
                            className="w-8 h-8"
                            src={`${process.env.PUBLIC_URL}/assets/images/icons/times.svg`}
                        />
                    </button>

                    <div className="flex items-center gap-4">

                        <Button  
                            className={'profile-btn bg-rose-600 py-1 flex gap-1 pr-3 pl-4'}
                            onClick={handleShowDeleteMessage}
                        >
                            <ReactSVG
                                src={`${process.env.PUBLIC_URL}/assets/images/icons/delete.svg`}
                                className="w-4"
                            />
                            حذف     
                        </Button>

                    </div>

                </div>

            </header>

            { showDeleteModal &&
                    
                <ConfirmModalDelete
                    title="حذف گفتگو"
                    description="آیا از حذف این گفتگو اطمینان دارید؟"
                    show={showDeleteModal}
                    onSetShow={setShowDeleteModal}
                    onConfirm={doDelete}
                    selectCount={1}
                >   

                    <div className="flex justify-center text-gray-600">
                    
                        <input type="checkbox" id="deleteBothInput" className="accent-primary rounded-lg w-5 cursor-pointer" onChange={() => setDeleteBoth(!deleteBoth) } checked={deleteBoth} />
                    
                        <label htmlFor="deleteBothInput" className="select-none cursor-pointer pr-2.5">
                            
                            حذف همچنین برای {userInfo.name} 

                        </label>

                    </div>

                </ConfirmModalDelete>
    
            }

        </>
    )

}

export default DeleteHeader