import { useDispatch, useSelector } from "react-redux"
import { useEffect, useRef, useState } from "react"
import { useCookies } from "react-cookie"
import { ReactSVG } from "react-svg"

import { getLocation } from "../../../redux/action/location/locationAction"
import { SET_CHOOSE_CITY, SET_CHOOSE_PROVINCE } from "../../../redux/action/location/locationType"

import ModalBottom from "../ModalBottom"
import SelectCitySearch from "../../ProfileSection/SelectCity/Search"
import SelectCityList from "../../ProfileSection/SelectCity/List"

const SelectCityUser = (props) => {

    const [showCity, setShowCity] = useState(false)
    const [showingCity, setShowingCity] = useState(false)

    const modalRef = useRef()

    const dispatch = useDispatch()
    const list = useSelector(state => state.location.list)
    const chooseProvince = useSelector(state => state.location.chooseProvince)
    const chooseCity = useSelector(state => state.location.chooseCity)
    const loading = useSelector(state => state.location.loading)
    
    const [cookies] = useCookies(['authToken'])

    useEffect(() => {

        if(list.length <= 0){
            getLocationData()
        }

        return () => {
            if(document.body.classList.contains('modal-opened'))
                document.body.classList.remove('modal-opened')
            
            dispatch({
                type: SET_CHOOSE_PROVINCE,
                payload: {id: ''}
            })
            dispatch({
                type: SET_CHOOSE_CITY,
                payload: {id: ''}
            })
            
        }

    }, [])

    useEffect(() => {

        // when choosed-province
        scrollToTopWhenProvinceSelected()

    }, [chooseProvince])

    useEffect(() => {

        closeModal()

    }, [chooseCity])

    const getLocationData = async () => {
        await dispatch( await getLocation(cookies.authToken) )
    }

    const scrollToTopWhenProvinceSelected = () => {
        
        if(chooseProvince?.id){
            modalRef.current.scrollTo({top: 0})
        }

    }

    const closeModal = () => {
        
        if(chooseCity?.name){
            // come hide modal
            setShowingCity(false)

            setTimeout(() => {
                setShowCity(false)
            }, 300)

            document.body.classList.remove('modal-opened')
        }

    }

    const openModal = () => {
        setShowCity(true)

        document.body.classList.add('modal-opened')
    }

    const backAction = () => {
        
        if(chooseProvince?.name){
            // delete province
            dispatch({
                type: SET_CHOOSE_PROVINCE,
                payload: {id: ''}
            })
        }

    }

    const cancleDataAndHideModal = () => {
        setShowingCity(false)
    
        setTimeout(() => {

            // come reset location data :))
            props.onCancleChoose()
    
            // come hide modal
            setShowCity(false)
            
        }, 300)

        document.body.classList.remove('modal-opened')
    }

    return (
        <>
            <div className="flex flex-col gap-2.5">

                <span className={`text-gray-500 ${props.labelSize === 'sm' && 'text-sm'}`}> انتخاب شهر : </span>

                <button onClick={ openModal } type="button" className="flex items-center justify-between gap-4 flex-wrap bg-white p-3 text-right text-gray-800 rounded-xl shadow-custom-md">

                    <div className="flex items-center gap-3">
                    
                        <ReactSVG src={`${process.env.PUBLIC_URL}/assets/images/icons/location.svg`} className={`w-5 text-primary ${(loading) && '!text-gray-400' }`} />

                        { !loading || !props.loadingMain ? props.location?.city?.name ? props.location?.city?.name : props?.data?.city?.name ? props?.data?.city?.name : <span className="text-gray-500">انتخاب نشده</span>
                            :
                            <ReactSVG src={`${process.env.PUBLIC_URL}/assets/images/icons/loading-dot.svg`} className="w-10 py-[7px] text-gray-400" /> 
                        }
                    
                    </div>

                    <div>
                        <ReactSVG src={`${process.env.PUBLIC_URL}/assets/images/icons/down.svg`} className="w-5" />
                    </div>

                </button>

            </div> 

            { showCity &&
                <ModalBottom
                    show={showCity}
                    onSetShow={setShowCity}
                    className="overflow-y-auto no-scrollbar"
                    refEl={modalRef}
                    showing={showingCity}
                    onSetShowing={setShowingCity}
                >
                    
                    <div className="sticky top-0 left-0 flex flex-col pb-4 bg-white w-full z-[1] border-b border-b-slate-200">

                        <div className="py-2 bg-white z-[1] mb-2">
                            <div className="mx-auto w-20 h-2 bg-gray-200 rounded-2xl"></div>
                        </div>

                        {/* back action --> btn */}
                        <div className="flex items-center justify-between gap-4 mb-4">

                            <button onClick={backAction} type="button" className="w-10 group" disabled={!chooseProvince?.name}>
                                
                                <ReactSVG src={`${process.env.PUBLIC_URL}/assets/images/icons/back.svg`} className="w-5 text-gray-600 hover:text-primary duration-300 rotate-180 mr-1.5 group-disabled:text-gray-400 group-disabled:opacity-70" />

                            </button>

                            <div className="flex items-center gap-0.5">

                                <span className="font-medium flex items-center gap-1.5">
                                    <ReactSVG src={`${process.env.PUBLIC_URL}/assets/images/icons/location.svg`} className="w-4 text-gray-400" />
                                    { !loading && props.location?.city?.name ? props.location?.city?.name : props?.data?.city?.name ? props?.data?.city?.name : 'انتخاب نشده' }
                                </span>

                                { props.onCancleChoose && (props.location?.city?.name ||  props?.data?.city?.name) && 
                                    <button onClick={cancleDataAndHideModal} type="button" className="h-full focus:outline-none outline-none w-8 flex justify-end">
                                        <ReactSVG 
                                            src={`${process.env.PUBLIC_URL}/assets/images/icons/delete.svg`}
                                            className="w-5 text-rose-600"
                                        />
                                    </button>
                                }

                            </div>

                        </div>

                        <SelectCitySearch />

                    </div>
                    
                    <SelectCityList 
                        onUpdateLocation={props.onUpdateLocation}
                    />

                </ModalBottom>
            }

        </>
    )

}

export default SelectCityUser