export const SET_LOADING_CONTACTS = 'SET_LOADING_CONTACTS'
export const UNSET_LOADING_CONTACTS = 'UNSET_LOADING_CONTACTS'
export const SET_FAILURE_CONTACTS = 'SET_FAILURE_CONTACTS'

export const SET_CONTACTS = 'SET_CONTACTS'
export const SET_FILTER_CONTACTS = 'SET_FILTER_CONTACTS'

export const SET_DROPDOWN_CONTACTS = 'SET_DROPDOWN_CONTACTS'
export const UNSET_DROPDOWN_CONTACTS = 'UNSET_DROPDOWN_CONTACTS'

export const SET_UPDATE_LOADING_CONTACTS = 'SET_UPDATE_LOADING_CONTACTS'
export const UNSET_UPDATE_LOADING_CONTACTS = 'UNSET_UPDATE_LOADING_CONTACTS'
export const SET_UPDATE_FAILURE_CONTACTS = 'SET_UPDATE_FAILURE_CONTACTS'

export const DELETE_USER_FROM_LIST = 'DELETE_USER_FROM_LIST'

export const SET_LOCATION_FOR_CONTACT = 'SET_LOCATION_FOR_CONTACT'

export const RESET_CONTACT_DATA = 'RESET_CONTACT_DATA'
export const RESET_LOCATION_CONTACTS = 'RESET_LOCATION_CONTACTS'

export const SET_POSITION_CONTACT = 'SET_POSITION_CONTACT'

export const SET_MAIN_FIELDS_CONTACT = 'SET_MAIN_FIELDS_CONTACT'
export const SET_MORE_INFO_FIELDS_CONTACT = 'SET_MORE_INFO_FIELDS_CONTACT'

export const SET_ENABLE_SUBMIT_BUTTON = 'SET_ENABLE_SUBMIT_BUTTON'

export const SET_CONTACT_INFO = 'SET_CONTACT_INFO'
export const RESET_CONTACT_INFO = 'RESET_CONTACT_INFO'
export const REPLACE_CONTACT_INFO = 'REPLACE_CONTACT_INFO'

export const ADD_TO_CONTACTS = 'ADD_TO_CONTACTS'
export const RESET_FIELDS_DATA = 'RESET_FIELDS_DATA'

export const SET_CONTACT_INFO_IN_UPDATE = 'SET_CONTACT_INFO_IN_UPDATE'
export const SET_ITEM_CONTACT_INFO_IN_UPDATE = 'SET_ITEM_CONTACT_INFO_IN_UPDATE'

export const SET_PROFILES_LIST = 'SET_PROFILES_LIST'
export const ADD_ITEM_TO_PROFILES_LIST = 'ADD_ITEM_TO_PROFILES_LIST'
export const EDIT_ITEM_FROM_PROFILE_LIST = 'EDIT_ITEM_FROM_PROFILE_LIST'

export const DELETE_CASE_FROM_CONTACTS = 'DELETE_CASE_FROM_CONTACTS'
export const DELETE_CASE_FROM_CONTACT_INFO_CONTACTS = 'DELETE_CASE_FROM_CONTACT_INFO_CONTACTS'
export const ADD_LAW_SUITS_FROM_PROFILE_LIST_IN_CONTACTS = 'ADD_LAW_SUITS_FROM_PROFILE_LIST_IN_CONTACTS'
export const ADD_LAW_SUITS_FROM_CONTACTS_INFO_IN_CONTACTS = 'ADD_LAW_SUITS_FROM_CONTACTS_INFO_IN_CONTACTS'
export const UPDATE_LAW_SUITS_FROM_PROFILE_LIST_IN_CONTACTS = 'UPDATE_LAW_SUITS_FROM_PROFILE_LIST_IN_CONTACTS'
export const UPDATE_LAW_SUITS_FROM_CONTACTS_INFO_IN_CONTACTS = 'UPDATE_LAW_SUITS_FROM_CONTACTS_INFO_IN_CONTACTS'