import { useSelector } from "react-redux"
import CaseContentInfo from "./Info"

import { ReactSVG } from "react-svg"
import CaseContentNotes from "./Notes"
import CaseContentEvents from "./Events"
import CaseContentFiles from "./Files"
import CaseFinancials from "./Financials"

const CaseContent = ({data, activeTab, ...props}) => {

    const singleCaseError = useSelector(state => state.cases.singleCaseError)
    const singleCaseLoading = useSelector(state => state.cases.singleCaseLoading)

    if(singleCaseLoading){

        return (
            <section className={`flex items-center justify-center h-20 ${props.spaceTop && 'mt-36'}`}>
                <ReactSVG 
                    src={`${process.env.PUBLIC_URL}/assets/images/icons/loading-dot.svg`}
                    className="w-10 text-second"
                />
            </section>
        )

    }

    else if(singleCaseError){
        return (
            <section className={`flex items-center gap-2  ${props.spaceTop && 'mt-36'}`}>

                <ReactSVG src={`${process.env.PUBLIC_URL}/assets/images/icons/info-box.svg`} className="w-6 text-rose-500" />

                <p className="text-gray-700 leading-7"> {singleCaseError} </p>

            </section>
        )
    }

    return (
        <div className={`${props.spaceTop && 'mt-40'}`}>
            
            { activeTab === 0 ?
                <CaseContentInfo 
                    data={data} onSetData={props.onSetData}
                    firstData={props.firstData}
                    changes={props.changes} onSetChanges={ props.onSetChanges }  
                />
                :
                activeTab === 1 ?
                    <CaseContentFiles files={data?.files} />
                :
                activeTab === 2 ?
                    <CaseContentNotes 
                        notes={data?.notes} 
                        title={data?.title}
                    />
                :
                activeTab === 3 ?
                    <CaseContentEvents  
                        events={data?.events}
                        title={data?.title}
                        sortedEvents={data?.sorted}
                    />
                : activeTab === 4 &&
                    <CaseFinancials 
                        financials={data?.financials}
                        financials_total_expense={data?.financials_total_expense}
                        financials_total_income={data?.financials_total_income}
                        money={data?.agreed_fee}
                        
                        firstData={ props.firstData }
                        onSetFirstData={  props.onSetFirstData }
                        
                        changes={ props.changes}
                        onSetChanges={  props.onSetChanges }
                        
                        data={data}
                        onSetData={  props.onSetData }
                    />
                
            }   

        </div>
    )

}

export default CaseContent