const CalendarTitleDays = (props) => {

    return (
        <div className={`flex items-center justify-center 5xm:text-base text-sm ${props.dayOff ? 'text-rose-500' : ''}`}>
                        
            <span className="sm:block hidden"> { props.longName } </span>
            <span className="sm:hidden block"> { props.smallName } </span>

        </div>
    )

}

export default CalendarTitleDays