import axios from 'axios'
import { apiKey } from '../../store'
import { ADD_ITEMS_TO_WALLET_HISTORY, SET_ERROR_WALLET, SET_LOADING_WALLET, SET_PAGINATION_WALLET, SET_PAYMENT_LOADING_WALLET, SET_WALLET_DATA } from './type'

const apiKeyWallet = apiKey + 'wallet'

export const getWalletInfo = async (authToken) => {

    return async (dispatch) => {
        
        dispatch({
            type: SET_LOADING_WALLET,
            payload: true
        })
    
        const config = {
            headers: {
                'Auth-Token': authToken,
                'X-Auth-Token': authToken,
                'header_app_type': 'client'
            },
            timeout: 15000
        }

        const data = await axios.get(`${apiKeyWallet}`, config)
        .catch(error => {
            
            dispatch({
                type: SET_ERROR_WALLET,
                payload: 'عدم برقراری ارتباط با سرور'
            })

            setTimeout(() => {

                dispatch({
                    type: SET_ERROR_WALLET,
                    payload: null
                })

            }, 1500)

        })

        if(data){

            if(data.data.success){

                dispatch({
                    type: SET_WALLET_DATA,
                    payload: data.data.data
                })
                
                dispatch({
                    type: SET_PAGINATION_WALLET,
                    payload: data.data.pagination
                })

                return data.data.data

            }

            else if(!data.data.success && data.data.errors){

                dispatch({
                    type: SET_ERROR_WALLET,
                    payload: data.data.errors[0]?.message
                })

                setTimeout(() => {

                    dispatch({
                        type: SET_ERROR_WALLET,
                        payload: null
                    })
    
                }, 1500)

            }

        }

    }

}

export const loadMoreWalletHistory = async (page, authToken) => {

    return async (dispatch) => {

        const config = {
            headers: {
                'Auth-Token': authToken,
                'X-Auth-Token': authToken,
                'header_app_type': 'client',
            },
            timeout: 15000
        }

        const data = await axios.get(`${apiKeyWallet}?page=${page}`, config)
        .catch(error => {
            
            dispatch({
                type: SET_ERROR_WALLET,
                payload: 'عدم برقراری ارتباط با سرور'
            })

            setTimeout(() => {

                dispatch({
                    type: SET_ERROR_WALLET,
                    payload: null
                })

            }, 1500)
            
            return true

        })

        if(data){

            if(data.data.success){

                dispatch({
                    type: ADD_ITEMS_TO_WALLET_HISTORY,
                    payload: data.data.data.history
                })
                
                dispatch({
                    type: SET_PAGINATION_WALLET,
                    payload: data.data.pagination
                })

                return true

            }

            else if(!data.data.success && data.data.errors){

                dispatch({
                    type: SET_ERROR_WALLET,
                    payload: data.data.errors[0]?.message
                })

                setTimeout(() => {

                    dispatch({
                        type: SET_ERROR_WALLET,
                        payload: null
                    })
    
                }, 1500)

                return true

            }

        }

    }

}

// -------------------------

export const paymentToWallet = async (amount, authToken, type = 0, planId = 0) => {

    return async (dispatch) => {
        
        dispatch({
            type: SET_PAYMENT_LOADING_WALLET,
            payload: true
        })
    
        const config = {
            headers: {
                'Auth-Token': authToken,
                'X-Auth-Token': authToken,
                'header_app_type': 'client',
            },
            timeout: 15000
        }

        const data = await axios.post(`${apiKeyWallet}/deposit?amount=${amount}&type=${type}&plan_id=${planId}&user_type=lawyer`, null, config)
        .catch(error => {
            
            dispatch({
                type: SET_ERROR_WALLET,
                payload: 'عدم برقراری ارتباط با سرور'
            })

            setTimeout(() => {

                dispatch({
                    type: SET_ERROR_WALLET,
                    payload: null
                })

            }, 1500)

            return false

        })

        if(data){

            if(data.data.success){

                dispatch({
                    type: SET_PAYMENT_LOADING_WALLET,
                    payload: false
                })

                return data.data.data

            }

            else if(!data.data.success && data.data.errors){

                dispatch({
                    type: SET_ERROR_WALLET,
                    payload: data.data.errors[0]?.message
                })

                setTimeout(() => {

                    dispatch({
                        type: SET_ERROR_WALLET,
                        payload: null
                    })
    
                }, 1500)

                return false

            }

        }

    }

}

export const withdrawFromWallet = async (amount, authToken, type = 0, planId = 0) => {

    return async (dispatch) => {
        
        dispatch({
            type: SET_PAYMENT_LOADING_WALLET,
            payload: true
        })
    
        const config = {
            headers: {
                'Auth-Token': authToken,
                'X-Auth-Token': authToken,
                'header_app_type': 'client'
            },
            timeout: 15000
        }

        const data = await axios.post(`${apiKeyWallet}/withdraw?amount=${amount}&type=${type}&plan_id=${planId}&user_type=lawyer`, null, config)
        .catch(error => {
            
            dispatch({
                type: SET_ERROR_WALLET,
                payload: 'عدم برقراری ارتباط با سرور'
            })

            setTimeout(() => {

                dispatch({
                    type: SET_ERROR_WALLET,
                    payload: null
                })

            }, 1500)

            return false

        })

        if(data){

            if(data.data.success){

                dispatch({
                    type: SET_PAYMENT_LOADING_WALLET,
                    payload: false
                })

                return true

            }

            else if(!data.data.success && data.data.errors){

                dispatch({
                    type: SET_ERROR_WALLET,
                    payload: data.data.errors[0]?.message
                })

                setTimeout(() => {

                    dispatch({
                        type: SET_ERROR_WALLET,
                        payload: null
                    })
    
                }, 1500)

                return false

            }

        }

    }

}