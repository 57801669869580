import { useSelector } from "react-redux"
import HomeStatusItem from "./Item"

const HomeStatus = ({items}) => {

    const loading = useSelector(state => state.home.loading)

    return (
        <section className="mb-10">

            <div className="bg-white py-4 4xm:px-6 px-4 shadow-custom-md rounded-2xl">

                <h2 className="md:text-lg font-medium mb-6"> آمار کلی سامانه </h2>

                <div className="flex items-center md:justify-between justify-around 5xm:gap-4 gap-1 sm:px-10 mb-3 md:px-12">

                    <HomeStatusItem 
                        loading={loading}
                        icon={'cases'}
                        title={'پرونده‌ها'}
                        link={'law-suits'}
                        count={items?.law_suits}
                    />
                    
                    <HomeStatusItem 
                        loading={loading}
                        icon={'events'}
                        title={'رویداد‌ها'}
                        link={'events'}
                        count={items?.events}
                    />
                    
                    <HomeStatusItem 
                        loading={loading}
                        icon={'notes'}
                        title={'یادداشت‌ها'}
                        link={'notes'}
                        count={items?.notes}
                    />

                    <HomeStatusItem 
                        loading={loading}
                        icon={'messages'}
                        title={'پیام‌ها'}
                        link={'messages'}
                        count={items?.conversations}
                    />

                </div>

            </div>

        </section>
    )

}

export default HomeStatus