import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { SET_NOTE_INFO, SET_NOTE_INFO_BODY, SET_NOTE_INFO_LAW_SUIT, SET_NOTE_INFO_SUBJECT, SET_NOTE_INFO_TITLE } from "../../../redux/action/notes/notesType"

import NoteAddFooterColor from "../Add/Footer"
import { useLocation, useNavigate, useParams } from "react-router-dom"
import { getNoteFromSearchId } from "../../../redux/action/notes/notesAction"
import { useCookies } from "react-cookie"
import { ReactSVG } from "react-svg"
import NoteAddFormCases from "../Add/Cases"
import NoteAddFormSubject from "../Add/Subject"

const NoteEditForm = (props) => {

    const dispatch = useDispatch()
    const noteInfo = useSelector(state => state.notes.noteInfo)
    const loading = useSelector(state => state.notes.loading)

    const [showCases, setShowCases] = useState(false)
    const [showSubject, setShowSubject] = useState(false)

    const location = useLocation()
    const navigate = useNavigate()
    const params = useParams()

    const [cookies] = useCookies(['authToken'])

    useEffect(() => {

        if(location.state?.id){

            const info =  {
                id: location.state.id,
                body: location.state.body,
                created_at: location.state.time,
                law_suit: location.state.law_suit,
                color: location.state.color,
                subject: location.state.subject
            }
  
            dispatch({
                type: SET_NOTE_INFO,
                payload: info
            })

            props.onSetFirstData(info)
        }
        else if(noteInfo.id == params?.id){
            props.onSetFirstData(noteInfo)
        }
        else {
            getNoteFromApi()
        }

    }, [])

    const getNoteFromApi = async () => {
        const data = await dispatch( await getNoteFromSearchId(params.id, cookies.authToken))

        if(data)
            props.onSetFirstData(data)

        else
            navigate('/notes', {replace: true})
    }

    const updateBody = (event) => {
        
        if(event.target.value.length > 2000 ) return

        dispatch({
            type: SET_NOTE_INFO_BODY,
            payload: event.target.value
        })

    }

    const showCasesList = () => {

        setShowCases(true)

        document.body.classList.add('modal-opened')

    }

    const showSubjectList = () => {

        setShowSubject(true)

        document.body.classList.add('modal-opened')

    }

    const updateCaseInfoField = (id, title) => {
        dispatch({
            type: SET_NOTE_INFO_LAW_SUIT,
            payload: {
                id,
                title
            }
        })
    }

    const updateSubjectField = (subject) => {
        dispatch({
            type: SET_NOTE_INFO_SUBJECT,
            payload: subject
        })
    }
    
    // ---- Render ----

    const selectColor = () => {
        if(noteInfo.color === 1)
            return 'bg-pink-200'

        else if(noteInfo.color === 2)
            return 'bg-blue-200'
        
        else if(noteInfo.color === 3)
            return 'bg-orange-200'

        else if(noteInfo.color === 4)
            return 'bg-green-200'
        
        else if(noteInfo.color === 5)  
            return 'bg-purple-200'

        else 
            return 'bg-pink-200'
    }

    return (
        <>
            <div className={`w-full 2xm:min-h-[calc(100vh-90px-56px-20px)] mb-16 ${!loading && selectColor()} rounded-xl relative`}>

                { !loading ? 
                    <form>
                        
                        <div className="pt-0 p-4">
                            
                            <button onClick={showCasesList} type="button" className={`w-full cursor-pointer pt-4 text-xs-12px font-medium flex items-center gap-1.5 border-b border-b-gray-700 pb-2 ${noteInfo?.law_suit?.title ? '' : 'text-gray-500'}`}>
                    
                                <ReactSVG
                                    src={`${process.env.PUBLIC_URL}/assets/images/icons/cases.svg`}
                                    className="w-3.5 h-3.5 mb-0.5"
                                />

                                { noteInfo?.law_suit?.title ? noteInfo?.law_suit?.title : 'انتخاب پرونده'  }
                                
                            </button>
                            
                        </div>

                        <div className="px-4 pb-3 ">
                            
                            <button onClick={showSubjectList} type="button" className={`bg-white flex items-center gap-2 rounded-md ${noteInfo?.subject ? 'pl-3 pr-2' : 'px-2'} py-1.5 text-sm`}>
                                <ReactSVG
                                    src={`${process.env.PUBLIC_URL}/assets/images/icons/file.svg`}
                                    className="w-4 h-4 mb-0.5"
                                />
                                { noteInfo?.subject ? noteInfo.subject :
                                    
                                    <div className="flex items-center gap-2">
                                        دسته‌بندی
                                        <ReactSVG
                                            src={`${process.env.PUBLIC_URL}/assets/images/icons/add.svg`}
                                            className="w-4 h-4"
                                        />

                                    </div>
                                }
                            </button>

                        </div>

                        <textarea 
                            className={`w-full no-scrollbar h-screen 2xm:max-h-[calc(100vh-90px-56px-20px-70px-46px)] max-h-[calc(100vh-90px-56px-20px-70px-80px)] bg-transparent resize-none outline-none focus:outline-none border-0 px-4 placeholder-gray-400 leading-8 text-gray-800`}
                            placeholder="تایپ کردن را آغاز کنید..."
                            autoFocus
                            onChange={updateBody}
                            value={noteInfo.body}
                            spellCheck={false}
                            maxLength={'2000'}
                        >

                        </textarea>

                    </form>
                    :
                    <div className="flex items-center justify-center h-20">
                            
                        <ReactSVG 
                            src={`${process.env.PUBLIC_URL}/assets/images/icons/loading-dot.svg`}
                            className="w-10 text-second"
                        />

                    </div>
                }

                { showCases && 
                    <NoteAddFormCases 
                        showCases={showCases}
                        onSetShowCases={setShowCases}

                        caseInfo={noteInfo.law_suit}
                        onUpdateCaseInfoField={updateCaseInfoField}                        
                    />
                }

                { showSubject && 
                    <NoteAddFormSubject
                        showSubject={showSubject}
                        onSetShowSubject={setShowSubject}

                        subject={noteInfo.subject}
                        onUpdateSubjectField={updateSubjectField}                        
                    />
                }

            </div>

            <NoteAddFooterColor 
                color={noteInfo.color}
            />

        </>
    )

}

export default NoteEditForm