export const SET_REQUEST_PROFILE = 'SET_REQUEST_PROFILE'
export const SET_PROFILE_PROFILE = 'SET_PROFILE_PROFILE'
export const SET_FAILURE_PROFILE = 'SET_FAILURE_PROFILE'
export const SET_UPDATE_IMAGE_PROFILE = 'SET_UPDATE_IMAGE_PROFILE'
export const SET_UPDATE_TEXT_PROFILE = 'SET_UPDATE_TEXT_PROFILE'
export const UPDATE_FIELD_INFO_PROFILE = 'UPDATE_FIELD_INFO_PROFILE'
export const SET_REQUEST_UPDATING_PROFILE = 'SET_REQUEST_UPDATING_PROFILE'
export const ADD_FIELDS_PROFILE = 'ADD_FIELDS_PROFILE'
export const DELETE_FIELDS_PROFILE = 'DELETE_FIELDS_PROFILE'
export const SET_USER_LOCATION = 'SET_USER_LOCATION'
export const SET_IMAGE_PROFILE = 'SET_IMAGE_PROFILE'
export const UNSET_REQUEST_UPDATING_PROFILE = 'UNSET_REQUEST_UPDATING_PROFILE'

export const SET_USED_GIFT_IN_PROFILE = 'SET_USED_GIFT_IN_PROFILE'
export const SET_NAME_OF_CLINET_IN_PROFILE = 'SET_NAME_OF_CLINET_IN_PROFILE'

export const RESTE_ALL_PROFILE_DATA_APP = 'RESTE_ALL_PROFILE_DATA_APP'