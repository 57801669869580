import { Link } from "react-router-dom"

const ContactProfileCaseItem = ({info, ...props}) => {

    return (
        <li className="flex flex-wrap items-center gap-2.5">
            
            <Link to={`/law-suits/${info.id}`} className="flex flex-col gap-2.5 group">

                <h3 className="4xm:text-base text-sm group-hover:text-primary duration-300"> پرونده {info.title} </h3>

                <span className="4xm:text-sm text-xs text-gray-500"> {info.position} </span>

            </Link> 

            {info.is_archived ?
                
                <span className="block mr-auto text-sm bg-rose-50 text-rose-600 border border-rose-100 py-1.5 px-4 rounded-md w-fit"> مختومه </span>
                
                :

                <span className="block mr-auto bg-green-50 text-green-600 border font-medium border-green-100 py-1.5 px-4 rounded-md w-fit xm:text-sm text-xs"> { info.state ? info.state : 'درحال رسیدگی' } </span>

            }

        </li>
    )

}

export default ContactProfileCaseItem