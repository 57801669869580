import { ToastContainer } from "react-toastify"
import ContactsSearch from "../../../components/Contacts/Search"
import Header from "../../../components/Header"
import HeaderAddBtn from "../../../components/Header/AddBtn"
import HeaderSearchBtn from "../../../components/Header/Search/Btn"
import MessagesContactList from "../../../components/Messages/Contacts"
import { useEffect } from "react"

const ContactsForMessages = () => {

    useEffect(() => {

        window.scrollTo({top: 0})

    }, [])

    return (
        <>

            <Header 
                title={'شروع گفتگو با'}
                backBtn={true}
            >

                <HeaderSearchBtn />

                <HeaderAddBtn 
                    path={'/add-contact'}
                />

            </Header>

            <main className="main-content">
            
                <ContactsSearch />

                <MessagesContactList />

                <ToastContainer className={'lg:!bottom-0 !bottom-[78px]'} />

            </main>

        </>
    )

}

export default ContactsForMessages