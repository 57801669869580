import { useDispatch } from "react-redux"
import { Link, useLocation } from "react-router-dom"
import { ReactSVG } from "react-svg"

import { SHOW_SIDE_MENU } from "../../redux/action/menu/side/sideType"

const Header = ({title, backBtn, search, ...props}) => {

    const dispatch = useDispatch()

    const location = useLocation()

    const showSideMenu = () => {
        
        dispatch({
            type: SHOW_SIDE_MENU
        })

    }

    // ===== render =====

    const handleBackOrMenuButton = () => {

        if(backBtn){
            return (
                <Link to={props.backPath ? props.backPath : location.key !== 'default' && !location?.state?.dontBack ? -1 : '/'} className="w-8 h-8 text-second flex items-center">
                    
                    <ReactSVG src={`${process.env.PUBLIC_URL}/assets/images/icons/back.svg`} className="w-5 group-hover:text-primary duration-300 rotate-180 mr-1.5" />

                </Link>
            )
        }

        return (
            <button onClick={showSideMenu} className="w-8 h-8 text-second" id="header-menu-btn">
                
                <ReactSVG src={`${process.env.PUBLIC_URL}/assets/images/icons/menu.svg`} className="w-8 h-8" />

            </button>
        )

    }
    
    return (
        <header className="fixed lg:pt-4 bg-custom-gray py-3 top-0 left-0 lg:w-3/4 lg:px-4 px-3 w-full z-[2]">

            <div className="bg-white border border-gray-200 flex items-center flex-wrap justify-between rounded-2xl shadow-custom-lg p-4">

                <div className="flex items-center gap-4">
        
                    { handleBackOrMenuButton() }

                    <h1 className={`text-lg 3xm:text-xl font-medium 3xm:max-w-[320px] ${(title?.length >= 20 && window.innerWidth <= 495) && '3xm:!text-lg' } 4xm:max-w-[205px] whitespace-nowrap overflow-hidden text-ellipsis text-second ${props.inMobileTitleBeHidden && '4xm:block hidden'}`}> { title } </h1>

                </div>

                <div className="flex items-center gap-4 mr-auto">

                    { props.children }

                </div>

            </div>

        </header>
    )

}

export default Header