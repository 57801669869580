import { useEffect, useRef } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useParams } from "react-router-dom"
import { SVAE_MESSAGE_INPUT_USER_IN_CHAT_PAGE } from "../../../../../redux/action/messages/chat/chatType"

const SendMessageInput = (props) => {

    const dispatch = useDispatch()
    const expendData = useSelector(state => state.messages.chat.loadedChatListExpendData)

    const params = useParams()

    const inputRef = useRef()

    useEffect(() => {

        if(typeof expendData === 'object'){

            if(expendData[params.id] && inputRef?.current){
                props.onUpdateTextInput(expendData[params.id]?.inputText)
            }

        }

    }, [inputRef])

    useEffect(() => {

        inputRef.current.style.height = '52px'
        inputRef.current.style.height =  inputRef.current.scrollHeight + 'px'

    }, [props.value])

    const updateHeight = (event) => {

        saveMessageEachPerson(event.target.value)

        props.onUpdateTextInput(event.target.value)
        
        handleSelectSectionOfText(event)
    
    }

    const handleSelectSectionOfText = (event) => {
        props.onSetSelectTextInputStart(event.target.selectionStart)
        props.onSetSelectTextInputEnd(event.target.selectionEnd)
    }

    const checkICloseEmojiTabOrNo = (event) => {

        if(event.key !== props.lastEmoji && props.showEmoji){
            props.onHideEmoji()
        }

    }

    const saveMessageEachPerson = (message) => {
        
        dispatch({
            type: SVAE_MESSAGE_INPUT_USER_IN_CHAT_PAGE,
            payload: {
                inputText: message,
                userId: params.id
            }
        })
    }

    return (
        <textarea 
            onChange={updateHeight}
            onClick={handleSelectSectionOfText}
            onKeyDown={checkICloseEmojiTabOrNo}
            value={props.value}
            ref={inputRef}
            type="text" 
            rows={1}
            className={`w-full no-scrollbar resize-none max-h-32 min-h-[48px] overflow-y-auto 3xm:pl-24 pl-[76px] bg-white rounded-3xl shadow-custom-lg py-3 px-4 focus:outline-none focus:border-gray-200 border-gray-100 border`}>
        </textarea>
    )

}

export default SendMessageInput