import { memo, useEffect, useState } from "react"

import Input from "../../../UI/Input"
import { connect } from "react-redux"
import { SET_SEARCH_LOCATION } from "../../../../redux/action/location/locationType"

const SelectCitySearch = (props) => {

    const [search, setSearch] = useState('')

    useEffect(() => {

        const setValue = setTimeout(() => {
            // come update the redux --> for access into all of the app
            props.setSearchValue(search)

        }, 500)

        return () => {
            if(setValue)
                clearTimeout(setValue)
        }

    }, [search])

    const updateSearch = (event) => {
        setSearch(event.target.value)
    }

    return (
        <div>
            
            <Input 
                id='search-city-field'
                placeholder={'جستجو شهر و استان...'}
                className={'shadow-custom-lg border-gray-300/60 focus:shadow-primary/5'}
                value={search}
                onChange={updateSearch}
            />

        </div>
    )

}

const mapStateToProps = (state) => {
    
    return {
        searchValue: state.location.search
    }

}

const mapDispatchToProps = (dispatch) => {
    
    return {
        
        setSearchValue: (value) => {
            
            dispatch({
                type: SET_SEARCH_LOCATION,
                payload: value
            })

        }

    }

}

const SelectCitySearchMemo = memo( connect(mapStateToProps, mapDispatchToProps)(SelectCitySearch) )

export default SelectCitySearchMemo