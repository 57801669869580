import { useEffect, useState } from "react"

const DropdownModal = (props) => {

    const [show, setShow] = useState(false)

    useEffect(() => {
        
        setShow(true) 

    }, [])

    const hide = () => {
        setShow(false)

        setTimeout(() => {
            props.onSetShow(false)
        }, 300);

        document.body.classList.remove('modal-opened')
    }

    return (
        <>
            <div className={`md:w-44 w-full bg-white z-40 md:!absolute fixed left-0 md:!top-full md:bottom-auto bottom-0 md:rounded-xl shadow-custom-lg md:border-2 border-4 rounded-t-[1.85rem] md:p-0 p-4 border-gray-200 ${show ? 'opacity-100' : 'opacity-0'} duration-300 pt-6 md:pt-0 md:before:hidden before:absolute before:top-0 before:left-0 before:right-0 before:mx-auto before:w-24 before:bg-gray-200 before:rounded-2xl before:h-2 before:mt-1.5 transition-opacity ${props.classList}`}>

                { props.title && <h4 className="md:hidden text-lg font-medium text-center my-2"> { props.title } </h4> }

                {props.children}

            </div>

            <div onClick={hide} className={`backdrop md:bg-black/10 bg-black/40 ${show ? 'opacity-100' : 'opacity-0'} duration-300 ${props.backdropClass}`}></div>
        </>
    )

}

export default DropdownModal