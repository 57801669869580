import { Link } from "react-router-dom"
import { ReactSVG } from "react-svg"

import WalletActionButtonAddBalance from "./AddBalance"

const WalletActionButtons = () => {

    return (
        <>
            <section className="mb-12">

                <div className="grid xl:grid-cols-5 md:grid-cols-4 sm:grid-cols-3 5xm:grid-cols-2 grid-cols-1 xm:gap-6 sm:gap-5 md:gap-7 gap-4">
                    
                    <WalletActionButtonAddBalance />

                    <Link to={'/plans'} className="bg-white shadow-custom-lg rounded-xl p-4 flex items-center flex-col justify-center gap-4">

                        <div className="bg-white shadow-custom-md flex items-center justify-center border-custom-gray border-2 w-14 h-14 rounded-full">

                            <ReactSVG
                                className="text-primary w-8"
                                src={`${process.env.PUBLIC_URL}/assets/images/icons/subscribe.svg`}
                            />

                        </div>

                        <span className="font-medium"> تهیه اشتراک </span>

                    </Link>

                </div>

            </section>
        </>
    )

}

export default WalletActionButtons