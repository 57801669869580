import CaseContentInfoDescription from "./Description"
import CaseContentInfoDetail from "./Detail"
import CaseContentInfoContacts from "./Contacts"
import { useEffect } from "react"

const CaseContentInfo = ({data, onSetData, firstData, ...props}) => {

    useEffect(() => {

        const timeout = setTimeout(() => {
            
            checkHaveChangeInInfo()

        }, 200);

        return () => {
            if(timeout)
                clearTimeout(timeout)
        }

    }, [data])

    const checkHaveChangeInInfo = () => {

        const checkChanges = ['step', 'category', 'branch', 'city', 'agreed_fee']

        let haveChanges = []

        for( const item of checkChanges ){

            if( (!data[item]?.name && ( (data[item] != firstData[item]) && (data[item] !== '' || firstData[item] !== null))) || (data[item]?.name !== firstData[item]?.name) ){
                haveChanges.push(item)
            }

        }

        props.onSetChanges( haveChanges )

    }

    return (
        <section>

            <div className="flex flex-col gap-8">
                
                <CaseContentInfoDetail 
                    code={data?.code}
                    archive_code={data?.archive_code}
                    state={data?.state}
                    province={data?.province?.name}
                    city={data?.city?.name}
                    branch={data?.branch}
                    step={data?.step}
                    created_at={data?.created_at}
                    jurisdiction={data?.jurisdiction?.name}
                    category={data?.category}
                    agreed_fee={data?.agreed_fee}

                    data={data}
                    firstData={ firstData }
                    onSetData={ onSetData }
                    changes={ props.changes }
                />

                <CaseContentInfoDescription 
                    description={data?.description}
                />
                
                <CaseContentInfoContacts 
                    title={'وکیل پرونده'}

                    lawyer={data?.lawyer}
                />

            </div>

        </section>
    )

}

export default CaseContentInfo