const TariffsItem = (props) => {

    return (
        <div className="bg-white flex items-end justify-between flex-wrap gap-4 p-4 relative before:absolute before:top-0 before:left-0 before:right-0 before:mx-auto before:w-4/5 before:bg-primary before:h-1 before:rounded-md rounded-xl shadow-custom-md">
         
            <div className="flex flex-col gap-3">

                <h3 className="font-medium"> {props.name} </h3>
                <p className="text-gray-500 text-sm"> {props.description} </p>

            </div>

            <div className="flex items-center gap-1">

                <span> {props.price && Number(props.price).toLocaleString('fa')} </span>
                <span> تومان </span>

            </div>

        </div>
    )

}

export default TariffsItem