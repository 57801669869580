import CaseContentInfoContactsItem from "./Item"

const CaseContentInfoContacts = ({lawyer, ...props}) => {

    return (
        <div className="flex flex-col gap-7">
            
            <h2 className="font-medium text-gray-700 text-lg"> {props.title} </h2>
        
            <ul className="grid sm:grid-cols-2 grid-cols-1 gap-4">
            
                <CaseContentInfoContactsItem 
                    name={lawyer?.name}
                    image={lawyer?.imagee}
                    mobile={lawyer?.mobile}
                    isUser={true}
                    userId={lawyer?.id}
                />

            </ul>

        </div>
    )

}

export default CaseContentInfoContacts