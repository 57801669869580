import { useLocation } from "react-router-dom"

const PaymentGetewayDescription = () => {

    const location = useLocation()

    return (
        <div className="mb-12">
            { location.state?.plan ? 
                <p className="text-gray-600 leading-8"> برای پرداخت هزینه <span className="font-medium text-[#004a8c]">اشتراک {location.state?.plan?.title}</span> به مبلغ <span className="font-medium text-[#004a8c]">{Number(location.state?.plan?.price)?.toLocaleString('fa')} تومان</span> یکی از درگاه های پرداخت زیر را انتخاب کنید </p>
                :
                location.state?.wallet ?
                <p className="text-gray-600 leading-8"> برای شارژ کیف‌پول خود به مبلغ <span className="font-medium text-[#004a8c]">{Number(location.state?.wallet?.price)?.toLocaleString('fa')} تومان</span> یکی از درگاه های پرداخت زیر را انتخاب کنید </p>
                :
                <p className="text-gray-600 leading-8"> برای پرداخت یکی از درگاه های پرداخت زیر را انتخاب کنید </p>
            }
        </div>
    )

}

export default PaymentGetewayDescription