export const SET_LOADING_WALLET = 'SET_LOADING_WALLET'
export const SET_ERROR_WALLET = 'SET_ERROR_WALLET'
export const SET_WALLET_DATA = 'SET_WALLET_DATA'

export const SET_PAGINATION_WALLET = 'SET_PAGINATION_WALLET'
export const ADD_ITEMS_TO_WALLET_HISTORY = 'ADD_ITEMS_TO_WALLET_HISTORY'

export const SET_PAYMENT_LOADING_WALLET = 'SET_PAYMENT_LOADING_WALLET'

export const SET_BALANCE_TOTAL_WALLET = 'SET_BALANCE_TOTAL_WALLET'

export const RESTE_ALL_WALLET_DATA_APP = 'RESTE_ALL_WALLET_DATA_APP'
