import { useEffect, useState } from "react"
import { Link, useLocation, useParams } from "react-router-dom"
import { useSelector } from "react-redux"
import { ReactSVG } from "react-svg"

import MessageProfileBtn from "./ProfileBtn"
import MessageMoreBtn from "./MoreBtn"
import MessageSearchBtn from "./SearchBtn"

const MessageHeader = (props) => {

    const receiver = useSelector(state => state.messages.chat.receiver)
    const contactInfo = useSelector(state => state.contacts.contactInfo)

    const [contactData, setContactData] = useState({})
    
    const location = useLocation()
    const params = useParams()

    useEffect(() => {

        if(receiver?.mobile == params.phone){
            setContactData(receiver)
        }

    }, [receiver])

    return (
        <>
            
            <header className="fixed lg:pt-4 bg-custom-gray py-3 top-0 left-0 lg:w-3/4 lg:px-4 px-3 w-full z-[3]">

                <div className="bg-white border border-gray-200 flex items-center flex-row-reverse flex-wrap justify-between rounded-2xl shadow-custom-lg px-4 5xm:py-2 py-3">

                    <div className="flex items-center sm:gap-3 gap-2.5">
                        
                        <MessageProfileBtn 
                            name={location?.state?.name ? location?.state?.name : contactData?.name ? contactData?.name : props.invite ? contactInfo?.name ? contactInfo.name : '...' : null}
                            mobile={params?.phone ? params.phone : contactData.mobile ? contactData?.mobile : null}
                            image={location?.state?.image ? location?.state?.image : params.id == contactData.id && contactData?.image ? contactData?.image : null}
                            user={null}
                            lastSeen={contactData?.last_seen}
                        />

                        <Link to={location.key !== 'default' ? -1 : '/'} className="w-8 h-8 text-gray-600 flex items-center">
                        
                            <ReactSVG src={`${process.env.PUBLIC_URL}/assets/images/icons/back.svg`} className="w-5 group-hover:text-primary duration-300 mr-1.5" />
                    
                        </Link>

                    </div>

                    <div className="flex items-center gap-4 ml-auto">

                        <MessageMoreBtn 
                            name={location?.state?.name ? location?.state?.name : receiver?.name ? receiver?.name : null}
                        />

                        {/* <MessageSearchBtn /> */}

                    </div>

                </div>

            </header>
        </>
    )

}

export default MessageHeader