import { useEffect, useState } from "react"
import { ReactSVG } from "react-svg"

const ToggleSwitch = ({ fieldsId, name, value = 0, label, className, loading, ...props }) => {

    const [checkedItem, setCheckedItem] = useState(0)
    
    useEffect(() => {

        setCheckedItem(value)
        
    }, [value])

    const toggleChecked = (event) => {

        if(event.target.id === fieldsId[0])
            setCheckedItem(0)
        else
            setCheckedItem(1)
    }

    return (
        <div {...props} className={`flex flex-col gap-2.5 ${className}`}>
            
            <span className="text-gray-500"> {label} : </span>

            <div className='p-2 bg-white rounded-lg w-fit shadow-custom-md flex items-center relative'>
                
                <label htmlFor={fieldsId[0]} className={`cursor-pointer select-none ${loading && 'opacity-0'}`}>
                    <input id={fieldsId[0]} type='radio' name={name} className='hidden peer' onChange={toggleChecked} value={checkedItem} checked={checkedItem === 0} />
                    <span className='toggle-switch-item py-2 block px-7 text-gray-500 rounded-lg peer-checked:bg-primary peer-checked:text-white'> مرد </span>
                </label>

                <label htmlFor={fieldsId[1]} className={`cursor-pointer select-none ${loading && 'opacity-0'}`}>
                    <input id={fieldsId[1]} type='radio' name={name} className='hidden peer' onChange={toggleChecked} value={checkedItem} checked={checkedItem === 1} />
                    <span className='toggle-switch-item py-2 block px-7 text-gray-500 rounded-lg peer-checked:bg-primary peer-checked:text-white'> زن </span>
                </label>

                { loading && <ReactSVG src="../assets/images/icons/loading-dot.svg" className="w-10 text-gray-400 absolute left-0 right-0 mx-auto my-10" /> }

            </div> 

        </div>
    )

}

export default ToggleSwitch