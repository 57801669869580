import MainMenuList from "./List"
import MainMenuLogo from "./Logo"
import MainMenuLogoutBtn from "../SubSection/LogoutBtn"

const MainMenu = (props) => {

    return (
        <aside className={`lg:w-1/4 w-full bg-custom-gray lg:p-4 p-3 fixed right-0 lg:top-0 bottom-0 lg:h-full z-[3] ${props.mobileBeHidden && 'lg:block hidden'}`}>

            <div className="bg-white border border-gray-200 w-full h-full shadow-custom-lg rounded-2xl lg:p-4 p-2.5">

                <MainMenuLogo />

                <div className="h-[calc(100%-57.5px)] flex flex-col justify-between">
                
                    <MainMenuList />

                    <MainMenuLogoutBtn />
                
                </div>

            </div>

        </aside>
    )

}

export default MainMenu