import { useEffect } from "react"
import { useCookies } from "react-cookie"
import { useDispatch, useSelector } from "react-redux"
import { ReactSVG } from "react-svg"

import { getContactsList } from "../../../redux/action/contacts/contactsAction"

import ContactItem from "./Item"
import LoadingDot from "../../UI/LoadingDot"
import { SET_CONTACTS } from "../../../redux/action/contacts/typeContacts"
import { useNavigate } from "react-router-dom"

const ContactList = () => {

    const dispatch = useDispatch()
    const contacts = useSelector(state => state.contacts.contacts)
    const error = useSelector(state => state.contacts.error)
    const loading = useSelector(state => state.contacts.loading)
    const filtered = useSelector(state => state.contacts.filtered)
    const updateLoading = useSelector(state => state.contacts.updateLoading)
    const getedContacts = useSelector(state => state.contacts.getedContacts)

    const navigate = useNavigate()

    const [cookies, , removeCookie] = useCookies(['authToken'])

    useEffect(() => {

        if(contacts.length <= 0 || !getedContacts){
            getContacts()
        }

        return () => {
            if(document.body.classList.contains('modal-opened'))
                document.body.classList.remove('modal-opened')
        }

    }, [])

    useEffect(() => {

        if(error){

            if(error === 'ابتدا وارد حساب کاربری خود شوید.'){
                // come go to authPage and remove cookies
                removeCookie('authToken')                

                navigate('/auth/login', {replace: true})
            }     

        }

    }, [error])

    const getContacts = async () => {
        if(!loading || !getedContacts) {
            const data = await dispatch( await getContactsList(cookies.authToken) )
            
            if(data)
                sortContactsByBaseIsUser(data)
        }
    }

    const sortContactsByBaseIsUser = (contacts) => {
        
        contacts = contacts.sort((a, b) => {
            if (a?.user?.last_login && !b?.user?.last_login) {
                return -1; // a قبلی از b قرار می‌گیرد
            } else if (!a?.user?.last_login && b?.user?.last_login) {
                return 1; // b قبلی از a قرار می‌گیرد
            } else {
                return 0; // ترتیب مانند قبلی باقی می‌ماند
            }
        })

        dispatch({
            type: SET_CONTACTS,
            payload: [...contacts]
        })

    } 

    const deleteItem = (id) => {
        const newContacts = contacts.filter(item => item.id !== id)

        dispatch({
            type: SET_CONTACTS,
            payload: newContacts
        })
    }

    if(loading){
        return (
            <section className="flex items-center justify-center h-20">
                <ReactSVG 
                    src={`${process.env.PUBLIC_URL}/assets/images/icons/loading-dot.svg`}
                    className="w-10 text-second"
                />
            </section>
        )
    }

    if(error) {
        return (
            <section className="flex items-center gap-2">

                <ReactSVG src={`${process.env.PUBLIC_URL}/assets/images/icons/info-box.svg`} className="w-6 text-rose-500" />

                <p className="text-gray-700 leading-7"> {error} </p>

            </section>
        )
    }

    if(filtered.searchedValue){
        return (
            <>
                <section>

                    <div className="flex mb-6 items-center flex-wrap gap-2 rounded-2xl shadow-custom-md border-[3px] border-white p-4">

                        <h2 className="text-lg font-medium"> نتیجه جستجو:‌ {filtered.searchedValue} </h2>

                        <span className="text-sm mt-0.5"> (<span className="font-medium">{filtered.list.length}</span> مخاطب یافت شده) </span>
                            
                    </div>

                    { filtered.list.length <= 0 && <p className="text-gray-600 flex items-center"> هیچ مخاطبی یافت نشد </p> }

                    <ul className="grid md:grid-cols-2 grid-cols-1 gap-4">

                        {filtered.list.map(item => 
                        
                            <ContactItem 
                                key={item.id}
                                item={item}
                                image={item?.user?.image}
                                isUser={ (item?.user && item?.user?.name && item?.user?.last_login) ? true : false}
                                onDeleteItem={deleteItem}
                            />

                        )}
                
                    </ul>

                </section>
                
                {updateLoading &&  <LoadingDot /> }
            </>
        )
    }

    return (
        <>
            <section>

                { contacts.length >= 1 ? 
                    <div className="flex mb-6 items-center flex-wrap gap-2 rounded-2xl shadow-custom-md border-[3px] border-white p-4">
                        
                        <h2 className="text-lg font-medium"> لیست مخاطبین </h2>

                        <span className="text-sm mt-1"> (<span className="font-medium">{contacts.length}</span> مخاطب) </span>
                                
                    </div>
                :   

                    <p className="text-gray-600 text-center h-20 flex items-center justify-center  rounded-2xl shadow-custom-md border-[3px] border-white p-4"> هنوز مخاطبی اضافه نشده است </p> 
                }

                <ul className="grid md:grid-cols-2 grid-cols-1 gap-4">

                    {contacts.map(item => 
                        
                        <ContactItem 
                            key={item.id}
                            item={item}
                            image={item?.user?.image}
                            isUser={(item?.user && item?.user?.name && item?.user?.last_login) ? true : false}
                            onDeleteItem={deleteItem}
                        />

                    )}
                    
                </ul>

            </section>
                        
            {updateLoading &&  <LoadingDot /> }
        </>
    )

}

export default ContactList