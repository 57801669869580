import { useState } from "react"
import { ReactSVG } from "react-svg"

import Dropdown from "../../../../../../UI/Dropdown"
import { Link, useParams } from "react-router-dom"
import ConfirmModal from "../../../../../../UI/ConfirmModal"
import { useDispatch } from "react-redux"
import { useCookies } from "react-cookie"

const CaseFinancialsItem = ({data, onSetSelectedItemDelete, selectedItemDelete}) => {

    const selectItemForDelete = () => {
        onSetSelectedItemDelete(data.id)
    }

    const handleSelectItem = () => {
        
        if(selectedItemDelete)
            onSetSelectedItemDelete(data.id)
        else
            return

    }

    return (
        <li onContextMenu={selectItemForDelete} onClick={handleSelectItem} className={`bg-white select-none relative rounded-xl shadow-custom-md p-4 ${selectedItemDelete && 'cursor-pointer'}`}>
                            
            <div className="flex items-center relative justify-between flex-wrap mb-3.5">

                { data.type === 'income' ?

                    <div className="flex items-center gap-0">
                        
                        <ReactSVG
                            className="w-5 text-green-500"
                            src={`${process.env.PUBLIC_URL}/assets/images/icons/add.svg`}
                        />

                        <h3 className="text-gray-600 font-medium"> <span className="text-green-500"> پرداخت </span> {data.category.includes('دریافت:') ? data.category.slice(7) : data.category} </h3>
                    
                    </div>

                    :

                    <div className="flex items-center gap-2.5">

                        <ReactSVG
                            className="w-2.5 -translate-x-1 text-rose-500"
                            src={`${process.env.PUBLIC_URL}/assets/images/icons/subtract.svg`}
                        />

                        <h3 className="text-gray-600 font-medium"> <span className="text-rose-500"> هزینه برای </span> {data.category && data.category.slice(0, 6).includes('هزینه') ? data.category.slice(5, data.category.length) : data.category} </h3>
                    
                    </div>

                }

                <div className="flex items-center gap-3">

                    { data.type === 'income' ? 
                        
                        <div className="bg-green-50 border border-green-500 text-green-600 flex items-center justify-center w-6 h-6 rounded-full">
                            <ReactSVG
                                className="w-4 h-4 rotate-180"
                                src={`${process.env.PUBLIC_URL}/assets/images/icons/down.svg`}
                            />
                        </div>
                        
                        :

                        <div className="bg-red-50 border border-red-500 text-red-600 flex items-center justify-center w-6 h-6 rounded-full">
                            <ReactSVG
                                className="w-4 h-4"
                                src={`${process.env.PUBLIC_URL}/assets/images/icons/down.svg`}
                            />
                        </div>
                    
                    }

                </div>

            </div>

            <div className="flex flex-wrap items-center justify-between gap-5">

                <div className="flex items-center gap-1">

                    <span className="text-sm text-gray-700"> مبلغ : </span>
                    <span className="font-medium"> {Number(data?.amount).toLocaleString('fa')} </span>
                    <span> تومان </span>

                </div>

                <div className="flex items-center gap-1 text-gray-700">

                    <svg className="w-3 h-3 mb-0.5" viewBox="0 0 24 24" fill="none">
                        <path d="M2.00162 10C2.00052 10.1027 2 10.2067 2 10.312V15.688C2 16.8044 2.05852 17.7698 2.23866 18.5804C2.42133 19.4024 2.74209 20.1251 3.30848 20.6915C3.87488 21.2579 4.59764 21.5787 5.41959 21.7613C6.23018 21.9415 7.19557 22 8.312 22H15.688C16.8044 22 17.7698 21.9415 18.5804 21.7613C19.4024 21.5787 20.1251 21.2579 20.6915 20.6915C21.2579 20.1251 21.5787 19.4024 21.7613 18.5804C21.9415 17.7698 22 16.8044 22 15.688V10.312C22 10.2067 21.9995 10.1027 21.9984 10H2.00162Z" fill="currentColor"></path>
                        <path d="M4.99991 4.34708V3C4.99991 2.44772 5.44762 2 5.99991 2C6.55219 2 6.99991 2.44772 6.99991 3V4.03477C7.41104 4.01008 7.84846 4 8.31191 4H15.6879C16.1514 4 16.5888 4.01008 16.9999 4.03477V3C16.9999 2.44772 17.4476 2 17.9999 2C18.5522 2 18.9999 2.44772 18.9999 3V4.34708C19.649 4.54034 20.2235 4.8406 20.6914 5.30848C21.2578 5.87488 21.5786 6.59764 21.7612 7.41959C21.8024 7.60498 21.8373 7.79846 21.8665 8H2.1333C2.16253 7.79846 2.19737 7.60498 2.23857 7.41959C2.42124 6.59764 2.742 5.87488 3.30839 5.30848C3.77627 4.8406 4.35086 4.54034 4.99991 4.34708Z" fill="currentColor"></path>
                    </svg>

                    <span className="text-xs"> {new Date(data.paid_at ? data.paid_at : data.created_at).toLocaleDateString('fa', {year: 'numeric', month: '2-digit', day: '2-digit'})} </span>

                </div>

            </div>
            
            { ( data.description || data.client) &&
                <div className="mt-auto">

                    { data.client && data.type !== 'expense' && 
                    
                        <hr className="my-4" />  
                    }

                    <div className="flex flex-wrap items-center gap-4 justify-between">

                        <div>
                            
                            { data.client && data.type !== 'expense' && 
                                <>
                                    <span className="2xm:text-sm text-xs"> پرداخت شده توسط </span>
                                    <span className="text-primary 5xm:text-base text-sm"> {data.client.name} </span>
                                </>
                            }

                        </div>
                        

                    </div>   

                    { data.description &&
                        
                        <>
                            <hr className="my-4" />

                            <p className="text-sm text-justify text-gray-700 leading-6"> {data.description} </p>

                        </>
                        
                    }

                </div>
            }

        </li>
    )

}

export default CaseFinancialsItem