import { combineReducers } from "redux";

import messagesListReducer from "./List/indexReducer";
import messagesChatReducer from "./Chat/indexReducer";

const messagesReducer =  combineReducers({
    list: messagesListReducer,
    chat: messagesChatReducer
})

export default messagesReducer