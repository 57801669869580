import { memo, useEffect, useRef, useState } from "react"
import { connect } from "react-redux"
import { ReactSVG } from "react-svg"

import { DELETE_CHOOSE_LOCATION, SET_CHOOSE_CITY, SET_CHOOSE_PROVINCE } from "../../../../redux/action/location/locationType"
import { SET_USER_LOCATION } from "../../../../redux/action/profile/profileType"
import { getFilteredList } from "../../../../redux/selector/location"


const SelectCityList = (props) => {

    useEffect(() => {

        return () => {
            props.deleteChooseData()
        }

    }, [])

    const selectCity = (item) => {
        props.selectCity(item)

        const location = {
            city: item,
            province: props.chooseProvince.id
        }

        // if user only selected city come find province and set in the location
        if(!location.province){

            let province = ''

            props.list.map(listItem => {
                listItem.cities.find(city => {
                    if(city.id === item.id){
                        province = listItem.id 
                    }
                })
            })

            location.province = province
        }

        props.onUpdateLocation(location)
    }

    // ============ render ===============

    if(props.loading){
        return (
            <div className="flex items-center justify-center h-20">  
                <ReactSVG src={`${process.env.PUBLIC_URL}/assets/images/icons/loading-dot.svg`} className="w-10 text-gray-500" />
            </div>
        )
    }

    if(props.error){
        return (
            <div className="flex items-center justify-center h-20 text-gray-600">
                {props.error}
            </div>
        )
    }

    const renderItems = () => {

        if(props.filtered && !props.chooseProvince.name){
            if(props.filtered.city.length >= 1 || props.filtered.province.length >= 1)
                return (
                    <>
                        {props.filtered.province.map(item => 
                            
                            <li 
                                key={'province_field_' + item.id}
                                className="text-gray-600 cursor-pointer py-4 first:pt-0 last:pb-0 border-b last:border-b-0 border-b-gray-200 flex items-center justify-between"
                                onClick={() => props.selectProvince(item)}
                            >

                                <span> استان {item.name} </span>

                                <ReactSVG src={`${process.env.PUBLIC_URL}/assets/images/icons/down.svg`} className="w-5 rotate-90 text-gray-500" />

                            </li>

                        )}
                        {props.filtered.city.map(item => 
                            
                            <li 
                                key={'province_field_' + item.id}
                                className="text-gray-600 cursor-pointer py-4 first:pt-0 last:pb-0 border-b last:border-b-0 border-b-gray-200 flex items-center justify-between"
                                onClick={() => selectCity(item)}
                            >

                                <span> شهر {item.name} </span>

                                <span className="text-sm text-primary"> انتخاب </span>

                            </li>

                        )}
                    </>
                )
            else
                return (
                    <span className="flex items-center justify-center h-20"> موردی یافت نشد </span>
                )
        }
        
        else if(!props.chooseProvince.name){
            return (
                props.list.map(item => 
                    
                    <li 
                        key={'province_field_' + item.id}
                        className="text-gray-600 cursor-pointer py-4 first:pt-0 last:pb-0 border-b last:border-b-0 border-b-gray-200 flex items-center justify-between"
                        onClick={() => props.selectProvince(item)}
                    >

                        <span> استان {item.name} </span>

                        <ReactSVG src={`${process.env.PUBLIC_URL}/assets/images/icons/down.svg`} className="w-5 rotate-90 text-gray-500" />

                    </li>

                )
            )
        }
    
        else if(props.chooseProvince){
    
            return (
                props.chooseProvince.cities.map(item => 
                    
                    <li 
                        key={'province_field_' + item.id}
                        className="text-gray-600 cursor-pointer py-4 first:pt-0 last:pb-0 border-b last:border-b-0 border-b-gray-200 flex items-center justify-between"
                        onClick={() => selectCity(item)}
                    >

                        <span> شهر {item.name} </span>

                        <span className="text-sm text-primary"> انتخاب </span>

                    </li>

                )
            )
    
        }
        
    }

    return (
        <ul className="flex flex-col mt-4">
            
            { renderItems() }

        </ul>
    )

}

const mapStateToProps = (state) => {
    
    return {
        list: state.location.list,
        loading: state.location.loading,
        error: state.location.error,
        chooseProvince: state.location.chooseProvince,
        chooseCity: state.location.chooseCity,
        filtered: getFilteredList(state.location)
    }

}

const mapDispatchToProps = (dispatch) => {

    return {

        deleteChooseData: () => {
            dispatch({
                type: DELETE_CHOOSE_LOCATION
            })
        },

        selectProvince: (provinceItem) => {
            dispatch({
                type: SET_CHOOSE_PROVINCE,
                payload: provinceItem
            })
        },

        selectCity: (cityItem) => {
            dispatch({
                type: SET_CHOOSE_CITY,
                payload: cityItem
            })
        },

    }

}

const SelectCityListMemo = memo(connect(mapStateToProps, mapDispatchToProps)(SelectCityList))

export default SelectCityListMemo