import { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useCookies } from "react-cookie"
import moment from "jalali-moment"

import { HIDE_SEARCH, SET_EVENTS_SEARCH, SHOW_SEARCH } from "../../../redux/action/search/searchType"
import { SET_FILTER_EVENTS, SET_FILTER_SORT_EVENTS, SET_LOADING_CALENDAR_DATE_EVENTS, SET_LOADING_EVENTS } from "../../../redux/action/events/eventsType"

import SearchBox from "../../Header/Search"
import { getEventsListByDateFromSearch, getEventsListFromSearch } from "../../../redux/action/events/eventsAction"

const EventsSearch = (props) => {

    const dispatch = useDispatch()
    const showSearch = useSelector(state => state.search.showSearch)
    const searchValue = useSelector(state => state.search.eventsSearch)
    
    const filtered = useSelector(state => state.events.filtered)

    const [cookies] = useCookies(['authToken'])

    useEffect(() => {

        if(!searchValue && showSearch){
            // close the search state
            hideSearch()
        }   
        else if(searchValue && !showSearch){
            dispatch({
                type: SHOW_SEARCH
            })
        }

    }, [])

    useEffect(() => {

        // dont send request to server when not in search state
        if(searchValue === '' || !showSearch ||  filtered.searchedValue === searchValue) return
        
        // fake loading - for watching better user.
        const timeout = setTimeout(() => {
            if(searchValue !== '')
                doSearch() 
        }, 1000) 

        return () => {
            if(timeout)
                clearTimeout(timeout)
        }

    }, [searchValue])

    const updateSearchField = (event) => {

        if(event.target.value === ''){
            clearSearchValue()
            return
        }

        dispatch({
            type: SET_EVENTS_SEARCH,
            payload: event.target.value
        })

    }

    const clearSearchValue = () => {
        
        dispatch({
            type: SET_EVENTS_SEARCH,
            payload: ''
        })
        
        // if exist search-value come show fake loading
        if(searchValue){
            dispatch({
                type: SET_FILTER_EVENTS,
                payload: {
                    value: '',
                    list: [],
                    pagination: {}
                }
            })

            // set fake loading
            if(props.sorted){
                dispatch({
                    type: SET_LOADING_EVENTS,
                    payload: true
                })
                setTimeout(() => {
                
                    dispatch({
                        type: SET_LOADING_EVENTS,
                        payload: false
                    })
        
                }, 250)
            }
            else{
                dispatch({
                    type: SET_LOADING_CALENDAR_DATE_EVENTS,
                    payload: true
                })

                setTimeout(() => {
                
                    dispatch({
                        type: SET_LOADING_CALENDAR_DATE_EVENTS,
                        payload: false
                    })
        
                }, 250)
            }
            
        }

    }

    const hideSearch = () => {
        
        // hide search
        dispatch({
            type: HIDE_SEARCH
        })

        clearSearchValue()

    }

    const doSearch = () => {

        if(props.sorted)
            getPublicEvents()
        
        else
            getSpecialEvents()

    }

    const getPublicEvents = async() => {
        const data = await dispatch( await getEventsListFromSearch(searchValue, cookies.authToken) )
        
        if(props.sorted && data){

            // come do sort :))
            handleSortEvents(data)

        }

    }

    const getSpecialEvents = async () => {
        await dispatch( await getEventsListByDateFromSearch(searchValue, props.start, props.end, cookies.authToken) )
    }

    const handleSortEvents = (events) => {

        const date = moment().utcOffset('+0330').format('jYYYY-jMM-jDD')

        const sortedData = {
            today: [],
            yesterday: []
        }

        events.map(item => {
            const itemDate = moment(item.starts_at).utcOffset('+0330').format('jYYYY-jMM-jDD')
            const dateSplit = date.split('-')
            const itemSplit = itemDate.split('-')

            if(itemDate === date){
                sortedData.today.push(item)
                return
            }
            else {
                
                if(dateSplit[0] === itemSplit[0] && dateSplit[1] === itemSplit[1] && ( (Number(dateSplit[2]) - 1) === Number(itemSplit[2]) ) ){
                    sortedData.yesterday.push(item)
                    return
                }
                
                else {
                    
                    if(!sortedData[itemDate]){
                        sortedData[itemDate] = []
                    }

                    sortedData[itemDate].push(item)
                }
                
            }
        })

        dispatch({
            type: SET_FILTER_SORT_EVENTS,
            payload: sortedData
        })

    }

    return (
        <SearchBox 
            value={searchValue}
            onUpdate={ updateSearchField }
            onClear={clearSearchValue}
        />
    )

}

export default EventsSearch