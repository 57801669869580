import { ReactSVG } from "react-svg"

const WalletHistoryItem = (props) => {

    return (
        <li className="bg-white p-2 pl-3 rounded-xl flex items-center flex-wrap gap-4 justify-between shadow-custom-sm"> 

            <div className="flex items-center gap-2">

                { props.action === 'wallet.withdraw' ?
                    <div className="bg-red-50 text-red-600 flex items-center justify-center w-12 h-12 rounded-xl outline outline-red-600/10">
                        <ReactSVG 
                            className="w-5 h-5 rotate-180"
                            src={`${process.env.PUBLIC_URL}/assets/images/icons/up.svg`}
                        />
                    </div>
                    : props.action !== 'wallet.gift.deposit' ?
                    <div className="bg-green-100 text-green-600 outline outline-green-500/20 flex items-center justify-center w-12 h-12 rounded-xl">
                        <ReactSVG 
                            className="w-5 h-5"
                            src={`${process.env.PUBLIC_URL}/assets/images/icons/up.svg`}
                        />
                    </div>
                    :
                    <div className="bg-orange-100 text-orange-600 outline outline-orange-600/20 flex items-center justify-center w-12 h-12 rounded-xl">
                        <ReactSVG 
                            className="w-6 h-6"
                            src={`${process.env.PUBLIC_URL}/assets/images/icons/gift.svg`}
                        />
                    </div>
                    
                }

                <div className="flex flex-col gap-2">

                    { props.title ?
                        <span className="text-sm-13px font-medium max-w-[205px] whitespace-nowrap text-ellipsis overflow-hidden"> {props.title && props.action === 'wallet.withdraw' ? `ثبت پرونده ${props.title}` : `حذف پرونده ${props.title}` } </span>
                        
                        : props.action === 'wallet.deposit' ?
                        <span className="text-sm-13px font-medium"> شارژ کیف‌پول </span>
                        
                        : props.action === 'wallet.gift.deposit' ?
                        <span className="text-sm-13px font-medium"> هدیه‌ واریز </span>
                        
                        : props.action === 'wallet.withdraw' &&
                        <span className="text-sm-13px font-medium"> برداشت از کیف پول </span>
                    }
                    
                    <span className="text-sm-13px text-gray-500"> { props.created_at ? new Date(props.created_at).toLocaleDateString('fa-IR', { year: 'numeric', month: '2-digit', day: '2-digit'}) : '۰۰۰۰/۰۰/۰۰' } </span>

                </div>

            </div>

            <div className={`flex 2xm:text-base text-sm items-center gap-1 ${props.action === 'wallet.withdraw' ? 'text-red-600' : props.action !== 'wallet.gift.deposit' ? 'text-green-600' : 'text-orange-600'} mr-auto`}>
                <span className="font-medium "> {props.amount ? Number(props.amount).toLocaleString('fa') : '۰'}  </span>
                <span> تومان </span>
            </div>

        </li>
    )

}

export default WalletHistoryItem