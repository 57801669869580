import { useEffect } from "react"
import { ReactSVG } from "react-svg"
import ModalImageHeader from "./Header"

const ModalImage = (props) => {

    useEffect(() => {

        props.onSetShowing(true)

    }, [])

    const hide = () => {
        props.onSetShowing(false)

        setTimeout(() => {
            props.onSetShow(false)
        }, 300);
        
        document.body.classList.remove('modal-opened')
    }

    return (
        <>
            <div className={`fixed top-0 left-0 w-full 5xm:h-16 5xm:py-0 py-2 flex bg-black z-50 duration-300 ${props.showing ? 'opacity-100' : 'opacity-0'}`}>
                
                <ModalImageHeader  
                    onHide={hide}
                    name={props.name}
                    time={props.time}
                    src={props.src}
                    id={props.id}
                />
                
            </div>

            <div className={`modal-opened pt-16 bg-black/0 max-w-xl max-h-[564px] w-fit h-full overflow-auto fixed left-0 right-0 top-0 bottom-0 m-auto z-40 ${props.showing ? 'opacity-100' : 'opacity-0'} duration-300 transition-opacity ${props.className && props.className}`}>

                <img className="w-full h-full bg-black/90 object-contain" src={props.src} alt="" />

            </div>

            <div onClick={hide} className={`backdrop bg-black/90 ${props.showing ? 'opacity-100' : 'opacity-0'} duration-300`}></div>
        </>
    )

}

export default ModalImage