import { add } from "date-fns-jalali"
import moment from "jalali-moment"

export const saveBaseDataInfoToLocaleStorage = (info) => {

    let now = moment()

    now = add(new Date(now), {days: 15})
    now = moment(now).format('YYYY-MM-DDTHH:mm:ss.000000')

    info = {
        ...info,
        created_at: now
    }

    localStorage.setItem('base_data', JSON.stringify(info) )
}

export const getBaseDataInfoFromLocaleStorage = () => {
    let info = localStorage.getItem('base_data')

    if(info){

        info = JSON.parse(info)

        const timestamp = Date.parse(info?.created_at) / 1000;
        const now = Date.parse( moment() ) / 1000

        // if time is allow come return data
        if(timestamp > now){
            return info
        }
        else {
            return null
        }

    }
}

export const checkShouldUpdateBaseData = (updateTime) => {
    if(!updateTime) return false

    const oneDayOfMilliseconds = 24 * 60 * 60 * 1000

    const updateDate =  new Date( updateTime ).getTime()
    
    let makedBaseDataTime = JSON.parse(localStorage.getItem('base_data'))?.created_at

    if(!makedBaseDataTime) return false

    makedBaseDataTime = new Date(makedBaseDataTime).getTime()

    const diffDays = Math.round(Math.abs((updateDate - makedBaseDataTime) / oneDayOfMilliseconds));

    if(diffDays <= 15){
        return true
    }

    return false
}