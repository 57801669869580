import { useDispatch } from "react-redux"
import { SET_NOTE_INFO_COLOR } from "../../../../redux/action/notes/notesType"

const NoteAddFooterColor = ({color}) => {

    const dispatch = useDispatch()

    const setColor = (value) => {
        dispatch({
            type: SET_NOTE_INFO_COLOR,
            payload: value
        })
    }

    return (
        <div className="fixed lg:pb-4 bg-custom-gray py-3 bottom-0 left-0 lg:w-3/4 px-3 w-full z-[1]">

            <div className="w-full h-full flex items-center gap-4 flex-wrap justify-between">

                <ul className="flex md:justify-start 5xm:gap-7 items-center gap-4 flex-wrap">

                    <li onClick={() => setColor(1)} className={`w-8 h-8 ${color === 1 && 'border-2 border-pink-500'} p-0.5 rounded-full flex items-center justify-center cursor-pointer duration-75`}>
                        <button className="w-full h-full border border-pink-300 bg-pink-200 rounded-full"></button>
                    </li>

                    <li onClick={() => setColor(2)} className={`w-8 h-8 ${color === 2 && 'border-2 border-blue-500'} p-0.5 rounded-full flex items-center justify-center cursor-pointer duration-75`}>
                        <button className="w-full h-full border border-blue-300 bg-blue-200 rounded-full"></button>
                    </li>

                    <li onClick={() => setColor(3)} className={`w-8 h-8 ${color === 3 && 'border-2 border-orange-500'} p-0.5 rounded-full flex items-center justify-center cursor-pointer duration-75`}>
                        <button className="w-full h-full border border-orange-300 bg-orange-200 rounded-full"></button>
                    </li>

                    <li onClick={() => setColor(4)} className={`w-8 h-8 ${color === 4 && 'border-2 border-green-500'} p-0.5 rounded-full flex items-center justify-center cursor-pointer duration-75`}>
                        <button className="w-full h-full border border-green-300 bg-green-200 rounded-full"></button>
                    </li>

                    <li onClick={() => setColor(5)} className={`w-8 h-8 ${color === 5 && 'border-2 border-purple-500'} p-0.5 rounded-full flex items-center justify-center cursor-pointer`}>
                        <button className="w-full h-full border border-purple-300 bg-purple-200 rounded-full"></button>
                    </li>

                </ul>

            </div>

        </div>
    )

}

export default NoteAddFooterColor