import { useState } from "react"
import { ReactSVG } from "react-svg"
import Dropdown from "../../../../UI/Dropdown"
import { SHOW_SEARCH } from "../../../../../redux/action/search/searchType"
import { useDispatch, useSelector } from "react-redux"
import ConfirmModalDelete from "../../../../UI/ConfirmModal/Delete"
import { useParams } from "react-router-dom"
import { deleteChatMessages } from "../../../../../redux/action/messages/list/listAction"
import { useCookies } from "react-cookie"
import { DELETE_ITEM_LOADEDCHATLIST } from "../../../../../redux/action/messages/chat/chatType"

const MessageMoreBtn = (props) => {

    const dispatch = useDispatch()
    const loading = useSelector(state => state.messages.chat.loading)
    const error = useSelector(state => state.messages.chat.error)
    const deleteLoading = useSelector(state => state.messages.list.deleteLoading)

    const [dropdown, setDropdown] = useState(false)
    
    const [showDeleteModal, setShowDeleteModal] = useState(false)
    const [deleteBoth, setDeleteBoth] = useState(true)

    const params = useParams()
    const [cookies] = useCookies(['authToken'])

    const showMoreOption = () => {
        setDropdown(true)
    
        document.body.classList.add('modal-opened')
    }

    const upToFirstChat = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        })

        setDropdown(false)
        document.body.classList.remove('modal-opened')
    }

    const showSearchBox = () => {
        setDropdown(false)
        document.body.classList.remove('modal-opened')

        dispatch({
            type: SHOW_SEARCH
        })

    }

    const handleShowDeleteMessage = (event) => {
        event.stopPropagation()

        setShowDeleteModal(true)
        setDropdown(false)
    }

    const deleteMessage = async () => {
        setShowDeleteModal(false)

        document.body.classList.remove('modal-opened')
        
        if(deleteLoading) return

        const scope = deleteBoth ? 'both' : 'self'

        const data = await dispatch( await deleteChatMessages(params.id, scope, cookies.authToken) )

        if(data){

            dispatch({
                type: DELETE_ITEM_LOADEDCHATLIST,
                payload: params.id
            })

        }
    }

    return (
        <div className="flex items-center justify-center">
            
            <button onClick={showMoreOption}>
                <ReactSVG src={`${process.env.PUBLIC_URL}/assets/images/icons/more.svg`} className="w-[22px]" />
            </button>

            { dropdown && 
                
                <Dropdown 
                    onSetShow={setDropdown}
                    title={props.name}
                >
                    
                    {/* <button onClick={showSearchBox} className="2xm:hidden p-4 md:text-base flex items-center justify-between gap-4 flex-wrap border-b border-b-gray-200 last:border-b-0 w-full text-gray-700 duration-300 relative">
                        جستجو

                        <ReactSVG src={`${process.env.PUBLIC_URL}/assets/images/icons/search.svg`} className="5xm:w-4 xm:w-[22px] w-3.5" />

                    </button> */}

                    <button onClick={upToFirstChat} className="p-4 md:text-base flex outline-none select-none items-center justify-between gap-4 flex-wrap border-b border-b-gray-200 last:border-b-0 w-full text-gray-700 duration-300 relative">
                        رفتن به اولین پیام

                        <ReactSVG src={`${process.env.PUBLIC_URL}/assets/images/icons/up.svg`} className="5xm:w-4 xm:w-[18px] w-3.5" />

                    </button>

                    <button onClick={handleShowDeleteMessage} className="p-4 md:text-base flex items-center justify-between gap-4 flex-wrap border-b border-b-gray-200 last:border-b-0 w-full text-gray-700 duration-300">
                        حذف گفتگو

                        <ReactSVG 
                            src={`${process.env.PUBLIC_URL}/assets/images/icons/delete-2.svg`}
                            className="5xm:w-5 xm:w-6 w-4 xm:-translate-x-1 text-gray-600 -translate-x-0.5"
                        />

                    </button>
                    
                </Dropdown>

            }

            { showDeleteModal &&
                    
                <ConfirmModalDelete
                    title="حذف گفتگو"
                    description="آیا از حذف این گفتگو اطمینان دارید؟"
                    show={showDeleteModal}
                    onSetShow={setShowDeleteModal}
                    onConfirm={deleteMessage}
                    selectCount={1}
                    disabled={error}
                >   

                    <div className="flex justify-center text-gray-600">
                    
                        <input type="checkbox" id="deleteBothInput" className="accent-primary rounded-lg w-5 cursor-pointer" onChange={() => setDeleteBoth(!deleteBoth) } checked={deleteBoth} />
                        
                        { !loading || props.name ?

                            <label htmlFor="deleteBothInput" className="select-none cursor-pointer pr-2.5">
                                
                                حذف همچنین برای {props.name} 

                            </label>

                            :

                            <label htmlFor="deleteBothInput" className="select-none cursor-pointer pr-2.5 flex items-center gap-2">
                                
                                حذف همچنین برای <ReactSVG src={`${process.env.PUBLIC_URL}/assets/images/icons/loading-dot.svg`} className="w-4 translate-y-0.5" />

                            </label>
                        }

                    </div>

                </ConfirmModalDelete>

            }

        </div>
    )

}

export default MessageMoreBtn