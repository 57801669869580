import { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { ReactSVG } from "react-svg";
import { useCookies } from "react-cookie";

import EditEventForm from "../../../components/Events/Edit";
import { EditEventToList, getEventItemData } from "../../../redux/action/events/eventsAction";
import { SET_EVENT_DATA_ERROR, SET_EVENT_DATA_VALUES } from "../../../redux/action/events/eventsType";

import Header from "../../../components/Header";
import Button from "../../../components/UI/Button";
import ToastCustom from "../../../components/UI/ToastCustom";
import LoadingDot from "../../../components/UI/LoadingDot";
import { removeIsNewUser } from "../../../utils/welcomeRegister";
import { UPDATE_EVENT_ITEM_IN_HOME_INFO } from "../../../redux/action/home/homeType";
import { UPDATE_EVENT_ITEM_IN_SINGLE_CASE } from "../../../redux/action/cases/casesType";
import moment from "jalali-moment";

const EditEvent = () => {

    const dispatch = useDispatch()
    const canISendData = useSelector(state => state.events.canISendData)
    const fields = useSelector(state => state.events.fields)
    
    const error = useSelector(state => state.events.error)
    const loading = useSelector(state => state.events.loading)

    const eventDataLoading = useSelector(state => state.events.eventDataLoading)
    const eventData = useSelector(state => state.events.eventData)
    const eventDataError = useSelector(state => state.events.eventDataError)

    const homeInfoLoaded = useSelector(state => state.home.loaded)
    const singlesCase = useSelector(state => state.cases.singlesCase)

    const location = useLocation()
    const params = useParams()
    const navigate = useNavigate()

    const [cookies] = useCookies(['authToken'])

    useEffect(() => {

        if(location?.state?.id && !eventData[params.id]){

            dispatch({
                type: SET_EVENT_DATA_VALUES,
                payload: {
                    id: params.id,
                    list: location.state
                }
            })

            if(eventDataError){

                dispatch({
                    type: SET_EVENT_DATA_ERROR,
                    payload: null
                })

            }

        }

        else if(!eventData[params.id] && !eventDataLoading){

            getEventData()

        }

        window.scrollTo({top: 0})

    }, [])

    const getEventData = async () => {
        
        await dispatch( await getEventItemData(params.id, cookies.authToken) )

    }

    const updateEvent = async () => {
        // make info
        const data = {
            // info
            title: fields.title.value,
            type: fields.type ? fields.type : '',
            description: fields.description.value,
            law_suit_id: fields.caseInfo.id ? fields.caseInfo.id : null,
            is_public: false,
            send_sms: fields.sendSms,
            
            // times
            alaram_at: null,
            alarm_at: null,
            starts_at: null,
            is_started: false,
            
            // my structer code (in future should remove)
            event_date: null,
            event_alaram_at: null,
        }

        // law-id for quick access when updating...
        const law_suit = fields?.caseInfo?.id ? fields.caseInfo : null

        // get Clock
        const clock = fields.clock.split(':')
        const hours = clock[0].trim()
        const mins =  clock[1].trim()

        // Set Event Time To English
        let startEventDateEnglish = moment(eventData[params.id].starts_at.slice(0, 10)).set({hours: (Number(hours) - 3), minutes: (Number(mins) - 30), seconds: 0}).format('YYYY-MM-DD HH:mm:ss')
        data.starts_at = startEventDateEnglish
        data.event_date = `${startEventDateEnglish}.000000Z`

        // if exist alarm
        if( fields.alaram_at.clock ){
            // Set Alarm Event Time To English
            const alarmClock = fields.alaram_at.clock.split(':')
            const alarmHours = alarmClock[0].trim()
            const alarmMins =  alarmClock[1].trim()

            let alarmEventTimeEnglish 

            const date = fields.alaram_at.englishDate.split('/')

            // custom
            if(fields.alaram_at.label !== 'یک روز قبل' && fields.alaram_at.label === ''){
                alarmEventTimeEnglish = moment( fields.alaram_at.englishDate.split('/').join('-') ).set({hours: (Number(alarmHours) - 3), minutes: (Number(alarmMins) - 30) , seconds: 0}).format('YYYY-MM-DD HH:mm:ss')
            }
            // moment
            else if (fields.alaram_at.label === 'در لحظه'){
                alarmEventTimeEnglish = moment( params.date ).set({hours: (Number(hours) - 3), minutes: (Number(mins) - 31), seconds: 0}).format('YYYY-MM-DD HH:mm:ss')
            }
            // hours
            else if(fields.alaram_at.label.includes('ساعت')){
                const result = getRefreshedAlarmDateHours(fields.alaram_at.englishDate.split('/').join('-'), Number(hours), Number(mins), fields.alaram_at.label)
                alarmEventTimeEnglish = result.time
            }
            // 1 day
            else if( Number(hours) <= 2 ){
                alarmEventTimeEnglish = moment( `${date[0]}-${date[1]}-${Number(date[2])}` ).set({hours: (Number(hours) - 3), minutes: (Number(mins) - 30), seconds: 0}).format('YYYY-MM-DD HH:mm:ss')
            }
            else{
                alarmEventTimeEnglish = moment( fields.alaram_at.englishDate.split('/').join('-') ).set({hours: (Number(hours) - 3), minutes: (Number(mins) - 30), seconds: 0}).format('YYYY-MM-DD HH:mm:ss')
            }            
            
            // add to data info
            data.alarm_at = alarmEventTimeEnglish
            data.alaram_at = alarmEventTimeEnglish
            
            data.event_alaram_at = `${alarmEventTimeEnglish}.000000Z`

        }

        const response = await dispatch( await EditEventToList(data, params.id, cookies.authToken) )
        
        if(response){

            if(homeInfoLoaded){

                dispatch({
                    type: UPDATE_EVENT_ITEM_IN_HOME_INFO,
                    payload: {
                        ...response,
                        law_suit
                    }
                })

            }

            if(law_suit?.id && singlesCase[law_suit?.id]){

                dispatch({
                    type: UPDATE_EVENT_ITEM_IN_SINGLE_CASE,
                    payload: {
                        id: law_suit?.id,
                        data: {
                            ...response,
                            law_suit
                        }
                    }
                })

            }

            // navigate(location.key !== 'default' ? -1 : `/events/date/${data.event_date.slice(0, 10)}` , {replace: true})

        }

    }

    const getRefreshedAlarmDateHours = (date, hours, mins, label) => {
        
        let time

        if( label.includes('یک') ){
            time = moment( date ).set({hours: (Number(hours) - 4), minutes: (Number(mins) - 31), seconds: 0}).format('YYYY-MM-DD HH:mm:ss')
        }

        else if( label.includes('دو') ){
            time = moment( date ).set({hours: (Number(hours) - 5), minutes: (Number(mins) - 31)}).format('YYYY-MM-DD HH:mm:ss')
        }

        else if( label.includes('سه') ){
            time = moment( date ).set({hours: (Number(hours) - 6), minutes: (Number(mins) - 31), seconds: 0}).format('YYYY-MM-DD HH:mm:ss')
        }

        else if( label.includes('چهار') ){
            time = moment( date ).set({hours: (Number(hours) - 7), minutes: (Number(mins) - 31), seconds: 0}).format('YYYY:MM:DD HH:mm:ss')
        }

        return { time }
    }

    return (
        <>
            
            <Header 
                title={'ویرایش رویداد'}
                backBtn={true}
            >
                
                <Button 
                    className={'profile-btn bg-primary py-1 px-4'}
                    disabled={false}
                    onClick={updateEvent}
                >
                    اعمال     
                </Button>

            </Header>

            <main className="main-content">

                <EditEventForm 
                    info={ eventData[params.id] }
                />

                { error && 
                    
                    <ToastCustom>
                    
                        <div className="flex items-center gap-2">
                            <ReactSVG
                                className="text-rose-500/90 4xm:w-8 w-6"
                                src={`${process.env.PUBLIC_URL}/assets/images/icons/info-box.svg`}
                            />

                            { error }

                        </div>

                    </ToastCustom>

                }

                { loading && <LoadingDot /> }

            </main>

        </>
    )

}

export default EditEvent