export const SET_LOADING_HOME = 'SET_LOADING_HOME'
export const SET_FAILURE_HOME = 'SET_FAILURE_HOME'
export const SET_INFO_HOME = 'SET_INFO_HOME'

export const UPDATE_INFO_HOME_NOTES = 'UPDATE_INFO_HOME_NOTES'
export const DELETE_ITEM_INFO_HOME_NOTES = 'DELETE_ITEM_INFO_HOME_NOTES'
export const ADD_ITEM_TO_HOME_INFO_NOTE = 'ADD_ITEM_TO_HOME_INFO_NOTE'
export const DELETE_ITEMS_INFO_HOME_NOTES = 'DELETE_ITEMS_INFO_HOME_NOTES'

export const DELETE_EVENT_FROM_HOME_INFO = 'DELETE_EVENT_FROM_HOME_INFO'
export const UPDATE_EVENT_ITEM_IN_HOME_INFO = 'UPDATE_EVENT_ITEM_IN_HOME_INFO'
export const ADD_EVENT_TO_HOME_INFO = 'ADD_EVENT_TO_HOME_INFO'

export const DELETE_CASE_FROM_HOME_INFO = 'DELETE_CASE_FROM_HOME_INFO'
export const ADD_ITEM_TO_LAW_SUITS_HOME = 'ADD_ITEM_TO_LAW_SUITS_HOME'
export const UPDATE_ITEM_TO_LAW_SUITS_HOME = 'UPDATE_ITEM_TO_LAW_SUITS_HOME'

export const SET_COUNT_OF_MESSAGE_IN_HOME = 'SET_COUNT_OF_MESSAGE_IN_HOME'

export const SUBTRACT_OF_HOME_INFO_CONVERSATION_COUNT = 'SUBTRACT_OF_HOME_INFO_CONVERSATION_COUNT'

export const RESTE_ALL_HOME_DATA_APP = 'RESTE_ALL_HOME_DATA_APP'