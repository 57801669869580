import { useSelector } from "react-redux"
import ContactProfileCaseItem from "./CaseItem"
import { ReactSVG } from "react-svg"

const ContactProfileCases = (props) => {

    const loading = useSelector(state => state.contacts.loading)

    const handleCasesItem = () => {
        
        if(loading && !props.law_suits){
            return (
                <div className="h-[25px] w-12 flex items-center justify-center">
                    <ReactSVG 
                        src={`${process.env.PUBLIC_URL}/assets/images/icons/loading-dot.svg`}
                        className="w-7 text-gray-500"
                    />
                </div>
            )
        }

        if(props.law_suits && props.law_suits.length >= 1){
            return (
                <ul className="flex flex-col gap-8">

                    {props.law_suits.map(item => 
                    
                        <ContactProfileCaseItem 
                            key={item.id+'-suit'}
                            info={item}
                        />
                    
                    )}

                </ul>
            )
        }

        return (
            <p className="text-gray-500">
                هیچ پرونده ای ثبت نشده
            </p>
        )

    }

    return (
        <section className="bg-white p-4 shadow-custom-md rounded-2xl">

            <h2 className="text-second font-medium border-b border-b-primary border-opacity-10 mb-4 pb-4"> پرونده های مرتبط </h2>

            <ul className="flex flex-col gap-8">

                { handleCasesItem() }

            </ul>

        </section>
    )

}

export default ContactProfileCases