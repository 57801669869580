import { useLocation, useNavigate } from "react-router-dom"
import { ReactSVG } from "react-svg"
import { savePeymented } from "../../../../utils/paymented"

const PaymentGetewayItem = ({logo, title, url, homeLogo}) => {

    const location = useLocation()
    const navigate = useNavigate()

    const back = () => {
        
        // add a data to wallet or plans page. that i may be a successfuly pay.
        savePeymented(true)
    
        // go back
        navigate(location.key !== 'default' ? -1 : '/', {replace: true, state: {increaseToWallet: false}})
    
    }

    return (
        <li>
                        
            <a href={url} onClick={back} target="_blank" className="group bg-white h-20 rounded-xl w-full p-4 shadow-custom-md flex items-center justify-between flex-wrap gap-4 hover:shadow-custom-xl duration-500">

                <div className="flex items-center flex-wrap gap-4">
                    <img className="w-12 flex-shrink-0" src={homeLogo ? `${process.env.PUBLIC_URL}/assets/images/pictures/payment/${logo}.png` : logo} alt={`payment icon ${title}`} />

                    <span className="font-medium"> {title} </span>
                </div>

                <ReactSVG 
                    className="w-4 -rotate-90 group-hover:scale-110 duration-500"
                    src={`${process.env.PUBLIC_URL}/assets/images/icons/up.svg`}
                />

            </a>

        </li>
    )

}

export default PaymentGetewayItem