import { useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Navigation, Pagination } from 'swiper';

import 'swiper/swiper.min.css';
import 'swiper/components/navigation/navigation.min.css';
import 'swiper/components/pagination/pagination.min.css';
import moment from "jalali-moment";
import { useSelector } from "react-redux";

SwiperCore.use([Navigation, Pagination]);

const AddEventFormCustomTimeListColck = (props) => {

    const differentDate = useSelector(state => state.events.computerDifferentTime)

    const [firstRoundMin, setFirstRoundMin] = useState(true)
    const [firstRoundHour, setFirstRoundHour] = useState(true)

    const updateHourAlarm = (swiper) => {
        
        if(firstRoundHour){
            const time = props.time.split(':')
            
            swiper.slideTo(Number(time[0])+8)
            
            setFirstRoundHour(false)

            return
        }

        const activeItem = swiper.activeIndex;
        const el = swiper.slides[activeItem]
        
        if(el)
            props.onSetHourOfTime({hour: el.innerHTML})
    }

    const updateMinAlarm = (swiper) => {

        if(firstRoundMin){
            const time = props.time.split(':')
            
            swiper.slideTo(Number(time[1])+8)

            setFirstRoundMin(false)

            return
        }

        const activeItem = swiper.activeIndex;
        const el = swiper.slides[activeItem]
        
        if(el)
            props.onSetMinOfTime({min: el.innerHTML})
    }

    return (
        <div className="time-picker relative w-64 mx-auto bg-white rounded-2xl">
            
            <div className="relative w-64 overflow-hidden mx-auto flex items-center justify-between border border-gray-200 rounded-2xl">
                        
                <Swiper 
                    className="group w-1/2 swiper-clock mins swiper h-48"
                    direction={'vertical'}
                    loop={true}
                    loopAdditionalSlides={5}
                    centeredSlides={true}
                    slidesPerView={3}
                    onSlideChange={updateMinAlarm}
                    slideToClickedSlide={true}
                    freeModeMomentumRatio={0.5}
                    freeModeMomentumVelocityRatio={1.25}
                    freeModeMinimumVelocity={0.1}
                    freeModeSticky={true}
                    freeMode={true}
                >
                    
                    {  Array.from({length: 60}).map((item, index) => 
                        <SwiperSlide key={'min-'+index} className="text-center text-xl flex items-center justify-center select-none opacity-25 duration-300 transition-opacity font-medium"> {index <= 9 ? `0${index}` : index} </SwiperSlide>
                    )}

                </Swiper>

                <Swiper 
                    className="group w-1/2 swiper-clock hours swiper h-48 border-r border-gray-200"
                    direction={'vertical'}
                    loop={true}
                    loopAdditionalSlides={5}
                    centeredSlides={true}
                    slidesPerView={3}
                    onSlideChange={updateHourAlarm}
                    slideToClickedSlide={true}
                    freeModeMomentumRatio={0.5}
                    freeModeMomentumVelocityRatio={0.25}
                    freeModeMinimumVelocity={0.1}
                    freeModeSticky={true}
                    freeMode={true}
                >

                    {  Array.from({length: 24}).map((item, index) => 
                        <SwiperSlide key={'hour-'+index} className="text-center text-xl flex items-center justify-center select-none opacity-25 duration-300 transition-opacity font-medium"> {index <= 9 ? `0${index}` : index} </SwiperSlide>
                    )}

                </Swiper>
                
            </div>

        </div>
    )

}

export default AddEventFormCustomTimeListColck