import { useState } from "react"
import { ReactSVG } from "react-svg"
import DropdownModal from "../../../../UI/Dropdown"
import ToastCustom from "../../../../UI/ToastCustom"
import ConfirmModalDelete from "../../../../UI/ConfirmModal/Delete"
import { useCookies } from "react-cookie"
import { useParams } from "react-router-dom"
import { useDispatch } from "react-redux"
import { deleteMessageFromChat } from "../../../../../redux/action/messages/chat/chatAction"
import ResendFileInChat from "../Image/ReSend"

const MessageContentFile = (props) => {

    const dispatch = useDispatch()

    const [showMoreOption, setShowMoreOption] = useState(false)
    const [showDownlodingState, setShowDownloadingState] = useState(false)

    const [showDeleteModal, setShowDeleteModal] = useState(false)
    const [deleteBoth, setDeleteBoth] = useState(true)

    const params = useParams()

    const [cookies] = useCookies(['authToken'])

    const handleShowMoreOption = (event) => {
        setShowMoreOption(true)

        document.body.classList.add('modal-opened')
    }

    const closeShowMoreOption = (value) => {
        setShowMoreOption(value)

        document.body.classList.remove('modal-opened')
    }

    const downloadFile = (event) => {
        
        event.stopPropagation()

        if( props.sendingStatus === 'error' ) return

        setTimeout(() => {
            setShowDownloadingState(true)
            closeShowMoreOption(false)
        }, 50);

        setTimeout(() => {
            setShowDownloadingState(false)
        }, 500);

        var element = document.createElement("a");
        element.href = props.src;
        element.download = props.caption + '-file';
        element.click();
    }

    const handleShowMessage = (event) => {
        event.stopPropagation()

        setShowDeleteModal(true)
        setShowMoreOption(false)
    }

    const deleteMessage = async () => {
        setShowDeleteModal(false)

        document.body.classList.remove('modal-opened')

        const scope = deleteBoth ? 'both' : 'self'
        await dispatch( await deleteMessageFromChat(props.id, params.id, scope, cookies.authToken) )
    }

    if(props.self)
        return (    
            <li 
                className="flex select-none flex-col gap-1.5 w-full relative"
                onClick={handleShowMoreOption}
                onContextMenu={handleShowMoreOption}
            >

                <div className="flex flex-col gap-1.5 w-fit lg:max-w-md md:max-w-sm max-w-xs">

                    <div onClick={downloadFile} className="bg-primary flex items-center gap-4 py-4 px-4 text-right text-white shadow-custom-md rounded-2xl rounded-tr-none">

                        <span> {props.caption} </span>

                        <button className="download-file bg-white/20 border-2 border-white/30 text-white flex-shrink-0 flex items-center justify-center w-12 h-12 rounded-xl">

                            <ReactSVG 
                                src={`${process.env.PUBLIC_URL}/assets/images/icons/download.svg`}
                                className="w-6 h-6 download-file"
                            />

                        </button>

                    </div>

                    <div className="flex justify-between items-center">
                                        
                        <span className="text-sm-13px text-gray-500"> {new Date(props.time)?.toLocaleTimeString('fa', {hour: '2-digit', minute: '2-digit', timeZone: '+0330'})} </span>

                        <div className="-translate-y-1 text-gray-500">
                            
                        { props.sendingStatus ?
                                props.sendingStatus === 'loading' ?
                                    <ReactSVG 
                                        src={`${process.env.PUBLIC_URL}/assets/images/icons/loading-circle.svg`}
                                        className="w-4 h-4 animate-spin"
                                    />
                                    :
                                    props.sendingStatus === 'error' ?
                                    <ReactSVG 
                                        src={`${process.env.PUBLIC_URL}/assets/images/icons/info-box.svg`}
                                        className="w-4 h-4 text-rose-500"
                                    />
                                    :
                                    <ReactSVG 
                                        src={`${process.env.PUBLIC_URL}/assets/images/icons/unseen.svg`}
                                        className="w-4 h-4 ml-1"
                                    />
                                :
                                ''
                            }

                            { props.seen ?

                                <ReactSVG 
                                    src={`${process.env.PUBLIC_URL}/assets/images/icons/seen.svg`}
                                    className="w-4 h-4"
                                />
                                : !props.sendingStatus &&
                                    <ReactSVG 
                                        src={`${process.env.PUBLIC_URL}/assets/images/icons/unseen.svg`}
                                        className="w-4 h-4 ml-1"
                                    />
                            }

                        </div>

                    </div>

                </div>

                { showMoreOption && 
                    
                    <DropdownModal
                        onSetShow={closeShowMoreOption}
                        classList={'border-gray-300 xm:!w-48 !border-1 !left-0 !top-0'}
                    >
                        
                        { props.sendingStatus && props.sendingStatus === 'error' &&
                            
                            <ResendFileInChat 
                                item={props.item}
                                onSetShowMoreOption={setShowMoreOption}
                            />
                                
                        }

                        <button onClick={downloadFile} className="p-2.5 text-sm flex outline-none select-none items-center gap-4 flex-wrap border-b border-b-gray-200 last:border-b-0 w-full text-gray-700 duration-300 relative">
                            
                            <ReactSVG src={`${process.env.PUBLIC_URL}/assets/images/icons/save.svg`} className="5xm:w-4 w-3.5 text-gray-500" />

                            ذخیره

                        </button>

                        <button onClick={handleShowMessage} className="p-2.5 text-sm flex outline-none select-none items-center gap-4 flex-wrap border-b border-b-gray-200 last:border-b-0 w-full text-gray-700 duration-300 relative">
                            
                            <ReactSVG src={`${process.env.PUBLIC_URL}/assets/images/icons/delete-3.svg`} className="5xm:w-[18px] w-3.5 text-gray-500" />

                            حذف

                        </button>

                    </DropdownModal>

                }

                { showDownlodingState && 
                    <ToastCustom>
                        درحال دانلود...
                    </ToastCustom>
                }

                { showDeleteModal &&
                    
                    <ConfirmModalDelete
                        title="حذف پیام"
                        description="آیا از حذف این پیام اطمینان دارید؟"
                        show={showDeleteModal}
                        onSetShow={setShowDeleteModal}
                        onConfirm={deleteMessage}
                        selectCount={1}
                    >   
    
                        <div className="flex justify-center text-gray-600">
                        
                            <input type="checkbox" id="deleteBothInput" className="accent-primary rounded-lg w-5 cursor-pointer" onChange={() => setDeleteBoth(!deleteBoth) } checked={deleteBoth} />
    
                            <label htmlFor="deleteBothInput" className="select-none cursor-pointer pr-2.5">حذف همچنین برای {props.name}</label>
    
                        </div>
    
                    </ConfirmModalDelete>
    
                }

            </li>
        )
        
    return (    
        <li 
            className="flex flex-col gap-1.5 w-full select-none"
            onClick={handleShowMoreOption}
            onContextMenu={handleShowMoreOption}
        >

            <div className="flex flex-col gap-1.5 w-fit mr-auto lg:max-w-md md:max-w-sm max-w-xs relative">

                <div onClick={downloadFile} className="bg-white flex items-center gap-4 py-4 px-4 text-gray-700 shadow-custom-lg rounded-2xl rounded-tl-none">

                    <span> {props.caption} </span>

                    <div className="bg-gray-200 border-2 border-gray-300 text-gray-700 flex-shrink-0 flex items-center justify-center w-12 h-12 rounded-xl">

                        <ReactSVG 
                            src={`${process.env.PUBLIC_URL}/assets/images/icons/download.svg`}
                            className="w-6 h-6"
                        />

                    </div>

                </div>

                <div className="flex justify-between items-center">
                                    
                    <span className="text-sm-13px text-gray-500"> {new Date(props.time)?.toLocaleTimeString('fa', {hour: '2-digit', minute: '2-digit', timeZone: '+0330'})} </span>

                </div>

                { showMoreOption && 
                        
                    <DropdownModal
                        onSetShow={closeShowMoreOption}
                        classList={'border-gray-300 xm:!w-48 !border-1 !-right-8 !top-0'}
                    >

                        <button onClick={downloadFile}  className="p-2.5 text-sm flex outline-none select-none items-center gap-4 flex-wrap border-b border-b-gray-200 last:border-b-0 w-full text-gray-700 duration-300 relative">
                            
                            <ReactSVG src={`${process.env.PUBLIC_URL}/assets/images/icons/save.svg`} className="5xm:w-4 w-3.5 text-gray-500" />

                            ذخیره

                        </button>

                    </DropdownModal>

                }

            </div>
            
            { showDownlodingState && 
                <ToastCustom>
                    درحال دانلود...
                </ToastCustom>
            }

            { showDeleteModal &&
                    
                <ConfirmModalDelete
                    title="حذف پیام"
                    description="آیا از حذف این پیام اطمینان دارید؟"
                    show={showDeleteModal}
                    onSetShow={setShowDeleteModal}
                    onConfirm={deleteMessage}
                    selectCount={1}
                >   

                    <div className="flex justify-center text-gray-600">
                    
                        <input type="checkbox" id="deleteBothInput" className="accent-primary rounded-lg w-5 cursor-pointer" onChange={() => setDeleteBoth(!deleteBoth) } checked={deleteBoth} />

                        <label htmlFor="deleteBothInput" className="select-none cursor-pointer pr-2.5">حذف همچنین برای {props.name}</label>

                    </div>

                </ConfirmModalDelete>

            }

        </li>
    )

}

export default MessageContentFile