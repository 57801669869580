import axios from 'axios'
import { apiKey } from '../../store'
import { ADD_CASE_TO_SINGLE_CASE, SET_CASES_CASES, SET_ERROR_ARCHIVE_LIKE_CASE, SET_ERROR_SINGLE_CASE, SET_FAILURE_LIST_CASES, SET_LIST_CASES, SET_LOADING_ARCHIVE_LIKE_CASE, SET_LOADING_LIST_CASES, SET_LOADING_SINGLE_CASE, SET_SUCCSESS_ARCHIVE_LIKE_CASE, UPDATE_ARCHIVE_DATA_FROM_CASE, UPDATE_FAVORITE_DATA_FROM_CASE } from './casesType'

const apiKeyCases = apiKey + 'client/law-suits'

export const getCasesLawyer = async (authToken) => {

    return async (dispatch) => {

        dispatch({
            type: SET_LOADING_LIST_CASES,
            payload: true
        })
    
        const config = {
            headers: {
                'Auth-Token': authToken,
                'X-Auth-Token': authToken,
                'header_app_type': 'client',
            },
            timeout: 15000
        }

        const data = await axios.get(`${apiKeyCases}`, config)
        .catch(error => {

            dispatch({
                type: SET_FAILURE_LIST_CASES,
                payload: 'عدم برقراری ارتباط با سرور'
            })

        })

        if(data){

            if(data.data.success){

                dispatch({
                    type: SET_CASES_CASES,
                    payload: {
                        list: data.data.data,
                        pagination: {}
                    }
                })

                return data.data.data
            }

            else if(!data.data.success && data.data.errors){

                dispatch({
                    type: SET_FAILURE_LIST_CASES,
                    payload: data.data.errors[0]?.message
                })

            }

        }

    }

}

export const getCasesList = async (authToken) => {

    return async (dispatch) => {

        dispatch({
            type: SET_LOADING_LIST_CASES,
            payload: true
        })
    
        const config = {
            headers: {
                'Auth-Token': authToken,
                'X-Auth-Token': authToken,
                'header_app_type': 'client',
            },
            timeout: 15000
        }

        const data = await axios.get(`${apiKeyCases}`, config)
        .catch(error => {

            dispatch({
                type: SET_FAILURE_LIST_CASES,
                payload: 'عدم برقراری ارتباط با سرور'
            })

        })

        if(data){

            if(data.data.success){

                dispatch({
                    type: SET_LIST_CASES,
                    payload: {
                        list: data.data.data,
                        pagination: data.data.pagination
                    }
                })

                return data.data.data
            }

            else if(!data.data.success && data.data.errors){

                dispatch({
                    type: SET_FAILURE_LIST_CASES,
                    payload: 'خطایی در گرفتن اطلاعات ب وجود اومده'
                })

            }

        }

    }

}


// -------------------------------------
// -------------------------------------
// -------------------------------------
export const getSingleCaseData = async (id, authToken) => {

    return async (dispatch) => {

        dispatch({
            type: SET_LOADING_SINGLE_CASE,
            payload: true
        })
    
        const config = {
            headers: {
                'Auth-Token': authToken,
                'X-Auth-Token': authToken,
                'header_app_type': 'client',
            },
            timeout: 15000
        }

        const data = await axios.get(`${apiKeyCases}/${id}`, config)
        .catch(error => {

            dispatch({
                type: SET_ERROR_SINGLE_CASE,
                payload: 'عدم برقراری ارتباط با سرور'
            })

        })

        if(data){

            if(data.data.success){

                dispatch({
                    type: ADD_CASE_TO_SINGLE_CASE,
                    payload: {
                        id,
                        data: data.data.data
                    }
                })

                return data.data.data
            }

            else if(!data.data.success && data.data.errors){

                dispatch({
                    type: SET_ERROR_SINGLE_CASE,
                    payload: data.data.errors[0].message
                })

            }

        }

    }

}

// -------------------------------------
// -------------------------------------
// -------------------------------------
export const caseMoveToArchive = async (id, authToken, listLoaded) => {

    return async (dispatch) => {

        dispatch({
            type: SET_LOADING_ARCHIVE_LIKE_CASE,
            payload: true
        })
    
        const config = {
            headers: {
                'Auth-Token': authToken,
                'X-Auth-Token': authToken,
                'header_app_type': 'client',
            },
            timeout: 15000
        }

        const data = await axios.post(`${apiKeyCases}/${id}/archive`, null, config)
        .catch(error => {

            dispatch({
                type: SET_ERROR_ARCHIVE_LIKE_CASE,
                payload: 'عدم برقراری ارتباط با سرور'
            })

            setTimeout(() => {

                dispatch({
                    type: SET_ERROR_ARCHIVE_LIKE_CASE,
                    payload: null
                })

            }, 2000)

            return false

        })

        if(data){

            if(data.data.success){

                dispatch({
                    type: SET_SUCCSESS_ARCHIVE_LIKE_CASE,
                    payload: 'پرونده به لیست بایگانی اضافه شد'
                })  

                setTimeout(() => {

                    dispatch({
                        type: SET_SUCCSESS_ARCHIVE_LIKE_CASE,
                        payload: null
                    })  

                }, 1500)

                // update case by base archive data
                if(listLoaded)
                    dispatch({
                        type: UPDATE_ARCHIVE_DATA_FROM_CASE,
                        payload: {
                            id,
                            status: true
                        }
                    })

                return true
            }

            else if(!data.data.success && data.data.errors){

                dispatch({
                    type: SET_ERROR_ARCHIVE_LIKE_CASE,
                    payload: data.data.errors[0].message
                })

                setTimeout(() => {

                    dispatch({
                        type: SET_ERROR_ARCHIVE_LIKE_CASE,
                        payload: null
                    })
    
                }, 2200)

                return false
            }

        }

    }

}

export const caseMoveToUnarchive = async (id, authToken, listLoaded) => {

    return async (dispatch) => {

        dispatch({
            type: SET_LOADING_ARCHIVE_LIKE_CASE,
            payload: true
        })
    
        const config = {
            headers: {
                'Auth-Token': authToken,
                'X-Auth-Token': authToken,
                'header_app_type': 'client',
            },
            timeout: 15000
        }

        const data = await axios.post(`${apiKeyCases}/${id}/unarchive`, null, config)
        .catch(error => {

            dispatch({
                type: SET_ERROR_ARCHIVE_LIKE_CASE,
                payload: 'عدم برقراری ارتباط با سرور'
            })

            setTimeout(() => {

                dispatch({
                    type: SET_ERROR_ARCHIVE_LIKE_CASE,
                    payload: null
                })

            }, 2000)

        })

        if(data){

            if(data.data.success){

                dispatch({
                    type: SET_SUCCSESS_ARCHIVE_LIKE_CASE,
                    payload: 'پرونده از لیست بایگانی خارج شد'
                })  

                setTimeout(() => {

                    dispatch({
                        type: SET_SUCCSESS_ARCHIVE_LIKE_CASE,
                        payload: null
                    })  

                }, 1500)

                // update case by base archive data
                if(listLoaded)
                    dispatch({
                        type: UPDATE_ARCHIVE_DATA_FROM_CASE,
                        payload: {
                            id,
                            status: false
                        }
                    })

                return true
            }

            else if(!data.data.success && data.data.errors){

                dispatch({
                    type: SET_ERROR_ARCHIVE_LIKE_CASE,
                    payload: data.data.errors[0].message
                })

                setTimeout(() => {

                    dispatch({
                        type: SET_ERROR_ARCHIVE_LIKE_CASE,
                        payload: null
                    })
    
                }, 2200)

                return false

            }

        }

    }

}

export const caseMoveToFavorite = async (id, authToken) => {

    return async (dispatch) => {

        dispatch({
            type: SET_LOADING_ARCHIVE_LIKE_CASE,
            payload: true
        })
    
        const config = {
            headers: {
                'Auth-Token': authToken,
                'X-Auth-Token': authToken,
                'header_app_type': 'client',
            },
            timeout: 15000
        }

        const data = await axios.post(`${apiKeyCases}/${id}/favorite`, null, config)
        .catch(error => {

            dispatch({
                type: SET_ERROR_ARCHIVE_LIKE_CASE,
                payload: 'عدم برقراری ارتباط با سرور'
            })

            setTimeout(() => {

                dispatch({
                    type: SET_ERROR_ARCHIVE_LIKE_CASE,
                    payload: null
                })

            }, 2000)

            return false

        })

        if(data){

            if(data.data.success){

                dispatch({
                    type: SET_SUCCSESS_ARCHIVE_LIKE_CASE,
                    payload: 'به لیست نشان‌شده‌ها اضافه شد'
                })  

                setTimeout(() => {

                    dispatch({
                        type: SET_SUCCSESS_ARCHIVE_LIKE_CASE,
                        payload: null
                    })  

                }, 1500)

                // update case by base archive data
                dispatch({
                    type: UPDATE_FAVORITE_DATA_FROM_CASE,
                    payload: {
                        id,
                        status: true
                    }
                })

                return true
            }

            else if(!data.data.success && data.data.errors){

                dispatch({
                    type: SET_ERROR_ARCHIVE_LIKE_CASE,
                    payload: data.data.errors[0].message
                })

                setTimeout(() => {

                    dispatch({
                        type: SET_ERROR_ARCHIVE_LIKE_CASE,
                        payload: null
                    })
    
                }, 2200)

                return false
            }

        }

    }

}

export const caseMoveToUnfavorite = async (id, authToken) => {

    return async (dispatch) => {

        dispatch({
            type: SET_LOADING_ARCHIVE_LIKE_CASE,
            payload: true
        })
    
        const config = {
            headers: {
                'Auth-Token': authToken,
                'X-Auth-Token': authToken,
                'header_app_type': 'client',
            },
            timeout: 15000
        }

        const data = await axios.post(`${apiKeyCases}/${id}/unfavorite`, null, config)
        .catch(error => {

            dispatch({
                type: SET_ERROR_ARCHIVE_LIKE_CASE,
                payload: 'عدم برقراری ارتباط با سرور'
            })

            setTimeout(() => {

                dispatch({
                    type: SET_ERROR_ARCHIVE_LIKE_CASE,
                    payload: null
                })

            }, 2000)

        })

        if(data){

            if(data.data.success){

                dispatch({
                    type: SET_SUCCSESS_ARCHIVE_LIKE_CASE,
                    payload: 'از لیست نشان‌شده‌ها حذف شد'
                })  

                setTimeout(() => {

                    dispatch({
                        type: SET_SUCCSESS_ARCHIVE_LIKE_CASE,
                        payload: null
                    })  

                }, 1500)

                // update case by base archive data
                dispatch({
                    type: UPDATE_FAVORITE_DATA_FROM_CASE,
                    payload: {
                        id,
                        status: false
                    }
                })

                return true
            }

            else if(!data.data.success && data.data.errors){

                dispatch({
                    type: SET_ERROR_ARCHIVE_LIKE_CASE,
                    payload: data.data.errors[0].message
                })

                setTimeout(() => {

                    dispatch({
                        type: SET_ERROR_ARCHIVE_LIKE_CASE,
                        payload: null
                    })
    
                }, 2200)

                return false

            }

        }

    }

}

