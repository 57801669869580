export const savePeymented = (data = false) => {
    localStorage.setItem('have_paymented', data)
}

export const getPeymentedStatus = () => {
    const data =  localStorage.getItem('have_paymented')

    if(data){
        return JSON.parse( data )
    }

    return false
}