import { useSelector } from "react-redux"
import { ReactSVG } from "react-svg"

const CaseContentTitle = (props) => {

    const singleCaseError = useSelector(state => state.cases.singleCaseError)

    return (
        <div className="rounded-2xl shadow-custom-lg border-[3px] border-white p-4 mb-8">
                            
            <h2 className="font-medium text-gray-500 mb-3 flex items-center gap-2">

                <ReactSVG 
                    src={`${process.env.PUBLIC_URL}/assets/images/icons/info-box.svg`}
                    className="w-5"
                />

                { props.label }

            </h2>
            
            <h3 className="text font-medium 6xm:text-lg text-base flex items-center gap-2"> 
            
                <ReactSVG 
                    src={`${process.env.PUBLIC_URL}/assets/images/icons/cases.svg`}
                    className="w-5"
                />

                {props.title ?
                
                    props.title

                    :

                    !singleCaseError &&
                        <ReactSVG 
                            src={`${process.env.PUBLIC_URL}/assets/images/icons/loading-dot.svg`}
                            className="w-9 mr-3"
                        />
                
                } 
            
            </h3>

        </div>
    )

}

export default CaseContentTitle