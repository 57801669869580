import { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"

import { HIDE_SEARCH, SET_CONTACTS_SEARCH } from "../../../redux/action/search/searchType"

import SearchBox from "../../Header/Search"

import { searchInList } from "../../../utils/search"
// import { useCookies } from "react-cookie"
// import { searchInContacts } from "../../../redux/action/contacts/contactsAction"
import { SET_FILTER_CONTACTS, SET_LOADING_CONTACTS, UNSET_LOADING_CONTACTS } from "../../../redux/action/contacts/typeContacts"

const ContactsSearch = () => {

    const dispatch = useDispatch()
    const showSearch = useSelector(state => state.search.showSearch)
    const searchValue = useSelector(state => state.search.contactsSearch)
    const filtered = useSelector(state => state.contacts.filtered)
    const contacts = useSelector(state => state.contacts.contacts)

    // const [cookies] = useCookies(['authToken'])

    useEffect(() => {

        if(!searchValue && showSearch){
            // close the search state
            hideSearch()
        }   

    }, [])

    useEffect(() => {
    
        // dont send request to server when not in search state
        if(searchValue === '' || !showSearch ||  filtered.searchedValue === searchValue) return
        if(contacts.length <= 0) return

        // fake loading - for watching better user.
        dispatch({
            type: SET_LOADING_CONTACTS
        })

        const timeout = setTimeout(async () => {

            // search in server
            // await dispatch( await searchInContacts(searchValue, cookies.authToken) )

            // search in front
            doSearch()

        }, 600)

        return () => {
            clearTimeout(timeout)
        }

    }, [searchValue])

    const updateSearchField = (event) => {

        if(event.target.value === ''){
            clearSearchValue()
            return
        }

        dispatch({
            type: SET_CONTACTS_SEARCH,
            payload: event.target.value
        })

    }

    const clearSearchValue = () => {
        
        dispatch({
            type: SET_CONTACTS_SEARCH,
            payload: ''
        })
        
        // if exist search-value come show fake loading
        if(searchValue){
            dispatch({
                type: SET_FILTER_CONTACTS,
                payload: {
                    value: '',
                    list: []
                }
            })

            // set fake loading
            dispatch({
                type: SET_LOADING_CONTACTS
            })
    
            setTimeout(() => {
                
                dispatch({
                    type: UNSET_LOADING_CONTACTS
                })
    
            }, 250)
        }

    }

    const hideSearch = () => {
        
        // hide search
        dispatch({
            type: HIDE_SEARCH
        })

        clearSearchValue()

    }

    const doSearch = () => {
        const filtered = searchInList(contacts, searchValue, ['name', 'mobile'])

        dispatch({
            type: SET_FILTER_CONTACTS,
            payload: {
                value: searchValue,
                list: filtered
            }
        })        
    }

    return (
        <SearchBox 
            value={searchValue}
            onUpdate={ updateSearchField }
            onClear={clearSearchValue}
        />
    )

}

export default ContactsSearch