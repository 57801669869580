export const time = 4.5

export const checkExistAuthStep1 = () => {

    // get auth-step-1-info from localstorage
    const authStep1 = JSON.parse(localStorage.getItem('auth-step-1'))

    // if not exist come back
    if(!authStep1){
        return
    } 

    // get now date
    const date = new Date()
    // set time local-storage
    const authenticateDate = new Date(authStep1.time)
    
    // subtract now of time local-storage
    const result = (authStep1.finishTime - date)

    // if less than 5 min is ok
    if(result >= 1){
        return {
            mobile: authStep1.mobile,
            remainTime: result,
            finishTime: authStep1.finishTime
        }
    }
    else {
        // exist info but -->expire<-- data.
        localStorage.removeItem('auth-step-1')

        return false
    }

}

export const setAuthStep1 = (data) => {
    localStorage.setItem( 'auth-step-1', JSON.stringify({...data, finishTime: new Date().getTime() + ( (10 * 1000 * 6) * 2)}) )
}

export const removeAuthStep1 = () => {
    localStorage.removeItem('auth-step-1')
}