import { useCookies } from "react-cookie"
import { useDispatch, useSelector } from "react-redux"
import { useParams } from "react-router-dom"
import { ReactSVG } from "react-svg"
import { sendMessageToChat } from "../../../../../../redux/action/messages/chat/chatAction"
import { ADD_TO_LIST_MESSAGES } from "../../../../../../redux/action/messages/list/listType"
import { CHANGE_STATUS_TEXT_MESSAGE_ITEM_IN_CHAT } from "../../../../../../redux/action/messages/chat/chatType"

const ResendTextInChat = (props) => {

    const dispatch = useDispatch()
    const error = useSelector(state => state.messages.chat.error)  
    const messages = useSelector(state => state.messages.chat.messages)
    const receiver = useSelector(state => state.messages.chat.receiver)
    const loading = useSelector(state => state.messages.chat.loading)
    
    const [cookies] = useCookies(['authToken'])

    const params = useParams()

    const resend = async (event) => {
        
        event.stopPropagation()

        // hidden the more option
        props.onSetShowMoreOption(false)
        document.body.classList.remove('modal-opened')

        makeLoadingStatusInMessage()

        sendMessage()
        
    }

    const makeLoadingStatusInMessage = () => {
        
        dispatch({
            type: CHANGE_STATUS_TEXT_MESSAGE_ITEM_IN_CHAT,
            payload: {
                fakeId: props.item.id,
                id: props.item.id,
                receiver_id: params.id,
                status: 'loading'
            }
        })

    }
    
    const sendMessage = async () => {

        // add message to server
        const data = await dispatch( await sendMessageToChat(params.id, props.item.body, cookies.authToken, props.item?.id) )
                    
        if(data){
    
            if(messages.length === 0){
                
                if(props.body.length >= 1){
    
                    const message = {
                        id: params.id,
                        name: receiver.name,
                        mobile: params.phone,
                        image: receiver.image,
                        unseen_count: 0,
                        last_message: {
                            id: Math.random()*209384+'text-item',
                            type: "text",
                            body: props.body,
                            created_at: new Date()
                        }
                    }
    
                    dispatch({
                        type: ADD_TO_LIST_MESSAGES,
                        payload: message
                    })
    
                }
    
            }
    
        }

    }

    return (
        <button onClick={resend} className="p-2.5 text-sm flex outline-none select-none items-center gap-4 flex-wrap border-b border-b-gray-200 last:border-b-0 w-full text-gray-700 duration-300 relative">
                                
            <ReactSVG src={`${process.env.PUBLIC_URL}/assets/images/icons/step.svg`} className="5xm:w-[18px] w-3 text-gray-500" />

            ارسال مجدد

        </button>
    )

}

export default ResendTextInChat