import { useEffect } from "react"
import { useCookies } from "react-cookie"
import { useDispatch, useSelector } from "react-redux"
import { useLocation, useParams } from "react-router-dom"
import { getContactInfo } from "../../../redux/action/contacts/contactsAction"
import { SET_CONTACT_INFO } from "../../../redux/action/contacts/typeContacts"

import ContactEditForm from "./Form"

const ContactEditSection = () => {

    const dispatch = useDispatch()
    const loading = useSelector(state => state.contacts.loading)
    const contactInfo = useSelector(state => state.contacts.contactInfo)

    const params = useParams()

    const [cookies] = useCookies(['authToken'])

    useEffect(() => {

        if(contactInfo.id != params.id){
            getUserInfo()
        }

    }, [])

    const getUserInfo = async () => {
        if(!loading)
            await dispatch( await getContactInfo(params.id, cookies.authToken) )
    }

    return (
        <section>
            
            <ContactEditForm />

        </section>
    )

}

export default ContactEditSection