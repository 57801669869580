import { Link } from "react-router-dom"

const HomeSliderItem = (props) => {

    return (
        <Link to={'https://' + props.link} className="block w-full h-full">

            <img 
                className="w-full h-full rounded-2xl object-cover" 
                src={props.image} alt="" 
            />

            <div className="flex flex-col items-start justify-end p-3 pt-10 absolute bottom-0 left-0 right-0 bg-gradient-to-t from-gray-900 to-gray-500/5">

                <h3 className="text-white mb-2 md:text-lg sm:text-base"> {props.caption} </h3>

            </div>

        </Link>
    )

}

export default HomeSliderItem