import { useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import InfiniteScroll from 'react-infinite-scroller'
import { ReactSVG } from "react-svg"
import { useCookies } from "react-cookie"

import { loadMoreWalletHistory } from "../../../redux/action/wallet/action"

import WalletHistoryItem from "./Item"

const WalletHistory = () => {

    const dispatch = useDispatch()
    const history = useSelector(state => state.wallet.wallet.history)
    const pagination = useSelector(state => state.wallet.pagination )
    const walletLoading = useSelector(state => state.wallet.loading)

    const [loading, setLoading] = useState(false)

    const [cookies] = useCookies(['authToken'])
 
    const loadMore = async () => {

        if(pagination?.has_more_pages && history && history.length >= 1 && !loading){
            
            setLoading(true) 
            
            const data = await dispatch( await loadMoreWalletHistory(pagination.current_page + 1, cookies.authToken) )
            
            if(data){
                setLoading(false)
            }

        }

    }

    return (
        <>

            <section className="lg:mb-3.5 md:mb-6 mb-4">

                <h2 className="mb-6 font-medium text-lg"> لیست تراکنش&zwnj;ها </h2>

                { !walletLoading ?  
                
                    <InfiniteScroll
                        pageStart={1}
                        loadMore={loadMore}
                        hasMore={pagination.has_more_pages}
                        loader={
                            <ReactSVG
                                key={0}
                                className="w-10 text-primary mx-auto block mt-10"
                                src={`${process.env.PUBLIC_URL}/assets/images/icons/loading-dot.svg`}
                            />
                        }
                    >
                        
                        <ul className="grid md:grid-cols-2 grid-cols-1 gap-x-8 gap-y-4">
                        
                            { history && history.length >= 1 && history?.map((item, index) => 
                                
                                <WalletHistoryItem 
                                    key={'wallet-histoy-item-' + item?.related?.id + '-' + index}
                                    action={item?.action}
                                    amount={item?.amount}
                                    title={item?.related?.title}
                                    created_at={item?.created_at}
                                />

                                )}
                        
                        </ul>

                    </InfiniteScroll>

                    :
                    
                    <div className="h-14 w-full flex items-center justify-center">

                        <ReactSVG
                            className="w-10 text-primary"
                            src={`${process.env.PUBLIC_URL}/assets/images/icons/loading-dot.svg`}
                        />

                    </div>
                    
                }


            </section>

        </>
    )

}

export default WalletHistory