export const SET_LOADING_LIST_CASES = 'SET_LOADING_LIST_CASES'
export const SET_FAILURE_LIST_CASES = 'SET_FAILURE_LIST_CASES'
export const SET_LIST_CASES = 'SET_LIST_CASES'

export const SET_CASES_CASES = 'SET_CASES_CASES'

export const SET_FILTER_CASES = 'SET_FILTER_CASES'

export const SHOW_FILTER_SECTION_CASES = 'SHOW_FILTER_SECTION_CASES'
export const SET_FILEDS_OF_FILTER = 'SET_FILEDS_OF_FILTER'
export const RESET_FILEDS_OF_FILTER = 'RESET_FILEDS_OF_FILTER'
export const SET_FILTER_SECTION_LOADING_CASES = 'SET_FILTER_SECTION_LOADING_CASES'
export const SET_FILTER_FOR_CASES = 'SET_FILTER_FOR_CASES'

export const ADD_CASE_TO_CASES = 'ADD_CASE_TO_CASES'

export const SET_DELETE_ERROR_CASE = 'DELETE_ERROR_CASE'
export const SET_DELETE_LOADING_CASE = 'DELETE_LOADING_CASE'
export const DELETE_ITEM_OF_CASES = 'DELETE_ITEM_OF_CASES'

export const ADD_CASE_TO_SINGLE_CASE = 'ADD_CASE_TO_SINGLE_CASE'
export const SET_LOADING_SINGLE_CASE = 'SET_LOADING_SINGLE_CASE'
export const SET_ERROR_SINGLE_CASE = 'SET_ERROR_SINGLE_CASE'
export const SET_ACTIVE_TAB_SINGLE_CASE = 'SET_ACTIVE_TAB_SINGLE_CASE'
export const SET_LAST_OPENED_SINGLE_CASE = 'SET_LAST_OPENED_SINGLE_CASE'
export const SET_SHOW_DESCRIPTION_COLLAPSE_SINGLE_CASE = 'SET_SHOW_DESCRIPTION_COLLAPSE_SINGLE_CASE'
export const UPDATE_NOTE_IN_SINGLE_CASE = 'UPDATE_NOTE_IN_SINGLE_CASE'
export const DELETE_ITEM_NOTE_SINGLE_CASE = 'DELETE_ITEM_NOTE_SINGLE_CASE'
export const DELETE_ITEMS_NOTE_SINGLE_CASE = 'DELETE_ITEMS_NOTE_SINGLE_CASE'
export const ADD_ITEM_TO_NOTE_IN_SINGLE_CASE = 'ADD_ITEM_TO_NOTE_IN_SINGLE_CASE'
export const SET_SORTED_INTO_SINGLE_CASE = 'SET_SORTED_INTO_SINGLE_CASE'
export const DELETE_EVENT_FROM_SINGLE_CASE = 'DELETE_EVENT_FROM_SINGLE_CASE'
export const UPDATE_EVENT_ITEM_IN_SINGLE_CASE = 'UPDATE_EVENT_ITEM_IN_SINGLE_CASE'
export const SET_SINGLES_CASES = 'SET_SINGLES_CASES'
export const ADD_EVENT_TO_SINGLE_CASE = 'ADD_EVENT_TO_SINGLE_CASE'

export const UPDATE_USERS_FROM_CASES = 'UPDATE_USETR_FROM_CASES'
export const UPDATE_CASE_FROM_CASES = 'UPDATE_CASE_FROM_CASES'


export const SET_LOADING_ARCHIVE_LIKE_CASE = 'SET_LOADING_ARCHIVE_LIKE_CASE'
export const SET_ERROR_ARCHIVE_LIKE_CASE = 'SET_ERROR_ARCHIVE_LIKE_CASE'
export const SET_SUCCSESS_ARCHIVE_LIKE_CASE = 'SET_SUCCSESS_ARCHIVE_LIKE_CASE'
export const UPDATE_ARCHIVE_DATA_FROM_CASE = 'UPDATE_ARCHIVE_DATA_FROM_CASE'
export const UPDATE_ARCHIVE_DATA_FROM_SINGLE_CASE = 'UPDATE_ARCHIVE_DATA_FROM_SINGLE_CASE'
export const SET_EXIST_FILTER_IS_ARCHIVE = 'SET_EXIST_FILTER_IS_ARCHIVE'
export const SET_EXIST_FILTER_IS_FAVORITE = 'SET_EXIST_FILTER_IS_FAVORITE'
export const REMOVE_FROM_ARCHIVE_CASE_IN_FILTER = 'REMOVE_FROM_ARCHIVE_CASE_IN_FILTER'

export const UPDATE_FAVORITE_DATA_FROM_CASE = 'UPDATE_FAVORITE_DATA_FROM_CASE'
export const UPDATE_FAVORITE_DATA_FROM_SINGLE_CASE = 'UPDATE_FAVORITE_DATA_FROM_SINGLE_CASE'
export const REMOVE_FROM_FAVORITE_CASE_IN_FILTER = 'REMOVE_FROM_FAVORITE_CASE_IN_FILTER'

export const SET_FILE_LOADING = 'SET_FILE_LOADING'
export const SET_FILE_SUCCESSFULLY = 'SET_FILE_SUCCESSFULLY'
export const SET_FILE_FAILURE = 'SET_FILE_FAILURE'
export const ADD_FILE_TO_FILES_IN_CASE = 'ADD_FILE_TO_FILES_IN_CASE'
export const DELETE_FILE_FROM_FILES_IN_CASE = 'DELETE_FILE_FROM_FILES_IN_CASE'

export const SET_FINANCIAL_DELETE_LOADING = 'SET_FINANCIAL_DELETE_LOADING'
export const SET_FINANCIAL_FAILURE = 'SET_FINANCIAL_FAILURE'
export const SET_FINANCIAL_DELETE_SUCCESSFULLY = 'SET_FINANCIAL_DELETE_SUCCESSFULLY'
export const DELETE_FINANCIAL_FROM_CASE = 'DELETE_FINANCIAL_FROM_CASE'
export const SET_FINANCIAL_LOADING = 'SET_FINANCIAL_LOADING'
export const ADD_FINANCIAL_TO_CASE = 'ADD_FINANCIAL_TO_CASE'
export const EDIT_FINANCIAL_TO_CASE = 'EDIT_FINANCIAL_TO_CASE'


export const RESTE_ALL_CONTACTS_DATA_APP = 'RESTE_ALL_CONTACTS_DATA_APP'

export const RESTE_ALL_CASES_DATA_APP = 'RESTE_ALL_CASES_DATA_APP'