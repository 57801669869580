import { useEffect, useState } from "react"
import { checkExistAuthStep1, removeAuthStep1 } from "../../../../../../utils/auth"
import { add } from "date-fns-jalali"
import { useNavigate } from "react-router-dom"

const VerifyTitleTimer = () => {

    const [timer, setTimer] = useState()

    const navigate = useNavigate()

    useEffect(() => {

        const result =  checkExistAuthStep1()

        let interval;

        if(result){

            const curretTime = new Date()
            const timeEnd = result.finishTime

            const diffMillis = timeEnd - curretTime

            if(diffMillis <= 0) {
                navigate('/auth/login', {replace: true})
            }

            let diffSeconds = Math.floor(diffMillis / 1000);

            if(diffSeconds >= 0){
                
                setTimer({
                    mins: Math.floor(diffSeconds / 60),
                    seconds: Math.floor(diffSeconds % 60)
                })

            }

            if(!interval){
    
                interval = setInterval(() => {
    
                    if( Number(diffSeconds) <= 0 ){
                        clearInterval(interval)
                        interval = null
    
                        navigate('/auth/login', {replace: true})
                    }
    
                    setTimer({
                        mins: Math.floor(diffSeconds / 60),
                        seconds: Math.floor(diffSeconds % 60)
                    })

                    --diffSeconds
        
                }, 1000);

            }

        }

        return () => {
            if(interval){
                clearInterval(interval)
                interval = null
            }
        }

    }, [])

    return (
        <>
            <div className="flex items-center gap-2 flex-wrap absolute left-16 translate-x-4 translate-y-1 -top-32">

                <div className="bg-white font-medium text-sm rounded-md shadow-custom-lg text-primary px-3 py-1 border border-primary/30 ">
                    <span className="translate-y-0.5 block"> {timer?.mins && timer?.mins <= 9 && timer.mins >= 0 ?  '0' + timer?.mins : timer?.mins <= 0 ? '00' : '02'}:{timer?.seconds ? timer?.seconds < 10 ? '0' + timer?.seconds : timer?.seconds : '00'} </span>
                </div>

            </div>  
        </>
    )

}

export default VerifyTitleTimer