import axios from 'axios'
import { apiKey } from '../../store'
import { ADD_EVENTS_TO_FILTER_EVENTS, ADD_ITEM_TO_LIST_EVENTS, ADD_NEW_EVENTS_TO_DATE_CALENDAR, ADD_NEW_EVENTS_TO_LIST, ADD_TO_CALENDAR_ITEMS, DELETE_ITEM_EVENT, DELETE_ITEM_EVENT_DAY, EDIT_ITEM_EVENT, SET_CALENDAR_ITEMS, SET_DAY_EVENTS_TO_EVENTS_CALENDAR, SET_EVENTS_LIST, SET_EVENT_DATA_ERROR, SET_EVENT_DATA_LOADING, SET_EVENT_DATA_VALUES, SET_FAILURE_CALENDAR, SET_FAILURE_CALENDAR_DATE_EVENTS, SET_FAILURE_EVENTS, SET_FILTER_ERROR_EVENTS, SET_FILTER_EVENTS, SET_LOADING_CALENDAR, SET_LOADING_CALENDAR_DATE_EVENTS, SET_LOADING_EVENTS, SET_LOADMORE_ERROR_EVENTS, SET_LOADMORE_LOADING_EVENTS, SET_PAGINATION_EVENTS_LIST } from './eventsType'

const apiKeyEvents = apiKey + 'client/events'

export const getDataFromCalendar = async (start, end, authToken) => {

    return async (dispatch) => {

        axios.defaults.timeout = 15000
        
        dispatch({
            type: SET_LOADING_CALENDAR,
            payload: true
        })
    
        const config = {
            headers: {
                'Auth-Token': authToken,
                'X-Auth-Token': authToken,
                'header_app_type': 'client',
            },
            timeout: 15000
        }

        const data = await axios.get(`${apiKeyEvents}/all?fromDate=${start}&toDate=${end}`, config)
        .catch(error => {

            dispatch({
                type: SET_FAILURE_CALENDAR,
                payload: 'عدم برقراری ارتباط با سرور'
            })

            setTimeout(() => {

                dispatch({
                    type: SET_FAILURE_CALENDAR,
                    payload: null
                })

            }, 1200)

        })

        if(data){

            if(data.data.success){

                dispatch({
                    type: SET_CALENDAR_ITEMS,
                    payload: data.data.data
                })

                return data.data.data

            }

            else if(!data.data.success && data.data.errors){

                dispatch({
                    type: SET_FAILURE_CALENDAR,
                    payload: data.data.errors[0]?.message
                })

                setTimeout(() => {

                    dispatch({
                        type: SET_FAILURE_CALENDAR,
                        payload: null
                    })
    
                }, 1200)

            }

        }

    }

}

export const getMoreDataFromCalendar = async (start, end, authToken) => {

    return async (dispatch) => {
        
        dispatch({
            type: SET_LOADING_CALENDAR,
            payload: true
        })
    
        const config = {
            headers: {
                'Auth-Token': authToken,
                'X-Auth-Token': authToken,
                'header_app_type': 'client',
            },
            timeout: 15000
        }

        const data = await axios.get(`${apiKeyEvents}/all?fromDate=${start}&toDate=${end}`, config)
        .catch(error => {

            dispatch({
                type: SET_FAILURE_CALENDAR,
                payload: 'عدم برقراری ارتباط با سرور'
            })
            
            setTimeout(() => {

                dispatch({
                    type: SET_FAILURE_CALENDAR,
                    payload: null
                })

            }, 1200)

        })

        if(data){

            if(data.data.success){

                dispatch({
                    type: ADD_TO_CALENDAR_ITEMS,
                    payload: data.data.data
                })

                return data.data.data

            }

            else if(!data.data.success && data.data.errors){

                dispatch({
                    type: SET_FAILURE_CALENDAR,
                    payload: 'خطایی در گرفتن اطلاعات ب وجود اومده'
                })

                setTimeout(() => {

                    dispatch({
                        type: SET_FAILURE_CALENDAR,
                        payload: null
                    })
    
                }, 1200)

            }

        }

    }

}

export const getEventsListByDate = async (start, end, authToken) => {

    return async (dispatch) => {

        dispatch({
            type: SET_LOADING_CALENDAR_DATE_EVENTS,
            payload: true
        })
    
        const config = {
            headers: {
                'Auth-Token': authToken,
                'X-Auth-Token': authToken,
                'header_app_type': 'client',
            },
            timeout: 15000
        }

        const data = await axios.get(`${apiKeyEvents}?fromDate=${start}&toDate=${end}`, config)
        .catch(error => {

            dispatch({
                type: SET_FAILURE_CALENDAR_DATE_EVENTS,
                payload: 'عدم برقراری ارتباط با سرور'
            })

        })

        if(data){

            if(data.data.success){

                dispatch({
                    type: SET_DAY_EVENTS_TO_EVENTS_CALENDAR,
                    payload: {
                        [start]: data.data.data,
                        pagination: data.data.pagination
                    }
                })

                return data.data.data

            }

            else if(!data.data.success && data.data.errors){

                dispatch({
                    type: SET_FAILURE_CALENDAR_DATE_EVENTS,
                    payload: 'خطایی در گرفتن اطلاعات ب وجود اومده'
                })


            }

        }

    }

}

export const getMoreEventsListByDate = async (page, start, end, authToken) => {

    return async (dispatch) => {

        dispatch({
            type: SET_LOADMORE_LOADING_EVENTS,
            payload: true
        })
    
        const config = {
            headers: {
                'Auth-Token': authToken,
                'X-Auth-Token': authToken,
                'header_app_type': 'client',
            },
            timeout: 15000
        }

        const data = await axios.get(`${apiKeyEvents}?fromDate=${start}&toDate=${end}&page=${page + 1}`, config)
        .catch(error => {

            dispatch({
                type: SET_LOADMORE_ERROR_EVENTS,
                payload: 'عدم برقراری ارتباط با سرور'
            })

            setTimeout(() => {
                
                dispatch({
                    type: SET_LOADMORE_ERROR_EVENTS,
                    payload: null
                })

            }, 1200);

        })

        if(data){

            if(data.data.success){

                dispatch({
                    type: ADD_NEW_EVENTS_TO_DATE_CALENDAR,
                    payload: {
                        date: start,
                        data : data.data.data,
                        pagination: data.data.pagination
                    }
                })

                return data.data.data

            }

            else if(!data.data.success && data.data.errors){

                dispatch({
                    type: SET_LOADMORE_ERROR_EVENTS,
                    payload: data.data.errors[0].message
                })

                setTimeout(() => {
                
                    dispatch({
                        type: SET_LOADMORE_ERROR_EVENTS,
                        payload: null
                    })
    
                }, 1200);

            }

        }

    }

}


// ----------------------------

export const getEventsList = async (authToken) => {

    return async (dispatch) => {

        dispatch({
            type: SET_LOADING_EVENTS,
            payload: true
        })
    
        const config = {
            headers: {
                'Auth-Token': authToken,
                'X-Auth-Token': authToken,
                'header_app_type': 'client',
            },
            timeout: 15000
        }

        const data = await axios.get(`${apiKeyEvents}`, config)
        .catch(error => {

            dispatch({
                type: SET_FAILURE_EVENTS,
                payload: 'عدم برقراری ارتباط با سرور'
            })

        })

        if(data){

            if(data.data.success){

                dispatch({
                    type: SET_EVENTS_LIST,
                    payload: data.data.data
                })

                dispatch({
                    type: SET_PAGINATION_EVENTS_LIST,
                    payload: data.data.pagination
                })

                return data.data.data

            }

            else if(!data.data.success && data.data.errors){

                dispatch({
                    type: SET_FAILURE_EVENTS,
                    payload: 'خطایی در گرفتن اطلاعات ب وجود اومده'
                })


            }

        }

    }

}

export const getMoreEventsList = async (page, filter = '', authToken, startDate = null, endDate = null) => {

    return async (dispatch) => {

        dispatch({
            type: SET_LOADMORE_LOADING_EVENTS,
            payload: true
        })
    
        const config = {
            headers: {
                'Auth-Token': authToken,
                'X-Auth-Token': authToken,
                'header_app_type': 'client',
            },
            timeout: 15000
        }

        let data;

        if( !startDate ){
            data = await axios.get(`${apiKeyEvents}?title=${filter ? filter : ''}&page=${page + 1}`, config)
            .catch(error => {
    
                dispatch({
                    type: SET_LOADMORE_ERROR_EVENTS,
                    payload: 'عدم برقراری ارتباط با سرور'
                })
    
                setTimeout(() => {
                    
                    dispatch({
                        type: SET_LOADMORE_ERROR_EVENTS,
                        payload: null
                    })
    
                }, 1200);
    
            })
        }
        else {
            
            data = await axios.get(`${apiKeyEvents}?fromDate=${startDate}&toDate=${endDate}&title=${filter ? filter : ''}&page=${page + 1}`, config)
            .catch(error => {
    
                dispatch({
                    type: SET_LOADMORE_ERROR_EVENTS,
                    payload: 'عدم برقراری ارتباط با سرور'
                })
    
                setTimeout(() => {
                    
                    dispatch({
                        type: SET_LOADMORE_ERROR_EVENTS,
                        payload: null
                    })
    
                }, 1200);
    
            }) 
        }

        if(data){

            if(data.data.success){

                if(!startDate && !endDate){

                    if(!filter){
    
                        dispatch({
                            type: ADD_NEW_EVENTS_TO_LIST,
                            payload: {
                                data: data.data.data,
                                pagination: data.data.pagination,
                            }
                        })
    
                    }
                    else {
                        
                        dispatch({
                            type: ADD_EVENTS_TO_FILTER_EVENTS,
                            payload: {
                                value: filter,
                                list: data.data.data,
                                pagination: data.data.pagination,
                            }
                        })
    
                    }

                }
                else if(filter){

                    console.log( data );

                    dispatch({
                        type: ADD_EVENTS_TO_FILTER_EVENTS,
                        payload: {
                            value: filter,
                            list: data.data.data,
                            pagination: data.data.pagination,
                        }
                    })

                }

                return data.data.data

            }

            else if(!data.data.success && data.data.errors){

                dispatch({
                    type: SET_LOADMORE_ERROR_EVENTS,
                    payload: data.data.errors[0].message
                })

                setTimeout(() => {
                
                    dispatch({
                        type: SET_LOADMORE_ERROR_EVENTS,
                        payload: null
                    })
    
                }, 1200);

            }

        }

    }

}

// -----------------------------

export const getEventsListFromSearch = async (search, authToken) => {

    return async (dispatch) => {

        dispatch({
            type: SET_LOADING_EVENTS,
            payload: true
        })
    
        const config = {
            headers: {
                'Auth-Token': authToken,
                'X-Auth-Token': authToken,
                'header_app_type': 'client',
            },
            timeout: 15000
        }

        const data = await axios.get(`${apiKeyEvents}?title=${search}`, config)
        .catch(error => {

            dispatch({
                type: SET_FILTER_ERROR_EVENTS,
                payload: 'عدم برقراری ارتباط با سرور'
            })

        })

        if(data){

            if(data.data.success){

                dispatch({
                    type: SET_FILTER_EVENTS,
                    payload: {
                        value: search,
                        list: data.data.data,
                        pagination: data.data.pagination
                    }
                })        

                return data.data.data

            }

            else if(!data.data.success && data.data.errors){

                dispatch({
                    type: SET_FILTER_ERROR_EVENTS,
                    payload: data.data.errors[0].message
                })

            }

        }

    }

}

export const getEventsListByDateFromSearch = async (search, start, end, authToken) => {

    return async (dispatch) => {

        dispatch({
            type: SET_LOADING_CALENDAR_DATE_EVENTS,
            payload: true
        })
    
        const config = {
            headers: {
                'Auth-Token': authToken,
                'X-Auth-Token': authToken,
                'header_app_type': 'client',
            },
            timeout: 15000
        }

        const data = await axios.get(`${apiKeyEvents}?fromDate=${start}&toDate=${end}&title=${search}`, config)
        .catch(error => {

            dispatch({
                type: SET_FILTER_ERROR_EVENTS,
                payload: 'عدم برقراری ارتباط با سرور'
            })

            dispatch({
                type: SET_LOADING_CALENDAR_DATE_EVENTS,
                payload: false
            })

        })

        if(data){

            if(data.data.success){

                dispatch({
                    type: SET_FILTER_EVENTS,
                    payload: {
                        value: search,
                        list: data.data.data,
                        pagination: data.data.pagination
                    }
                })     
                
                dispatch({
                    type: SET_LOADING_CALENDAR_DATE_EVENTS,
                    payload: false
                })

                return data.data.data

            }

            else if(!data.data.success && data.data.errors){

                dispatch({
                    type: SET_FILTER_ERROR_EVENTS,
                    payload: data.data.errors[0].message
                })

                dispatch({
                    type: SET_LOADING_CALENDAR_DATE_EVENTS,
                    payload: false
                })

            }

        }

    }

}

// -----------------------------

export const AddEventToList = async (info, authToken) => {

    return async (dispatch) => {

        dispatch({
            type: SET_LOADING_EVENTS,
            payload: true
        })
    
        const config = {
            headers: {
                'Auth-Token': authToken,
                'X-Auth-Token': authToken,
                'header_app_type': 'client',
            },
            timeout: 15000
        }

        const data = await axios.post(`${apiKeyEvents}`, info, config)
        .catch(error => {

            dispatch({
                type: SET_FAILURE_EVENTS,
                payload: 'عدم برقراری ارتباط با سرور'
            })

            setTimeout(() => {

                dispatch({
                    type: SET_FAILURE_EVENTS,
                    payload: null
                })

            }, 1600)

        })

        if(data){

            if(data.data.success){

                dispatch({
                    type: ADD_ITEM_TO_LIST_EVENTS
                })

                return data.data.data

            }

            else if(!data.data.success && data.data.errors){

                dispatch({
                    type: SET_FAILURE_EVENTS,
                    payload: data.data.errors[0].message
                })

                setTimeout(() => {

                    dispatch({
                        type: SET_FAILURE_EVENTS,
                        payload: null
                    })
    
                }, 1600)

            }

        }

    }

}

export const getEventItemData = async (id, authToken) => {

    return async (dispatch) => {

        dispatch({
            type: SET_EVENT_DATA_LOADING,
            payload: true
        })
    
        const config = {
            headers: {
                'Auth-Token': authToken,
                'X-Auth-Token': authToken,
                'header_app_type': 'client',
            },
            timeout: 15000
        }

        const data = await axios.get(`${apiKeyEvents}/${id}`, config)
        .catch(error => {

            dispatch({
                type: SET_EVENT_DATA_ERROR,
                payload: 'عدم برقراری ارتباط با سرور'
            })

            dispatch({
                type: SET_FAILURE_EVENTS,
                payload: 'عدم برقراری ارتباط با سرور'
            })

            setTimeout(() => {

                dispatch({
                    type: SET_FAILURE_EVENTS,
                    payload: null
                })

            }, 1600)

        })

        if(data){

            if(data.data.success){

                dispatch({
                    type: SET_EVENT_DATA_VALUES,
                    payload: {
                        id,
                        list: data.data.data
                    }
                })

                return data.data.data

            }

            else if(!data.data.success && data.data.errors){

                dispatch({
                    type: SET_EVENT_DATA_ERROR,
                    payload: data.data.errors[0].message
                })

                dispatch({
                    type: SET_FAILURE_EVENTS,
                    payload: data.data.errors[0].message
                })

                setTimeout(() => {

                    dispatch({
                        type: SET_FAILURE_EVENTS,
                        payload: null
                    })
    
                }, 1600)

            }

        }

    }

}

export const EditEventToList = async (info, id, authToken) => {

    return async (dispatch) => {

        dispatch({
            type: SET_LOADING_EVENTS,
            payload: true
        })
    
        const config = {
            headers: {
                'Auth-Token': authToken,
                'X-Auth-Token': authToken,
                'header_app_type': 'client',
            },
            timeout: 15000
        }

        const data = await axios.post(`${apiKeyEvents}/${id}`, info, config)
        .catch(error => {

            dispatch({
                type: SET_FAILURE_EVENTS,
                payload: 'عدم برقراری ارتباط با سرور'
            })

            setTimeout(() => {

                dispatch({
                    type: SET_FAILURE_EVENTS,
                    payload: null
                })

            }, 1600)

        })

        if(data){

            if(data.data.success){

                // do update :))
                dispatch({
                    type:  EDIT_ITEM_EVENT,
                    payload: {id, value: info}
                })

                return {
                    id: id,
                    ...info
                }

            }

            else if(!data.data.success && data.data.errors){

                dispatch({
                    type: SET_FAILURE_EVENTS,
                    payload: data.data.errors[0].message
                })

                setTimeout(() => {

                    dispatch({
                        type: SET_FAILURE_EVENTS,
                        payload: null
                    })
    
                }, 1600)

            }

        }

    }

}

// -----------------------------

export const deleteEventFromList = async (id, date = null, authToken) => {

    return async (dispatch) => {

        dispatch({
            type: SET_LOADING_EVENTS,
            payload: true
        })
    
        const config = {
            headers: {
                'Auth-Token': authToken,
                'X-Auth-Token': authToken,
                'header_app_type': 'client',
            },
            timeout: 15000
        }

        const data = await axios.post(`${apiKeyEvents}/${id}/delete`, null, config)
        .catch(error => {

            dispatch({
                type: SET_FAILURE_EVENTS,
                payload: 'عدم برقراری ارتباط با سرور'
            })

            setTimeout(() => {

                dispatch({
                    type: SET_FAILURE_EVENTS,
                    payload: null
                })

            }, 1600)

        })

        if(data){

            if(data.data.success){

                if(date){
                    
                    dispatch({
                        type: DELETE_ITEM_EVENT_DAY,
                        payload: {
                            id,
                            date
                        }
                    })
                    
                }
                else{
                    dispatch({
                        type: DELETE_ITEM_EVENT,
                        payload: id,
                    })
                }

                return true

            }

            else if(!data.data.success && data.data.errors){

                dispatch({
                    type: SET_FAILURE_EVENTS,
                    payload: data.data.errors[0].message
                })

                setTimeout(() => {

                    dispatch({
                        type: SET_FAILURE_EVENTS,
                        payload: null
                    })
    
                }, 1600)

            }

        }

    }

}
