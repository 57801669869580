import { ReactSVG } from "react-svg"

const Textarea = ({className, label, labelClass, id, value = '', deactive, labelSize = 'sm', labelColor = 'text-gray-500', isValid = true, icon, loading, ...props}) => {

    let iconElement;

    // icons name is exist in README.md file and icons exist in assets/images files.
    if(icon){   
        iconElement = <ReactSVG src={`${process.env.PUBLIC_URL}/assets/images/icons/${icon}.svg`} className={`w-5 h-5 text-primary absolute right-3 top-4 peer-focus:!text-primary duration-300 ${!isValid && 'text-rose-500'} ${loading && '!text-gray-400'}`} />
    }

    const textareaElement = (
        <div className={`relative`}>
                
            <textarea
                id={id}
                readOnly={deactive}
                value={value}
                className={`py-3 px-4 peer w-full focus:outline-none shadow-custom-md border border-gray-200/70 focus:!border-primary duration-300 transition-colors rounded-xl pl-16 appearance-none ${!isValid && 'border-rose-500'} ${icon && 'pr-10'} ${className}`}
                {...props}
            >
            </textarea>

            { loading && <ReactSVG src={`${process.env.PUBLIC_URL}/assets/images/icons/loading-dot.svg`} className={`absolute top-[21px] right-4 ${icon && 'right-12'} w-10 text-gray-400 group-focus-within:text-primary duration-300`} /> }

            { icon && iconElement }

        </div>
    )

    if(!label){
        return textareaElement
    }
    else if(label){
        return (
            <div className="flex flex-col gap-2.5 group cursor-pointer">

                <label 
                    htmlFor={id} 
                    className={`${labelSize === 'sm' ? 'text-sm' : 'text-base'} ${labelColor} group-focus-within:text-primary duration-300 cursor-pointer ${labelClass ? labelClass : ''}`}
                >
                    { label } :
                </label>

                { textareaElement }

            </div>
        )
    }

}

export default Textarea