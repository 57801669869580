import { useEffect, useState } from "react"
import { useCookies } from "react-cookie"
import { useDispatch, useSelector } from "react-redux"
import { getBaseDataLawyer } from "../../../../../../redux/action/baseData/baseAction"
import { SET_FILEDS_OF_FILTER } from "../../../../../../redux/action/cases/casesType"
import { ReactSVG } from "react-svg"
import { SET_SUCCESS_BASEDATA } from "../../../../../../redux/action/baseData/baseType"
import { getBaseDataInfoFromLocaleStorage, saveBaseDataInfoToLocaleStorage } from "../../../../../../utils/base-data"

const CasesFilterSectionJudicialList = (props) => {

    const dispatch = useDispatch()
    const baseDataJudicial = useSelector(state => state.baseData.info?.jurisdictions)
    const baseDataLoading = useSelector(state => state.baseData.loading)
    const baseDataError = useSelector(state => state.baseData.error)
    const baseDataLoaded = useSelector(state => state.baseData.loaded)
    const fields = useSelector(state => state.cases.fieldsFilter)

    const [showChilds, setShowChilds] = useState(null)

    const [cookies] = useCookies(['authToken'])

    useEffect(() => {

        getJudicialFromBaseData()

    }, [])

    const getJudicialFromBaseData = async () => {

        if(baseDataLoaded) return

        if(baseDataLoading) return

        const baseData = getBaseDataInfoFromLocaleStorage()

        if(!baseData){
            const data = await dispatch( await getBaseDataLawyer(cookies.authToken) )

            if(data){
                saveBaseDataInfoToLocaleStorage(data)
            }

        }
        else {

            dispatch({
                type: SET_SUCCESS_BASEDATA,
                payload: baseData
            })

        }

    }

    const updateJudicialField = (value) => {

        dispatch({
            type: SET_FILEDS_OF_FILTER,
            payload: {
                ...fields,
                judicial: {
                    value,
                    applied: value.length >= 1 ? true : false
                }
            }
        })
    }

    const showChildList = (item) => {
        setShowChilds(item.childs)

        props.onSetSubActiveItemState(true)
    }
    
    const selectedJudicial = (value) => {
        setShowChilds(null)

        props.onSetSubActiveItemState(false)
        props.onSetActiveItemState('')

        if(value)
            updateJudicialField(value)
    }

    if(baseDataLoading){
        return (
            <div className="flex items-center justify-center h-20">  
                <ReactSVG src={`${process.env.PUBLIC_URL}/assets/images/icons/loading-dot.svg`} className="w-10 text-gray-500" />
            </div>
        )
    }

    else if(baseDataError){
        return (
            <div className="flex items-center justify-center h-20 text-gray-600">
                {baseDataError}
            </div>
        )
    }

    else if(props.subActiveItemState){
        return (
            <div className="mt-4">

                <ul className="mt-4">

                    {showChilds?.map(item => 
                        <li
                            key={item.item.id}
                            className="text-gray-600 cursor-pointer py-4 first:pt-0 last:pb-0 border-b last:border-b-0 border-b-gray-200 flex items-center justify-between"
                            onClick={() => selectedJudicial(item.item.name)}
                        >
                            {item.item.name}

                            <span className="text-sm text-primary"> انتخاب </span>
                        </li>    
                    )}

                </ul>

            </div>
        )
    }

    return (
        <div className="mt-4">
            
            <ul className="mt-4">

                {baseDataJudicial?.map(item => 
                    <li
                        key={item.item.id}
                        className="text-gray-600 cursor-pointer py-4 first:pt-0 last:pb-0 border-b last:border-b-0 border-b-gray-200 flex items-center justify-between"
                        onClick={() => item.childs.length >= 1 ? showChildList(item) : selectedJudicial(item.item.name) }
                    >
                        {item.item.name}

                        { item.childs.length <= 0 ?
                            <span className="text-sm text-primary"> انتخاب </span>
                            :
                            <ReactSVG src={`${process.env.PUBLIC_URL}/assets/images/icons/down.svg`} className="w-5 rotate-90 text-primary" />
                        }

                    </li>    
                )}

            </ul>

        </div>
    )

}

export default CasesFilterSectionJudicialList