import { SET_FINANCIAL_DELETE_LOADING, SET_FINANCIAL_DELETE_SUCCESSFULLY, SET_FINANCIAL_FAILURE, SET_FINANCIAL_LOADING } from "../../action/cases/casesType"

const initalState = {
    
    deleteLoading: false,
    errorMessage: null,
    successMessage: null,

    loading: false 

}

const casesFinancialsReducer = (state = initalState, action) => {

    switch(action.type){
        
        case SET_FINANCIAL_DELETE_LOADING :
            return {
                ...state,
                deleteLoading: action.payload,
            }

        case SET_FINANCIAL_LOADING :
            return {
                ...state,
                loading: action.payload,
            }


        case SET_FINANCIAL_DELETE_SUCCESSFULLY :
            return {
                ...state,
                deleteLoading: false,
                errorMessage: null,
                successMessage: action.payload,
                loading: false,
            }

        case SET_FINANCIAL_FAILURE :
            return {
                ...state,
                deleteLoading: false,
                errorMessage: action.payload,
                successMessage: null,
                loading: false
            }

        default :
            return state
    }

}

export default casesFinancialsReducer