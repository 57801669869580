import { Link } from 'react-router-dom'

const MainMenuLogo = () => {

    return (
        <div className="lg:flex hidden items-center justify-center">   

            <Link to="/" className="lg:flex w-full items-center justify-center gap-3 lg:pb-4 lg:border-b border-b-gray-100 lg:mb-4" >

                <img className="w-9 lg:block hidden" src={`${process.env.PUBLIC_URL}/assets/images/logo/logo.png`} alt="موکلین وکیل‌سان" />

                <span className="font-medium text-xl lg:text-gray-900 text-second"> وکیل‌سان </span>

            </Link>

        </div>
    )

}

export default MainMenuLogo