import Header from "../../components/Header"
import HeaderSearchBtn from "../../components/Header/Search/Btn"
import HeaderAddBtn from "../../components/Header/AddBtn"
import NotesSearch from "../../components/Notes/Search"
import NotesList from "../../components/Notes/List"
import { useEffect } from "react"

const Notes = () => {

    useEffect(() => {

        window.scrollTo({top: 0})

    }, [])

    return (
        <>
            <Header 
                title={'یادداشت‌ها'}
            >

                <HeaderSearchBtn />

                <HeaderAddBtn 
                    path={'add'}
                />

            </Header>

            <main className="main-content">

                <NotesSearch />

                <NotesList />

            </main>
        </>
    )

}

export default Notes