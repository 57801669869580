import { Link, useLocation, useNavigate } from 'react-router-dom'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { SET_MOBILE_AUTH } from '../../../../../redux/action/auth/authType'

import Button from '../../../../UI/Button'
import Input from '../../../../UI/Input'

import { checkExistAuthStep1 } from '../../../../../utils/auth'
import LoginFormTerms from './Terms'

const LoginForm = (props) => {
    
    const [phone, setPhone] = useState('')

    const dispatch = useDispatch()
    const loading = useSelector(state => state.auth.loading)

    const location = useLocation()
    const navigate = useNavigate()

    useEffect(() => {

        // when expire veify code - verify pass me to this location and then come set phone
        if(location.state && location.state?.mobile){

            setPhone(location.state?.mobile)

            dispatch({
                type: SET_MOBILE_AUTH,
                payload: location.state?.mobile
            })
        }
        else {
            checkExistMobileInLocalStorage()
        }


    }, [])

    const checkExistMobileInLocalStorage = () => {

        const result = checkExistAuthStep1()

        if(result && result?.mobile){
            
            dispatch({
                type: SET_MOBILE_AUTH,
                payload: result?.mobile
            })  

            setPhone(result?.mobile)
        }
        else {
            navigate('/auth/login', {replace: true})
        }
        
    }

    const updatePhoneField = (event) => {

        if( isNaN(event.target.value) ) return

        if(event.target.value.length <= 11){
            setPhone(event.target.value)
        }
    }

    const submitForm = (event) => {
        event.preventDefault()

        let result = checkExistAuthStep1()

        props.onSubmit(phone, result?.mobile, result?.finishTime)
    }

    return (
        <form onSubmit={submitForm} className="flex flex-col">

            <div className='mb-6'>
                <Input 
                    placeholder="مثال ۰۹۳۳۵۴۱۳۷۴۲" 
                    type="tel"
                    value={phone}
                    onInput={updatePhoneField}
                    max={11}
                    className={'text-right'}
                />
            </div>

            <LoginFormTerms />

            <Button onClick={submitForm} className="w-full" disabled={phone.length !== 11}>
                { !loading ? 'ورود' :     
                    <svg className='w-6 h-6 fill-white animate-spin' viewBox="0 0 1024 1024">
                        <path d="M988 548c-19.9 0-36-16.1-36-36 0-59.4-11.6-117-34.6-171.3a440.45 440.45 0 0 0-94.3-139.9 437.71 437.71 0 0 0-139.9-94.3C629 83.6 571.4 72 512 72c-19.9 0-36-16.1-36-36s16.1-36 36-36c69.1 0 136.2 13.5 199.3 40.3C772.3 66 827 103 874 150c47 47 83.9 101.8 109.7 162.7 26.7 63.1 40.2 130.2 40.2 199.3.1 19.9-16 36-35.9 36z" />
                    </svg> 
                }
            </Button>

        </form>
    )

}

export default LoginForm