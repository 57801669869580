import { useDispatch, useSelector } from "react-redux"
import { HIDE_SIDE_MENU } from "../../../redux/action/menu/side/sideType"
import { useEffect, useRef, useState } from "react"

import SideMenuUserInfo from "./UserInfo"
import SideMenuItems from "./Items"
import SideMenuLinks from "./Links"
import LogoutBtn from '../SubSection/LogoutBtn'

const SideMenu = () => {

    const dispatch = useDispatch()
    const showMenu = useSelector(state => state.menu.side.showMenu)

    const [enableAnimation, setEnableAnimation] = useState(false)
    const [destroyMenu, setDestroyMenu] = useState(!enableAnimation)

    const navRef = useRef()

    useEffect(() => {

        if(showMenu){
            document.body.classList.add('modal-opened')
            
            setEnableAnimation(true)
            setDestroyMenu(false)

            // add animation to nav
            navRef.current.classList.add('duration-300')

            setTimeout(() => {
                // remove animation from nav
                navRef.current.classList.remove('duration-300')
            }, 300)
        }
        else{
            document.body.classList.remove('modal-opened')
            
            setEnableAnimation(false)
            
            // add animation to nav
            if(navRef.current){
                navRef.current.classList.add('duration-300')
            }

            // after 300ml come destroyMenu
            setTimeout(() => {
                setDestroyMenu(true)

                if(navRef.current){
                    navRef.current.classList.remove('duration-300')
                }
            }, 300)
        }

    }, [showMenu])

    useEffect(() => {

        return () => {
            // come close the menu
            dispatch({
                type: HIDE_SIDE_MENU
            })
        }

    }, [])

    const hideMenu = () => {
        
        dispatch({
            type: HIDE_SIDE_MENU
        })

    }

    if(showMenu || !destroyMenu){
        
        return (
            <>
    
                <nav ref={navRef} className={`fixed top-0 lg:left-0 lg:right-auto right-0 ${!enableAnimation || destroyMenu ? 'lg:-translate-x-full translate-x-full' : '' } duration-300 z-40 bg-white lg:w-80 3xm:w-72 w-64 h-full shadow-custom-md lg:p-4 lg:pb-0 lg:border-r lg:border-l-0 lg:border-gray-200 flex flex-col justify-between gap-4 select-none`}>

                    <SideMenuUserInfo 
                        onHideMenu={hideMenu}
                    />

                    <div className="mb-auto overflow-y-auto">

                        <SideMenuItems />

                        <hr className="w-full border-t-gray-100 my-4" />

                        <SideMenuLinks />

                    </div>
                    
                    <LogoutBtn 
                        className="mb-2.5 lg:!hidden !flex"
                        modalClass="lg:hidden"
                        backdropClass="lg:hidden"
                    />

                </nav>
            
                {/* backdrop */}
                { showMenu && <div onClick={hideMenu} className="backdrop bg-black/70"></div> }
    
            </>
        )

    }

}

export default SideMenu