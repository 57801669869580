import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import { Provider } from 'react-redux'

import store from './redux/store'

import Layout from './components/Layout'
import Home from './pages/Home'
import Profile from './pages/Profile'
import Contacts from './pages/Contacts'
import ContactAdd from './pages/Contacts/Add'
import ContactEdit from './pages/Contacts/Edit'
import ContactProfile from './pages/Contacts/Profile'
import Messages from './pages/Messages'
import Message from './pages/Messages/Message'
import Notes from './pages/Notes'
import AddNote from './pages/Notes/Add'
import EditNote from './pages/Notes/Edit'
import Events from './pages/Events'
import EventsDateList from './pages/Events/List'
import AddEvent from './pages/Events/Add'
import EditEvent from './pages/Events/Edit'
import Tariffs from './pages/Tariffs'
import Cases from './pages/Cases'
import Case from './pages/Cases/Case'
import Wallet from './pages/Wallet'
import Plans from './pages/Plans'
import PaymentGeteway from './pages/Wallet/PaymentGeteway'
import BuyPlans from './pages/Plans/Buy'
import LayoutHavePlan from './components/LayoutHavePlan'


import Auth from './components/Auth'
import Login from './pages/Auth/Login'
import Verify from './pages/Auth/Verify'
import WelcomeRegister from './pages/WelcomeRegister'
import ContactsForMessages from './pages/Messages/Contacts'
import ServiceWorkerApp from './service-worker'
import Offline from './pages/Offline'

const App = () => {

    return (
        <Provider store={store}>

            <ServiceWorkerApp />
        
            <Router>

                <Routes>

                    <Route path='/offline' element={<Offline />} />
                    
                    <Route path='' element={ <Layout /> }>

                        <Route path='' element={ <Home /> } />

                        <Route path='profile' element={ <Profile /> } />
                        
                        <Route path='tariffs' element={ <Tariffs /> } />

                        <Route path='wallet' element={ <Wallet /> } />
                        <Route path='payment-geteway' element={ <PaymentGeteway /> } />


                        <Route path='plans' element={ <Plans /> } />
                        <Route path='buy-plans' element={ <BuyPlans /> } />

                        <Route path='' element={ <LayoutHavePlan /> }>

                            <Route path='contacts' element={ <Contacts /> } />
                            <Route path='add-contact' element={ <ContactAdd /> } />
                            <Route path='edit-contact/:id' element={ <ContactEdit /> } />
                            <Route path='contact/:id' element={ <ContactProfile /> } />                            

                            <Route path='messages' element={ <Messages /> } />
                            <Route path='messages/contacts' element={ <ContactsForMessages /> } />

                            <Route path="notes" element={ <Notes /> } />

                            <Route path="events" element={ <Events /> } />
                            <Route path="events/date/:date" element={ <EventsDateList /> } />
                            <Route path="events/date/:date/add" element={ <AddEvent /> } />
                            <Route path="events/:id" element={ <EditEvent /> } />

                            <Route path='law-suits' element={ <Cases /> } />
                            <Route path='law-suits/:id' element={ <Case /> } />

                        </Route>

                    </Route>

                    <Route path='' element={ <LayoutHavePlan /> }>

                        <Route path='messages/:id/:phone' element={ <Message /> } />

                        <Route path="notes/add" element={ <AddNote /> } />
                        <Route path="notes/add/:id" element={ <AddNote /> } />
                        <Route path="notes/:id" element={ <EditNote /> } />

                    </Route>

                    <Route path='auth' element={ <Auth /> }>

                        <Route path='' element={ <Login /> } />
                        <Route path='login' element={ <Login /> } />
                        <Route path='verify' element={ <Verify /> } />

                    </Route>

                    <Route path='welcome' element={ <WelcomeRegister /> } />

                </Routes>
                
            </Router>
            
        </Provider>
    )

}

export default App