import { useEffect, useState } from "react"

import CopyToClipboard from "react-copy-to-clipboard"
import { toast } from "react-toastify"
import { ReactSVG } from "react-svg"

import ModalBottom from "../../UI/ModalBottom"
import ConatctShareDropdownItem from "./ShareItem"
import ToastCustom from "../ToastCustom"

const ConatctShareDropdown = (props) => {

    const [showing] = useState(true)
    const [socialListForComputer] = useState([
        {
            name: 'تلگرام',
            icon: 'telegram',
            color: 'blue',
        },
        {
            name: 'واتساپ',
            icon: 'whatsapp',
            color: 'green',
        },
        {
            name: 'ایتا',
            icon: 'eitaa',
            color: 'orange',
        }
    ])
    const [successMessage, setSuccessMessage] = useState(null)

    useEffect(() => {

        return () => {
            if(document.body.classList.contains('modal-opened'))
                document.body.classList.remove('modal-opened')
        }

    }, [])

    const copyDownloadLink = () => {
            
        setSuccessMessage('کپی شد')
        
        setTimeout(() => {
            setSuccessMessage(null)
        }, 600);

    }

    if(props.show){
        return (
            <>
                
                <ModalBottom
                    title={`دعوت "${props.name}" به برنامه`} 
                    showing={showing}
                    onSetShowing={props.setShow}
                    show={props.show}
                    className="overflow-y-auto no-scrollbar !max-h-96"
                    onSetShow={props.setShow}
                >
                    
                    <div className="sticky top-0 left-0 flex flex-col pb-4 bg-white w-full z-[1] border-b border-b-slate-200">

                        <div className="py-2 bg-white z-[1] mb-2">
                            <div className="mx-auto w-20 h-2 bg-gray-200 rounded-2xl"></div>
                        </div>

                        <h4 className="text-lg font-medium text-center mt-1 mb-4"> دعوت "{props.name}"</h4>

                        <p className="text-sm text-gray-600 leading-7 text-center mb-6"> با یکی از روش های زیر، لینک دانلود وکیل‌سان را برای {props.name} ارسال کنید </p>
                    
                        <CopyToClipboard text={'www.client.vakilsun.com'} onCopy={copyDownloadLink}>
                            <button className="rounded-xl border border-gray-300 bg-custom-gray hover:bg-gray-100 duration-300 w-fit mx-auto px-5 py-1.5 flex items-center justify-center gap-2"> 
                                کپی لینک 
                            
                                <ReactSVG 
                                    src={`${process.env.PUBLIC_URL}/assets/images/icons/copy.svg`}
                                    className="w-4 text-gray-700"
                                />

                            </button>
                        </CopyToClipboard>

                    </div>

                    <div className="pt-4 md:mt-8 flex flex-wrap justify-evenly gap-8">

                        {socialListForComputer.map(item => 
                            
                            <ConatctShareDropdownItem 
                                key={item.icon}
                                color={item.color}
                                name={item.name}
                                icon={item.icon}
                            />

                        )}

                    </div>
                    
                </ModalBottom>

                {successMessage &&
                
                    <ToastCustom className="z-50 outline outline-2 outline-gray-300/30">
                        
                        <div className="flex items-center gap-2">
                            <ReactSVG
                                className="text-green-600 4xm:w-8 w-6"
                                src={`${process.env.PUBLIC_URL}/assets/images/icons/tick.svg`}
                            />

                            {successMessage}

                        </div>

                    </ToastCustom>
                }
                
            </>
        )
    }

}

export default ConatctShareDropdown