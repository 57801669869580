import { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { ReactSVG } from "react-svg"
import { useCookies } from "react-cookie"

import { getWalletInfo } from "../../redux/action/wallet/action"

import WalletBalanceTop from "./BalanceTop"
import ToastCustom from "../UI/ToastCustom"
import WalletHistory from "./History"
import WalletActionButtons from "./ActionButtons"
import WalletDiscount from "./Discount"


const WalletContent = () => {

    const dispatch = useDispatch()
    const walletError = useSelector(state => state.wallet.error)
    const walletLoaded = useSelector(state => state.wallet.loaded)
    const walletLoading = useSelector(state => state.wallet.loading)

    const [cookies] = useCookies(['authToken'])

    useEffect(() => {

        window.scrollTo({top: 0})

        if(!walletLoaded && !walletLoading){
            getWalletData()
        }

    }, [])

    const getWalletData = async () => {

        await dispatch( await getWalletInfo(cookies.authToken) )

    }

    return (
        <>

            <WalletBalanceTop />

            <WalletDiscount />

            <WalletActionButtons />

            <WalletHistory />

            {/* Handle Error */}
            { walletError && 

                <ToastCustom className="!z-50">
                                        
                    <div className="flex items-center gap-2">
                        <ReactSVG
                            className="text-rose-500/90 4xm:w-8 w-6"
                            src={`${process.env.PUBLIC_URL}/assets/images/icons/info-box.svg`}
                        />

                        { walletError }

                    </div>

                </ToastCustom>

            }

        </>
    )
    
}

export default WalletContent