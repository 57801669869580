import { SET_LOADING_MESSAGES, SET_FILTER_MESSEAGES, UNSET_LOADING_MESSAGES, SET_FAILURE_MESSAGES, SET_LIST_MESSAGES, SET_LOADING_DELETE, SET_FAILURE_DELETE, SET_SUCCESS_DELETE, ADD_TO_LIST_MESSAGES, RESTE_ALL_MESSAGES_LIST_DATA_APP, SEEN_MESSAGE_ITEM_COUNT } from '../../../action/messages/list/listType'

const initalState = {
    messages: [],
    
    filtered: {
        searchedValue: '',
        list: []
    },

    loading: false,
    error: null,

    loaded: false,

    deleteLoading: false,
    deleteError: null
}

const messagesListReducer = (state = initalState, action) => {

    switch(action.type){

        case SET_LOADING_MESSAGES :
            return {
                ...state,
                loading: true,
            }

        case SET_FAILURE_MESSAGES :
            return {
                ...state,
                loading: false,
                error: action.payload
            }
        
        case UNSET_LOADING_MESSAGES :
            return {
                ...state,
                loading: false,
            }

        case SET_FILTER_MESSEAGES :
            return {
                ...state,
                loading: false,
                filtered: {
                    searchedValue: action.payload.value,
                    list: action.payload.list
                }
            }
            
        case SET_LIST_MESSAGES :
            return {
                ...state,
                messages: action.payload,
                loading: false,
                loaded: true,
                error: null
            }
        
        case ADD_TO_LIST_MESSAGES :
            return {
                ...state,
                messages: [
                    action.payload,
                    ...state.messages
                ]
            }

        case SET_LOADING_DELETE :
            return {
                ...state,
                deleteLoading: action.payload
            }

        case SET_FAILURE_DELETE :
            return {
                ...state,
                deleteLoading: false,
                deleteError: action.payload
            }

        case SET_SUCCESS_DELETE :
            return {
                ...state,
                deleteLoading: false,
                deleteError: null,
                messages: state.messages.filter(item => item.id != action.payload)
            }

        case SEEN_MESSAGE_ITEM_COUNT :
            return {
                ...state,
                messages: [
                    ...state.messages.map(item => {
                        if(item.id === action.payload){
                            item.unseen_count = 0
                        }

                        return item
                    }),
                ]
            }

        case RESTE_ALL_MESSAGES_LIST_DATA_APP :
            return {
                ...state,
                ...initalState
            }
        
        default :
            return state

    }

}

export default messagesListReducer