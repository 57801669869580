import { Link } from "react-router-dom"
import { ReactSVG } from "react-svg"

const HomeCaseItem = (props) => {

    return (
        <li>
            
            <Link to={`/law-suits/${props.id}`} state={{home: true}} className="flex items-center flex-col bg-white border border-primary border-opacity-[0.15] py-6 p-4 rounded-xl shadow-custom-md gap-4">
                
                <div className="bg-white w-10 flex items-center justify-center h-10 shadow-custom-lg rounded-xl">
                    <ReactSVG 
                        src={`${process.env.PUBLIC_URL}/assets/images/icons/cases.svg`}
                        className="text-primary w-6"
                    />
                </div>

                { props.title }

            </Link>

        </li>
    )

}

export default HomeCaseItem