import { useEffect, useState } from "react"
import { ReactSVG } from "react-svg"
import { getPeymentedStatus, savePeymented } from "../../../utils/paymented"
import { useDispatch, useSelector } from "react-redux"
import { getWalletInfo } from "../../../redux/action/wallet/action"
import { useCookies } from "react-cookie"

const WalletHeaderAction = () => {

    const dispatch = useDispatch()
    const walletError = useSelector(state => state.wallet.error)

    const [havePayment, setHavePayment] = useState(false)

    const [cookies] = useCookies(['authToken'])

    useEffect(() => {

        // check user have payment in the last of 10s
        const paymentStatus =  getPeymentedStatus()

        if(paymentStatus){
            setHavePayment(true)
        }

    }, [])

    const reloadWalletData = async () => {
        
        const data = await dispatch( await getWalletInfo(cookies.authToken) )

        if(data){
            // remove payemnt state
            savePeymented(false)
            setHavePayment(false)   
        }

    }

    return (
        <>
            { (havePayment || walletError)  &&

                <button onClick={ reloadWalletData } className="w-10 h-8 flex items-center justify-end">
                    <ReactSVG 
                        src={`${process.env.PUBLIC_URL}/assets/images/icons/turn-back.svg`} 
                        className="w-5 text-primary" 
                    />
                </button>

            }
        </>
    )

}

export default WalletHeaderAction