import { Link } from "react-router-dom"
import { ReactSVG } from "react-svg"
import { calculateLeftTime } from "../../../../utils/time"

const HomeNoteItem = (props) => {

    const selectColor = () => {
        if(props.color === 1)
            return 'bg-pink-200'

        else if(props.color === 2)
            return 'bg-blue-200'
        
        else if(props.color === 3)
            return 'bg-orange-200'

        else if(props.color === 4)
            return 'bg-green-200'
        
        else if(props.color === 5)  
            return 'bg-purple-200'

        else 
            return 'bg-pink-200'
    }

    const putTime = () => {
        return calculateLeftTime(props.time)
    }

    return (
        <Link 
            to={`/notes/${props.id}`} 
            className={`h-full min-h-[184px] shadow-custom-sm border border-gray-200/80 ${selectColor()} rounded-xl p-4 flex flex-col gap-2.5 relative`} 
            state={{id: props.id, body: props.body, time: props.time, law_suit: props.law, color: props.color, subject: props.subject}}
        >

            <div className="flex items-center justify-between flex-wrap gap-4">

                {props?.law?.title &&
                    <span className="text-xs-12px font-medium flex items-center gap-1.5 border-b border-b-gray-700 pb-2 w-full">
                        
                        <ReactSVG
                            src={`${process.env.PUBLIC_URL}/assets/images/icons/cases.svg`}
                            className="w-3.5 h-3.5 mb-0.5 mt-0.5"
                        />

                        {props?.law?.title}
                        
                    </span>
                }     
                
            </div>

            {props.subject &&
                <span className="text-xs px-2 py-0.5 bg-white flex items-center gap-2 text-ellipsis overflow-hidden whitespace-nowrap rounded-lg outline outline-2 outline-white/40 max-w-full w-fit mb-1">
                    
                    <ReactSVG
                        src={`${process.env.PUBLIC_URL}/assets/images/icons/cases.svg`}
                        className="w-3.5 h-3.5 mb-0.5 mt-0.5 flex-shrink-0"
                    />

                    {props.subject}
                    
                </span>
            }     

            <p className="text-gray-600 text-sm">
                { props.body.slice(0, 140) }{props.body.length >= 141 && '...'}
            </p>

            <div className="flex items-center flex-wrap gap-4 justify-between mt-auto">
                    
                <div className="flex items-center gap-1 text-gray-600 mr-auto mt-auto">

                    <ReactSVG
                        src={`${process.env.PUBLIC_URL}/assets/images/icons/calculate.svg`}
                        className="w-3 h-3 mb-0.5 mt-1"
                    />

                    <span className="text-xs mt-1"> {putTime()} </span>

                </div>
        
            </div>

        </Link>
    )

}

export default HomeNoteItem