import { ReactSVG } from "react-svg"
import NoteItem from "../../../../Notes/List/Item"
import { Link, useParams } from "react-router-dom"
import { useEffect, useState } from "react"
import DeleteNotesHeader from "../../../../Notes/List/DeleteHeader"
import { useSelector } from "react-redux"
import LoadingDot from "../../../../UI/LoadingDot"
import ToastCustom from "../../../../UI/ToastCustom"

const CaseContentNotes = ({notes = [], title, ...props}) => {

    const deleteLoading = useSelector(state => state.notes.deleteLoading)
    const deleteError = useSelector(state => state.notes.deleteError)

    const [items, setItems] = useState(notes)
    const [selectedItemDelete, setSelectedItemDelete] = useState([])

    const params = useParams()

    useEffect(() => {

        if(notes && notes.length >= 1){

            if( notes[0].updated_at === undefined ) {
                setItems(notes)
                
                return
            }
                
            let items = notes.map(item => {
                
                if(item.updated_at){
                    item.updated_at = item?.updated_at.slice(0, 19)
                    item.updated_at = item?.updated_at.split('-').join('').replace('T', '').split(':').join('')
                }

                return item
            })

            items = items.sort((a, b) => b?.updated_at - a?.updated_at)
            
            setItems(items)

        }

    }, [notes])

    return (
        <section>
            
            { selectedItemDelete.length >= 1 &&
                <DeleteNotesHeader 
                    selectedItemId={selectedItemDelete}
                    onSetSelectedItemDelete={setSelectedItemDelete}
                    law_id={params.id}
                />
            }

            {notes && notes.length >= 1 ?

                <ul className="grid xl:grid-cols-3 4xm:grid-cols-2 sm:gap-4 4xm:gap-3 gap-4">

                    { items.map((item) => 
                        
                        <NoteItem 
                            key={item.id + '-notes-law'}
                            id={item.id}
                            body={item.body}
                            subject={item.subject}
                            law={''}
                            lawData={{title, id: params.id}}
                            time={item.updated_at ? `${item.updated_at.slice(0, 4)}-${item.updated_at.slice(4, 6)}-${item.updated_at.slice(6, 8)}T${item.updated_at.slice(8, 10)}:${item.updated_at.slice(10, 12)}:${item.updated_at.slice(12, 14)}.000000Z` : item.created_at}
                            color={item.color}
                            selectedItemDelete={selectedItemDelete}
                            onSetSelectedItemDelete={setSelectedItemDelete}
                        />

                    )}

                </ul>

                :
                <div className="text-gray-600 flex items-center justify-center h-36 gap-4 flex-col">
                    
                    <ReactSVG 
                        src={`${process.env.PUBLIC_URL}/assets/images/icons/notes.svg`}
                        className="w-10 text-gray-500 text-opacity-80"
                    />

                    <div>
                        هیچ <span className="font-medium">یادداشتی</span> برای پرونده ثبت نشده است 
                    </div>
               
                </div>

            }

            { deleteLoading && <LoadingDot /> }

            { deleteError && 
                <ToastCustom>
    
                    <div className="flex items-center gap-2">
                        
                        <ReactSVG
                            className="text-rose-500/90 4xm:w-8 w-6"
                            src={`${process.env.PUBLIC_URL}/assets/images/icons/info-box.svg`}
                        />

                        {  deleteError }

                    </div>

                </ToastCustom>
            }

            <Link to={ `/notes/add/${params.id}` } state={{ law_suit: {id: params.id, title: title} }} className={`header-btn fixed lg:bottom-4 lg:left-4 md:bottom-[104px] bottom-24 left-4 mx-auto z-[1] w-10 h-10`}>

                <ReactSVG src={`${process.env.PUBLIC_URL}/assets/images/icons/add.svg`} className="w-[85%] h-[85%]" />

            </Link>

        </section>
    )

}

export default CaseContentNotes