import Header from "../../components/Header"
import HeaderAddBtn from "../../components/Header/AddBtn"
import ContactList from "../../components/Contacts/List"
import HeaderSearchBtn from "../../components/Header/Search/Btn"
import ContactsSearch from "../../components/Contacts/Search"
import { useDispatch, useSelector } from "react-redux"
import { useEffect } from "react"
import { SET_UPDATE_FAILURE_CONTACTS } from "../../redux/action/contacts/typeContacts"
import { ToastContainer } from 'react-toastify'
import "react-toastify/dist/ReactToastify.min.css";


import { showErrorMessage, showSuccessMessage } from "../../utils/toast"
import { useLocation, useNavigate } from "react-router-dom"

const Contacts = () => {

    const dispatch = useDispatch()
    const updateError = useSelector(state => state.contacts.updateError)

    const location = useLocation()
    const navigate = useNavigate()

    useEffect(() => {

        if(location?.state?.successMessage){
            showSuccessMessage(location.state?.successMessage)

            setTimeout(() => {

                navigate(location.pathname, {replace: true})

            }, 650)
        }

        window.scrollTo({top: 0})

        return () => {
            // clean the update-error
            dispatch({
                type: SET_UPDATE_FAILURE_CONTACTS,
                payload: null
            })
        }

    }, [])

    useEffect(() => {

        if(updateError){
            showErrorMessage(updateError)

            dispatch({
                type: SET_UPDATE_FAILURE_CONTACTS,
                payload: null
            })
        }

    }, [updateError])

    return (
        <>
            <Header 
                title={'مخاطبین'}
                backBtn={true}
            >

                <HeaderSearchBtn />

                <HeaderAddBtn 
                    path={'/add-contact'}
                />

            </Header>

            <main className="main-content">

                <ContactsSearch />

                <ContactList />

                <ToastContainer className={'lg:!bottom-0 !bottom-[78px]'} />

            </main>

        </>
    )

}

export default Contacts