import { useCookies } from "react-cookie"
import { useDispatch, useSelector } from "react-redux"
import { Link } from "react-router-dom"
import { ReactSVG } from "react-svg"
import { getBaseDataInfoFromLocaleStorage, saveBaseDataInfoToLocaleStorage } from "../../../utils/base-data"
import { getBaseDataLawyer } from "../../../redux/action/baseData/baseAction"
import { SET_SUCCESS_BASEDATA } from "../../../redux/action/baseData/baseType"
import { useEffect } from "react"

const HeaderSupportBtn = () => {

    const dispatch = useDispatch()
    const baseDataConfig = useSelector(state => state.baseData?.info?.config)
    const baseDataLoading = useSelector(state => state.baseData.loading)
    const baseDataLoaded = useSelector(state => state.baseData.loaded)

    const [cookies] = useCookies(['authToken'])

    useEffect(() => {

        getLinksFromBaseData()

    }, [])

    const getLinksFromBaseData = async () => {

        if(baseDataLoaded) return

        if(baseDataLoading) return

        const baseData = getBaseDataInfoFromLocaleStorage()

        if(!baseData){
            const data = await dispatch( await getBaseDataLawyer(cookies.authToken) )

            if(data){
                saveBaseDataInfoToLocaleStorage(data)
            }

        }
        else {

            dispatch({
                type: SET_SUCCESS_BASEDATA,
                payload: baseData
            })

        }

    }

    return (
        <Link target="_blank" to={baseDataConfig?.support_url ? baseDataConfig?.support_url : 'https://blog.vakilsun.com/contactus'} className="header-btn">

            <ReactSVG src={`${process.env.PUBLIC_URL}/assets/images/icons/support-2.svg`} className="w-[70%] h-[70%]" />

        </Link>
    )

}

export default HeaderSupportBtn