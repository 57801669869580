import { useState } from "react"
import { ReactSVG } from "react-svg"
import { useDispatch } from "react-redux"

import { logoutRequest } from "../../../../redux/action/auth/authAction"
import { useCookies } from "react-cookie"
import { useNavigate } from "react-router-dom"

import { showSuccessMessage } from '../../../../utils/toast'
import ConfirmModal from "../../../UI/ConfirmModal"

const MainMenuLogoutBtn = (props) => {
    
    const [showModal, setShowModal] = useState(false)
    
    const dispatch = useDispatch()

    const [cookies, setCookies, removeCookies] = useCookies(['authToken'])

    const navigate = useNavigate()

    const logout = () => {
        // send request to server for logout
        dispatch( logoutRequest(cookies.authToken) )

        localStorage.removeItem('selected-client-type')
        localStorage.removeItem('base_data')

        // success
        showSuccessMessage('با موفقیت از حساب خود خارج شدید')

        // remove cookie auth-token
        removeCookies("authToken", {path: '/'})
        
        navigate('/auth/login', {replace: true})
    }

    const openModal = () => {
        setShowModal(true)

        document.body.classList.add('modal-opened')
    }
    
    return (
        <>
            <button 
                onClick={ openModal } 
                className={`lg:flex hidden py-2 group items-center gap-2 text-gray-600 lg:px-0 px-4 mt-auto mb-4 group ${props.className}`}
            >
            
                <ReactSVG src={`${process.env.PUBLIC_URL}/assets/images/icons/logout.svg`} className="w-5 h-5 group-hover:text-red-700 group-disabled:group-hover:text-gray-600" />

                <span className="group-hover:text-red-700 text-sm  group-disabled:group-hover:text-gray-600"> خروج از حساب کاربری </span>

            </button>
        
            <ConfirmModal
                title="خروج از حساب کاربری"
                description="آیا میخواهید از حساب کاربری خود خارج شوید؟"
                show={showModal}
                setShow={setShowModal}
                onConfirm={logout}
                className={props.modalClass}
                backdropClass={props.backdropClass}
            >   

                <div className="w-14 h-14 bg-white shadow-custom-lg flex items-center justify-center rounded-2xl text-rose-500">
                    <ReactSVG src={`${process.env.PUBLIC_URL}/assets/images/icons/logout.svg`} className="w-10 h-10" />
                </div>

            </ConfirmModal>
        </>

    )

}

export default MainMenuLogoutBtn