import { useDispatch, useSelector } from "react-redux"
import { ReactSVG } from "react-svg"
import { sendMessageToChat } from "../../../../../redux/action/messages/chat/chatAction"
import { useParams } from "react-router-dom"
import { useCookies } from "react-cookie"
import { ADD_TO_LIST_MESSAGES } from "../../../../../redux/action/messages/list/listType"
import { ADD_TEXT_MESSAGE_ITEM_TO_CHAT, SVAE_MESSAGE_INPUT_USER_IN_CHAT_PAGE } from "../../../../../redux/action/messages/chat/chatType"

const SendMessageBtn = (props) => {

    const dispatch = useDispatch()
    const error = useSelector(state => state.messages.chat.error)  
    const messages = useSelector(state => state.messages.chat.messages)
    const receiver = useSelector(state => state.messages.chat.receiver)
    const loading = useSelector(state => state.messages.chat.loading)
    
    const [cookies] = useCookies(['authToken'])

    const params = useParams()

    const sendMessage = async () => {

        if(props.body.length >= 1){

            const id = Math.random()*209384+'text-item'

            // add message for show fake message. (message is loading)
            dispatch({
                type: ADD_TEXT_MESSAGE_ITEM_TO_CHAT,
                payload: {
                    id: id,
                    body: props.body,
                    type: 'text',
                    file: null,
                    seen: false,
                    status: 'loading',
                    receiver_id: params.id,
                    date: new Date()?.toLocaleDateString('fa', {year: 'numeric', month: '2-digit', day: '2-digit', timeZone: '+0330'})
                }
            })

            setTimeout(() => {
                
                window.scrollTo({
                    top: window.document.body.scrollHeight + 10000
                })

            }, 9)

            props.onResetTextValue('')

            dispatch({
                type: SVAE_MESSAGE_INPUT_USER_IN_CHAT_PAGE,
                payload: {
                    inputText: '',
                    userId: params.id
                }
            })

            const text = props.body

            // add message to server
            const data = await dispatch( await sendMessageToChat(params.id, props.body, cookies.authToken, id) )
            
            if(data){

                if(messages.length === 0){
                    
                    if(props.body.length >= 1){

                        const message = {
                            id: params.id,
                            name: receiver.name,
                            mobile: params.phone,
                            image: receiver.image,
                            unseen_count: 0,
                            last_message: {
                                id: Math.random()*209384+'text-item',
                                type: "text",
                                body: text,
                                created_at: new Date()
                            }
                        }
    
                        dispatch({
                            type: ADD_TO_LIST_MESSAGES,
                            payload: message
                        })

                    }

                }

            }
            
        }

    }
    
    return (
        <button onClick={sendMessage} disabled={error || props.invite || loading} type="submit" className={`w-10 h-10 mb-[5px] flex items-center justify-center bg-primary text-white flex-shrink-0 rounded-xl shadow-sm shadow-primary outline outline-2 outline-primary/10 disabled:opacity-50`}>
                        
            <ReactSVG src={`${process.env.PUBLIC_URL}/assets/images/icons/send.svg`} className="w-6 h-6" />

        </button>
    )

}

export default SendMessageBtn