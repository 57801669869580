import { Link } from "react-router-dom"
import { ReactSVG } from "react-svg"

const HeaderAddBtn = (props) => {

    return (
        <Link to={ props.path } className={`header-btn ${props.className && props.className}`}>

            <ReactSVG src={`${process.env.PUBLIC_URL}/assets/images/icons/add.svg`} className="w-[85%] h-[85%]" />

        </Link>
    )

}

export default HeaderAddBtn