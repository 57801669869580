import { useDispatch, useSelector } from "react-redux"
import Header from "../../../components/Header"
import MessageLayout from "../../../components/Messages/Message/Layout"
import Button from "../../../components/UI/Button"
import { deleteNoteFromNotes, editNoteInListNotes } from "../../../redux/action/notes/notesAction"
import { useCookies } from "react-cookie"
import { useNavigate, useParams } from "react-router-dom"
import ToastCustom from "../../../components/UI/ToastCustom"
import { ReactSVG } from "react-svg"
import LoadingDot from "../../../components/UI/LoadingDot"
import NoteEditForm from "../../../components/Notes/Edit"
import { useEffect, useState } from "react"
import ConfirmModalDelete from "../../../components/UI/ConfirmModal/Delete"
import { DELETE_ITEM_INFO_HOME_NOTES, UPDATE_INFO_HOME_NOTES } from "../../../redux/action/home/homeType"
import { ADD_ITEM_TO_NOTE_IN_SINGLE_CASE, DELETE_ITEM_NOTE_SINGLE_CASE, UPDATE_NOTE_IN_SINGLE_CASE } from "../../../redux/action/cases/casesType"

const EditNote = () => {

    const dispatch = useDispatch()
    const noteInfo = useSelector(state => state.notes.noteInfo)
    const loading = useSelector(state => state.notes.noteLoading)
    const error = useSelector(state => state.notes.noteError)
    const deleteLoading = useSelector(state => state.notes.deleteLoading)
    const deleteError = useSelector(state => state.notes.deleteError)
    const homeInfo = useSelector(state => state.home.info)
    const singlesCase = useSelector(state => state.cases.singlesCase)

    const [firstData, setFirstData] = useState({})
    const [deleteModal, setDeleteModal] = useState(false)

    const navigate = useNavigate()
    const params = useParams()

    const [cookies] = useCookies(['authToken'])

    useEffect(() => {

        window.scrollTo({top: 0})

    }, [])

    const editNoteOfNotes = async () => {

        // check is fill noteInfo or no
        if(noteInfo.body.length === 0) return

        // make data
        const info = noteInfo

        if(noteInfo?.law_suit?.id != firstData?.law_suit?.id)
            info.law_suit_id = noteInfo?.law_suit?.id

        // send data 
        const data = await dispatch( await editNoteInListNotes(info, cookies.authToken) )
        
        if(data){

            // if exist into the home page come do :))
            const note =  homeInfo?.notes?.filter(item => item.id == info.id)
            
            if(note && note[0]?.id){
                // update Home Notes
                dispatch({
                    type: UPDATE_INFO_HOME_NOTES,
                    payload: info
                })
            }

            const law_id = noteInfo?.law_suit?.id
            
            if(law_id){

                if(singlesCase[law_id] && singlesCase[law_id]?.title){
                    const note = singlesCase[law_id].notes.filter(item => item.id == params.id)

                    if((note && note[0]?.id) && noteInfo?.law_suit?.id == firstData?.law_suit?.id){
                        
                        dispatch({
                            type: UPDATE_NOTE_IN_SINGLE_CASE,
                            payload: {
                                id: law_id,
                                data: info
                            }
                        })

                    } 
                    else if(noteInfo?.law_suit?.id != firstData?.law_suit?.id){
                    
                        let note;
                        
                        if(firstData?.law_suit?.id && singlesCase[firstData?.law_suit?.id])
                            note = singlesCase[firstData?.law_suit?.id].notes.filter(item => item.id == params.id)
    
                        if(note && note[0]?.id){
                            
                            dispatch({
                                type: DELETE_ITEM_NOTE_SINGLE_CASE,
                                payload: {
                                    id: firstData?.law_suit?.id,
                                    data_id: params.id
                                }
                            })
    
                        }
                        
                        dispatch({
                            type: ADD_ITEM_TO_NOTE_IN_SINGLE_CASE,
                            payload: {
                                id: noteInfo?.law_suit?.id,
                                note: info
                            }
                        })
    
                    }
                     
                }
                else if(singlesCase && firstData?.law_suit?.id && singlesCase[firstData?.law_suit?.id]){
                    
                    let note = singlesCase[firstData?.law_suit?.id].notes.filter(item => item.id == params.id)
    
                    if(note && note[0]?.id){
                        
                        dispatch({
                            type: DELETE_ITEM_NOTE_SINGLE_CASE,
                            payload: {
                                id: firstData?.law_suit?.id,
                                data_id: params.id
                            }
                        })
    
                    }
    
                }

            }
            else if(singlesCase && firstData?.law_suit?.id && singlesCase[firstData?.law_suit?.id]){
                    
                let note = singlesCase[firstData?.law_suit?.id].notes.filter(item => item.id == params.id)

                if(note && note[0]?.id){
                    
                    dispatch({
                        type: DELETE_ITEM_NOTE_SINGLE_CASE,
                        payload: {
                            id: firstData?.law_suit?.id,
                            data_id: params.id
                        }
                    })

                }

            }

            setFirstData(info)

        }
    }

    const showDeleteModal = () => {
        setDeleteModal(true)

        document.body.classList.add('modal-opened')
    }

    const doDelete = async () => {
        setDeleteModal(false)
        document.body.classList.remove('modal-opened')

        const data = await dispatch( await deleteNoteFromNotes(params.id, cookies.authToken) )
        
        if(data){
            
            if(homeInfo?.notes?.length >= 1){
                
                // update Home Notes
                dispatch({
                    type: DELETE_ITEM_INFO_HOME_NOTES,
                    payload: noteInfo.id
                })

            }

            const law_id = noteInfo?.law_suit?.id
            if(law_id){
                
                if(singlesCase[law_id] && singlesCase[law_id]?.title){
                    
                    dispatch({
                        type: DELETE_ITEM_NOTE_SINGLE_CASE,
                        payload: {
                            id: law_id,
                            data_id: noteInfo.id
                        }
                    })
    
                }

            }

            navigate(-1, {replace: true})

        }
    }

    return (
        <MessageLayout
            noneMarginBottom={true}
        >

            <Header 
                title={'ویرایش یادداشت'}
                backBtn={true}
                inMobileTitleBeHidden={true}
            >

                <button onClick={showDeleteModal} className="w-10 h-8 flex items-center justify-center text-rose-500">
                    <ReactSVG src={`${process.env.PUBLIC_URL}/assets/images/icons/delete-3.svg`} className="w-[22px]" />
                </button>

                <Button 
                    className={'profile-btn bg-primary py-1 px-4'}
                    disabled={loading || (noteInfo.body.length === 0) || ( (firstData.body === noteInfo.body) && (firstData.color === noteInfo.color) && (noteInfo?.law_suit?.id == firstData?.law_suit?.id) && (noteInfo?.subject == firstData?.subject) )}
                    onClick={editNoteOfNotes}
                >
                    اعمال     
                </Button>

            </Header>

            <main className="main-content">

                <NoteEditForm 
                    onSetFirstData={setFirstData}
                />

                { (loading || deleteLoading) && 
                    
                    <LoadingDot />

                }

                { (error || deleteError) && 

                    <ToastCustom>
    
                        <div className="flex items-center gap-2">
                            
                            <ReactSVG
                                className="text-rose-500/90 4xm:w-8 w-6"
                                src={`${process.env.PUBLIC_URL}/assets/images/icons/info-box.svg`}
                            />

                            { error || deleteError }

                        </div>

                    </ToastCustom>
                
                }

                { deleteModal && 

                    <ConfirmModalDelete
                        title="حذف یادداشت"
                        description="آیا از حذف این یادداشت اطمینان دارید؟"
                        show={showDeleteModal}
                        onSetShow={setDeleteModal}
                        onConfirm={doDelete}
                        selectCount={1}
                    >   

                    </ConfirmModalDelete>

                }

            </main>

        </MessageLayout>
    )

}

export default EditNote