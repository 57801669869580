import { useEffect, useState } from "react"
import ModalBottom from "../../../UI/ModalBottom"
import NoteAddFormSubjectSearch from "./Search"
import NoteAddFormSubjectList from "./List"
import { ReactSVG } from "react-svg"

const NoteAddFormSubject = (props) => {

    const [showingModal, setShowingModal] = useState(true)
    const [filteredList, setFilteredList] = useState({
        searched: false,
        list: []
    })

    useEffect(() => {

        return () => {
            if(document.body.classList.contains('modal-opened'))
                document.body.classList.remove('modal-opened')
        }

    }, [])

    const hideModal = () => {
        setShowingModal( false )

        setTimeout(() => {
            props.onSetShowSubject(false)
        }, 300);

        document.body.classList.remove('modal-opened')
    }

    const resetDataAndHideModal = () => {
        hideModal()

        props.onUpdateSubjectField(null)
    }

    const selectedSubject = (subject) => {
        props.onUpdateSubjectField(subject)

        hideModal()
    }

    return (
        <>
            <ModalBottom
                show={props.showSubject}
                onSetShow={props.onSetShowSubject}
                className="overflow-y-auto no-scrollbar h-full"
                showing={showingModal}
                onSetShowing={setShowingModal}
            >
                
                <div className="sticky top-0 left-0 flex flex-col bg-white w-full z-[1] border-b border-b-slate-200">

                    <div className="py-2 bg-white z-[1] mb-2">
                        <div className="mx-auto w-20 h-2 bg-gray-200 rounded-2xl"></div>
                    </div>

                    <div className="flex items-center justify-between gap-4 mb-4">

                        <button onClick={hideModal} type="button" className="w-10 group">
                            
                            <ReactSVG src={`${process.env.PUBLIC_URL}/assets/images/icons/back.svg`} className="w-5 text-gray-600 hover:text-primary duration-300 rotate-180 mr-1.5 group-disabled:text-gray-400 group-disabled:opacity-70" />

                        </button>

                        <div className="flex items-center gap-0.5">

                            <span className="font-medium flex items-center gap-1.5">
                                <ReactSVG src={`${process.env.PUBLIC_URL}/assets/images/icons/file.svg`} className="w-[18px] text-gray-500" />

                                { props?.subject ? props.subject : 'انتخاب نشده' }
                            </span>

                            { props?.subject &&
                                <button onClick={resetDataAndHideModal} className="h-full focus:outline-none outline-none w-8 flex justify-end">
                                    <ReactSVG 
                                        src={`${process.env.PUBLIC_URL}/assets/images/icons/delete.svg`}
                                        className="w-5 text-rose-600"
                                    />
                                </button>
                            }

                        </div>

                    </div>

                    <NoteAddFormSubjectSearch 
                        onSetFilteredList={setFilteredList}
                    />

                </div>

                <NoteAddFormSubjectList 
                    onSelectedSubject={selectedSubject}
                    filteredList={filteredList}
                />

            </ModalBottom>
        </>
    )

}

export default NoteAddFormSubject