import { toast } from 'react-toastify'

export const showErrorMessage = (error) => {
    // show error message
    toast.error(error, {
        position: toast.POSITION.BOTTOM_CENTER,
        'className': '!font-iransans !bg-rose-500',
        rtl: true,
        theme: 'colored',
        autoClose: 2500
    })
}

export const showSuccessMessage = (message = 'با موفقیت انجام شد') => {
    // show sucess message
    toast.success(message, {
        position: toast.POSITION.BOTTOM_CENTER,
        'className': '!font-iransans',
        rtl: true,
        theme: 'colored',
        autoClose: 650,
        delay: 0
    })
}

export const showWarningMessgae = (warning) => {
    // show error message
    toast.warning(warning, {
        position: toast.POSITION.BOTTOM_CENTER,
        'className': '!font-iransans',
        rtl: true,
        theme: 'colored',
        autoClose: 1000
    })
}