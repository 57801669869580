// for searching locations
export const getFilteredList = (location) => {

    // exist search value and list locations
    if(location.search !== '' && location.list.length >= 1){
        
        const data = {
            city: [],
            province: []
        }

        location.list.map(item => {

            // find province 
            if(item.name.includes(location.search))
                data.province.push(item)

            // find cities
            item.cities.map(city => {
                if(city.name.includes(location.search))
                    data.city.push(city)
            })

        })

        return data

    }   

    return null
}