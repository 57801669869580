import { useEffect, useState } from "react"

const ServiceWorkerAppInstall = () => {


    const [installed, setInstalled] = useState()
    const [rejectedInstallByUser, setRejectedInstallByUser] = useState(false)
    const [notSupportInstall, setNotSupportInstall] = useState(false)

    useEffect(() => {

        if('serviceWorker' in navigator){
        
            window.addEventListener('beforeinstallprompt', showAlertToInstall)
            setNotSupportInstall(false)

        }
        else {
            setInstalled(true)   
            setRejectedInstallByUser(false)
            setNotSupportInstall(true)
        }


        window.addEventListener('beforeinstallprompt', showAlertToInstall)

    }, [])

    const checkIsCameWithMobile = () => {

        const toMatch = [
            /Android/i,
            /webOS/i,
            /iPhone/i,
            /iPad/i,
            /iPod/i,
            /BlackBerry/i,
            /Windows Phone/i,
        ];
        
        return toMatch.some((toMatchItem) => {
            return navigator.userAgent.match(toMatchItem);
        });

    }

    const showAlertToInstall = (e) => {
        e.preventDefault()

        const mobile =  checkIsCameWithMobile()
        if( !mobile ) return

        setInstalled( e )

        const rejectedByUser = localStorage.getItem('rejected-install-propt')

        setRejectedInstallByUser(rejectedByUser)

        if(!rejectedByUser){
            document.body.classList.add('modal-opened')
        }
        else
            document.body.classList.remove('modal-opened')

        window.removeEventListener('beforeinstallprompt', showAlertToInstall)
    }

    const rejectInstall = () => {
        
        setRejectedInstallByUser(true)

        localStorage.setItem('rejected-install-propt', true)

        document.body.classList.remove('modal-opened')

    }

    const installApp = async () => {

        document.body.classList.remove('modal-opened')
        
        if(!notSupportInstall){

            const data = await installed.prompt()
            
            if(data?.outcome == "dismissed"){
                rejectInstall()
            }
    
            setInstalled(null)
            setRejectedInstallByUser(true)

        }

        else {

            // come download this app
            
            const appLink = '...'

            const a = document.createElement('a')
            a.href = appLink
            a.download = appLink.split('/').pop()
            document.body.appendChild(a)
            a.click()
            document.body.removeChild(a)

        }


    }

    // ---------
    // Render
    // ---------

    if( installed && !rejectedInstallByUser ){

        return (
            <div>
    
                <div onClick={() => {}} className={`backdrop`}></div>
    
                <div className="fixed max-w-sm w-10/12 left-0 bottom-4 rounded-xl right-0 mx-auto bg-white shadow-2xl p-4 z-50">
    
                    <div className="flex flex-row items-center gap-3 mb-4 border-b pb-4">
    
                        <div className="bg-white shadow-custom-lg rounded-xl w-11 h-11 p-2">
                            
                            <img className="w-full" src={`${process.env.PUBLIC_URL}/assets/images/logo/logo.png`} alt="" />
    
                        </div>
    
                        <span className="font-medium text-second"> وکیل‌سان </span>
    
                    </div>
    
                    <p className="font-medium mb-6"> آیا تمایل به نصب اپلیکیشن <span className="text-second">وکیل‌سان</span> دارید؟  </p>
    
                    <div className="flex items-center gap-3 flex-wrap">
    
                        <button onClick={installApp} className="bg-primary font-medium shadow-primary/20 shadow-custom-sm px-5 py-2 btn rounded-lg border-2 border-white/20 relative"> نصب </button>
                        <button onClick={rejectInstall} className="bg-transparent text-gray-700 px-5 py-2 btn rounded-lg border-2 border-white/20 relative"> تمایلی ندارم </button>
    
                    </div>
    
                </div>
    
            </div>
        )

    }

    return (<></>)

}

export default ServiceWorkerAppInstall