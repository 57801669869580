import { useLocation, useParams } from "react-router-dom"
import ContactProfileHeader from "../../../components/Contacts/Profile/Header"
import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { ADD_ITEM_TO_PROFILES_LIST, REPLACE_CONTACT_INFO, SET_UPDATE_FAILURE_CONTACTS } from "../../../redux/action/contacts/typeContacts"
import { getContactInfo } from "../../../redux/action/contacts/contactsAction"
import { useCookies } from "react-cookie"
import ContactProfileInfo from "../../../components/Contacts/Profile/Info"
import { showErrorMessage } from "../../../utils/toast"

import { ToastContainer } from 'react-toastify'
import "react-toastify/dist/ReactToastify.min.css";
import ContactProfileCases from "../../../components/Contacts/Profile/Cases"


const ContactProfile = () => {

    const dispatch = useDispatch()
    const contactInfo = useSelector(state => state.contacts.contactInfo)
    const profilesList = useSelector(state => state.contacts.profilesList)
    const error = useSelector(state => state.contacts.updateError)

    const [contactData, setContactData] = useState()
    
    const [cookies] = useCookies(['authToken'])

    const location = useLocation()
    const params = useParams()

    useEffect(() => {   

        window.scrollTo({
            top: 0
        })

        if(params.id == contactInfo.id && contactInfo.law_suits){
            setContactData(contactInfo)
            return
        }

        else if (profilesList[params.id]){
            setContactInfo()
        }

        else if(location.state?.userInfo){
            setInitialDataProfile()

            getContact()
        }

        else {
            getContact()
        }

        // unmount
        return () => {
            if(error)
                dispatch({
                    type: SET_UPDATE_FAILURE_CONTACTS,
                    payload: null
                })
        }

    }, [])

    useEffect(() => {

        if(error){
            showErrorMessage(error)

            // destroy the error
            dispatch({
                type: SET_UPDATE_FAILURE_CONTACTS,
                payload: null
            })
        }

    }, [error])

    const setInitialDataProfile = () => {
        const data = {
            id: useParams.id,
            name: location.state.userInfo.name,
            mobile: location.state.userInfo.mobile,
            image: location.state.userInfo?.user?.image,
        }

        dispatch({
            type: REPLACE_CONTACT_INFO,
            payload: data
        })
    }

    const getContact = async () => {
       const data = await dispatch( await getContactInfo(params.id, cookies.authToken) )
        
       dispatch({
            type: ADD_ITEM_TO_PROFILES_LIST,
            payload: {
                [params.id]: data
            }
       })

       setContactData( data )
    }

    const setContactInfo = () => {
        dispatch({
            type: REPLACE_CONTACT_INFO,
            payload: profilesList[params.id]
        })

        setContactData( profilesList[params.id] )
    }

    return (
        <main className="main-content mt-1.5 relative before:z-[1] before:fixed before:top-0 before:left-0 before:lg:h-4 before:h-3 before:bg-custom-gray before:w-full">
            
            <ContactProfileHeader 
                name={contactData?.name}
                mobile={contactData?.mobile}
                image={contactData?.user?.image ? contactData?.user?.image : ''}
                id={contactData?.id}
            />

            <ContactProfileInfo 
                mobile={contactInfo.mobile}
                phone={contactInfo.phone}
                email={contactInfo.email}
                description={contactInfo.description}
                address={contactInfo.address}
                city={contactInfo?.city?.name}
                province={contactInfo?.province?.name}
            />

            <ContactProfileCases 
                law_suits={contactInfo.law_suits}
            />

            <ToastContainer className={'lg:left-[calc(100%-61.5%)] lg:!bottom-0 !bottom-[78px]'} />

        </main>
    )

}

export default ContactProfile