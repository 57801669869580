import { useEffect, useState } from "react"

const ModalBottom = (props) => {

    useEffect(() => {

        props.onSetShowing(true)

    }, [])

    const hide = () => {
        props.onSetShowing(false)

        setTimeout(() => {
            props.onSetShow(false)

            if(props.onHided) props.onHided()
        }, 300);
        
        document.body.classList.remove('modal-opened')
    }

    return (
        <>
            <div className={`modal-opened md:max-w-xl overflow-auto fixed left-0 right-0 md:top-0 bottom-0 m-auto md:max-h-[500px] md:h-full max-h-[calc(100vh-17.5%)] bg-white border-4 border-gray-200 shadow-custom-md z-40 md:rounded-2xl rounded-t-[1.85rem] pt-0 p-4 pb-4 ${props.showing ? 'opacity-100' : 'opacity-0'} duration-300 transition-opacity ${props.className}`} ref={props.refEl}>

                {props.children}

            </div>

            <div onClick={hide} className={`backdrop ${props.showing ? 'opacity-100' : 'opacity-0'} duration-300`}></div>
        </>
    )

}

export default ModalBottom