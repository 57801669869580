import axios from 'axios'
import { apiKey } from '../../../store'
import { SET_FAILURE_MESSAGES, SET_LOADING_MESSAGES, SET_LIST_MESSAGES, SET_LOADING_DELETE, SET_FAILURE_DELETE, SET_SUCCESS_DELETE } from './listType'
import { DELETE_ALL_MESSAGES_IN_CHAT, SET_LOADING_CHAT } from '../chat/chatType'

const apiKeyMessages = apiKey + 'conversations'

export const getMessages = async (authToken) => {
    
    return async (dispatch) => {

        dispatch({
            type: SET_LOADING_MESSAGES
        })

        const config = {
            headers: {
                'Auth-Token': authToken,
                'X-Auth-Token': authToken,
                'header_app_type': 'client',
            },
            timeout: 15000
        }

        const data = await axios.get(apiKeyMessages, config)
        .catch(error => {
            
            dispatch({
                type: SET_FAILURE_MESSAGES,
                payload: 'عدم برقراری ارتباط با سرور'
            })

        })

        if(data){

            if(data.data.success){

                dispatch({
                    type: SET_LIST_MESSAGES,
                    payload: data.data.data
                })

                return true

            }

            else if(!data.data.success && data.data.errors){

                dispatch({
                    type: SET_FAILURE_MESSAGES,
                    payload: data.data.errors[0].message
                })

            }

        }

    }

}

export const getMessagesWithoutLoading = async (authToken) => {
    
    return async (dispatch) => {

        const config = {
            headers: {
                'Auth-Token': authToken,
                'X-Auth-Token': authToken,
                'header_app_type': 'client',
            },
            timeout: 15000
        }

        const data = await axios.get(apiKeyMessages, config)
        .catch(error => {
        })

        if(data){

            if(data.data.success){

                dispatch({
                    type: SET_LIST_MESSAGES,
                    payload: data.data.data
                })

                return true

            }

        }

    }

}

// delete a chat
export const deleteChatMessages = async (id, scope, authToken) => {
    
    return async (dispatch) => {

        dispatch({
            type: SET_LOADING_DELETE,
            payload: true
        })

        const config = {
            headers: {
                'Auth-Token': authToken,
                'X-Auth-Token': authToken,
                'header_app_type': 'client',
            },
            timeout: 15000
        }

        const info = {
            scope
        }

        const data = await axios.post(`${apiKeyMessages}/with/${id}/delete`, info, config)
        .catch(error => {
            
            dispatch({
                type: SET_FAILURE_DELETE,
                payload: 'عدم برقراری ارتباط با سرور'
            })

            setTimeout(() => {
                
                dispatch({
                    type: SET_FAILURE_DELETE,
                    payload: null
                })

            }, 1100);

            return false

        })

        if(data){

            if(data.data.success){

                dispatch({
                    type: SET_SUCCESS_DELETE,
                    payload: id
                })

                dispatch({
                    type: DELETE_ALL_MESSAGES_IN_CHAT,
                    payload: id
                })

                return true

            }

            else if(!data.data.success && data.data.errors){

                dispatch({
                    type: SET_FAILURE_DELETE,
                    payload: data.data.errors[0].message
                })

                setTimeout(() => {
                
                    dispatch({
                        type: SET_FAILURE_DELETE,
                        payload: null
                    })
    
                }, 1100);

                return false

            }

        }

    }

}