import { ReactSVG } from "react-svg"

import Button from "../../../UI/Button"
import { useDispatch, useSelector } from "react-redux"
import { deleteListOfNoteFromNotes, deleteNoteFromNotes } from "../../../../redux/action/notes/notesAction"
import { useCookies } from "react-cookie"
import LoadingDot from "../../../UI/LoadingDot"
import { DELETE_ITEMS_INFO_HOME_NOTES, DELETE_ITEM_INFO_HOME_NOTES } from "../../../../redux/action/home/homeType"
import { DELETE_ITEMS_NOTE_SINGLE_CASE, DELETE_ITEM_NOTE_SINGLE_CASE } from "../../../../redux/action/cases/casesType"

const DeleteNotesHeader = (props) => {

    const dispatch = useDispatch()
    const deleteLoading = useSelector(state => state.notes.deleteLoading)
    const homeInfo = useSelector(state => state.home.info)
    const singlesCase = useSelector(state => state.cases.singlesCase)

    const [cookies] = useCookies(['authToken'])

    const destroyDeleteMode = () => {
        props.onSetSelectedItemDelete([])
    }

    const handleDelete = () => {
        
        doDeletesWithList()

    }

    const doDelete = async () => {
        if(deleteLoading) return

        const data = await dispatch( await deleteNoteFromNotes(props.selectedItemId, cookies.authToken) )
        
        if(data){

            if(homeInfo?.notes?.length >= 1){
                
                // update Home Notes
                dispatch({
                    type: DELETE_ITEM_INFO_HOME_NOTES,
                    payload: props.selectedItemId
                })

            }

            if(props.law_id){

                if(singlesCase[props.law_id] && singlesCase[props.law_id]?.title){
                    
                    dispatch({
                        type: DELETE_ITEM_NOTE_SINGLE_CASE,
                        payload: {
                            id: props.law_id,
                            data_id: props.selectedItemId
                        }
                    })
    
                }

            }
            else if(Object.keys(singlesCase).length >= 1){

                let id = ''

                Object.keys(singlesCase).map(item => {
                    
                    singlesCase[item].notes.map(noteItem => {
                        if(noteItem.id == props.selectedItemId)
                            id = item
                    })

                })

                if(id !== ''){

                    dispatch({
                        type: DELETE_ITEM_NOTE_SINGLE_CASE,
                        payload: {
                            id: id,
                            data_id: props.selectedItemId
                        }
                    })

                }

            }

            destroyDeleteMode()
        }

    }

    const doDeletesWithList = async () => {

        if(deleteLoading) return

        const data = await dispatch( await deleteListOfNoteFromNotes(props.selectedItemId, cookies.authToken) )

        if(data){

            if(homeInfo?.notes?.length >= 1){
                
                // update Home Notes
                dispatch({
                    type: DELETE_ITEMS_INFO_HOME_NOTES,
                    payload: props.selectedItemId
                })

            }

            if(props.law_id){

                if(singlesCase[props.law_id] && singlesCase[props.law_id]?.title){
                    
                    dispatch({
                        type: DELETE_ITEMS_NOTE_SINGLE_CASE,
                        payload: {
                            id: props.law_id,
                            data_id: props.selectedItemId
                        }
                    })
    
                }

            }
            else if(Object.keys(singlesCase).length >= 1){

                let id = ''

                Object.keys(singlesCase).map(item => {
                    
                    singlesCase[item].notes.map(noteItem => {
                        if(noteItem.id == props.selectedItemId)
                            id = item
                    })

                })

                if(id !== ''){

                    dispatch({
                        type: DELETE_ITEMS_NOTE_SINGLE_CASE,
                        payload: {
                            id: id,
                            data_id: props.selectedItemId
                        }
                    })

                }

            }

            destroyDeleteMode()
        }
        
    }

    return (
        <>
            <header className="fixed lg:pt-4 bg-custom-gray py-3 top-0 left-0 lg:w-3/4 lg:px-4 px-3 w-full z-[2]">

                <div className="bg-white border border-gray-200 flex items-center flex-wrap justify-between rounded-2xl shadow-custom-lg p-4">

                    <button onClick={destroyDeleteMode} className="text-gray-600 h-full">
                        <ReactSVG 
                            className="w-8 h-8"
                            src={`${process.env.PUBLIC_URL}/assets/images/icons/times.svg`}
                        />
                    </button>

                    <div className="flex items-center gap-4">

                        <Button  
                            className={'profile-btn bg-rose-600 py-1 flex gap-1 pr-3 pl-4 disabled:opacity-60'}
                            onClick={handleDelete}
                            disabled={deleteLoading}
                        >
                            <ReactSVG
                                src={`${process.env.PUBLIC_URL}/assets/images/icons/delete.svg`}
                                className="w-4"
                            />
                            حذف
                        </Button>

                    </div>

                </div>

            </header>

            {  deleteLoading &&
                <LoadingDot />
            }
        </>
    )

}

export default DeleteNotesHeader