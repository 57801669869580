import { Link } from "react-router-dom"
import { ReactSVG } from "react-svg"
import { HIDE_SIDE_MENU } from "../../../../redux/action/menu/side/sideType"
import { useDispatch } from "react-redux"

const SideMenuItem = (props) => {

    const dispatch = useDispatch()

    const hideSideMenu = () => {

        dispatch({
            type: HIDE_SIDE_MENU
        })

    }

    // ===== render =====

    // when not link = link is for in the app
    if(!props.link){
        return (    

            <li className="w-full">

                <Link to={props.path} onClick={hideSideMenu} className="flex flex-row items-center gap-3 bg-gray-100 w-full rounded-xl py-3 px-4 cursor-pointer border-dashed border border-gray-200 hover:bg-gray-200 duration-300">

                    { props.icon && <ReactSVG src={`${process.env.PUBLIC_URL}/assets/images/icons/${props.icon}.svg`} className="w-5 text-gray-600" /> }

                    <span className="text-sm"> {props.text} </span>

                </Link>

            </li>

        )
    }

    // when is link - link is not for this app and go to another url
    return (    

        <li className="w-full">

            <a href={props.path} target="_blank" onClick={hideSideMenu} className="flex flex-row items-center gap-3 bg-gray-100 w-full rounded-xl py-3 px-4 cursor-pointer border-dashed border border-gray-200 hover:bg-gray-200 duration-300">

                { props.icon && <ReactSVG src={`${process.env.PUBLIC_URL}/assets/images/icons/${props.icon}.svg`} className="w-5 text-gray-600" /> }

                <span className="text-sm"> {props.text} </span>

            </a>

        </li>

    )
}

export default SideMenuItem