import { Link } from "react-router-dom"
import { ReactSVG } from "react-svg"

const HomeStatusItem = (props) => {

    const scrollToTop = () => {
        window.scrollTo({
            top: 0
        })
    }

    return (
        <Link 
            to={props.link}
            className="flex flex-col items-center gap-2.5"
            onClick={scrollToTop}
        >

            <ReactSVG 
                src={`${process.env.PUBLIC_URL}/assets/images/icons/${props.icon}.svg`}
                className="md:w-10 4xm:w-9 5xm:w-7 w-6 text-primary duration-300"
            />

            { !props.loading ? 
                <span className="font-medium 5xm:text-base text-sm"> {props?.count?.toLocaleString('fa')} </span>
                :
                <span className="font-medium 5xm:text-base text-sm">
                    <ReactSVG 
                        src={`${process.env.PUBLIC_URL}/assets/images/icons/loading-dot.svg`}
                        className="text-gray-400 w-6 py-1.5"
                    />
                </span>
            }

            <span className="4xm:text-sm text-xs text-gray-600"> {props.title} </span>

        </Link>
    )

}

export default HomeStatusItem