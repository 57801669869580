import { SET_REQUEST_PROFILE, SET_FAILURE_PROFILE, SET_PROFILE_PROFILE, SET_UPDATE_IMAGE_PROFILE, SET_UPDATE_TEXT_PROFILE, UPDATE_FIELD_INFO_PROFILE, SET_REQUEST_UPDATING_PROFILE, ADD_FIELDS_PROFILE, DELETE_FIELDS_PROFILE, SET_USER_LOCATION, SET_IMAGE_PROFILE, RESTE_ALL_PROFILE_DATA_APP, UNSET_REQUEST_UPDATING_PROFILE, SET_USED_GIFT_IN_PROFILE, SET_NAME_OF_CLINET_IN_PROFILE } from '../../action/profile/profileType'

const initialState = {
    loading: false,
    error: null,
    profile: {},
    updateLoading: false,

    updatedImage: null,
    updatedText: false,

    fields: {},

    userLocation: {}
}

const profileReducer = (state = initialState, action) => {

    switch(action.type){
        
        // set base profile
        case SET_REQUEST_PROFILE :
            return {
                ...state,
                loading: true,
                error: null,
                updated: false,
                updateLoading: false
            }

        case SET_FAILURE_PROFILE :
            return {
                ...state,
                loading: false,
                error: action.payload,
                updateLoading: false,
            }

        case SET_PROFILE_PROFILE :
            return {
                ...state,
                loading: false,
                error: null,
                profile: action.payload,
                updatedImage: null,
                updateLoading: false,
                updatedText: false
            }

        case SET_REQUEST_UPDATING_PROFILE : 
            return {
                ...state,
                updateLoading: true,
                error: null
            }

        case UNSET_REQUEST_UPDATING_PROFILE : 
            return {
                ...state,
                updateLoading: false,
                updatedImage: null, 
            }

        case SET_IMAGE_PROFILE :
            return {
                ...state,
                profile: {
                    ...state.profile,
                    image: action.payload,
                    updateLoading: false,
                    error: null,  
                    updatedImage: null, 
                }
            }
        
        // set update status content profile
        case SET_UPDATE_IMAGE_PROFILE :
            return {
                ...state,
                updatedImage: action.payload,
                updateLoading: false,
                error: null   
            }

        case SET_UPDATE_TEXT_PROFILE :
            return {
                ...state,
                updatedText: action.payload
            }
        
        // mount and unmount fields data---> when profile page opened come add and when profile page destroy come delete
        case ADD_FIELDS_PROFILE :
            return {
                ...state,
                fields: action.payload,
                updatedText: false
            }

        case DELETE_FIELDS_PROFILE :
            return {
                ...state,
                fields: {},
                updatedText: false,
                updatedImage: null
            }
            
        // update fields
        case UPDATE_FIELD_INFO_PROFILE :
            return {
                ...state,
                fields: action.payload.fields,
                updatedText: action.payload.updated
            }

        case SET_USER_LOCATION :
            return {
                ...state,
                userLocation: action.payload
            }

        case SET_USED_GIFT_IN_PROFILE : 
            return {
                ...state,
                profile: {
                    ...state.profile,

                    used_gift: action.payload
                }
            }

        case SET_NAME_OF_CLINET_IN_PROFILE :
            return {
                ...state,
                profile: {
                    ...state.profile,
                    ...action.payload
                }
            }


        case RESTE_ALL_PROFILE_DATA_APP :
            return {
                ...state,
                ...initialState
            }

        default : 
            return state

    }

}

export default profileReducer