import { useEffect, useState } from "react";
import { ReactSVG } from "react-svg"

import { Swiper, SwiperSlide } from "swiper/react";

const CaseHeaderTabs = ({activeTab, onSetActiveTab, ...props}) => {

    const [hideBlurBg, setHideBlurBg] = useState(false)

    return (
        <div className={`flex items-center gap-5 mb-8 w-full bg-custom-gray ${props.fixedTop && 'fixed z-[1] !w-[calc(100%-28px)] top-16 pt-9 pb-4'}`}>
            
            <Swiper
                className="mr-0 w-full"
                // spaceBetween={15}
                slidesPerView={'auto'}
                breakpoints={{
                    400: {
                        spaceBetween: 15,
                    },
                    0: {
                        spaceBetween: 5,
                    },
                }}
                onSlideChange={(swiper) => setHideBlurBg( swiper.isEnd ) }
                onReachEnd={(swiper) => setHideBlurBg(true)}    
            >
                
                <SwiperSlide className="w-fit">

                    <div onClick={() => {onSetActiveTab(0)}} className={`w-fit ${activeTab === 0 ? 'border-white/60 bg-primary text-white' : 'border-gray-300 text-gray-700'} border py-1.5 px-3 rounded-lg flex items-center gap-2 duration-300 cursor-pointer 4xm:text-base text-sm`}>

                        {/* <ReactSVG 
                            src={`${process.env.PUBLIC_URL}/assets/images/icons/info-box.svg`}
                            className="w-4"
                        /> */}

                        اطلاعات

                    </div>

                </SwiperSlide>
          
                <SwiperSlide className="w-fit">

                    <div onClick={() => {onSetActiveTab(1)}} className={`w-fit ${activeTab === 1 ? 'border-white/60 bg-primary text-white' : 'border-gray-300 text-gray-700'} border py-1.5 px-3 rounded-lg flex items-center gap-2 duration-300 cursor-pointer 4xm:text-base text-sm`}>

                        {/* <ReactSVG 
                            src={`${process.env.PUBLIC_URL}/assets/images/icons/tag.svg`}
                            className="w-4 rotate-45"
                        /> */}

                        مدارک

                    </div>

                </SwiperSlide>
                
                <SwiperSlide className="w-fit">

                    <div onClick={() => {onSetActiveTab(2)}} className={`w-fit ${activeTab === 2 ? 'border-white/60 bg-primary text-white' : 'border-gray-300 text-gray-700'} border py-1.5 px-3 rounded-lg flex items-center gap-2 duration-300 cursor-pointer 4xm:text-base text-sm`}>

                        {/* <ReactSVG 
                            src={`${process.env.PUBLIC_URL}/assets/images/icons/notes.svg`}
                            className="w-4"
                        /> */}

                        یادداشت

                    </div>

                </SwiperSlide> 

                <SwiperSlide className="w-fit">

                    <div onClick={() => {onSetActiveTab(3)}} className={`w-fit ${activeTab === 3 ? 'border-white/60 bg-primary text-white' : 'border-gray-300 text-gray-700'} border py-1.5 px-3 rounded-lg flex items-center gap-2 duration-300 cursor-pointer 4xm:text-base text-sm`}>

                        {/* <ReactSVG 
                            src={`${process.env.PUBLIC_URL}/assets/images/icons/events.svg`}
                            className="w-4"
                        /> */}

                        رویداد

                    </div>

                </SwiperSlide>  

                <SwiperSlide className="w-fit">

                    <div onClick={() => {onSetActiveTab(4)}} className={`w-fit ${activeTab === 4 ? 'border-white/60 bg-primary text-white' : 'border-gray-300 text-gray-700'} border py-1.5 px-3 rounded-lg flex items-center gap-2 duration-300 cursor-pointer 4xm:text-base text-sm`}>

                        {/* <ReactSVG 
                            src={`${process.env.PUBLIC_URL}/assets/images/icons/events.svg`}
                            className="w-4"
                        /> */}

                        مالی

                    </div>

                </SwiperSlide>  

                { !hideBlurBg &&
                    <div className="absolute top-0 left-0 w-10 h-full z-[1] overflow-blur"></div>
                }

            </Swiper>

        </div>
    )

}

export default CaseHeaderTabs