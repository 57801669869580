import { isNative } from "lodash"
import { useEffect, useRef, useState } from "react"

const OtpInput = (props) => {

    const inputRefs = useRef([])

    useEffect(() => {

        [...Array(props.inputCount).keys()].map(item => {
            
            if(inputRefs.current[item]){
                inputRefs.current[item].blur()
            }

        })

    }, [])

    const updateField = (index, event) => {

        const field = inputRefs.current[index]
        const value = event.target.value

        if( !isNaN(value) ){

            // check exist any number or no
            if(value.length <= 1){
                field.value = value
            }
            else{
                event.target.value = ''
            }
    
            // if put a value come focus on the next input
            if(event.target.value != '')
                nextFocus(index)
    
            // go and send value to parent
            const otpValue = inputRefs.current.map(item => item.value).join('')
            props.onUpdateValues(otpValue)

        }
        else {
            field.value = ''
            const otpValue = inputRefs.current.map(item => item.value).join('')
            props.onUpdateValues(otpValue)
        }

    }

    const nextFocus = (index) => {
        
        if(index + 1 < inputRefs.current.length){
            inputRefs.current[index + 1].focus()
        }

    }

    const comeBackFocus = (event, index) => {

        if( (event.code === "Backspace") && event.target.value === ''){
            
            if(index - 1 >= 0){
                inputRefs.current[index].value = ''
                inputRefs.current[index - 1].focus()
            }

        }

        else if(event.code === 'ArrowRight'){
            nextFocus(index)
        }

    }

    const focusOnThem = (event) => {
        event.target.select()
    }

    return (
        <div className="flex items-center justify-center 3xm:gap-6 gap-4" dir="ltr">
            { [...Array(props.inputCount).keys()].map(item => 
                <input 
                    key={'otp-field-'+item}
                    type="tel"
                    id={'otp-fiel-'+item}
                    ref={ (ref) => inputRefs.current[item] = ref }
                    onChange={(event) => updateField(item, event)}
                    className="3xm:w-14 3xm:h-14 3xm:text-xl 5xm:w-10 5xm:h-10 w-8 h-8 flex items-center justify-center text-center rounded-xl bg-white shadow-custom-lg border border-gray-300 outline-none focus:border-primary duration-300"
                    autoFocus={item === 0}
                    onKeyDown={(event) => comeBackFocus(event, item)}
                    onClick={focusOnThem}
                />      
            )}
        </div>
    )

}

export default OtpInput