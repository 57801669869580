const replacePersianDigits = (value) => {
    const persianDigits = '۰۹۱۲۳۴۵۶۷۸'
    const englishDigits = '0912345678'

    return value.replace(/[۰-۹]/g, (digit) => englishDigits[persianDigits.indexOf(digit)])
}

export const searchInList = (list, searchValue, searchList) => {
    // if be empty come do
    if(!searchValue) return null
    if(list.length === 0) return []

    let value = searchValue.toLowerCase()

    // if be text, come change numbers to english number
    if(isNaN(value)){
        value = replacePersianDigits(value)
    }

    const filtered = list.reduce( (filter, item) => {

        let notAdd = true
        
        searchList.map(field => {
            if(item[field]?.toLowerCase()?.includes(value) && notAdd){
                filter.push(item)
                notAdd = false
            }
        })

        return filter
        
    }, [])

    return filtered
}

export const searchInCases = (list, searchValue, nameField) => {
    if(!searchValue) return null
    if(list.length === 0) return []

    let value = searchValue;
    
    if(typeof searchValue !== 'boolean'){

        value = searchValue?.toLowerCase()

        // if be text, come change numbers to english number
        if(isNaN(value)){
            value = replacePersianDigits(value)
        }

    }

    let filtered;

    if(nameField !== 'lawyer' && nameField !== 'category' && nameField !== 'is_archived' && nameField !== 'is_favorite'){

        filtered = list.reduce( (filter, item) => {
    
            if(item[nameField]?.toLowerCase()?.includes(value))
                filter.push(item)
    
            return filter
            
        }, [])

    }
    else if(nameField === 'lawyer') {

        
        filtered = list.reduce( (filter, item) => {
    
            if(item?.['lawyer']?.name?.toLowerCase()?.includes(value)){
                filter.push(item)
            }
    
            return filter
            
        }, [])

    }
    else if(nameField === 'category'){
        filtered = list.reduce( (filter, item) => {
    
            if(item[nameField]?.name?.toLowerCase()?.includes(value))
                filter.push(item)
    
            return filter
            
        }, [])
    }
    else if( nameField === 'is_archived' || nameField === 'is_favorite'){
        filtered = list.reduce( (filter, item) => {
    
            if(item[nameField] === searchValue)
                filter.push(item)
    
            return filter
            
        }, [])
    }
    
    return filtered
}