import { useEffect, useState } from "react"
import { Link, useParams } from "react-router-dom"

import { format } from "date-fns-jalali"

import Header from "../../../components/Header"
import HeaderSearchBtn from "../../../components/Header/Search/Btn"
import HeaderAddBtn from "../../../components/Header/AddBtn"
import EventsDateItems from "../../../components/Events/DateItems"
import { useDispatch, useSelector } from "react-redux"
import { SET_FAILURE_EVENTS, SET_LOADING_CALENDAR_DATE_EVENTS } from "../../../redux/action/events/eventsType"
import EventsSearch from "../../../components/Events/Search"
import { ReactSVG } from "react-svg"
import ToastCustom from "../../../components/UI/ToastCustom"
import LoadingDot from "../../../components/UI/LoadingDot"

const EventsDateList = () => {

    const dispatch = useDispatch()
    const deleteLoading = useSelector(state => state.events.loading)
    const deleteError = useSelector(state => state.events.error)

    const [persianDate, setPersianDate] = useState('')
    const [persianEnglishDate, setPersianEnglishDate] = useState('')

    const params = useParams()

    useEffect(() => {

        // show date into the header title
        if(params.date){
            setDateInHeaderText()
        }

        if(deleteError){
            setTimeout(() => {

                dispatch({
                    type: SET_FAILURE_EVENTS,
                    payload: null
                })

            }, 1000)
        }

        window.scrollTo({top: 0})
        
        return () => {
            dispatch({
                type: SET_LOADING_CALENDAR_DATE_EVENTS,
                payload: false
            })
        }

    }, [])

    const setDateInHeaderText = () => {

        let month = params.date.slice(5, 7)
        month = Number(month) - 1
        const time = new Date( params.date.slice(0, 4), month , params.date.slice(8) )
        const date = format(time, 'yyyy/MM/dd', { jalali: true })

        setPersianEnglishDate( date )

        const year = Number(date.slice(0, 4)).toLocaleString('fa').replace('٬', '')
        let months = Number(date.slice(5, 7))
        let day = Number(date.slice(8))

        setPersianDate(`${year}/${months <= 9 ? '۰' + months.toLocaleString('fa') : months.toLocaleString('fa')}/${day <= 9 ? '۰' + day.toLocaleString('fa') : day.toLocaleString('fa')}`)
    }

    const getEndDate = () => {
        const date = persianEnglishDate.split('/')

        const endDay = Number(date[2]) + 1
        const endDate = `${date[0]}/${date[1]}/${endDay}`

        return endDate
    }

    return (
        <>
            <Header 
                title={persianDate}
                backBtn={true}
                backPath={'/events'}
            >

                <HeaderSearchBtn />

                <Link to={ 'add' } state={{persianEnglishDate}} className="header-btn">

                    <ReactSVG src={`${process.env.PUBLIC_URL}/assets/images/icons/add.svg`} className="w-[85%] h-[85%]" />

                </Link>

            </Header>

            <main className="main-content">
                
                <EventsSearch 
                    start={persianEnglishDate}
                    end={getEndDate()}
                />

                <EventsDateItems 
                    date={persianEnglishDate}
                />

                {  deleteError &&
                    
                    <ToastCustom>
                        
                        <div className="flex items-center gap-2">
                            <ReactSVG
                                className="text-rose-500/90 4xm:w-8 w-6"
                                src={`${process.env.PUBLIC_URL}/assets/images/icons/info-box.svg`}
                            />

                            { deleteError }

                        </div>

                    </ToastCustom>

                }

                { deleteLoading && <LoadingDot /> }

            </main>
        </>
    )

}

export default EventsDateList