import { useEffect, useState } from "react"
import { ReactSVG } from "react-svg"
import ModalBottom from "../../../UI/ModalBottom"
import AddEventFormCasesSearch from "../../../Events/Add/Form/Cases/Search"
import AddEventFormCasesList from "../../../Events/Add/Form/Cases/List"

const NoteAddFormCases = (props) => {

    const [showingModal, setShowingModal] = useState(true)
    const [filteredList, setFilteredList] = useState({
        searched: false,
        list: []
    })

    useEffect(() => {

        return () => {
            if(document.body.classList.contains('modal-opened'))
                document.body.classList.remove('modal-opened')
        }

    }, [])

    const hideModal = () => {
        setShowingModal( false )

        setTimeout(() => {
            props.onSetShowCases(false)
        }, 300);

        document.body.classList.remove('modal-opened')
    }

    const resetDataAndHideModal = () => {
        hideModal()

        props.onUpdateCaseInfoField(null, '')
    }

    const selectedCase = (id, title) => {
        props.onUpdateCaseInfoField(id, title)

        hideModal()
    }

    return (
        <>
            <ModalBottom
                show={props.showCases}
                onSetShow={props.onSetShowCases}
                className="overflow-y-auto no-scrollbar h-full"
                showing={showingModal}
                onSetShowing={setShowingModal}
            >
                
                <div className="sticky top-0 left-0 flex flex-col bg-white w-full z-[1] border-b border-b-slate-200">

                    <div className="py-2 bg-white z-[1] mb-2">
                        <div className="mx-auto w-20 h-2 bg-gray-200 rounded-2xl"></div>
                    </div>

                    <div className="flex items-center justify-between gap-4 mb-4">

                        <button onClick={hideModal} type="button" className="w-10 group">
                            
                            <ReactSVG src={`${process.env.PUBLIC_URL}/assets/images/icons/back.svg`} className="w-5 text-gray-600 hover:text-primary duration-300 rotate-180 mr-1.5 group-disabled:text-gray-400 group-disabled:opacity-70" />

                        </button>

                        <div className="flex items-center gap-0.5">

                            <span className="font-medium flex items-center gap-1.5">
                                <ReactSVG src={`${process.env.PUBLIC_URL}/assets/images/icons/folder.svg`} className="w-[18px] text-gray-500" />

                                { props.caseInfo?.id ? props.caseInfo?.title : 'انتخاب نشده' }
                            </span>

                            { props.caseInfo?.id &&
                                <button onClick={resetDataAndHideModal} className="h-full focus:outline-none outline-none w-8 flex justify-end">
                                    <ReactSVG 
                                        src={`${process.env.PUBLIC_URL}/assets/images/icons/delete.svg`}
                                        className="w-5 text-rose-600"
                                    />
                                </button>
                            }

                        </div>

                    </div>

                    <AddEventFormCasesSearch 
                        onSetFilteredList={setFilteredList}
                    />

                </div>

                <AddEventFormCasesList 
                    onSelectCase={selectedCase}
                    filteredList={filteredList}
                />

            </ModalBottom>
        </>
    )

}

export default NoteAddFormCases