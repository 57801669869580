import { SET_LOADING_BASEDATA, SET_FAILURE_BASEDATA, SET_SUCCESS_BASEDATA } from '../../action/baseData/baseType'

const initalState = {
    info: {},
    loading: false,
    error: null,
    loaded: false
}

const baseDataReducer = (state = initalState, action) => {

    switch(action.type){
        
        case SET_LOADING_BASEDATA :
            return {
                ...state,
                loading: action.payload,
            }

        case SET_FAILURE_BASEDATA :
            return {
                ...state,
                loading: false,
                error: action.payload
            }

        case SET_SUCCESS_BASEDATA :
            return {
                ...state,
                loading: false,
                error: null,
                info: action.payload,
                loaded: true
            }

        default : 
            return state

    }

}

export default baseDataReducer