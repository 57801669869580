import { useEffect, useRef, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { ReactSVG } from "react-svg"
import { SVAE_HAVE_NEW_MESSAGE_IN_CHAT_PAGE, SVAE_SCROLL_POINT_USER_IN_CHAT_PAGE } from "../../../../redux/action/messages/chat/chatType"
import { useParams } from "react-router-dom"

const GoToDownMessageBtn = ({onSetMessageIsUp}) => {

    const dispatch = useDispatch()
    const expendData = useSelector(state => state.messages.chat.loadedChatListExpendData)

    const [haveNewMessage, setHaveNewMessage] = useState(false)

    const buttonRef = useRef()

    const params = useParams()

    useEffect(() => {

        const time = setTimeout(() => {
            
            buttonRef.current?.classList.remove('opacity-0')

        }, 100);

        return () => {
            clearTimeout(time)
        }

    }, [])

    useEffect(() => {

        if(expendData && expendData[params.id]){

            if(expendData[params.id]?.haveNewMessage){
                setHaveNewMessage(true)
            }
            else {
                setHaveNewMessage(false)
            }

        }

    }, [expendData])

    const scrollToDown = () => {
        
        window.scrollTo({
            top: window.document.body.scrollHeight + 10000,
            behavior: 'smooth'
        })

        onSetMessageIsUp(false)

        dispatch({
            type: SVAE_SCROLL_POINT_USER_IN_CHAT_PAGE,
            payload: {
                scrollPoint: window.document.body.scrollHeight + 200000 + window.scrollY,
                userId: params.id
            }
        })

        setTimeout(() => {
            
            dispatch({
                type: SVAE_HAVE_NEW_MESSAGE_IN_CHAT_PAGE,
                payload: {
                    haveNewMessage: false,
                    userId: params.id
                }
            })

        }, 1000);

    }

    return (
        <button onClick={scrollToDown} ref={buttonRef} className="w-10 h-10 opacity-0 duration-500 bg-white flex items-center justify-center shadow-custom-xl border border-primary/80 rounded-full fixed left-4 bottom-[88px] z-10">
            
            <ReactSVG
                className="text-primary w-6"
                src={`${process.env.PUBLIC_URL}/assets/images/icons/down.svg`}
            />

            { haveNewMessage &&

                <span className="absolute right-0 top-0 w-2.5 h-2.5 border border-rose-100 btn-pulse-animation shadow-custom-md bg-rose-500 rounded-full"></span>

            }

        </button>
    )

}

export default GoToDownMessageBtn