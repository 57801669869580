import { useLocation } from "react-router-dom"
import PaymentGetewayItem from "./Item"

const PaymentGetewayItems = () => {

    const location = useLocation()

    return (
        <ul className="sm:grid sm:grid-cols-2 flex flex-col gap-8 w-full">
            
            { location.state?.urls && location.state?.urls?.map(item =>

                <PaymentGetewayItem
                    key={item.url}
                    homeLogo={ item.title === 'zarinpal' || item.title === 'sep'} // have image into the front codes.
                    logo={item.title === 'zarinpal' ? 'zarinpal' : item.title === 'sep' ? 'sep' : item.logo} 
                    title={item.title === 'zarinpal' ? 'زرین‌پال' : item.title === 'sep' ? 'سامان کیش' : item.title}
                    url={item.url}
                />

            )}

        </ul>

    )

}

export default PaymentGetewayItems