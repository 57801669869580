import { useSelector } from "react-redux"
import { ReactSVG } from "react-svg"

const PlanItemPaymentItem = ({icon, text, click, clickedButton}) => {

    const paymentLoading = useSelector(state => state.wallet.paymentLoading)
    
    return (
        <button onClick={click} disabled={paymentLoading} className="5xm:w-1/2 w-1/2 flex-shrink-0 flex flex-col items-center gap-1">
                
            <div className="bg-custom-gray border border-gray-200 6xm:w-14 6xm:h-14 w-12 h-12 flex items-center justify-center rounded-xl">

                <ReactSVG 
                    src={`${process.env.PUBLIC_URL}/assets/images/icons/${icon}.svg`} 
                    className="6xm:w-8 w-7 text-blue-500" 
                />

            </div>

            {  text !== 'loading' ?  
                <span className="6xm:text-base text-sm"> {text} </span>
                :   
                <ReactSVG 
                    src={`${process.env.PUBLIC_URL}/assets/images/icons/loading-dot.svg`} 
                    className="6xm:w-8 mt-2 w-7 text-gray-600" 
                />
            }

        </button>
    )

}

export default PlanItemPaymentItem