import { Link } from "react-router-dom"
import Header from "../../components/Header"
import HeaderSearchBtn from "../../components/Header/Search/Btn"
import MessagesList from "../../components/Messages/List"
import MessagesSearch from "../../components/Messages/Search"
import { ReactSVG } from "react-svg"
import { useEffect } from "react"

const Messages = () => {

    useEffect(() => {

        window.scrollTo({top: 0})

    }, [])

    return (
        <>
            <Header 
                title={'پیام‌ها'}
            >

                <HeaderSearchBtn />

                <Link to={ 'contacts' } className="header-btn">

                    <ReactSVG src={`${process.env.PUBLIC_URL}/assets/images/icons/add-message.svg`} className="w-[60%] h-[60%]" />

                </Link>

            </Header>

            <main className="main-content">

                <MessagesSearch />

                <MessagesList />

            </main>

        </>
    )

}

export default Messages