import { useEffect, useState } from "react"
import AddEventFormSuggestItem from "./Item"
import { useDispatch, useSelector } from "react-redux"
import { useCookies } from "react-cookie"
import { getBaseDataInfoFromLocaleStorage, saveBaseDataInfoToLocaleStorage } from "../../../../../utils/base-data"
import { getBaseDataLawyer } from "../../../../../redux/action/baseData/baseAction"
import { SET_SUCCESS_BASEDATA } from "../../../../../redux/action/baseData/baseType"
import { ReactSVG } from "react-svg"
const AddEventFormSuggest = (props) => {

    const dispatch = useDispatch()
    const baseDataEvents = useSelector(state => state.baseData.info?.misc?.events)
    const baseDataLoading = useSelector(state => state.baseData.loading)
    const baseDataLoaded = useSelector(state => state.baseData.loaded)

    const [cookies] = useCookies(['authToken'])

    useEffect(() => {

        getSuggestEventType()

    }, [])

    const getSuggestEventType = async () => {

        if(baseDataLoaded) return

        if(baseDataLoading) return

        const baseData = getBaseDataInfoFromLocaleStorage()

        if(!baseData){
            const data = await dispatch( await getBaseDataLawyer(cookies.authToken) )

            if(data){
                saveBaseDataInfoToLocaleStorage(data)
            }

        }
        else {

            dispatch({
                type: SET_SUCCESS_BASEDATA,
                payload: baseData
            })

        }

    }

    // const [suggests, setSuggests] = useState([
    //     'رسیدگی', 'کارشناسی', 'اتیان سوگند',
    //     'استماع شهادت شهود', 'معاینه محل', 'مواجهه‌ی حضور',
    //     'تفهیم اتهام', 'آخرین دفاع', 'مزایده',
    //     'توضیح شکایت',
    // ])

    return (
        <>  
            { baseDataLoading &&
                
                <div className="h-20 w-full flex items-center justify-center">

                    <ReactSVG src={`${process.env.PUBLIC_URL}/assets/images/icons/loading-dot.svg`} className={`w-10 text-gray-500`} />

                </div>

            }

            <ul className="flex items-center flex-wrap gap-5 gap-y-7">
                { baseDataEvents && baseDataEvents.length >= 1 && baseDataEvents?.map(item => 
                    
                    <AddEventFormSuggestItem 
                        key={item}
                        text={item}
                        onAddToTitle={props.onAddToTitle}
                        isActivePrefix={props.isActivePrefix}
                    />
                    
                )}
            </ul>
        </>
    )

}

export default AddEventFormSuggest