import { ReactSVG } from "react-svg"
import { useDispatch } from "react-redux"

import { SHOW_SEARCH } from "../../../../redux/action/search/searchType"

const HeaderSearchBtn = () => {

    const dispatch = useDispatch()

    const showSearchBox = () => {

        dispatch({
            type: SHOW_SEARCH
        })

    }

    return (
        <button onClick={showSearchBox}>
            <ReactSVG src={`${process.env.PUBLIC_URL}/assets/images/icons/search.svg`} className="w-[22px]" />
        </button>
    )

}

export default HeaderSearchBtn