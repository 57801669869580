import { HIDE_SEARCH, SHOW_SEARCH, SET_CONTACTS_SEARCH, SET_MESSAGES_SEARCH, SET_CHAT_SEARCH, SET_NOTES_SEARCH, SET_EVENTS_SEARCH, SET_CASES_SEARCH } from "../../action/search/searchType"

const initalState = {
    showSearch: false,

    contactsSearch: '',
    messagesSearch: '',
    chatSearch: '',
    notesSearch: '',
    eventsSearch: '',
    casesSearch: ''
}

const searchReducer = (state = initalState, action) => {

    switch(action.type){

        case HIDE_SEARCH :
            return {
                ...state,
                showSearch: false
            }

        case SHOW_SEARCH :
            return {
                ...state,
                showSearch: true
            }

        case SET_CONTACTS_SEARCH :
            return {
                ...state,
                contactsSearch: action.payload
            }

        case SET_MESSAGES_SEARCH :
            return {
                ...state,
                messagesSearch: action.payload
            }

        case SET_CHAT_SEARCH :
            return {
                ...state,
                chatSearch: action.payload
            }

        case SET_NOTES_SEARCH :
            return {
                ...state,
                notesSearch: action.payload
            }

        case SET_EVENTS_SEARCH :
            return {
                ...state,
                eventsSearch: action.payload
            }

        case SET_CASES_SEARCH :
            return {
                ...state,
                casesSearch: action.payload
            }

        default :
            return state

    }    

}

export default searchReducer