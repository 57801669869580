import { useSelector } from "react-redux"
import { ReactSVG } from "react-svg"

const WalletActionButtonAddBalanceModalBalance = () => {

    const balance = useSelector(state => state.wallet.wallet.balance)
    const loading = useSelector(state => state.wallet.loading)

    return (
        <div className="flex items-center justify-between w-full gap-4 sm:text-base text-sm md:mb-14 mb-12">

            <div className="flex items-center gap-1">
                
                <span className="text-gray-600"> موجودی فعلی: </span>

            </div>

            <div className="flex items-center gap-1">  

                { !loading ? 
                    <span className="font-medium text-base">{ Number(balance.total).toLocaleString('fa') }</span>
                    :
                    <ReactSVG src={`${process.env.PUBLIC_URL}/assets/images/icons/loading-dot.svg`} className="w-8 text-gray-600 hover:text-primary duration-300 rotate-180 group-disabled:text-gray-400 group-disabled:opacity-70" />
                }
                <span className="text-gray-600">تومان</span>

            </div>

        </div>
    )

}

export default WalletActionButtonAddBalanceModalBalance