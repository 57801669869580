import { useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import { useCookies } from "react-cookie"

import { REMOVE_ERROR_CHAT_FILE, SET_LIST_CHAT, SET_LIST_CHAT_WITH_SORTED_DATE, SET_PAGINATION_IN_CHAT, SET_RECEIVER_CHAT, SVAE_HAVE_NEW_MESSAGE_IN_CHAT_PAGE, SVAE_SCROLL_POINT_USER_IN_CHAT_PAGE } from "../../../redux/action/messages/chat/chatType"
import { getChatMessages, getChatMessagesWithoutLoading } from "../../../redux/action/messages/chat/chatAction"

import MessageContent from "../../../components/Messages/Message/Content"
import MessageHeader from "../../../components/Messages/Message/Header"
import MessageLayout from "../../../components/Messages/Message/Layout"
import SendMessageFooter from "../../../components/Messages/Message/Send"
import LoadMoreMessageBtn from "../../../components/Messages/Message/LoadMoreBtn"
import DeleteChatOption from "../../../components/Messages/Message/DeleteChatOption"
import { getContactInfo } from "../../../redux/action/contacts/contactsAction"
import { ADD_ITEM_TO_PROFILES_LIST } from "../../../redux/action/contacts/typeContacts"
import InviteToChat from "../../../components/Messages/Message/Invite"
import ToastCustom from "../../../components/UI/ToastCustom"
import { ReactSVG } from "react-svg"
import GoToDownMessageBtn from "../../../components/Messages/Message/GoToDownBtn"
import ConfirmModal from "../../../components/UI/ConfirmModal"
import { debounce } from "lodash"

const Message = () => {

    const dispatch = useDispatch()
    const loadedChatList= useSelector(state => state.messages.chat.loadedChatList)
    const loading = useSelector(state => state.messages.chat.loading)
    const chats = useSelector(state => state.messages.list.messages)
    const chatFileError = useSelector(state => state.messages.chat.chatFileError)
    const expendData = useSelector(state => state.messages.chat.loadedChatListExpendData)

    const [invite, setInvite] = useState(false)
    const [messageIsUp, setMessageIsUp] = useState(false)
    const [errorState, setErrorState] = useState(false)


    const params = useParams()
    const [cookies] = useCookies()

    useEffect(() => {

        if(!loading){
            checkLoadedChatOrNo()
        }

        window.addEventListener('scroll', debounceUpdateScrollHeight)

        return () => {

            window.removeEventListener('scroll', debounceUpdateScrollHeight)
        }

    }, [])

    useEffect(() => {

        let timeoutForGettingNewMessage;
        
        timeoutForGettingNewMessage = setInterval(() => {

            if(!loadedChatList[params.id])
                getMessagesListWithoutLoadingNow()
            else {
                getChatMessagesListWithoutLoading()
            }

        }, 20001);

        return () => {
            
            if(timeoutForGettingNewMessage){
                clearInterval(timeoutForGettingNewMessage)
            }

        }

    }, [loadedChatList])

    useEffect(() => {

        if(chatFileError && !chatFileError?.includes('file.mime وارد نشده است')){
            setTimeout(() => {

                dispatch({
                    type: REMOVE_ERROR_CHAT_FILE
                })
                
            }, 1000)
        }
        else if(chatFileError && chatFileError?.includes('file.mime وارد نشده است')){

            setErrorState(true)
            document.body.classList.add('modal-opened')

        }

    }, [chatFileError])


    // --------------------------------------
    // for updating height when resize page
    const updateScrollPoint = () => {
        
        // update into the info of chatmessage.
        dispatch({
            type: SVAE_SCROLL_POINT_USER_IN_CHAT_PAGE,
            payload: {
                scrollPoint: window.scrollY,
                userId: params.id
            }
        })

        
        if( (document.body.scrollHeight - window.scrollY) > 801) {
            
            setMessageIsUp(true)
        
        }
        else {
            
            setMessageIsUp(false)
           
            setTimeout(() => {
                
                dispatch({
                    type: SVAE_HAVE_NEW_MESSAGE_IN_CHAT_PAGE,
                    payload: {
                        haveNewMessage: false,
                        userId: params.id
                    }
                })
                
            }, 1000);

        }

    }
    const debounceUpdateScrollHeight = debounce(updateScrollPoint, 1000)

    // ---------------------------------------

    const checkLoadedChatOrNo = () => {

        if(params.phone && !loadedChatList[params.id]){
            getChatMessagesList()
        }

        else if(loadedChatList[params.id]){

            if(loadedChatList[params.id]?.data?.moreMessages?.length >= 1){
                
                dispatch({
                    type: SET_LIST_CHAT,
                    payload: [
                        ...loadedChatList[params.id].data.moreMessages,
                        ...loadedChatList[params.id].data.messages
                    ]
                })

                // Apply Dating on Messages
                const object = loadedChatList[params.id].data.messagesSortByDate

                const moreMessages = loadedChatList[params.id].data.moreMessages

                moreMessages?.map(item => {

                    const date = new Date( item?.created_at )?.toLocaleDateString('fa', {year: 'numeric', month: '2-digit', day: '2-digit', timeZone: '+0330'}) 

                    if(!object[date]){
                        object[date] = [item]
                    }
                    else {
                        object[date] = [...object[date], item]
                    }

                })

                dispatch({
                    type: SET_LIST_CHAT_WITH_SORTED_DATE,
                    payload: object
                })

            }

            else{
                
                dispatch({
                    type: SET_LIST_CHAT,
                    payload: loadedChatList[params.id].data.messages
                })

                dispatch({
                    type: SET_LIST_CHAT_WITH_SORTED_DATE,
                    payload: loadedChatList[params.id].data.messagesSortByDate
                })

            }

            dispatch({
                type: SET_RECEIVER_CHAT,
                payload: loadedChatList[params.id].data.receiver
            })

            dispatch({
                type: SET_PAGINATION_IN_CHAT,
                payload: loadedChatList[params.id].pagination
            })

            getChatMessagesListWithoutLoading()
            
            if(!expendData[params.id]){

                setTimeout(() => {

                    window.scrollTo({
                        top: window.document.body.scrollHeight + 10000,
                    })
                   
                    dispatch({
                        type: SVAE_SCROLL_POINT_USER_IN_CHAT_PAGE,
                        payload: {
                            scrollPoint: window.document.body.scrollHeight + 10000,
                            userId: params.id
                        }
                    })
            
                    
                }, 6);

            }
            else if(expendData[params.id] && expendData[params.id]?.scrollPoint ){

                setTimeout(() => {
                    
                    window.scrollTo({
                        top: expendData[params.id]?.scrollPoint,
                    })
                    
                }, 3);

            }
        }
        
    }

    const getChatMessagesList = async () => {
        const data = await dispatch( await getChatMessages(params.phone, params.id, cookies.authToken) )
        
        if(data){
            
            window.scrollTo({
                top: window.outerHeight + 10000,
            })

            dispatch({
                type: SVAE_SCROLL_POINT_USER_IN_CHAT_PAGE,
                payload: {
                    scrollPoint: window.document.body.scrollHeight + 10000,
                    userId: params.id
                }
            })

            if(data?.invite){
                // come show a box for invite user :))
                getContactInfoForInvite()

                setInvite(true)
            }

        }
    }

    const getChatMessagesListWithoutLoading = async () => {

        const time = loadedChatList[params.id].loadedTime 
        
        const now = new Date().getTime()
        
        const leftTime = ( (now - time) / 1000 );
        
        if(leftTime >= 16){
            // get chat :))
            const data = await dispatch( await getChatMessagesWithoutLoading(params.phone, params.id, cookies.authToken) )
            
            if(data){

                const lastMessage = data.messages[data.messages.length - 1]

                if(lastMessage?.sender_id == params?.id){
            
                    const lastMessageShowed = loadedChatList[params.id]?.data?.messages[loadedChatList[params.id]?.data?.messages?.length - 1]

                    if(lastMessageShowed){
                        
                        if( lastMessageShowed?.id !== lastMessage?.id ){
                            
                            // is have new message.
                            dispatch({
                                type: SVAE_HAVE_NEW_MESSAGE_IN_CHAT_PAGE,
                                payload: {
                                    haveNewMessage: true,
                                    userId: params.id
                                }
                            })

                        }

                    }

                }

                if(chats.length >= 1){
                    
                    chats.map(item => {
                        if(item.id == data.receiver.id){
                            item.last_message = data.messages[data.messages.length - 1]
                        }
    
                        return item
                    })

                }

                if( (document.body.scrollHeight - window.scrollY) <= 800){
                    
                    window.scrollTo({
                        top: window.document.body.scrollHeight,
                        behavior: 'smooth'
                    })

                    // update into the info of chatmessage.
                    dispatch({
                        type: SVAE_SCROLL_POINT_USER_IN_CHAT_PAGE,
                        payload: {
                            scrollPoint: window.document.body.scrollHeight,
                            userId: params.id
                        }
                    })

                }

            }
        }

    }

    const getMessagesListWithoutLoadingNow = async () => {
        const data = await dispatch( await getChatMessagesWithoutLoading(params.phone, params.id, cookies.authToken, true) )
        
        if(data){
            
            if(chats.length >= 1){
                chats.map(item => {
                    if(item.id == data.receiver.id){
                        item.last_message = data.messages[0]
                    }

                    return item
                })
            }

            if( (document.body.scrollHeight - window.scrollY) <= 800){
                
                window.scrollTo({
                    top: window.outerHeight + 10000,
                })

                // update into the info of chatmessage.
                dispatch({
                    type: SVAE_SCROLL_POINT_USER_IN_CHAT_PAGE,
                    payload: {
                        scrollPoint: window.scrollY,
                        userId: params.id
                    }
                })

            }

        }
    }

    const getContactInfoForInvite = async () => {

        const data = await dispatch( await getContactInfo(params.id, cookies.authToken) )
        
        if(data){
            
            dispatch({
                 type: ADD_ITEM_TO_PROFILES_LIST,
                 payload: {
                     [params.id]: data
                 }
            })

        }

    }

    const hideError = () => {
        
        dispatch({
            type: REMOVE_ERROR_CHAT_FILE
        })

        setErrorState(false)

        document.body.classList.remove('modal-opened')

    }

    return (
        <>
            <MessageLayout>

                <MessageHeader 
                    invite={invite}
                />

                <main className="main-content lg:!mb-24 mb-3">
                    
                    { !invite ?

                        <>
                            <LoadMoreMessageBtn />

                            <MessageContent />  

                            <DeleteChatOption />

                            { messageIsUp && 
                             
                                <GoToDownMessageBtn 
                                    onSetMessageIsUp={setMessageIsUp}
                                />
                         
                            }

                        </>

                        :

                        <InviteToChat />

                    }

                </main>

                <SendMessageFooter 
                    invite={invite}
                /> 

                { ( chatFileError && !chatFileError?.includes('file.mime وارد نشده است') ) && 
                    
                    <ToastCustom className="!z-50">
                                        
                        <div className="flex items-center gap-2">
                            <ReactSVG
                                className="text-rose-500/90 4xm:w-8 w-6"
                                src={`${process.env.PUBLIC_URL}/assets/images/icons/info-box.svg`}
                            />
    
                            { chatFileError }
    
                        </div>
    
                    </ToastCustom>

                }

                { ( chatFileError && chatFileError?.includes('file.mime وارد نشده است') ) && 

                    <ConfirmModal
                        show={errorState}
                        setShow={setErrorState}
                        title="عدم پشتیبانی از فرمت‌ فایل"
                        description={`فرمت‌های مجاز <span class="font-medium">(docx, jpg, png, pdf)</span> میباشند.`}
                        hideButtonCancel={true}
                        buttonConfirmText={'متوجه شدم'}
                        isHtml={true}
                        onConfirm={hideError}
                        onHide={hideError}
                    >
                        <ReactSVG
                            className="text-rose-500/90 4xm:w-8 w-6"
                            src={`${process.env.PUBLIC_URL}/assets/images/icons/info-box.svg`}
                        />

                    </ConfirmModal>

                }

            </MessageLayout>
        </>
    )

}

export default Message