import { useEffect } from "react"
import EventsClendar from "../Clendar"
import EventsLayoutList from "../List"
import EventsSearch from "../Search"
import { useLocation } from "react-router-dom"

const EventsLayout = (props) => {

    const location = useLocation()

    if(props.calendar === true || location.state?.forAddCase){

        return (
            <EventsClendar />
        )

    }

    return (
        <>
            <EventsSearch 
                sorted={true}
            />

            <EventsLayoutList />
        </>
    )

}

export default EventsLayout