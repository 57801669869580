import { useEffect, useRef, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { format, parse } from "date-fns-jalali"
import { useCookies } from "react-cookie"
import { ReactSVG } from "react-svg"

import { SET_DATE_EVENTS, SET_DELETE_ITEM_STATUS_EVENTS, SET_FILTER_SORT_EVENTS, SET_HAVE_CHANGE_IN_STORAGE, SET_INITIAL_DATE_EVENTS, SET_START_END_DATE_EVENTS } from "../../../redux/action/events/eventsType"
import { getDataFromCalendar, getMoreDataFromCalendar, getMoreEventsList } from "../../../redux/action/events/eventsAction"
import { getStartEndDate } from "../../../utils/time"

import CalendarBodyList from "./BodyList"
import CalendarFooterBtns from "./Footer"
import CalendarTitleSection from "./Title"
import ToastCustom from "../../UI/ToastCustom"
import EventsSearch from "../Search"
import LoadMoreEventsList from "../List/LoadMoreBtn"
import EventDateItem from "../DateItems/Item"
import DeleteHeader from "../DateItems/DeleteHeader"
import moment from "jalali-moment"
import InfiniteScroll from "react-infinite-scroller"

const EventsClendar = () => {

    const dispatch = useDispatch()
    
    // get iran-time from server
    const serverTime = useSelector(state => state.profile.profile?.time)

    // current - date => selected date 
    const date = useSelector(state => state.events.date)
    
    // today - date
    const initialDate = useSelector(state => state.events.initialDate)
    
    // i have events from this date
    const startDateEvents = useSelector(state => state.events.startDate)
    // i have events until this date 
    const endDateEvents = useSelector(state => state.events.endDate)

    // events object => events data - get events from api
    const calendarItems = useSelector(state => state.events.calendarItems)
    const loadedCalendar = useSelector(state => state.events.calendarLoaded)
    const loadingCalendar = useSelector(state => state.events.calendarLoading)
    const errorCalendar = useSelector(state => state.events.calendarError)
    
    // if search events --> events data exist into
    const filtered = useSelector(state => state.events.filtered)
    const filterSorted = useSelector(state => state.events.filterSorted)
    const filterError = useSelector(state => state.events.filterError)
    const loadMoreLoading = useSelector(state => state.events.loadMoreLoading)

    // if have a change in to the events or delete a item come reload events. come resend a request to the api for get events object.
    const haveAChangeInStorage = useSelector(state => state.events.haveAChangeInStorage)
    const deletedItem = useSelector(state => state.events.deletedItem)

    const [selectedItemDelete, setSelectedItemDelete] = useState(null)
    const [loadingMore, setLoadingMore] = useState(false)

    const [cookies] = useCookies(['authToken'])

    useEffect(() => {

        // get server date
        const serverObj = {
            year: Number(serverTime?.slice(0, 4)),
            month: Number(serverTime?.slice(5, 7)) - 1,
            day: Number(serverTime?.slice(8, 10)),
            hours: Number(serverTime?.slice(11, 13)) + 3,
            mins: Number(serverTime?.slice(14, 16)) + 30,
            seconds: Number(serverTime?.slice(17, 19))
        }
        const resultServerDate =  new Date(serverObj.year, serverObj.month, serverObj.day, serverObj.hours, serverObj.mins, serverObj.seconds)

        if(date !== '' && loadedCalendar && ( (initialDate?.getTime() === resultServerDate?.getTime()) && (!isNaN(resultServerDate?.getTime()) && !isNaN(initialDate?.getTime())) ) ) return;

        // get today date from system
        // get Iran Time in SYSTEM date
        const currentN = moment().utcOffset('+0330').format('yyyy/MM/DDTHH:mm:ss')
        // change to sub dates - split date.
        const dateObj = {
            year: Number(currentN.slice(0, 4)),
            month: Number(currentN.slice(5, 7)) - 1,
            day: Number(currentN.slice(8, 10)),
            hours: Number(currentN.slice(11, 13)),
            mins: Number(currentN.slice(14, 16)),
            seconds: Number(currentN.slice(17))
        }
        const resultDate = new Date(dateObj.year, dateObj.month, dateObj.day, dateObj.hours, dateObj.mins, dateObj.seconds)

        // ----------------------------------------

        // if not exist server date come set default be system date
        if( isNaN( serverObj.year ) ){

            // set current date
            dispatch({
                type: SET_DATE_EVENTS,
                payload: resultDate
            })
            
            // set intial Date
            if(!initialDate){
                
                dispatch({
                    type: SET_INITIAL_DATE_EVENTS,
                    payload: resultDate
                })
    
            }

        }
        // when exist server date come set server date
        else {

            // set current date
            dispatch({
                type: SET_DATE_EVENTS,
                payload: resultServerDate
            })
            
            // set intial Date
            dispatch({
                type: SET_INITIAL_DATE_EVENTS,
                payload: resultServerDate
            })
    
        }

        // ----------------------------------------

        // get date from api
        let newDate = format(new Date( isNaN( serverObj.year ) ? resultDate : resultServerDate ), 'yyyy/M/', { jalali: true })
        
        const dataDate = getStartEndDate(newDate)
        
        const toYear = Number(newDate.slice(0, 4)).toLocaleString('fa').replace('٬', '')    

        // if not set start and end date come set
        if(!startDateEvents){
            
            dispatch({
                type: SET_START_END_DATE_EVENTS,
                payload: {
                    start: dataDate.startDate,
                    end: dataDate.endDate
                }
            })

        }

        // come get calendar from the server
        if(!calendarItems[toYear] && !loadedCalendar){
            getData(dataDate.startDate, dataDate.endDate)
        }
        else if(haveAChangeInStorage.calendar){
            getData(dataDate.startDate, dataDate.endDate)
        }

    }, [serverTime])

    useEffect(() => {

        if(startDateEvents){

            const canIUpdate = checkCanUpdateCalculateEvents()

            if(canIUpdate){
                // come get new calculate events
                updateCalendarData(canIUpdate)
            }
        }
        
        if(haveAChangeInStorage.calendar){
            
            //----------------------------- 
            // server date
            const serverObj = {
                year: Number(serverTime?.slice(0, 4)),
                month: Number(serverTime?.slice(5, 7)) - 1,
                day: Number(serverTime?.slice(8, 10)),
                hours: Number(serverTime?.slice(11, 13)) + 3,
                mins: Number(serverTime?.slice(14, 16)) + 30,
                seconds: Number(serverTime?.slice(17, 19))
            }
            const resultServerDate =  new Date(serverObj.year, serverObj.month, serverObj.day, serverObj.hours, serverObj.mins, serverObj.seconds)
            //----------------------------- 

            // --------------------------------
            // system date
            const currentN = moment().utcOffset('+0330').format('yyyy/MM/DDTHH:mm:ss')
            // change to sub dates - split date.
            const dateObj = {
                year: Number(currentN.slice(0, 4)),
                month: Number(currentN.slice(5, 7)) - 1,
                day: Number(currentN.slice(8, 10)),
                hours: Number(currentN.slice(11, 13)),
                mins: Number(currentN.slice(14, 16)),
                seconds: Number(currentN.slice(17))
            }
            const resultDate = new Date(dateObj.year, dateObj.month, dateObj.day, dateObj.hours, dateObj.mins, dateObj.seconds)
            // --------------------------------
            
            let newDate = format(new Date( isNaN( serverObj.year ) ? resultDate : resultServerDate ), 'yyyy/M/', { jalali: true })
            
            const dataDate = getStartEndDate(newDate)
            getData(dataDate.startDate, dataDate.endDate)
        }

        if(deletedItem){
           
            const date = moment().utcOffset('+0330').format('jYYYY-jMM-jDD')

            const sortedData = {
                today: [],
                yesterday: [],
            }

            filtered.list.map(item => {
                
                const itemDate = moment(item.starts_at).utcOffset('+0330').format('jYYYY-jMM-jDD')
                const dateSplit = date.split('-')
                const itemSplit = itemDate.split('-')

                if(itemDate === date){
                    sortedData.today.push(item)
                    return
                }
                else {
                    
                    if(dateSplit[0] === itemSplit[0] && dateSplit[1] === itemSplit[1] && ( (Number(dateSplit[2]) - 1) === Number(itemSplit[2]) ) ){
                        sortedData.yesterday.push(item)
                        return
                    }
                    
                    else {
                        
                        if(!sortedData[itemDate]){
                            sortedData[itemDate] = []
                        }

                        sortedData[itemDate].push(item)
                    }
                    
                }
                
            })

            dispatch({
                type: SET_FILTER_SORT_EVENTS,
                payload: {
                    ...sortedData
                }
            })

            dispatch({
                type: SET_DELETE_ITEM_STATUS_EVENTS,
                payload: false
            })
            
        }

    }, [date, deletedItem] )

    const getData = async (start, end) => {
       const data = await dispatch(await getDataFromCalendar(start, end, cookies.authToken) )
        
       dispatch({
        type: SET_HAVE_CHANGE_IN_STORAGE,
            payload: {
                list: haveAChangeInStorage.list,
                calendar: false,
                eventDate: haveAChangeInStorage.eventDate,
            }
        })
    }

    const checkCanUpdateCalculateEvents = () => {
        let newDate = format(new Date(date), 'yyyy/M/1', { jalali: true })

        newDate = newDate.split('/')
        const currentYear = Number(newDate[0])
        const currentMonth = Number(newDate[1])

        const existStartDate = startDateEvents.split('/')
        const existStartYear = Number(existStartDate[0])
        const existStartMonth = Number(existStartDate[1])

        const existEndDate = endDateEvents.split('/')
        const existEndYear = Number(existEndDate[0])
        const existEndMonth = Number(existEndDate[1])
        

        // check if not have this month come get more data
        if(currentYear > existEndYear){
            return 'front'
        }
        
        if(currentYear < existStartYear){
            return 'back'
        }
        
        if( (currentYear === existEndYear) && existEndMonth < currentMonth ){
            return 'front'
        }

        if( (currentYear === existStartYear) && existStartMonth > currentMonth ){
            return 'back'
        }

        return false
    }

    const updateCalendarData = async (position) => {
        
        // if getting data be success update date
        if(position === 'back'){
            
            let newDate = format(new Date(date), 'yyyy/M/', { jalali: true })
            let dataDate = getStartEndDate(newDate)
            dataDate = getStartEndDate(dataDate.startDate)
            
            const data = await dispatch( await getMoreDataFromCalendar(dataDate.startDate, startDateEvents, cookies.authToken) )
            
            if(data){

                dispatch({
                    type: SET_START_END_DATE_EVENTS,
                    payload: {
                        start: dataDate.startDate,
                        end: endDateEvents
                    }
                })

            }
            
        }
        else if(position === 'front') {
            let newDate = format(new Date(date), 'yyyy/M/', { jalali: true })
            let dataDate = getStartEndDate(newDate)

            const data = await dispatch( await getMoreDataFromCalendar(endDateEvents, dataDate.endDate, cookies.authToken) )

            if(data){

                dispatch({
                    type: SET_START_END_DATE_EVENTS,
                    payload: {
                        start: startDateEvents,
                        end: dataDate.endDate
                    }
                })

            }

        }

    }

    const loadMore = async () => {
        
        if( filtered.list && filtered.list.length >= 12 && !loadMoreLoading && !loadingMore && window.scrollY >= window.screenTop ) { 

            setLoadingMore(true)

            const data = await dispatch( await getMoreEventsList(filtered?.pagination?.current_page ? filtered?.pagination?.current_page : 1, filtered.searchedValue ? filtered.searchedValue : '', cookies.authToken) )
            
            if(data){

                setLoadingMore(false)
                
                if(!filtered.searchedValue)
                    handleSortLoadMoreEvents(data)
                else    
                    handleSortSearchedEvents(data)
            }

        }

    }

    const handleSortLoadMoreEvents = (events) => {

        const date = moment().utcOffset('+0330').format('jYYYY-jMM-jDD')

        events.reverse().map(item => {
            const itemDate = moment(item.starts_at).utcOffset('+0330').format('jYYYY-jMM-jDD')
            const dateSplit = date.split('-')
            const itemSplit = itemDate.split('-')

            if(itemDate === date){
                filterSorted.today.push(item)
                return
            }
            else {
                
                if(dateSplit[0] === itemSplit[0] && dateSplit[1] === itemSplit[1] && ( (Number(dateSplit[2]) + 1) === Number(itemSplit[2]) ) ){
                    filterSorted.tomorrow.push(item)
                    return
                }

                else if(dateSplit[0] === itemSplit[0] && dateSplit[1] === itemSplit[1] && ( (Number(dateSplit[2]) - 1) === Number(itemSplit[2]) ) ){
                    filterSorted.yesterday.push(item)
                    return
                }
                
                else {
                    
                    if(!filterSorted[itemDate]){
                        filterSorted[itemDate] = []
                    }

                    filterSorted[itemDate].push(item)
                }
                
            }
            
        })

        dispatch({
            type: SET_FILTER_SORT_EVENTS,
            payload: {...filterSorted}
        })
    }

    const handleSortSearchedEvents = (events) => {
        const date = moment().utcOffset('+0330').format('jYYYY-jMM-jDD')

        events.map(item => {
            const itemDate = moment(item.starts_at).utcOffset('+0330').format('jYYYY-jMM-jDD')
            const dateSplit = date.split('-')
            const itemSplit = itemDate.split('-')

            if(itemDate === date){
                filterSorted.today.push(item)
                return
            }
            else {
                
                                
                if(dateSplit[0] === itemSplit[0] && dateSplit[1] === itemSplit[1] && ( (Number(dateSplit[2]) + 1) === Number(itemSplit[2]) ) ){
                    filterSorted.tomorrow.push(item)
                    return
                }

                else if(dateSplit[0] === itemSplit[0] && dateSplit[1] === itemSplit[1] && ( (Number(dateSplit[2]) - 1) === Number(itemSplit[2]) ) ){
                    filterSorted.yesterday.push(item)
                    return
                }

                else {
                    
                    if(!filterSorted[itemDate]){
                        filterSorted[itemDate] = []
                    }

                    filterSorted[itemDate].push(item)
                }
                
            }
        })

        dispatch({
            type: SET_FILTER_SORT_EVENTS,
            payload: filterSorted
        })
    }

    return (
        <>

            <EventsSearch 
                sorted={true}
            />

            { filterError ? 
                
                <section className="flex items-center gap-2">

                    <ReactSVG src={`${process.env.PUBLIC_URL}/assets/images/icons/info-box.svg`} className="w-6 text-rose-500" />

                    <p className="text-gray-700 leading-7"> { filterError } </p>

                </section>
                
                :

                filtered?.searchedValue ? 

                    <>
                        { selectedItemDelete &&
                            <DeleteHeader 
                                selectedItemId={selectedItemDelete}
                                onSetSelectedItemDelete={setSelectedItemDelete}
                            />
                        }

                        { filtered?.list?.length === 0 ? 
                            
                            <p className="text-gray-600 flex items-center gap-2"> 
                                هیچ رویدادی یافت نشد
                            </p>
                            
                            :
                            
                            <div className="min-h-screen"> 
                    
                                <InfiniteScroll
                                    pageStart={1}
                                    loadMore={loadMore}
                                    threshold={0}
                                    hasMore={filtered.pagination?.has_more_pages}
                                    loader={ loadMoreLoading &&
                                        <ReactSVG
                                            key={0}
                                            className="w-10 text-primary mx-auto block mt-10"
                                            src={`${process.env.PUBLIC_URL}/assets/images/icons/loading-dot.svg`}
                                        />
                                    }
                                >

                                { Object.keys(filterSorted).map(item => 
                            
                                    filterSorted[item]?.length >= 1  &&

                                        <div key={item} className="border-b pb-8 mb-5 last:!border-b-0">
                                            
                                            <h2 className="text-lg font-medium mb-4"> { item === 'today' ? 'امروز' : item === 'yesterday' ? 'دیروز' : `${Number(item.split('-')[0]).toLocaleString('fa').replace('٬', '')}/${Number(item.split('-')[1]) <= 9 ? '۰' + Number(item.split('-')[1]).toLocaleString('fa') : Number(item.split('-')[1]).toLocaleString('fa')}/${Number(item.split('-')[2]) <= 9 ? '۰' + Number(item.split('-')[2]).toLocaleString('fa') : Number(item.split('-')[2]).toLocaleString('fa')}` } </h2>

                                            <ul className="grid md:grid-cols-2 grid-cols-1 gap-6">

                                                { filterSorted[item].map((item, index) => 
                                                
                                                    <EventDateItem 
                                                        key={item.id}
                                                        type={item.type}
                                                        title={item.title}
                                                        body={item.description}
                                                        time={item.starts_at}
                                                        case={item.law_suit ? item.law_suit.title : ''}
                                                        count={index + 1}
                                                        item={item}
                                                        id={`${item.id}`}
                                                        selectedItemDelete={selectedItemDelete}
                                                        onSetSelectedItemDelete={setSelectedItemDelete}
                                                        isStarted={item?.is_started}
                                                    />

                                                )}

                                            </ul>

                                        </div>
                                )}

                                </InfiniteScroll>

                            </div>
                            
                        }

                    </>

                :

                <section>

                    <div className="bg-white rounded-xl border-2 border-primary shadow-custom-md p-4 pb-0 relative">

                        <div className="flex flex-col items-center gap-2 bg-gradient-to-br outline outline-[6px] outline-primary/20 from-primary to-second p-4 rounded-lg">
                            
                            <h2 className="font-medium text-white text-lg"> {date !== '' ? date.toLocaleDateString('fa', {month: 'long'}) : ''} ماه </h2>
                            <h3 className="text-white font-medium text-xl"> {date !== '' ? date.toLocaleDateString('fa', {year: 'numeric'}) : ''} </h3>

                        </div>

                        <div className="pt-8 grid grid-cols-7 md:gap-y-6 gap-y-5 md:min-h-[488px] sm:min-h-[416px] 4xm:min-h-[392px]">
                            
                            <CalendarTitleSection />
                            
                            <CalendarBodyList 
                                now={date}
                            />

                        </div>

                        <CalendarFooterBtns 
                            now={date}
                        />

                        { loadingCalendar && 
                            
                            <div className="w-fit h-fit absolute top-0 left-0 bottom-0 right-0 m-auto flex items-center justify-center z-[1] bg-white px-3 py-2 shadow-custom-md border border-gray-100 rounded-md">
                                
                                <ReactSVG 
                                    className="w-12 text-primary"
                                    src={`${process.env.PUBLIC_URL}/assets/images/icons/loading-dot.svg`}
                                />

                            </div>

                        }

                        { errorCalendar && 
                            
                            <ToastCustom className="lg:-translate-x-2/3">
            
                                <div className="flex items-center gap-2">
                                    <ReactSVG
                                        className="text-rose-500/90 4xm:w-8 w-6"
                                        src={`${process.env.PUBLIC_URL}/assets/images/icons/info-box.svg`}
                                    />

                                    {errorCalendar}

                                </div>

                            </ToastCustom>
                        }

                    </div>

                </section>
            }

        </>
    )

}

export default EventsClendar