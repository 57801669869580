import { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"

import { HIDE_SEARCH, SET_NOTES_SEARCH, SHOW_SEARCH } from "../../../redux/action/search/searchType"
import { SET_FILTER_NOTES, SET_LOADING_NOTES } from "../../../redux/action/notes/notesType"

import SearchBox from "../../Header/Search"
import { useCookies } from "react-cookie"
import { getNotesFromSearchBody } from "../../../redux/action/notes/notesAction"

const NotesSearch = () => {

    const dispatch = useDispatch()
    const showSearch = useSelector(state => state.search.showSearch)
    const searchValue = useSelector(state => state.search.notesSearch)
    
    const filtered = useSelector(state => state.notes.filtered)

    const [cookies] = useCookies(['authToken'])

    useEffect(() => {

        if(!searchValue && showSearch){
            // close the search state
            hideSearch()
        }   
        else if(searchValue && !showSearch){
            dispatch({
                type: SHOW_SEARCH
            })
        }

    }, [])

    useEffect(() => {

        // dont send request to server when not in search state
        if(searchValue === '' || !showSearch ||  filtered.searchedValue === searchValue) return
        
        // fake loading - for watching better user.
        const timeout = setTimeout(() => {
            if(searchValue !== '')
                doSearch() 
        }, 500) 

        return () => {
            if(timeout)
                clearTimeout(timeout)
        }

    }, [searchValue])

    const updateSearchField = (event) => {

        if(event.target.value === ''){
            clearSearchValue()
            return
        }

        dispatch({
            type: SET_NOTES_SEARCH,
            payload: event.target.value
        })

    }

    const clearSearchValue = () => {
        
        dispatch({
            type: SET_NOTES_SEARCH,
            payload: ''
        })
        
        // if exist search-value come show fake loading
        if(searchValue){
            dispatch({
                type: SET_FILTER_NOTES,
                payload: {
                    value: '',
                    list: [],
                    pagination: {}
                }
            })

            // set fake loading
            dispatch({
                type: SET_LOADING_NOTES,
                payload: true
            })
    
            setTimeout(() => {
                
                dispatch({
                    type: SET_LOADING_NOTES,
                    payload: false
                })
    
            }, 250)
        }

    }

    const hideSearch = () => {
        
        // hide search
        dispatch({
            type: HIDE_SEARCH
        })

        clearSearchValue()

    }

    const doSearch = async () => {

        await dispatch( await getNotesFromSearchBody(searchValue, cookies.authToken) )
    
    }

    return (
        <SearchBox 
            value={searchValue}
            onUpdate={ updateSearchField }
            onClear={clearSearchValue}
        />
    )

}

export default NotesSearch