import { ADD_ITEMS_TO_WALLET_HISTORY, RESTE_ALL_WALLET_DATA_APP, SET_BALANCE_TOTAL_WALLET, SET_ERROR_WALLET, SET_LOADING_WALLET, SET_PAGINATION_WALLET, SET_PAYMENT_LOADING_WALLET, SET_WALLET_DATA } from "../../action/wallet/type"

const initialState = {
    wallet: {},
    loading: false,
    error: null,
    loaded: false,

    pagination: {},

    paymentLoading: false,
}

const walletReducer = (state = initialState, action) => {

    switch(action.type){
        
        // set base profile
        case SET_LOADING_WALLET :
            return {
                ...state,
                loading: action.payload,
                error: null
            }

        case SET_ERROR_WALLET :
            return {
                ...state,
                loading: false,
                error: action.payload,
                paymentLoading: false
            }

        case SET_WALLET_DATA :
            return {
                ...state,
                loading: false,
                error: false,
                wallet: action.payload,
                loaded: true
            }

        case SET_PAGINATION_WALLET :
            return {
                ...state,
                pagination: action.payload,
            }

        case ADD_ITEMS_TO_WALLET_HISTORY :
            return {
                ...state,
                wallet: {
                    ...state.wallet,
                    history: [
                        ...state.wallet.history,
                        ...action.payload
                    ]
                }
            }


        case SET_PAYMENT_LOADING_WALLET :
            return {
                ...state,
                paymentLoading: action.payload
            }

        case SET_BALANCE_TOTAL_WALLET :
            return {
                ...state,
                wallet: {
                    ...state.wallet,
                    balance: {
                        ...state.wallet.balance,
                        total: action.payload
                    }
                }
            }


        case RESTE_ALL_WALLET_DATA_APP :
            return {
                ...state,
                ...initialState
            }

        default : 
            return state

    }

}

export default walletReducer