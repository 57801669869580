import { SET_REQUEST_AUTH, SET_SUCCESS_AUTH, SET_FAILURE_AUTH, SET_MOBILE_AUTH, SET_TOKEN_AUTH, DISABLE_LOADING_AUTH } from '../../action/auth/authType'

const initialState = {
    loading: false,
    error: null,
    mobile: '',
    authToken: ''
}

const authReducer = (state = initialState, action) => {

    switch(action.type){

        case SET_REQUEST_AUTH :
            return {
                ...state,
                loading: true,
                error: null,
            }

        case SET_SUCCESS_AUTH :
            return {
                ...state,
                loading: false,
                error: null,
                mobile: action.payload.mobile,
                authToken: action.payload.token,
            }

        case SET_FAILURE_AUTH :
            return {
                ...state,
                loading: false,
                error: action.payload,
            }

        case SET_MOBILE_AUTH :
            return {
                ...state,
                loading: false,
                error: null,
                mobile: action.payload
            }

        case SET_TOKEN_AUTH :
            return {
                ...state,
                loading: false,
                error: null,
                authToken: action.payload
            }
        
        case DISABLE_LOADING_AUTH :
            return {
                ...state,
                loading: false,
                error: null
            }

        default :
            return state

    }

}

export default authReducer