import moment from "jalali-moment"
import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { ReactSVG } from "react-svg"
import HomeEventItem from "./Item"
import { getBaseDataInfoFromLocaleStorage, saveBaseDataInfoToLocaleStorage } from "../../../utils/base-data"
import { getBaseDataLawyer } from "../../../redux/action/baseData/baseAction"
import { SET_SUCCESS_BASEDATA } from "../../../redux/action/baseData/baseType"
import { useCookies } from "react-cookie"

const HomeEvents = ({items}) => {

    const dispatch = useDispatch()
    const loading = useSelector(state => state.home.loading)
    const time = useSelector(state => state.profile.profile?.time)
    
    const baseDataUserId = useSelector(state => state.baseData.info?.profile?.user_id)
    const baseDataLoading = useSelector(state => state.baseData.loading)
    const baseDataLoaded = useSelector(state => state.baseData.loaded)

    const [todayItems, setTodayItems] = useState([])
    const [tomorrowItems, setTomorrowItems] = useState([])

    const [cookies] = useCookies(['authToken'])

    useEffect(() => {

        if(!baseDataLoaded){
            getBaseDataInfo()
        }

    }, [])

    useEffect(() => {

        moveItemsToSelfDate()

    }, [items, time])

    const moveItemsToSelfDate = () => {

        if(!items || items?.length === 0 || !time) return

        const originalDate = new Date( new Date(time.slice(0, 19)).getTime() + (1000 * 60 * 30) + (1000*60*60*3) ).getTime()
        const diffrent = moment() - originalDate

        const date = moment( new Date(originalDate + diffrent) ).utcOffset('+0330').format('jYYYY-jMM-jDD')

        const today = []
        const tomorrow = []

        items.map(item => {

            const itemDate = moment(item.starts_at).utcOffset('+0330').format('jYYYY-jMM-jDD')
            const dateSplit = date.split('-')
            const itemSplit = itemDate.split('-')
    
            if(itemDate === date){
                today.push(item)
                return
            }
            else if( dateSplit[0] === itemSplit[0] && dateSplit[1] === itemSplit[1] && ( (Number(dateSplit[2]) + 1) === Number(itemSplit[2]) ) ){
                tomorrow.push(item)
                return
            }

        })

        setTodayItems( today )
        setTomorrowItems( tomorrow )

    }

    const getBaseDataInfo = async () =>{

        if(baseDataLoaded) return

        if(baseDataLoading) return

        const baseData = getBaseDataInfoFromLocaleStorage()

        if(!baseData){
            const data = await dispatch( await getBaseDataLawyer(cookies.authToken) )

            if(data){
                saveBaseDataInfoToLocaleStorage(data)
            }

        }
        else {

            dispatch({
                type: SET_SUCCESS_BASEDATA,
                payload: baseData
            })

        }

    }

    return (
        <section className="mb-10">

            { todayItems?.length >= 1 &&
                <>
                    <h2 className="text-lg font-medium mb-6"> رویداد‌های امروز </h2>

                    <ul className="grid md:grid-cols-2 grid-cols-1 gap-6">

                        {todayItems.slice(0, 4).map((item, index) => 
                            
                            <HomeEventItem 
                                key={item.id + '-home-event'}
                                type={item.type}
                                title={item.title}
                                body={item.description}
                                time={item.starts_at}
                                case={item.law_suit ? item.law_suit.title : ''}
                                count={index + 1}
                                id={`${item.id}`}
                                item={item}
                                send_sms={item?.send_sms}
                                myId={baseDataUserId ? baseDataUserId : item?.creator}
                                creator={item?.creator}
                            />
                            
                        )}

                    </ul>
                </>
            }

            {  tomorrowItems?.length >= 1 &&
                <>
                    <h2 className={`text-lg font-medium mb-6 ${todayItems.length >= 1 && 'mt-12'}`}> رویداد‌های فردا </h2>

                    <ul className="grid md:grid-cols-2 grid-cols-1 gap-6">

                        {tomorrowItems.slice(0, 4).map((item, index) => 
                            
                            <HomeEventItem 
                                key={item.id + '-home-event'}
                                type={item.type}
                                title={item.title}
                                body={item.description}
                                time={item.starts_at}
                                case={item.law_suit ? item.law_suit.title : ''}
                                count={index + 1}
                                id={`${item.id}`}
                                item={item}
                                send_sms={item?.send_sms}
                            />
                            
                        )}

                    </ul>
                </>
            }

            { loading &&
                
                <div className="w-full h-20 flex items-center justify-center">

                    <ReactSVG 
                        src={`${process.env.PUBLIC_URL}/assets/images/icons/loading-dot.svg`}
                        className="text-gray-500 w-10"
                    />
                    
                </div>

            }

        </section>
    )

}

export default HomeEvents