import { memo, useEffect } from 'react'
import { connect } from 'react-redux'

import { SET_UPDATE_IMAGE_PROFILE } from '../../redux/action/profile/profileType'

import PicPicker from '../UI/PicPicker'
import ProfileForma from './Forma'

const ProfileSection = ({ loading, profile, changeUpdateImageValue }) => {

    return (
        <section className="rounded-2xl shadow-custom-md border-[3px] border-white lg:max-w-3xl md:max-w-2xl md:p-6 py-6 px-4 mx-auto">

            <h3 className="mb-10 text-center text-lg text-gray-600"> شما میتونید اطلاعات خود را بروز کنید </h3>

            <PicPicker 
                className="mx-auto"
                image={profile.image}
                id="profile-image"
                label="تصویر پروفایل"
                loading={loading}
                onImageChanged={(value) => changeUpdateImageValue(value)}
            />

            <ProfileForma />

            {/* i dont know - question */}
            {/* <div className='mt-10'>

                <Button className={'w-full bg-white shadow-custom-md border border-rose-300 text-rose-500 max-w-md mx-auto'}>
                    خروج از حساب کاربری
                </Button>

            </div> */}

        </section>
    )

}

const mapStateToProps = (state) => {
    return {
        loading: state.profile.loading,
        profile: state.profile.profile
    }
}

const mapDispatchToProps = (dispatch) => {

    return {
        changeUpdateImageValue: (value) => {
            
            dispatch({
                type: SET_UPDATE_IMAGE_PROFILE,
                payload: value
            })

        }
    }

}

const ProfileSectionMemo = memo( connect(mapStateToProps, mapDispatchToProps)(ProfileSection) )

export default ProfileSectionMemo