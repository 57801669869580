import { ReactSVG } from "react-svg"

const PlanItemPaymentTitle = () => {

    return (
        <div className="flex items-center justify-between flex-wrap gap-2">
            
            <span className="font-medium"> انتخاب روش خرید  </span>

        </div>
    )

}

export default PlanItemPaymentTitle