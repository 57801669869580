import { useSelector } from "react-redux"
import { ReactSVG } from "react-svg"
import PlanItem from "./Item";
import { useEffect, useState } from "react";
import ToastCustom from "../../UI/ToastCustom";
import { isArray } from "lodash";

const PlansItems = () => {

    const items = useSelector(state => state.plans.items)
    const loading = useSelector(state => state.plans.loading)
    const error = useSelector(state => state.plans.error)
    
    const usedGift = useSelector(state => state.profile.profile.used_gift)
    const planStatus = useSelector(state => state.profile.profile.active_user_plan)
    const freePlanError = useSelector(state => state.plans.freePlanError)

    const [freePlanSuccess, setFreePlanSuccess] = useState(null)

    const [activePaymentItem, setActivePaymentItem] = useState()
    

    const toggleActivePaymentItem = (id) => {
        if(id === activePaymentItem){
            setActivePaymentItem(null)
        }
        else 
            setActivePaymentItem(id)
    }

    if(error) {

        return (
            <section>
                
                <h2 className="font-medium text-xl mb-12"> خرید اشتراک </h2>
                
                <div className="flex items-center gap-2 mb-6">
                    
                    <ReactSVG 
                        src={`${process.env.PUBLIC_URL}/assets/images/icons/info-box.svg`} 
                        className="w-6 text-red-500" 
                    />

                    <p> {error} </p>

                </div>

            </section>
        )

    }

    return (
        <section>

            <h2 className="font-medium text-xl mb-12"> { planStatus && planStatus[0] && planStatus[0]?.id ? 'تمدید اشتراک' : 'خرید اشتراک' } </h2>

            <ul className="xl:grid grid-cols-2 lg:flex md:grid flex flex-col gap-8">

                { items && items.length >= 1 ? 

                    items.map((item, index) => 
                        ( (index === 0 && usedGift === 1 && index <= 0 ) ) ? index :
                            <PlanItem 
                                key={'plans-' + item.id + '-' + index}

                                id={item?.id}
                                title={item?.title}
                                
                                real_price={item?.real_price}
                                discounted_price={item?.discounted_price}
                                discount={item?.discount}
        
                                duration_month={item?.duration_month}

                                activePaymentItem={activePaymentItem}
                                onToggleActivePaymentItem={toggleActivePaymentItem}

                                onSetFreePlanSuccess={setFreePlanSuccess}
                            />
                    )

                    :

                    ( items && items[1] && !isArray(items) ) &&
                        Object.keys(items).map((item, index) => 
                            <PlanItem
                                key={'plans-' + items[item].id + '-' + index}
    
                                id={items[item]?.id}
                                title={items[item]?.title}
                                
                                real_price={items[item]?.real_price}
                                discounted_price={items[item]?.discounted_price}
                                discount={items[item]?.discount}
    
                                duration_month={items[item]?.duration_month}
    
                                activePaymentItem={activePaymentItem}
                                onToggleActivePaymentItem={toggleActivePaymentItem}
    
                                onSetFreePlanSuccess={setFreePlanSuccess}
                            />
                        )
                }

            </ul>

            {   loading &&
                <div className="w-full flex items-center justify-center h-10">

                    <ReactSVG 
                        src={`${process.env.PUBLIC_URL}/assets/images/icons/loading-dot.svg`} 
                        className="md:w-12 w-10 text-primary" 
                    />

                </div>
            }


            { freePlanSuccess &&
                
                <ToastCustom>
        
                    <div className="flex items-center gap-2">
                        
                        <ReactSVG
                            className="text-green-600/90 4xm:w-8 w-6"
                            src={`${process.env.PUBLIC_URL}/assets/images/icons/tick.svg`}
                        />

                        { freePlanSuccess }

                    </div>

                </ToastCustom> 

            }

            { freePlanError &&
                
                <ToastCustom>
    
                    <div className="flex items-center gap-2">
                        
                        <ReactSVG
                            className="text-rose-500/90 4xm:w-8 w-6"
                            src={`${process.env.PUBLIC_URL}/assets/images/icons/info-box.svg`}
                        />

                        { freePlanError }

                    </div>

                </ToastCustom>

            }

        </section>
    )

}

export default PlansItems