import { connect, useSelector } from "react-redux"
import { useCookies } from "react-cookie"
import { useEffect, memo } from "react"

import { getProfileInfo, updateImageProfile, updateInfoProfile } from "../../redux/action/profile/profileAction"
import { SET_FAILURE_PROFILE } from "../../redux/action/profile/profileType"

import { ToastContainer } from 'react-toastify'
import "react-toastify/dist/ReactToastify.min.css";

import Header from "../../components/Header"
import Button from "../../components/UI/Button"
import ProfileSection from "../../components/ProfileSection"
import LoadingDot from "../../components/UI/LoadingDot"

import { showErrorMessage, showSuccessMessage } from "../../utils/toast"

const Profile = (props) => {

    const profile = useSelector(state => state.profile.profle)
    const profileLoading = useSelector(state => state.profile.loading)

    const [cookies] = useCookies(['authToken'])

    useEffect(() => {

        if(props.error){
            showErrorMessage(props.error)

            return
        }

    }, [props.error])

    useEffect(() => {

        if(!profile?.first_name && !profileLoading){
            props.getProfile(cookies.authToken)
        }

        // if not exist profile come get
        if(!props.profile.first_name && !props.loadingGettingProfile && !props.error){
            props.getProfile(cookies.authToken)
        }

        window.scrollTo({top: 0})

        return () => {
            props.resetError()
        }

    }, [])

    const sendNewDataProfileToApi = async () => {

        // go and update image
        if(props.updatedImage){
            await props.doUpdateImage(props.updatedImage, cookies.authToken)
        }

        // go and update text
        if(props.updatedText) {

            // if profile exist come do ===> if my network be off dont have profile but by base fields can fill profile and its not good.
            if(Object.keys(props.profile).length >= 1){

                const newProfileData = {...props.profile}

                Object.keys(props.fields).map(item => {
                    newProfileData[item] = props.fields[item].value
                })

                // ----set location----
                newProfileData.city = props.location.city
                newProfileData.province = props.location.province

                // and for sending data - we should give id to it
                if(props.location.city){
                    newProfileData.city_id = props.location.city.id;
                    newProfileData.province_id = props.location.province;
                }

                if(typeof newProfileData.province_id === 'object')
                    newProfileData.province_id = props.location.province.id

                if(!newProfileData?.gender){
                    newProfileData.gender = 'male'
                }

                const success = await props.doUpdateFields(newProfileData, cookies.authToken)
                
                if(success){
                    showSuccessMessage('ویرایش اطلاعات با موفقیت انجام شد')
                }

            }
            else{
                showErrorMessage('عدم برقراری ارتباط با سرور')
            }

        }

    }

    return (
        <>
            
            <Header 
                backBtn
                title={'پروفایل'}                    
            >
                
                <Button 
                    className={'profile-btn bg-primary py-1 px-4'}
                    disabled={!props.updatedImage && !props.updatedText}
                    onClick={sendNewDataProfileToApi}
                >
                    ثبت     
                </Button>

            </Header>

            <main className="main-content">

                <ProfileSection />    

                { props.loading && <LoadingDot /> }

                <ToastContainer className={'lg:left-[calc(100%-61.5%)] lg:!bottom-0 !bottom-[78px]'} />

            </main>

        </>
    )

}

const mapStateToProps = (state) => {
    return {
        updatedImage: state.profile.updatedImage,
        updatedText: state.profile.updatedText,
        error: state.profile.error,
        profile: state.profile.profile,
        loading: state.profile.updateLoading,
        loadingGettingProfile: state.profile.loading,
        fields: state.profile.fields,
        location: state.profile.userLocation
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        
        getProfile: async (authToken) => {
            await dispatch( await getProfileInfo(authToken) )
        },

        resetError: () => {
            
            dispatch({
                type: SET_FAILURE_PROFILE,
                payload: null
            })

        },

        doUpdateImage: async (image, authToken) => {
            return await dispatch( await updateImageProfile(image, authToken) )
        },

        doUpdateFields: async (data, authToken) => {
            return await dispatch( await updateInfoProfile(data, authToken) )
        }

    }
}

const ProfileMemo = memo( connect(mapStateToProps, mapDispatchToProps)(Profile) )

export default ProfileMemo