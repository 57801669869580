import { Link } from "react-router-dom"
import { ReactSVG } from "react-svg"
import { calculateLeftTime } from "../../../../utils/time"
import { isDate } from "lodash"

const NoteItem = (props) => {

    const chooseActiveItem = (event) => {
        if(props.selectedItemDelete.length <= 0) return

        event.preventDefault()
        selectItemForDelete()
    }

    const selectItemForDelete = () => {
        
        if(props.selectedItemDelete.includes(props.id)){
            props.onSetSelectedItemDelete([
                ...props.selectedItemDelete.filter(item => item != props.id)
            ])
        }

        else {
            
            props.onSetSelectedItemDelete([
                ...props.selectedItemDelete,
                props.id
            ])

        }

    }
 
    // ----- RENDER ------

    const selectColor = () => {
        if(props.color === 1)
            return 'bg-pink-200'

        else if(props.color === 2)
            return 'bg-blue-200'
        
        else if(props.color === 3)
            return 'bg-orange-200'

        else if(props.color === 4)
            return 'bg-green-200'
        
        else if(props.color === 5)  
            return 'bg-purple-200'

        else 
            return 'bg-pink-200'
    }

    const putTime = () => {

        if( isNaN(props.time) && !isNaN(props.time.slice(0, props.time.length - 8)) &&  props.time.slice(props.time.length - 8) === '.000000Z'){
            const time = calculateLeftTime(`${props.time.slice(0, 4)}-${props.time.slice(4, 6)}-${props.time.slice(6, 8)}T${props.time.slice(8, 10)}:${props.time.slice(10, 12)}:${props.time.slice(12, 14)}.000000Z`)
            
            if(time.includes('−۱ ساعت پیش'))
                return 'همین حالا'

            return time
        }
    
        else if(!isNaN(props.time)){

            if(!isDate( props.time )){
                const time = calculateLeftTime(`${props.time.slice(0, 4)}-${props.time.slice(4, 6)}-${props.time.slice(6, 8)}T${props.time.slice(8, 10)}:${props.time.slice(10, 12)}:${props.time.slice(12, 14)}.000000Z`)
                if(time.includes('−۱ ساعت پیش'))
                    return 'همین حالا'

                return time
            }
            else{

                const time = calculateLeftTime(props?.time)
                
                if(time.includes('−۱ ساعت پیش'))
                    return 'همین حالا'

                return time
            }

        }
        else {
            const time = calculateLeftTime(props?.time)
                
                if(time.includes('−۱ ساعت پیش'))
                    return 'همین حالا'

            return time
        }
        
    }

    return (
        <li>

            <Link 
                to={`/notes/${props.id}`} 
                state={{id: props.id, body: props.body, time: props.time, law_suit: props.lawData, color: props.color, subject: props.subject}}
                className={`h-full min-h-[184px] shadow-custom-sm border border-gray-200/80 ${selectColor()} rounded-xl p-4 flex flex-col gap-2.5 relative`} 
                onClick={chooseActiveItem} 
                onContextMenu={selectItemForDelete}
            >
            
                {props.law &&
                    <span className="text-xs-12px font-medium flex items-center gap-1.5 border-b border-b-gray-700 pb-2 overflow-hidden whitespace-nowrap text-ellipsis">
                        
                        <ReactSVG
                            src={`${process.env.PUBLIC_URL}/assets/images/icons/cases.svg`}
                            className="w-3.5 h-3.5 mb-0.5 mt-0.5 flex-shrink-0"
                        />

                        {props.law}
                        
                    </span>
                }     

                {props.subject &&
                    <span className="text-xs px-2 py-0.5 bg-white flex items-center gap-2 text-ellipsis overflow-hidden whitespace-nowrap rounded-lg outline outline-2 outline-white/40 max-w-full w-fit mb-1">
                        
                        <ReactSVG
                            src={`${process.env.PUBLIC_URL}/assets/images/icons/file.svg`}
                            className="w-3.5 h-3.5 mb-0.5 mt-0.5 flex-shrink-0"
                        />

                        {props.subject}
                        
                    </span>
                }     

                <p className="text-gray-600 text-sm">
                    { props.body.slice(0, 140) }{props.body.length >= 141 && '...'}
                </p>

                <div className="flex items-center flex-wrap gap-4 justify-between mt-auto">
                    

                    <div className="flex items-center gap-1 text-gray-600 mr-auto mt-auto">

                        <ReactSVG
                            src={`${process.env.PUBLIC_URL}/assets/images/icons/calculate.svg`}
                            className="w-3 h-3 mb-0.5 mt-1"
                        />

                        <span className="text-xs mt-1"> {putTime()} </span>

                    </div>
            
                </div>

                { props.selectedItemDelete.includes(props.id) && 
                    

                    <ReactSVG
                        className="w-6 h-6 text-green-600 text-opacity-90 rounded-full absolute bottom-2 right-3 outline outline-2"
                        src={`${process.env.PUBLIC_URL}/assets/images/icons/tick.svg`}
                    />

                }

            </Link>

        </li>
    )

}

export default NoteItem