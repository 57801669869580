import Header from "../../components/Header"
import WalletContent from "../../components/Wallet"
import WalletHeaderAction from "../../components/Wallet/HeaderAction"

const Wallet = () => {

    return (
        <>
            <Header 
                title={'کیف پول'}
                backBtn={true}
            >
                
                <WalletHeaderAction />

            </Header>

            <main className="main-content">
            
                <WalletContent />

            </main>

        </>
    )

}

export default Wallet