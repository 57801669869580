import { useDispatch, useSelector } from "react-redux"
import { ReactSVG } from "react-svg"
import { SET_SUCCESS_BASEDATA } from "../../../redux/action/baseData/baseType"
import { useCookies } from "react-cookie"
import { useEffect } from "react"
import { getBaseDataInfoFromLocaleStorage, saveBaseDataInfoToLocaleStorage } from "../../../utils/base-data"
import { getBaseDataLawyer } from "../../../redux/action/baseData/baseAction"

const PlansDescription = () => {

    const dispatch = useDispatch()
    const baseDataDescription = useSelector(state => state.baseData.info?.config?.clientplans_adv)
    const baseDataLoading = useSelector(state => state.baseData.loading)
    const baseDataLoaded = useSelector(state => state.baseData.loaded)

    const [cookies] = useCookies(['authToken'])

    useEffect(() => {

        getDescriptionFromBaseData()

    }, [])

    const getDescriptionFromBaseData = async () => {

        if(baseDataLoaded) return

        if(baseDataLoading) return

        const baseData = getBaseDataInfoFromLocaleStorage()

        if(!baseData){
            const data = await dispatch( await getBaseDataLawyer(cookies.authToken) )

            if(data){
                saveBaseDataInfoToLocaleStorage(data)
            }

        }
        else {

            dispatch({
                type: SET_SUCCESS_BASEDATA,
                payload: baseData
            })

        }

    }

    return (
        <section className="mb-12">

            <div className="flex justify-between shadow-custom-sm items-center gap-5 bg-gradient-to-br outline-4 mx-1 outline-third/20 from-white to-white rounded-2xl py-6 lg:p-6 md:p-5 p-4 mb-12">
                        
                <div className="flex flex-col gap-2">
                    
                    { !baseDataLoading ?
                        <p className="leading-9">  { baseDataDescription ? baseDataDescription : 'کاربر محترم، یکی از طرح های اشتراک در برنامه را انتخاب نمایید' } </p>
                        :
                        <ReactSVG 
                            src={`${process.env.PUBLIC_URL}/assets/images/icons/loading-dot.svg`} 
                            className="w-14 mt-5 text-gray-600" 
                        />
                    }
        
                </div>
                
                <div className="mr-auto flex-shrink-0">
                    
                    <img className="w-20 scale-110" src={`/assets/images/pictures/download (7).svg`} alt="" />
        
                </div>
        
            </div>
        

        </section>
    )
    
}

export default PlansDescription