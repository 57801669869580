import { ReactSVG } from "react-svg"
import PlanItemPaymentTitle from "./Title"
import PlanItemPaymentItems from "./Items"

const PlanItemPayment = ({id, title, price, onToggleActivePaymentItem = () => {}}) => {

    return (
        <div className="flex flex-col gap-10">

            <PlanItemPaymentTitle />

            <PlanItemPaymentItems 
                id={id}
                price={price}
                title={title}

                onToggleActivePaymentItem={onToggleActivePaymentItem}
            />
        
        </div>
    )

}

export default PlanItemPayment