import { memo } from 'react'
import Input from '../Input'

const InputGroup = (props) => {

    return (
        <div className={`grid md:grid-cols-2 grid-cols-1 gap-8 ${props.className}`}>
            {props.children}
        </div>
    )

}

const InputGroupMemo = memo(InputGroup)

export default InputGroupMemo