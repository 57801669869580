import { useSelector } from "react-redux"
import ContactProfileInfoItem from "./Item"

const ContactProfileInfo = (props) => {

    const loading = useSelector(state => state.contacts.loading)

    return (
        <section className="bg-white p-4 shadow-custom-md rounded-2xl mb-10">
            
            <div className="grid sm:grid-cols-2 grid-cols-1 gap-6">

                <ContactProfileInfoItem 
                    data={props.mobile}
                    label={'موبایل'}
                    icon={'mobile'}
                    phoneNumber={true}
                    loading={loading && !props.mobile}
                />

                <ContactProfileInfoItem 
                    data={props.phone}
                    label={'تلفن'}
                    icon={'telephone'}
                    phoneNumber={true}
                    loading={loading && !props.mobile}
                />

            </div>

            <hr className="my-4 border-t-gray-100" />

            <div className="grid sm:grid-cols-2 grid-cols-1 gap-6">

                <ContactProfileInfoItem 
                    data={props.email}
                    label={'پست الکترونیک'}
                    icon={'email'}
                    loading={loading}
                />

                <ContactProfileInfoItem 
                    data={props.city && 'استان ' + props.province + '،  شهر   ' + props.city}
                    label={'موقعیت'}
                    icon={'location'}
                    loading={loading}
                />

            </div>

            <hr className="my-4 border-t-gray-100" />

            <ContactProfileInfoItem 
                data={props.description}
                label={'توضیحات'}
                icon={'description'}
                loading={loading}
            />

            <hr className="my-4 border-t-gray-100" />

            <ContactProfileInfoItem 
                data={props.address}
                label={'آدرس'}
                icon={'map'}
                loading={loading}
            />

        </section>
    )

}

export default ContactProfileInfo