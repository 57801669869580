import { Link, useParams } from "react-router-dom"
import { getProfileColor } from "../../../../../utils/messages"
import { ReactSVG } from "react-svg"
import { useDispatch, useSelector } from "react-redux"
import { useEffect, useState } from "react"
import { useCookies } from "react-cookie"
import { getContactInfoByBaseMobile, getContactsList } from "../../../../../redux/action/contacts/contactsAction"
import { calculateLeftTime } from "../../../../../utils/time"
import MessageProfileSaveContact from "./SaveContact"
import ToastCustom from "../../../../UI/ToastCustom"
import { SET_CONTACTS } from "../../../../../redux/action/contacts/typeContacts"

const MessageProfileBtn = (props) => {

    const disptach = useDispatch()
    const loading = useSelector(state => state.messages.chat.loading)

    const contactError = useSelector(state => state.contacts.updateError)
    const contacts = useSelector(state => state.contacts.contacts)
    const loadedContacts = useSelector(state => state.contacts.getedContacts)

    const [onlineStatus, setOnlineStatus] = useState('')
    const [contactId, setContactId] = useState('')
    const [canGoToProfile, setCanGoToProfile] = useState(false)
    const [showSaveContact, setShowSaveContact] = useState(false)
    const [addUserToContactMessage, setAddUserToContactMessage] = useState('')

    const params = useParams()

    useEffect(() => {

        putTime()

    }, [props.lastSeen])

    useEffect(() => {

        if(contactId && !contactError){
            setCanGoToProfile(true)
        }

    }, [contactId])

    useEffect(() => {

        if(!loadedContacts){
            getContactId()
        }
        else {
            findContactId(contacts)
        }

    }, [])

    const [cookies] = useCookies(['authToken'])

    const getContactId = async () => {

        const data = await disptach( await getContactsList(cookies.authToken) )
        
        if(data){
            
            sortContactsByBaseIsUser(data)

            findContactId(data)

        }

    }

    const sortContactsByBaseIsUser = (contacts) => {
        
        contacts = contacts.sort((a, b) => {
            if (a?.user?.last_login && !b?.user?.last_login) {
                return -1; // a قبلی از b قرار می‌گیرد
            } else if (!a?.user?.last_login && b?.user?.last_login) {
                return 1; // b قبلی از a قرار می‌گیرد
            } else {
                return 0; // ترتیب مانند قبلی باقی می‌ماند
            }
        })

        disptach({
            type: SET_CONTACTS,
            payload: [...contacts]
        })

    } 

    const findContactId = (contacts) => {
        
        const exist = contacts?.find(item => item?.mobile == params.phone)

        if(exist){
            setContactId(exist?.id)
        }

    }

    const handleCanLookProfile = (event) => {
        
        if( !canGoToProfile && !contactId ){
            event.preventDefault()
            setShowSaveContact(true)
            document.body.classList.add('modal-opened')
            return
        }
            
    }

    const handleNoImage = () => {

        const color = getProfileColor(params.phone ? params.phone[10] : 2)

        return (
            <div className={`flex-shrink-0 5xm:w-12 5xm:h-12 w-10 h-10 profile-${color} border-[2px] shadow-lg rounded-xl flex items-center justify-center`}>
                { props.name ?
                    <span>{props.name[0]}</span>
                    :
                    loading &&
                        <ReactSVG 
                            src={`${process.env.PUBLIC_URL}/assets/images/icons/loading-dot.svg`}
                            className="w-5"
                        /> 
                }
            </div> 
        )
    }

    const putTime = () => {
        if(props.lastSeen){
            setOnlineStatus( calculateLeftTime(props.lastSeen) )
        }
    }

    return (
        <>
            <Link to={`/contact/${contactId}`} onClick={handleCanLookProfile} state={{userInfo: {...props, id: params.id} }} className="flex items-center flex-wrap gap-3">
                    
                <div className="flex flex-col gap-0.5 justify-end">

                    <h3 className="font-medium 5xm:text-base text-sm-13px mr-auto"> 
                        
                        { props.name ? 
                            props.name
                            :
                            loading &&
                                <div className="w-20 flex items-center justify-center">
                                    <ReactSVG 
                                        src={`${process.env.PUBLIC_URL}/assets/images/icons/loading-dot.svg`}
                                        className="w-9 text-gray-500"
                                    />
                                </div>
                        } 
                    
                    </h3>

                    {props.lastSeen ?
                        onlineStatus == 'همین حالا' ?
                        <span className="text-xs text-primary mr-auto font-medium"> آنلاین </span>
                        :
                        <span className="2xm:text-xs text-xs-10px text-gray-600 mr-auto"> آخرین بازدید {onlineStatus} </span>
                        :
                        loading &&
                            <ReactSVG 
                                src={`${process.env.PUBLIC_URL}/assets/images/icons/loading-dot.svg`}
                                className="w-5 text-gray-500 mt-1 mr-auto"
                            />
                    }

                </div>

                { props.image ? 
                
                    <div className="flex-shrink-0 5xm:w-12 5xm:h-12 w-10 h-10 bg-slate-200 5xm:border-[3px] border-2 border-slate-200 shadow-lg rounded-xl">
                        <img className="rounded-xl w-full h-full object-cover" src={props.image} alt="" />
                    </div>
                    
                    :
                    
                    handleNoImage()
                    
                }


            </Link>
                
            { (!canGoToProfile && !contactId && showSaveContact) && 
                
                <MessageProfileSaveContact 
                    userData={props}
                    onSetShowSaveContact={setShowSaveContact}
                    onSetAddUserToContactMessage={setAddUserToContactMessage}
                    onSetContactId={setContactId}
                />

            }

            { (addUserToContactMessage && addUserToContactMessage?.type) && 
                <>
                    <ToastCustom className="!z-50">
                                        
                        <div className="flex items-center gap-2">

                            { addUserToContactMessage?.type === 'error' ?
                                
                                <ReactSVG
                                    className="text-rose-500/90 4xm:w-8 w-6"
                                    src={`${process.env.PUBLIC_URL}/assets/images/icons/info-box.svg`}
                                />

                                :

                                <ReactSVG
                                    className="text-green-500/90 4xm:w-8 w-6"
                                    src={`${process.env.PUBLIC_URL}/assets/images/icons/tick.svg`}
                                />

                            }
    
                            { addUserToContactMessage?.message }
    
                        </div>
        
                    </ToastCustom>
                </>
            }

        </>
    )

}

export default MessageProfileBtn