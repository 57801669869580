import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux";
import moment from "jalali-moment";

import { SET_CAN_I_SEND_DATA_EVENTS, SET_ENTERED_DATA_EVENTS, SET_FIELDS_EVENTS } from "../../../redux/action/events/eventsType";

import AddEventFormCustomTimeListColck from "../Add/Form/CustomTime/List/Clock";
import Input from "../../UI/Input";
import AddEventFormSuggest from "../Add/Form/Suggest";
import AddEventFormCustomTimeField from "../Add/Form/CustomTime";
import Switch from "../../UI/Switch";
import { ReactSVG } from "react-svg";
import AddEventFormCasesField from "../Add/Form/Cases";
import Textarea from "../../UI/Textarea";
import { getBaseDataInfoFromLocaleStorage, saveBaseDataInfoToLocaleStorage } from "../../../utils/base-data";
import { getBaseDataLawyer } from "../../../redux/action/baseData/baseAction";
import { useCookies } from "react-cookie";
import { SET_SUCCESS_BASEDATA } from "../../../redux/action/baseData/baseType";

const EditEventForm = ({info, ...props}) => {

    const dispatch = useDispatch()
    const enteredData = useSelector(state => state.events.enteredData)
    const fields = useSelector(state => state.events.fields)
    const eventDataLoading = useSelector(state => state.events.eventDataLoading)
    const eventDataError = useSelector(state => state.events.eventDataError)
    const differentDate = useSelector(state => state.events.computerDifferentTime)
    const reciveTimeFromServer = useSelector(state => state.events.serverDateObject)
    
    // for switch messages
    const baseDataEventSwitchs = useSelector(state => state.baseData.info?.config?.terms_event)
    const baseDataLoading = useSelector(state => state.baseData.loading)
    const baseDataError = useSelector(state => state.baseData.error)
    const baseDataLoaded = useSelector(state => state.baseData.loaded)

    const [time, setTime] = useState()
    const [date, setDate] = useState()
    const [alarm, setAlarm] = useState(null)

    const [loaded, setLoaded] = useState(false) 
    const [error, setError] = useState({})

    const [cookies] = useCookies(['authToken'])

    useEffect(() => {

        getSwitchMessagesFromBaseData()

    }, [])

    useEffect(() => {

        if(!info?.id) return

        const time = moment( info.starts_at ).utcOffset('+0330').format('HH:mm')
        const date =  moment( info.starts_at ).utcOffset('+0330').format('jYYYY/jMM/jDD')

        const responseAlarm = getAlarmInfo(date, time)

        dispatch({
            type: SET_FIELDS_EVENTS,
            payload: {
                title: {value: info?.title ? info?.title : null, max: 50, isValid: true, prefix: ''},
                showEventToMovakel: info?.is_public ? info.is_public : false,
                type: info?.type ? info.type : '',
                caseInfo: {
                    id: info?.law_suit?.id ? info.law_suit?.id : '',
                    text: info?.law_suit?.title ? info?.law_suit?.title : ''
                },
                description: {value: info?.description ? info.description : '', min: 3, isValid: true},
                alaram_at: responseAlarm,
                clock: time,
                sendSms: info.send_sms
            }
        })

        setTime( time )
        setDate(date)
        setLoaded(true)

    }, [info])

    useEffect(() => {

        const timeout = setTimeout(() => {
            
            checkExistError()

        }, 500)

        return () => {
            if(timeout)
                clearTimeout(timeout)
        }

    }, [fields])

    const getSwitchMessagesFromBaseData = async () => {
        if(baseDataLoaded) return

        if(baseDataLoading) return

        const baseData = getBaseDataInfoFromLocaleStorage()

        if(!baseData){
            const data = await dispatch( await getBaseDataLawyer(cookies.authToken) )

            if(data){
                saveBaseDataInfoToLocaleStorage(data)
            }

        }
        else {

            dispatch({
                type: SET_SUCCESS_BASEDATA,
                payload: baseData
            })

        }
    }

    const getAlarmInfo = (date, time) => {

        if(!info.alarm_at) return { clock: '', date: '', label: '',  englishDate: '' }

        const clock = moment( info.alarm_at ).utcOffset('+0330').format('HH:mm')
        let dates = moment( info.alarm_at ).utcOffset('+0330').format('jYYYY/jMM/jDD')

        const helperDates = dates.split('/')
        const helperDate = date.split('/')

        const alarm = {
            clock,
            date: dates,
            label: '',
            englishDate: ''
        }

        let mins = Number( time.split(':')[1]?.trim())

        let hours = Number( time.split(':')[0]?.trim() )

        if(mins === 0)
            hours = hours - 1

        if( mins === 0 ){
            mins = 59
        }

        if(clock === time && dates === date){
            alarm.label = 'در لحظه'
        }
        else if(dates === date && (Number( clock.split(':')[0]?.trim() ) + 1) === hours && (Number( clock.split(':')[1]?.trim() )) === (mins === 59 ? mins : (mins - 1)) ){
            alarm.label = 'یک ساعت قبل'
        }
        else if( dates === date && (Number( clock.split(':')[0]?.trim() ) + 2) === hours && (Number( clock.split(':')[1]?.trim() )) === (mins === 59 ? mins : (mins - 1)) ){
            alarm.label = 'دو ساعت قبل'
        }
        else if( dates === date && (Number( clock.split(':')[0]?.trim() ) + 3) === hours && (Number( clock.split(':')[1]?.trim() )) ===  (mins === 59 ? 0 : (mins - 1)) ){
            alarm.label = 'سه ساعت قبل'
        }
        else if( ( Number(helperDates[0]) === Number(helperDate[0]) ) && ( Number(helperDates[1]) === Number(helperDate[1]) ) && ( Number(helperDates[2] + 1) === Number(helperDate[2]) ) && clock === time ){
            alarm.label = 'یک روز قبل'
        }

        // make english date
        alarm.englishDate = info.alarm_at.slice(0, 10)

        setAlarm(alarm)

        return alarm
    }

    // direct update fields  
    const updateTitleFields = (event) => {
        
        if(event.target.value.length >= 51)
            return

        if(event.target.value.length <= 2){

            dispatch({
                type: SET_FIELDS_EVENTS,
                payload: {
                    ...fields,
                    title: {
                        ...fields.title,
                        value: event.target.value,
                        isValid: false,
                    }
                }
            })

            setError({
                ...error,
                title: true
            })

            dispatch({
                type: SET_ENTERED_DATA_EVENTS,
                payload: true
            })
        }
        else {

            dispatch({
                type: SET_FIELDS_EVENTS,
                payload: {
                    ...fields,
                    title: {
                        ...fields.title,
                        value: event.target.value,
                        isValid: true,
                    }
                }
            })

            if(error && error.title){
                
                delete error['title']

                setError({
                    ...error,
                })
                
            }
        }

    }

    const updateShowToMovakelField = (value) => {

        dispatch({
            type: SET_FIELDS_EVENTS,
            payload: {
                ...fields,
                showEventToMovakel: value
            }
        })

    }

    const updateSendSmsField = (value) => {

        dispatch({
            type: SET_FIELDS_EVENTS,
            payload: {
                ...fields,
                sendSms: value
            }
        })

    }

    const updateDescriptionFields = (event) => {
        
        if(event.target.value.length > 300) return

        if(event.target.value.length >= 1 && event.target.value.length <= 2){

            dispatch({
                type: SET_FIELDS_EVENTS,
                payload: {
                    ...fields,
                    description: {value: event.target.value, min: fields.description.min, isValid: false}
                }
            })

            setError({
                ...error,
                description: true
            })            

        }
        
        else {

            dispatch({
                type: SET_FIELDS_EVENTS,
                payload: {
                    ...fields,
                    description: {
                        value: event.target.value, 
                        min: fields.description.min, 
                        isValid: true
                    }
                }
            })

            if(error && error.description){
                
                delete error['description']

                setError({
                    ...error,
                })
                
            }

        }
    }

    const updateTypeFields = (value) => {
        
        dispatch({
            type: SET_FIELDS_EVENTS,
            payload: {
                ...fields,
                type: value
            }
        })

    }

    const updateCaseInfoField = (id, text) => {

        dispatch({
            type: SET_FIELDS_EVENTS,
            payload: {
                ...fields,
                caseInfo: {
                    id,
                    text
                }
            }
        })

    }

    const updateClockField = ({hour, min}) => {

        if(hour){
            
            dispatch({
                type: SET_FIELDS_EVENTS,
                payload: {
                    ...fields,
                    clock: hour + ':' + fields.clock.split(':')[1]
                }
            })

            if( Number(hour) < Number(time.split(':')[0]) ){
                setError({
                    ...error,
                    hour: true
                })
            }
            else{
                delete error['hour']

                setError({
                    ...error,
                })
            }

        }

        else if(min){
            
            dispatch({
                type: SET_FIELDS_EVENTS,
                payload: {
                    ...fields,
                    clock: fields.clock.split(':')[0] + ':' + min
                }
            })

            if( Number(min) < Number(time.split(':')[1]) && Number(fields.clock.split(':')[0]) <= Number(time.split(':')[0])){
                setError({
                    ...error,
                    min: true
                })
            }
            else{
                delete error['min']

                setError({
                    ...error,
                })
            }

        }

    }

    // alaram_at
    const updateAlarmClock = (time, label) => {
  
        dispatch({
            type: SET_FIELDS_EVENTS,
            payload: {
                ...fields,
                alaram_at: {
                    clock: time,
                    date: fields.alaram_at.date,
                    label: label ? label : '',
                    englishDate: fields.alaram_at.englishDate
                }
            }
        })

    }

    const updateAlaramDate = (date, time, label, englishDate) => {
        
        dispatch({
            type: SET_FIELDS_EVENTS,
            payload: {
                ...fields,
                alaram_at: {
                    clock: time ? time : fields.alaram_at.clock,
                    date,
                    label: label ? label : '',
                    englishDate: englishDate ? englishDate : ''
                }
            }
        })

    }

    const resetAlarmData = () => {

        dispatch({
            type: SET_FIELDS_EVENTS,
            payload: {
                ...fields,
                alaram_at: {
                    clock: '',
                    date : '',
                    label: '',
                    englishDate: ''
                }
            }
        })
        
    }

    const checkExistError = () => {

        if(!enteredData || Object.keys(error).length >= 1 || fields.title.value === ''){
            dispatch({
                type: SET_CAN_I_SEND_DATA_EVENTS,
                payload: false
            })
        }
        else {
            dispatch({
                type: SET_CAN_I_SEND_DATA_EVENTS,
                payload: true
            })
        }

    }

    return (
        <form className="flex flex-col gap-8">
            
            <div className="flex flex-col gap-8 rounded-2xl shadow-custom-lg border-[3px] border-white p-4 relative mt-6">

                <div className="rounded-2xl bg-custom-gray w-fit mx-auto left-0 right-0 top-0 absolute -translate-y-1/2 shadow-custom-lg border-[3px] font-medium border-white px-4 p-3 text-gray-700">
                    { date ? 
                        `${Number(date?.split('/')[0]).toLocaleString('fa').split('٬').join('')}/${Number(date?.split('/')[1]) < 10 ? '۰' + Number(date?.split('/')[1]).toLocaleString('fa') : Number(date?.split('/')[1]).toLocaleString('fa') }/${Number(date?.split('/')[2]) < 10 ? '۰' + Number(date?.split('/')[2]).toLocaleString('fa') : Number(date?.split('/')[2]).toLocaleString('fa')}`
                        :
                        `۱۱۱۱/۱۱/۱۱`
                    }
                </div>

                <div className="md:w-fit md:ml-auto flex flex-col gap-2.5">

                    <span className="text-sm text-gray-600"> زمان : </span>

                    {fields.clock && reciveTimeFromServer?.year && loaded && info?.starts_at ?
                    
                        <AddEventFormCustomTimeListColck 
                            time={fields.clock}
                            onSetHourOfTime={updateClockField}
                            onSetMinOfTime={updateClockField}
                        />
                        :
                        <div className="h-48 w-64 relative bg-white overflow-hidden mx-auto flex items-center justify-center border border-gray-200 rounded-2xl">

                            { !eventDataError &&

                                <ReactSVG 
                                    className="w-10 text-gray-600"
                                    src={`${process.env.PUBLIC_URL}/assets/images/icons/loading-dot.svg`}
                                />
                                
                            }

                            { eventDataError && 
                                
                                <ReactSVG
                                    className="text-rose-500/90 w-10"
                                    src={`${process.env.PUBLIC_URL}/assets/images/icons/info-box.svg`}
                                />

                            }

                        </div>
                    }

                </div>

                <div className="grid xm:grid-cols-2 grid-cols-1 gap-8">

                    <Input
                        id={'event-title'}
                        label={'عنوان'}
                        max={fields.title.max}
                        icon={'title'}
                        value={ fields.title.prefix + fields.title.value}
                        isValid={fields.title.isValid}
                        onChange={ updateTitleFields }
                        loading={eventDataLoading}
                    />

                    <hr className="xm:hidden" />

                    <AddEventFormCustomTimeField
                        persianDate={date}
                        clock={fields.clock}
                        alaram_at={fields.alaram_at}

                        OnUpdateAlaramClock={updateAlarmClock}
                        onUpdateAlaramDate={updateAlaramDate}
                        onResetAlarmData={resetAlarmData} 
                        loading={eventDataLoading} 
                        mainAlarm={alarm}
                    />

                    <hr className="xm:hidden" />
                    
                    <AddEventFormCasesField 
                        caseInfo={fields.caseInfo}
                        onUpdateCaseInfoField={updateCaseInfoField}
                        loading={false}
                        mainCase={ info && info?.law_suit?.title ? {text: info.law_suit.title, id: info.law_suit.id} : null }
                    />

                </div>

                <hr />

                <Textarea 
                    rows={4}
                    icon={'description'}
                    label={'توضیحات'}
                    max={300}
                    value={fields.description.value}
                    isValid={fields.description.isValid}
                    onChange={(event) => updateDescriptionFields(event)}
                    loading={false}
                /> 

                <hr />

                <div className="grid md:grid-cols-2 grid-cols-1 items-center 3xm:gap-4 gap-5">
                                
                    <Switch 
                        name={'sendSms'}
 
                        title={ (baseDataError || baseDataLoading || !baseDataEventSwitchs?.sms_term) ? 'ارسال پیامک یادآوری در زمان هشدار' : baseDataEventSwitchs?.sms_term }
                        description={ (baseDataError || baseDataLoading || !baseDataEventSwitchs?.sms_term) ? 'با کسر هزینه 200 تومان از کیف پول ' : baseDataEventSwitchs?.sms_desc }
                        
                        value={fields.sendSms}
                        onUpdateSwitchValue={updateSendSmsField}
                    />

                </div>

            </div>

        </form>
    )

}

export default EditEventForm