import { useEffect, useState } from "react"

import ModalBottom from "../../../../UI/ModalBottom"
import WalletActionButtonAddBalanceModalTitle from "./Title"
import WalletActionButtonAddBalanceModalBalance from "./Balance"
import WalletActionButtonAddBalanceModalSelectPrice from "./SelectPrice"
import WalletActionButtonAddBalanceModalPriceField from "./PriceField"
import { useDispatch, useSelector } from "react-redux"
import { useCookies } from "react-cookie"
import { paymentToWallet } from "../../../../../redux/action/wallet/action"
import { SET_ERROR_WALLET } from "../../../../../redux/action/wallet/type"
import { ReactSVG } from "react-svg"
import { useLocation, useNavigate } from "react-router-dom"

const WalletActionButtonAddBalanceModal = (props) => {

    const dispatch = useDispatch()
    const walletError = useSelector(state => state.wallet.error)
    const paymentLoading = useSelector(state => state.wallet.paymentLoading)

    const [price, setPrice] = useState('')

    const navigate = useNavigate()

    const [cookies] = useCookies(['authToken'])

    useEffect(() => {

        return () => {
            if(document.body.classList.contains('modal-opened'))
                document.body.classList.remove('modal-opened')

            if(props.shouldIncreasePrice)
                props.OnSetShouldIncreasePrice(null)
        }

    }, [])

    const pay = async () => {
        
        let amount = price.split('٬').join('').split('')
        
        amount = amount.map(item => {
            item = item.replace('۰', '0')
            item = item.replace('۱', '1')
            item = item.replace('۲', '2')
            item = item.replace('۳', '3')
            item = item.replace('۴', '4')
            item = item.replace('۵', '5')
            item = item.replace('۶', '6')
            item = item.replace('۷', '7')
            item = item.replace('۸', '8')
            item = item.replace('۹', '9')
            return item
        }).join('')

        amount = Number(amount)

        if( (amount <= 9999 || price === '')){
         
            if(!walletError){

                // show error
                dispatch({
                    type: SET_ERROR_WALLET,
                    payload: 'حداقل مبلغ ۱۰,۰۰۰ تومان است'
                })
    
                setTimeout(() => {
    
                    // destroy error
                    dispatch({
                        type: SET_ERROR_WALLET,
                        payload: null
                    })
                    
                }, 1000);

            }

            return
        }

        const data = await dispatch( await paymentToWallet(amount, cookies.authToken) )

        if(data){

            // send user to that page.
            navigate('/payment-geteway', {
                state: {
                    urls: data?.urls,
                    wallet: {
                        price: amount
                    }
                }
            })
            // window.open(data.urls[0].url, '_blank')

            setPrice('')

            props.onSetShowPayModal(false)
            props.onSetShowingPayModal(false)
        }

    }

    return (
        <ModalBottom
            show={props.showPayModal}
            onSetShow={props.onSetShowPayModal}
            className="md:!max-h-[430px] overflow-y-hidden no-scrollbar h-full !max-h-[410px]"
            showing={props.showingPayModal}
            onSetShowing={props.onSetShowingPayModal}
        >
            
            <WalletActionButtonAddBalanceModalTitle props={props} />
            
            <div className="mt-4 flex flex-col w-full h-full">

                <WalletActionButtonAddBalanceModalBalance />

                <WalletActionButtonAddBalanceModalSelectPrice 
                    price={price}
                    onSetPrice={setPrice}
                />

                <WalletActionButtonAddBalanceModalPriceField 
                    price={price}
                    onSetPrice={setPrice}
                    shouldIncreasePrice={props.shouldIncreasePrice}
                />

                <button onClick={pay} disabled={paymentLoading} className="btn disabled:opacity-90 w-full md:mt-14 mt-12 h-12 mb-1 outline font-medium rounded-xl outline-[3px] outline-primary/20"> 
                    { !paymentLoading ? 
                        'پرداخت' 
                        :
                        <ReactSVG 
                            className="w-12"
                            src={`${process.env.PUBLIC_URL}/assets/images/icons/loading-dot.svg`}
                        />
                    }

                </button>

            </div>

        </ModalBottom>

    )

}

export default WalletActionButtonAddBalanceModal