export const checkIsNewUser = () => {
    const isNew = JSON.parse( localStorage.getItem('isNewUser') )

    return isNew
}

export const removeIsNewUser = () => {
    localStorage.removeItem('isNewUser')
}

export const setIsNewUser = (data) => {
    localStorage.setItem('isNewUser', JSON.stringify(data))
}