const ToastCustom = (props) => {

    return (
        <div className={`fixed bottom-0 top-0 right-0 left-0 m-auto w-fit h-fit md:max-w-[500px] xm:max-w-[400px] max-w-[300px] p-4 bg-white z-[1] rounded-xl shadow-custom-lg border border-gray-100 ${props.className && props.className}`}>

            {props.children}

        </div>
    )

}

export default ToastCustom