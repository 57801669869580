import { ReactSVG } from "react-svg"
import CaseContentFilesItem from "./Item";

const CaseContentFiles = ({files = [], ...props}) => {

    return (
        <section>

            {files && files.length >= 1 ?

                <ul className="grid sm:grid-cols-2 grid-cols-1 lg:gap-6 md:gap-4 gap-5">

                    { files.length >= 1 && files.map(item => 
                        
                        <CaseContentFilesItem 
                            key={item.id+"-file"}
                            info={item}
                        />
                    
                    )}

                </ul>

                :
                <div className="text-gray-600 flex items-center justify-center h-36 gap-4 flex-col">
                    
                    <ReactSVG 
                        src={`${process.env.PUBLIC_URL}/assets/images/icons/tag.svg`}
                        className="w-10 text-gray-500 text-opacity-80 "
                    />

                    <div>
                        هیچ <span className="font-medium">فایلی</span> برای پرونده ثبت نشده است 
                    </div>

                </div>

            }

        </section>
    )

}

export default CaseContentFiles