import { Link } from "react-router-dom"
import { ReactSVG } from "react-svg"

const HeaderProfileBtn = () => {

    return (
        <Link to={'/contacts'} className="header-btn">

            <ReactSVG src={`${process.env.PUBLIC_URL}/assets/images/icons/profile.svg`} className="w-[70%] h-[70%]" />

        </Link>
    )

}

export default HeaderProfileBtn