import { SHOW_SIDE_MENU, HIDE_SIDE_MENU, SET_SIDE_MENU_LINKS, SET_SIDE_MENU_ITEMS } from '../../../action/menu/side/sideType'

const initialState = {
    showMenu: false,

    menuItems: [],

    menuLinks: []
}

const sideMenuReducer = (state = initialState, action) => {

    switch(action.type){

        case SHOW_SIDE_MENU :
            return {
                ...state,
                showMenu: true
            }
            

        case HIDE_SIDE_MENU : {
            return {
                ...state,
                showMenu: false
            }
        }

        case SET_SIDE_MENU_LINKS :
            return {
                ...state,
                menuLinks: action.payload
            }

        case SET_SIDE_MENU_ITEMS :
            return {
                ...state,
                menuItems: action.payload
            }

        default :
            return state

    }

}

export default sideMenuReducer