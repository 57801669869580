import { ReactSVG } from "react-svg"

const CasesFilterSectionCategory = (props) => {

    const showCategorySection = () => {
       
        props.onSetActiveItemState('categories')

    }

    return (
        <>
                                    
            <div className="flex flex-col gap-2.5">

                <div className="flex items-center justify-between gap-3">
                
                    <span className="text-sm text-gray-500 group-focus-within:text-primary duration-300 cursor-pointer">نوع دعوی :</span>
                
                    { props.value && 
                        <button onClick={props.onResetCategoryField} className="w-12 flex items-center justify-end outline-none">
                            <ReactSVG 
                                className="w-5 text-rose-600"
                                src={`${process.env.PUBLIC_URL}/assets/images/icons/times.svg`}
                            />
                        </button>
                    }

                </div>

                <button onClick={ showCategorySection} type="button" className="flex items-center justify-between gap-4 flex-wrap bg-white p-3 text-right text-gray-800 rounded-xl shadow-custom-md border border-gray-300/70">

                    <div className="flex items-center gap-3">
                        
                        <ReactSVG src={`${process.env.PUBLIC_URL}/assets/images/icons/folder.svg`} className={`w-5 text-primary ${(!props.loading) && '!text-gray-400' }`} />

                        { props.loading ? props.value ? props.value : <span className="text-gray-500">انتخاب نشده</span>
                            :
                            <ReactSVG src={`${process.env.PUBLIC_URL}/assets/images/icons/loading-dot.svg`} className="w-10 py-[7px] text-gray-400" /> 
                        }
                    
                    </div>

                    <div>
                        <ReactSVG src={`${process.env.PUBLIC_URL}/assets/images/icons/down.svg`} className="w-5 rotate-90" />
                    </div>

                </button>

            </div>            

        </>
    )

}

export default CasesFilterSectionCategory