import { ReactSVG } from "react-svg"
import { add, sub } from "date-fns-jalali"
import { useDispatch, useSelector } from "react-redux"

import { SET_DATE_EVENTS } from "../../../../redux/action/events/eventsType"

const CalendarFooterBtns = (props) => {

    const dispatch = useDispatch()
    const initialDate = useSelector(state => state.events.initialDate)

    const prevMonth = () => {

        const newDate = sub(props.now, {months: 1})
        
        dispatch({
            type: SET_DATE_EVENTS,
            payload: newDate
        })

    }

    const nextMonth = () => {

        const newDate = add(props.now, {months: 1})

        dispatch({
            type: SET_DATE_EVENTS,
            payload: newDate
        })
    }

    const goToToday = () => {
        
        dispatch({
            type: SET_DATE_EVENTS,
            payload: initialDate
        })

    }

    return (
        <div className="border-t border-t-gray-200 mt-8 flex items-center justify-center md:gap-20 5xm:gap-16 gap-10 relative">
            
            {/* 
            <button className="w-14 pt-4 pb-4 flex items-center justify-center text-primary !border-0 !outline-none">

                <ReactSVG 
                    className={'sm:w-[18px] 2xm:w-4 w-3'}
                    src={'/assets/images/icons/right.svg'}
                />

                <ReactSVG 
                    className={'sm:w-[18px] 2xm:w-4 w-3'}
                    src={'/assets/images/icons/right.svg'}
                />

            </button> */}

            <button onClick={nextMonth} className="w-14 pt-4 pb-4 flex items-center justify-center text-primary !border-0 !outline-none">

                <ReactSVG 
                    className={'sm:w-5 2xm:w-[18px] w-4'}
                    src={'/assets/images/icons/right.svg'}
                />

            </button>

            <button onClick={prevMonth} className="w-14 pt-4 pb-4 flex items-center justify-center text-primary !border-0 !outline-none">

                <ReactSVG 
                    className={'sm:w-5 2xm:w-[18px] w-4 rotate-180'}
                    src={'/assets/images/icons/right.svg'}
                />

            </button>

            {/* <button className="w-14 pt-4 pb-4 flex items-center justify-center text-primary !border-0 !outline-none">

                <ReactSVG 
                    className={'sm:w-[18px] 2xm:w-4 w-3 rotate-180'}
                    src={'/assets/images/icons/right.svg'}
                />
                
                <ReactSVG 
                    className={'sm:w-[18px] 2xm:w-4 w-3 rotate-180'}
                    src={'/assets/images/icons/right.svg'}
                />

            </button> */}
    
            <button onClick={goToToday} className={`absolute left-0 bottom-0 top-0 my-auto 5xm:w-10 5xm:h-10 w-9 h-9 pt-4 pb-4 flex items-center justify-center text-primary !outline-none bg-white rounded-full shadow-custom-lg border border-primary/50 opacity-0 ${(props.now && initialDate) && ( (props.now.getMonth() === initialDate.getMonth()) && (props.now.getFullYear() === initialDate.getFullYear()) ) ? 'opacity-0' : 'opacity-100'} duration-300`}>

                <ReactSVG 
                    className={'5xm:w-7 w-6 -rotate-90'}
                    src={'/assets/images/icons/back-now.svg'}
                />

            </button>

        </div>
    )

}

export default CalendarFooterBtns