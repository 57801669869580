import { ADD_ITEMS_TO_FILTER_NOTES, ADD_ITEM_TO_NOTES, EDIT_ITEM_FROM_NOTES, INCRAESE_NOTES, REMOVE_LIST_OF_NOTES_FROM_NOTES, REMOVE_NOTE_FROM_NOTES, RESET_NOTE_INFO, RESTE_ALL_NOTES_DATA_APP, SET_DELETE_ERROR_NOTES, SET_FAILURE_NOTES, SET_FILTER_ERROR_NOTES, SET_FILTER_NOTES, SET_LOADING_ERROR_NOTES, SET_LOADING_NOTES, SET_LOAD_MORE_LOADING_NOTES, SET_NOTES, SET_NOTE_ERROR, SET_NOTE_INFO, SET_NOTE_INFO_BODY, SET_NOTE_INFO_COLOR, SET_NOTE_INFO_LAW_SUIT, SET_NOTE_INFO_SUBJECT, SET_NOTE_LOADING, SET_PAGINATION_NOTES } from "../../action/notes/notesType"

const initalState = {
    notes: [],
    
    filtered: {
        searchedValue: '',
        list: [],
        pagination: {}
    },
    filterError: null,

    loading: false,
    error: null,
    loaded: false,

    deleteLoading: false,
    deleteError: null,
    loadMoreLoading: false,

    pagination: {},

    noteInfo: {
        id: null,
        body: '',
        color: 1,
        law_suit: null,
        subject: null
    },
    noteLoading: false,
    noteError: null
}

const notesReducer = (state = initalState, action) => {

    switch(action.type){

        case SET_LOADING_NOTES :
            return {
                ...state,
                loading: action.payload,
            }

        case SET_FAILURE_NOTES :
            return {
                ...state,
                loading: false,
                error: action.payload
            }

        case SET_NOTES :
            return {
                ...state,
                loading: false,
                error: null,
                notes: action.payload,
                loaded: true
            }

        case SET_FILTER_NOTES :
            return {
                ...state,
                loading: false,
                filtered: {
                    searchedValue: action.payload.value,
                    list: action.payload.list,
                    pagination: action.payload.pagination
                },
                filterError: null,
            }

        case SET_FILTER_ERROR_NOTES :
            return {
                ...state,
                filterError: action.payload,
                loading: false
            }

        case ADD_ITEMS_TO_FILTER_NOTES : 
            return {
                ...state,
                loading: false,
                filterError: null,
                filtered: {
                    searchedValue: state.filtered.searchedValue,
                    list: [
                        ...state.filtered.list,
                        ...action.payload.list,
                    ],
                    pagination: action.payload.pagination
                }
            }

        case SET_PAGINATION_NOTES :
            return {
                ...state,
                pagination: action.payload
            }

        case SET_LOAD_MORE_LOADING_NOTES :
            return {
                ...state,
                loadMoreLoading: action.payload
            }

        case INCRAESE_NOTES :
            return {
                ...state,
                notes: [
                    ...state.notes,
                    ...action.payload
                ],
                loadMoreLoading: false
            }

        case SET_DELETE_ERROR_NOTES :
            return {
                ...state,
                deleteError: action.payload,
                deleteLoading: false
            }

        case SET_LOADING_ERROR_NOTES :
            return {
                ...state,
                deleteLoading: action.payload
            }

        case REMOVE_NOTE_FROM_NOTES :
            return {
                ...state,
                deleteLoading: false,
                deleteError: null,
                notes: state.notes.filter(item => item.id != action.payload)
            }

        case REMOVE_LIST_OF_NOTES_FROM_NOTES :
            return {
                ...state,
                deleteLoading: false,
                deleteError: null,
                notes: state.notes.filter(item => !action.payload.includes(item.id)),
                filtered: {
                    ...state.filtered,
                    list: state.filtered.list.filter(item => !action.payload.includes(item.id))
                }
            }

        case SET_NOTE_INFO :
            return {
                ...state,
                noteInfo: action.payload
            }

        case RESET_NOTE_INFO :
            return {
                ...state,
                noteInfo: {
                    id: null,
                    body: '',
                    color: 1,
                    law_suit: null,
                    subject: null
                }
            }

        case SET_NOTE_INFO_COLOR :
            return {
                ...state,
                noteInfo: {
                    ...state.noteInfo,
                    color: action.payload
                }
            }

        case SET_NOTE_INFO_BODY :
            return {
                ...state, 
                noteInfo: {
                    ...state.noteInfo,
                    body: action.payload
                }
            }

        case SET_NOTE_INFO_LAW_SUIT :
            return {
                ...state,
                noteInfo: {
                    ...state.noteInfo,
                    law_suit: action.payload
                }
            }

        case SET_NOTE_INFO_SUBJECT :
            return {
                ...state,
                noteInfo: {
                    ...state.noteInfo,
                    subject: action.payload
                }
            }

        case ADD_ITEM_TO_NOTES :
            return {
                ...state,
                notes: [
                    action.payload,
                    ...state.notes
                ]
            }

        case SET_NOTE_LOADING :
            return {
                ...state,
                noteLoading: action.payload
            }

        case SET_NOTE_ERROR :
            return {
                ...state,
                noteError: action.payload,
                noteLoading: false
            }

        case EDIT_ITEM_FROM_NOTES : 
            return {
                ...state,
                notes: [
                    action.payload,
                    ...state.notes.filter(item => item.id != action.payload.id)
                ]
            }

        case RESTE_ALL_NOTES_DATA_APP :
            return {
                ...state,
               ...initalState 
            }

        default : 
            return state

    }

}

export default notesReducer