import { ReactSVG } from 'react-svg'
import Button from '../../Button'

const ConfirmModalDelete = (props) => {

    const hide = (event) => {
        event.stopPropagation()

        props.onSetShow(false)

        document.body.classList.remove('modal-opened')
    }

    const dontDoAnyWork = (event) => {
        event.stopPropagation()
    }

    return (
        <>
            <div onClick={dontDoAnyWork} className={`modal px-3 max-w-md fixed top-0 left-0 bottom-0 right-0 m-auto h-fit z-50 ${props.className && props.className}`}>

                <div className="w-full rounded-2xl bg-white p-5 flex flex-col gap-6">
                
                    <div className="flex flex-col items-center text-gray-700">

                        <div className="w-14 h-14 mb-4 bg-white shadow-custom-lg flex items-center justify-center rounded-2xl text-rose-500">
                            <ReactSVG src={`${process.env.PUBLIC_URL}/assets/images/icons/delete.svg`} className="w-10 h-10" />
                        </div>

                        <span className="text-lg mb-2 font-medium"> {props.selectCount <= 1 ? props.title : props.title + ' (' + props.selectCount + ')'} </span>

                        <p className="text-center leading-7 text-sm"> {props.description} </p>

                    </div>

                    {props.children}

                    <div className="flex items-center justify-between gap-4">

                        <Button onClick={props.onConfirm} className={'w-full disabled:opacity-80'} disabled={props.disabled}> تایید </Button>
                        <Button onClick={hide} className="w-full bg-gray-100 text-black"> لغو </Button>

                    </div>

                </div>

            </div>

            <div onClick={hide} className={`backdrop ${props.backdropClass && props.backdropClass}`}></div>
        </>
    )

}

export default ConfirmModalDelete