import { useDispatch, useSelector } from 'react-redux'
import Input from '../../../UI/Input'
import InputGroup from '../../../UI/InputGroup'
import Textarea from '../../../UI/Textarea'
import { SET_CONTACT_INFO, SET_ENABLE_SUBMIT_BUTTON, SET_MAIN_FIELDS_CONTACT } from '../../../../redux/action/contacts/typeContacts'
import { validateField } from '../../../../utils/form'
import { useEffect } from 'react'

const ContactMainForm = (props) => {

    const dispatch = useDispatch()
    const mainFields = useSelector(state => state.contacts.mainFields)
    const enableSubmitButton = useSelector(state => state.contacts.enableSubmitButton)

    useEffect(() => {

        return () => {
            clearDataFromMainField()
        }
        
    }, [])

    useEffect(() => {
        
        if(!mainFields.name.value && !mainFields.mobile.value) return 
        
        const timeout = setTimeout(() => {

            const status = canAddUserOrNo()
            
            if(enableSubmitButton || status)
                comeSaveNewDataToContactInfo()

            dispatch({
                type: SET_ENABLE_SUBMIT_BUTTON,
                payload: status
            })

        }, 400)

        return () => {
            if(timeout)
                clearTimeout(timeout)
        }

    }, [mainFields])

    useEffect(() => {

        if(enableSubmitButton)
            comeSaveNewDataToContactInfo()

    }, [enableSubmitButton])

    const updateField = (event) => {

        const value = event.target.value

        // if be bigger than max do not any work 
        if(mainFields[event.target.id].max && value.length > mainFields[event.target.id].max) return

        if(isNaN(event.target.value) && mainFields[event.target.id].type === 'tel') return

        // update field value
        mainFields[event.target.id].value = value

        // check exist invalid value or no
        const valid = validateField(mainFields[event.target.id])
        mainFields[event.target.id].isValid = valid

        // check global validate :)
        checkAllInfoIsValid(event.target.id, valid)

        // save value 
        dispatch({
            type: SET_MAIN_FIELDS_CONTACT,
            payload: mainFields
        })
    }

    const checkAllInfoIsValid = (id, valid) => {

        // if be valid and not exist invalid
        if(valid && !props.invalid[id])
            return true

        // if field is invalid come add to invalids data 
        if(!valid && !props.invalid[id]){
            props.setInvalid({
                ...props.invalid,
                [id]: true
            })

            return false
        }

        // come remove of invalid data when field is valid
        if(valid && props.invalid[id]){
            const newInvalids = { ...props.invalid }
            delete newInvalids[id]

            props.setInvalid(newInvalids)

            return true
        }
        
    }

    const canAddUserOrNo = () => {
        const invalids = Object.keys(props.invalid).length

        if(mainFields.name.isValid && mainFields.mobile.isValid && mainFields.name.value && mainFields.mobile.value){
            props.setMainComplete(true)
        }

        if(invalids !== 0) return false

        // when is updated dont come to check
        if(invalids === 0 && enableSubmitButton) return true
        
        // check can add or no
        for(const item in mainFields){
            
            if(mainFields[item].validate.required && !mainFields[item].value)
                return false
            

            if(mainFields[item].value){
               const valid = validateField(mainFields[item])
                
               if(!valid)
                    return false

            }

        }

        return true
    }

    const clearDataFromMainField = () => {
        // come empty the fields
        Object.keys(mainFields).map(item => {
            mainFields[item].value = ''
            mainFields[item].isValid = true
        })

        // save
        dispatch({
            type: SET_MAIN_FIELDS_CONTACT,
            payload: mainFields
        })

        dispatch({
            type: SET_ENABLE_SUBMIT_BUTTON,
            payload: false
        })
    }

    const comeSaveNewDataToContactInfo = () => {
        const fields = {}

        // check can add or no
        Object.keys(mainFields).map(item => {
            if(mainFields[item].value){
                fields[item] = mainFields[item].value
            }
        })
                
        dispatch({
            type: SET_CONTACT_INFO,
            payload: fields
        })
    }

    return (
        <form className="rounded-2xl shadow-custom-lg border-[3px] border-white p-4 flex flex-col gap-5 relative">
            
            <InputGroup className="xm:!grid-cols-2">

                <Input
                    id={'name'}
                    label={mainFields.name.label}
                    max={mainFields.name.max}
                    icon={mainFields.name.icon}
                    value={mainFields.name.value}
                    isValid={mainFields.name.isValid}
                    onChange={ updateField }
                />

                <Input
                    id={'mobile'}
                    label={mainFields.mobile.label}
                    max={mainFields.mobile.max}
                    icon={mainFields.mobile.icon}
                    value={mainFields.mobile.value}
                    type={mainFields.mobile.type}
                    isValid={mainFields.mobile.isValid}
                    onChange={ updateField }
                    className={'text-right'}
                />

            </InputGroup>

            <hr className='my-3.5' />

            <Textarea 
                id={'description'}
                label={mainFields.description.label}
                max={mainFields.description.max}
                icon={mainFields.description.icon}
                value={mainFields.description.value}
                isValid={mainFields.description.isValid}
                rows="3"
                onChange={ updateField }
            />

        </form>
    )

}

export default ContactMainForm