import { useDispatch, useSelector } from "react-redux"
import { ReactSVG } from "react-svg"
import { useRef } from "react"

import { HIDE_SEARCH } from "../../../redux/action/search/searchType"

const SearchBox = (props) => {

    const dispatch = useDispatch()
    const showSearch = useSelector(state => state.search.showSearch)

    const searchRef = useRef()

    const clearSearchValue = () => {
        
        props.onClear()

        searchRef.current.focus()

    }

    const hideSearch = () => {

        // hide search
        dispatch({
            type: HIDE_SEARCH
        })

        // clear the search-value
        props.onClear()     

    }

    if(showSearch){

        return (
            <div className="fixed lg:pt-4 bg-transparent py-3 top-0 left-0 lg:w-3/4 lg:px-4 px-3 lg:h-[94px] h-[90px] w-full z-[2]">

                <div className="absolute lg:px-4 px-3 bg-transparent m-auto top-0 right-0 left-0 bottom-0 w-full h-full lg:pt-4 py-3 rounded-2xl">
        
                    <form onSubmit={(event) => event.preventDefault()} className="bg-white border border-gray-200 w-full h-full rounded-2xl p-2"> 
        
                        <div className="w-full h-full bg-slate-200 rounded-lg flex items-center justify-between gap-2">
        
                            <button onClick={hideSearch} type="button" className="md:w-10 w-8 pr-2 h-full rounded-lg flex focus:outline-none items-center justify-center flex-shrink-0">
                                <ReactSVG src={`${process.env.PUBLIC_URL}/assets/images/icons/back.svg`} className="rotate-180 w-5 text-gray-500" />
                            </button>
        
                            <input 
                                type="text" 
                                className="w-full h-full bg-transparent focus:outline-none" 
                                placeholder="جستجو کنید..."
                                value={props.value}
                                onChange={props.onUpdate}
                                autoFocus={true}
                                ref={searchRef}
                                id="search-field"
                                autoComplete={'off'}
                            />
        
                            <button onClick={clearSearchValue} type="button" className={`md:w-10 w-8 pl-1.5 h-full rounded-lg flex focus:outline-none items-center justify-center outline-none flex-shrink-0 duration-300 ${props.value.length >= 1 ? 'pointer-events-auto opacity-100' : 'opacity-0 pointer-events-none'} `} >
                                <ReactSVG src={`${process.env.PUBLIC_URL}/assets/images/icons/times.svg`} className="md:w-6 w-[22px] text-gray-500" />
                            </button>
        
                        </div>
        
                    </form>
        
                </div>

            </div>
        )
    }

}

export default SearchBox