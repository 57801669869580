import { useEffect, useState } from "react"
import Input from "../../../../../UI/Input"
import { useDispatch, useSelector } from "react-redux"
import { SET_LOADING_LIST_CASES } from "../../../../../../redux/action/cases/casesType"

const AddEventFormCasesSearch = (props) => {

    const dispatch = useDispatch()
    const cases = useSelector(state => state.cases.cases)

    const [search, setSearch] = useState('')

    useEffect(() => {

        const setValue = setTimeout(() => {
            // come update the redux --> for access into all of the app
            findItemsFromSearch()

        }, 500)

        return () => {
            if(setValue)
                clearTimeout(setValue)
        }

    }, [search])

    const updateSearch = (event) => {
        setSearch(event.target.value)

        dispatch({
            type: SET_LOADING_LIST_CASES,
            payload: true
        })
    }

    const findItemsFromSearch = () => {
        const list = cases.filter(item => item.title.includes(search))
        
        props.onSetFilteredList({
            searched: search.length >= 1 ? true : false,
            list
        })

        dispatch({
            type: SET_LOADING_LIST_CASES,
            payload: false
        })
    }

    return (
        <div className="mb-4">
            
            <Input 
                id='search-cases-field'
                placeholder={'جستجو پرونده...'}
                className={'shadow-custom-lg border-gray-300/60 focus:shadow-primary/5'}
                value={search}
                onChange={updateSearch}
            />

        </div>
    )

}

export default AddEventFormCasesSearch