import { useEffect } from "react"
import { useCookies } from "react-cookie"
import { useDispatch, useSelector } from "react-redux"
import { Link } from "react-router-dom"
import { ReactSVG } from "react-svg"

import { getProfileInfo } from "../../../../redux/action/profile/profileAction"
import SideMenuUserInfoPlanStatus from "./PlanStatus"

const SideMenuUserInfo = (props) => {

    const dispatch = useDispatch()
    const profile = useSelector(state => state.profile.profile)
    const loading = useSelector(state => state.profile.loading)

    const [cookie] = useCookies(['authToken'])

    useEffect(() => {

        // if not exist profile come get profile
        if(!profile.first_name && !loading){
            getProfile()
        }

    }, [])

    const getProfile = async () => {
        // when this method work that be a problem in the app. and we retry to get profile info
        await dispatch( await getProfileInfo(cookie.authToken) )
    }

    return  (
        <div className="navbar-top-box flex items-center gap-x-3 bg-primary lg:py-3 py-6 lg:rounded-xl flex-shrink-0">
                    
            <div className="flex-shrink-0">

                <Link to={'/profile'} onClick={props.onHideMenu} className="cursor-pointer select-none w-11 h-11 text-xl flex items-center justify-center overflow-hidden shadow-xl outline outline-[3px] outline-white/30 rounded-lg bg-white text-primary mr-4">
                    
                    { !loading ? profile.image ? 
                        <img className="w-full h-full object-cover" src={profile.image} alt="" /> 
                    :
                        <ReactSVG src={`${process.env.PUBLIC_URL}/assets/images/icons/add.svg`} className="w-5 h-5" />
                    :
                        <ReactSVG src={`${process.env.PUBLIC_URL}/assets/images/icons/loading-dot.svg`} className="w-5" />
                    }

                </Link>

            </div>

            <div className="flex flex-col lg:gap-0.5 gap-1">

                <Link to="/profile" onClick={props.onHideMenu} className="text-white lg:text-base font-medium">
                    <span className="h-6 flex items-center">
                    { !loading ? 
                        profile.first_name ? profile.first_name : 'نامی یافت نشد...'
                        :
                            <ReactSVG src={`${process.env.PUBLIC_URL}/assets/images/icons/loading-dot.svg`} className="w-10" />
                        }
                    </span>
                </Link>

                <SideMenuUserInfoPlanStatus 
                    onHideMenu={props.onHideMenu}
                    activePlan={profile?.active_user_plan}
                    now={profile?.time}
                    loading={loading}
                    loaded={!profile.first_name && !loading}
                    planLeftDays={profile?.planLeftDays}
                />

            </div>

        </div>
    )

}

export default SideMenuUserInfo