const WalletActionButtonAddBalanceModalSelectPrice = ({price, onSetPrice}) => {

    const updatePrice = (amount) => {
        onSetPrice(amount.toLocaleString('fa'))
    }

    return (
        <div className="flex items-center justify-around sm:gap-3 2xm:gap-0 gap-4 md:mb-8 mb-8">

            <button onClick={() => updatePrice(10000)} className="bg-white shadow-custom-lg border border-gray-300/60 text-primary rounded-lg px-3 py-1.5 3xm:text-base text-sm hover:border-primary duration-300 transition-colors"> ۱۰٬۰۰۰ تومان </button>
            
            <button onClick={() => updatePrice(20000)} className="bg-white shadow-custom-lg border border-gray-300/60 text-primary rounded-lg px-3 py-1.5 3xm:text-base text-sm hover:border-primary duration-300 transition-colors"> ۲۰٬۰۰۰ تومان </button>
            
            <button onClick={() => updatePrice(50000)} className="bg-white shadow-custom-lg border border-gray-300/60 text-primary rounded-lg px-3 py-1.5 3xm:text-base text-sm hover:border-primary duration-300 transition-colors"> ۵۰٬۰۰۰ تومان </button>
            
            <button onClick={() => updatePrice(100000)} className="bg-white shadow-custom-lg border border-gray-300/60 text-primary rounded-lg px-3 py-1.5 3xm:text-base text-sm hover:border-primary duration-300 transition-colors 2xm:block hidden"> ۱۰۰٬۰۰۰ تومان </button>

        </div>
    )

}

export default WalletActionButtonAddBalanceModalSelectPrice