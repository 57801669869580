import { combineReducers } from "redux"

import mainMenuReducer from "./main/mainMenuReducer"
import sideMenuReducer from "./side/sideMenuReducer"

const menuReducer = combineReducers({
    main: mainMenuReducer,
    side: sideMenuReducer
})

export default menuReducer