import { useEffect } from "react"
import { useSelector } from "react-redux"
import { Outlet, useLocation, useNavigate } from "react-router-dom"

const LayoutHavePlan = () => {

    const activePlan = useSelector(state => state.profile.profile.active_user_plan)
    const loading = useSelector(state => state.profile.loading)
    const error = useSelector(state => state.profile.error)

    const location = useLocation()
    const navigate = useNavigate()

    useEffect(() => {

        if(!navigator.onLine){
            navigate('/offline', {state: {url: location.pathname}})
        }

        let timeGoToBuy

        if((activePlan && activePlan[0] && activePlan[0]?.id) || loading || error) return
        
        else {
            
            timeGoToBuy = setTimeout(() => {

                localStorage.setItem('last-path-of-user', location.pathname)
                
                navigate('/buy-plans', {replace: true})

            }, 1100)

        }

        return () => {
            if(timeGoToBuy)
                clearTimeout(timeGoToBuy)
        }

    }, [activePlan, loading])

    return (
        <>
            <Outlet />
        </>
    )

}

export default LayoutHavePlan