import { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"

import { getMainMenuList } from "../../../../redux/action/menu/main/mainAction"

import MainMenuListItem from "./Item"

const MainMenuList = () => {

    const dispatch = useDispatch()
    const list = useSelector(state => state.menu.main.menuList)
    const homeInfoState = useSelector((state) => state.home.info?.stats)

    useEffect(() => {

        dispatch( getMainMenuList() )

    }, [])

    return (
        <ul className="flex lg:flex-col flex-row items-center lg:gap-5 5xm:gap-6 gap-1.5 lg:overflow-y-auto lg:mb-4">
            
            { list.map(item => 
                <MainMenuListItem 
                    key={item.id}
                    id={item.id}
                    text={item.text}
                    icon={item.icon}
                    path={item.path}

                    countMessage={ homeInfoState?.conversations }
                />
            )}

        </ul>
    )

}

export default MainMenuList