import { useDispatch, useSelector } from "react-redux"
import ContactAddSection from "../../../components/Contacts/Add"
import Header from "../../../components/Header"
import Button from "../../../components/UI/Button"
import { AddContact } from "../../../redux/action/contacts/contactsAction"
import { useEffect, useState } from "react"
import { RESET_CONTACT_DATA, RESET_CONTACT_INFO, RESET_FIELDS_DATA, SET_CONTACT_INFO, SET_UPDATE_FAILURE_CONTACTS } from "../../../redux/action/contacts/typeContacts"
import { useCookies } from "react-cookie"
import LoadingDot from "../../../components/UI/LoadingDot"
import { ToastContainer } from "react-toastify"
import "react-toastify/dist/ReactToastify.min.css";
import { showErrorMessage, showSuccessMessage, showWarningMessgae } from "../../../utils/toast"
import { useNavigate } from "react-router-dom"


const ContactAdd = () => {

    const dispatch = useDispatch()
    const contactInfo = useSelector(state => state.contacts.contactInfo)
    const enableSubmitButton = useSelector(state => state.contacts.enableSubmitButton)
    const error = useSelector(state => state.contacts.updateError)
    const loading = useSelector(state => state.contacts.updateLoading)

    const navigate = useNavigate()

    const [cookies] = useCookies(['authToken'])

    useEffect(() => {

        window.scrollTo({top: 0})

        return () => {
            if(error)
                dispatch({
                    type: SET_UPDATE_FAILURE_CONTACTS,
                    payload: null
                })
        }

    }, [])

    useEffect(() => {

        if(error){
            showErrorMessage(error)

            // destroy the error
            dispatch({
                type: SET_UPDATE_FAILURE_CONTACTS,
                payload: null
            })
        }

    }, [error])

    const addToContact = async () => {
        
        const data = Object.keys(contactInfo).reduce((result, item) => {
            if(contactInfo[item])
                result[item] = contactInfo[item]

            return result
        }, {})

        if(contactInfo?.location?.province){
            data.city_id = contactInfo.location.city.id
            data.province_id = contactInfo.location.province
        }

        // when is loading come not send request
        if(loading){
            showWarningMessgae('مخاطب هنوز در حال ذخیره سازی است')
            return
        }

        // send to api
        const success = await dispatch( await AddContact(data, cookies.authToken) )
        
        if(success){
            showSuccessMessage('مخاطب با موفقیت ایجاد شد')

            // reset data
            dispatch({
                type: RESET_CONTACT_INFO
            })

            dispatch({
                type: RESET_CONTACT_DATA
            })
            
            dispatch({
                type: RESET_FIELDS_DATA
            })

            navigate(-1, {replace: true})

        }

    }

    return (
        <>
            <Header 
                title={'ایجاد مخاطب'}
                backBtn={true}
            >

                <Button 
                    className={'profile-btn bg-primary py-1 px-4'}
                    disabled={!enableSubmitButton}
                    onClick={addToContact}
                >
                    ایجاد     
                </Button>

            </Header>

            <main className="main-content">

                <ContactAddSection />

                { loading && <LoadingDot /> }

                <ToastContainer className={'lg:!bottom-0 !bottom-[78px]'} />

            </main>

        </>
    ) 

}

export default ContactAdd