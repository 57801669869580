import { useDispatch, useSelector } from "react-redux"
import Header from "../../../components/Header"
import MessageLayout from "../../../components/Messages/Message/Layout"
import Button from "../../../components/UI/Button"
import NoteAddForm from "../../../components/Notes/Add"
import { addNoteInListNotes } from "../../../redux/action/notes/notesAction"
import { useCookies } from "react-cookie"
import { useNavigate, useParams } from "react-router-dom"
import ToastCustom from "../../../components/UI/ToastCustom"
import { ReactSVG } from "react-svg"
import LoadingDot from "../../../components/UI/LoadingDot"
import { ADD_ITEM_TO_HOME_INFO_NOTE } from "../../../redux/action/home/homeType"
import { ADD_ITEM_TO_NOTE_IN_SINGLE_CASE } from "../../../redux/action/cases/casesType"
import { useEffect } from "react"

const AddNote = () => {

    const dispatch = useDispatch()
    const noteInfo = useSelector(state => state.notes.noteInfo)
    const loading = useSelector(state => state.notes.noteLoading)
    const error = useSelector(state => state.notes.noteError)
    const homeInfoLoaded = useSelector(state => state.home.loaded)
    const singlesCase = useSelector(state => state.cases.singlesCase)

    const navigate = useNavigate()
    const params = useParams()

    const [cookies] = useCookies(['authToken'])

    useEffect(() => {

        window.scrollTo({top: 0})

    }, [])

    const addNoteToNotes = async () => {

        // check is fill noteInfo or no
        if(noteInfo.body.length === 0) return

        // make data
        const info = noteInfo
        if(params.id)
            info.law_suit_id = params.id
        else if(info.law_suit?.id)
            info.law_suit_id = info.law_suit?.id

        // send data 
        const data = await dispatch( await addNoteInListNotes(info, cookies.authToken) )
        
        if(data){

            // add to home page
            if(homeInfoLoaded && info?.law_suit?.id){

                let info = data

                if(info.updated_at){
                    info.updated_at = info.updated_at.split('-').join('').replace('T', '').split(':').join('')
                }

                dispatch({
                    type: ADD_ITEM_TO_HOME_INFO_NOTE,
                    payload: info
                })

            }

            // add to single-case
            if(singlesCase && (info?.law_suit?.id && singlesCase[info?.law_suit?.id]) || (params.id && singlesCase[params.id])){

                let info = data

                if(info.updated_at){
                    info.updated_at = info.updated_at.split('-').join('').replace('T', '').split(':').join('')
                }

                dispatch({
                    type: ADD_ITEM_TO_NOTE_IN_SINGLE_CASE,
                    payload: {
                        id: info?.law_suit?.id ? info?.law_suit?.id : params.id,
                        note: info
                    }
                })

            }

            // go to note page :))
            navigate(-1, {replace: true})
        }

    }

    return (
        <MessageLayout
            noneMarginBottom={true}
        >

            <Header 
                title={'افزودن یادداشت'}
                backBtn={true}
            >

                <Button 
                    className={'profile-btn bg-primary py-1 px-4'}
                    disabled={loading || (noteInfo.body.length === 0)}
                    onClick={addNoteToNotes}
                >
                    ایجاد     
                </Button>

            </Header>

            <main className="main-content">

                <NoteAddForm />

                { loading && 
                    
                    <LoadingDot />

                }

                { error && 

                    <ToastCustom>
    
                        <div className="flex items-center gap-2">
                            
                            <ReactSVG
                                className="text-rose-500/90 4xm:w-8 w-6"
                                src={`${process.env.PUBLIC_URL}/assets/images/icons/info-box.svg`}
                            />

                            { error }

                        </div>

                    </ToastCustom>
                
                }

            </main>

        </MessageLayout>
    )

}

export default AddNote