import { useSelector } from "react-redux"
import HomeNoteItem from "./Item"
import { ReactSVG } from "react-svg"
import { useEffect, useState } from "react"

const HomeNotes = ({items}) => {

    const [list, setList] = useState(items)
    const loading = useSelector(state => state.home.loading)

    useEffect(() => {

        if(items){

            items = items.map(item => {
                if(item.updated_at){
                    item.updated_at = item.updated_at.slice(0, 19)
                    item.updated_at = item.updated_at.split('-').join('').replace('T', '').split(':').join('')
                }
                return item
            })

            items = items.sort((a, b) => b.updated_at - a.updated_at)
            
            setList(items)
        }

    }, [items])

    return (
        <section className="mb-10">

            <h2 className="text-lg font-medium mb-6"> آخرین یادداشت‌ها </h2>

            { ( (!items || !list || list?.length <= 0)  && !loading )  &&
                <div className="flex items-center gap-2">

                    <ReactSVG 
                        src={`${process.env.PUBLIC_URL}/assets/images/icons/notes.svg`}
                        className="w-4 text-gray-700"
                    />

                    <span> هنوز یادداشتی به ثبت نرسیده </span>
                
                </div>
            }

            { items && list &&
                <ul className="grid xl:grid-cols-3 4xm:grid-cols-2 sm:gap-4 4xm:gap-3 gap-4">

                    {list.slice(0, 4).map(item => 
                        
                        <HomeNoteItem 
                            key={item.id + '-home-note'}
                            id={item.id}
                            title={item.title}
                            subject={item.subject}
                            body={item.body}
                            time={item.updated_at ? `${item.updated_at.slice(0, 4)}-${item.updated_at.slice(4, 6)}-${item.updated_at.slice(6, 8)}T${item.updated_at.slice(8, 10)}:${item.updated_at.slice(10, 12)}:${item.updated_at.slice(12, 14)}.000000Z` : item.created_at}
                            law={item.law_suit}
                            color={item.color}
                        />
                        
                    )}

                </ul>
            }

            { loading &&
                
                <div className="w-full h-20 flex items-center justify-center">

                    <ReactSVG 
                        src={`${process.env.PUBLIC_URL}/assets/images/icons/loading-dot.svg`}
                        className="text-gray-500 w-10"
                    />
                    
                </div>

            }

        </section>
    )

}

export default HomeNotes