import { ReactSVG } from "react-svg"
import { getProfileColor } from "../../../../../../../utils/messages"
import { Link } from "react-router-dom"
import { useState } from "react"

const CaseContentInfoContactsItem = (props) => {

    const getColor = () => {
        
        const color = getProfileColor(props.mobile.slice(10))
        
        return color
    }

    return (
        <div className="bg-white shadow-custom-md border border-primary/10 rounded-xl p-3 flex items-center justify-between flex-wrap relative before:absolute before:w-0.5 before:h-14 before:rounded-xl before:my-auto before:bottom-0 before:bg-primary before:top-0 before:right-0">

            <div className="flex-shrink-0 flex items-center gap-3">

                <div className={`flex-shrink-0 w-11 h-11 ${ props.mobile && !props.image ? `profile-${getColor()}` : 'bg-slate-200' }  border-2 border-slate-200 shadow-lg rounded-xl`}>
                    { props.image ?
                        <img className="rounded-xl w-full h-full" src={props.image} alt={props.name} />
                        :
                        props.name &&
                            <span className="font-medium text-center flex items-center justify-center h-full w-full">
                                {props.name[0]}
                            </span> 
                    }
                </div>

                <div className="flex flex-col gap-2">

                    <span className="font-medium"> {props.name} </span>

                    { props.position &&
                        <span className="text-gray-500 text-sm"> {props.position} </span>
                    }
 
                </div>

            </div>

            <div className="flex mr-auto items-center flex-wrap gap-4 flex-shrink-0">

                <div className="flex items-center gap-1">

                    { props.isUser &&
                        <Link to={`/messages/${props.userId}/${props.mobile}`} className="w-8 h-8 flex items-center justify-center text-gray-700 hover:text-primary duration-300">
                            
                            <ReactSVG 
                                src={`${process.env.PUBLIC_URL}/assets/images/icons/message-2.svg`}
                                className="w-6"
                            />

                        </Link> 
                    }

                    <a href={`tel:${props.mobile}`} className="w-8 h-8 flex items-center justify-center text-gray-700 hover:text-primary duration-300">
                        
                        <ReactSVG 
                            src={`${process.env.PUBLIC_URL}/assets/images/icons/mobile.svg`}
                            className="w-6"
                        />

                    </a>


                </div>

            </div>  

        </div>
    )

}

export default CaseContentInfoContactsItem