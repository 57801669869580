import axios from "axios"
import { useCookies } from "react-cookie"
import { useDispatch } from "react-redux"
import { useParams } from "react-router-dom"
import { ReactSVG } from "react-svg"
import { apiKey } from "../../../../../../redux/store"
import { SET_ERROR_FOR_FILE_IN_CHAT_USER, SET_LOADING_FILE_IN_CHAT_USER, SET_SUCCESS_FILE_IN_CHAT_USER } from "../../../../../../redux/action/messages/chat/chatType"

const ResendFileInChat = (props) => {

    const dispatch = useDispatch()

    const params = useParams()

    const [cookies] = useCookies(['authToken'])

    const resend = async (event) => {

        event.stopPropagation()

        props.onSetShowMoreOption(false)
        document.body.classList.remove('modal-opened')

        const config = {
            headers: {
                'Auth-Token': cookies.authToken,
                'X-Auth-Token': cookies.authToken
            },
            timeout: 15000
        }

        dispatch({
            type: SET_LOADING_FILE_IN_CHAT_USER,
            payload: {
                receiver_id: params.id,
                fileId: props.item?.file?.id
            }
        })

        setTimeout(() => {
            window.scrollTo({top: window.innerHeight + 50000, behavior: 'smooth'})
        }, 200);

        // add file to the server
        const response = await axios.post(`${apiKey}conversations/with/${params.id}`, {
            ...props.item,
            file: {
                ...props.item.file,
                data: props.item.file.src
            },
        }, config)
        .catch(error => {
            
            dispatch({
                type: SET_ERROR_FOR_FILE_IN_CHAT_USER,
                payload: {
                    receiver_id: params.id,
                    fileId: props.item?.file?.id,    
                    message: 'عدم برقراری ارتباط با سرور',      
                }
            })
            
        })

        if(response && response?.data?.success){
            
            dispatch({
                type: SET_SUCCESS_FILE_IN_CHAT_USER,
                payload: {
                    receiver_id: params.id,
                    fileId: props.item?.file?.id,
                    messageId: response.data?.data.id,
                }
            })

        }
        else if(response && response.data.errors.length >= 1) {
            
            // show error
            dispatch({
                type: SET_ERROR_FOR_FILE_IN_CHAT_USER,
                payload: {
                    receiver_id: params.id,
                    fileId: props.item?.file?.id,    
                    message: response.data.errors[0]?.message,      
                }
            })

        }

    }

    return (
        <button onClick={resend} className="p-2.5 text-sm flex outline-none select-none items-center gap-4 flex-wrap border-b border-b-gray-200 last:border-b-0 w-full text-gray-700 duration-300 relative">
                                
            <ReactSVG src={`${process.env.PUBLIC_URL}/assets/images/icons/step.svg`} className="5xm:w-[18px] w-3 text-gray-500" />

            ارسال مجدد

        </button>
    )

}

export default ResendFileInChat