import { debounce } from "lodash"
import { useEffect, useRef, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { ReactSVG } from "react-svg"
import { SET_SHOW_DESCRIPTION_COLLAPSE_SINGLE_CASE } from "../../../../../../redux/action/cases/casesType"

const CaseContentInfoDescription = ({description, ...props}) => {

    const dispatch = useDispatch()
    const showCollapse = useSelector(state => state.cases.showDescriptionCollapse)

    const [collapseContentHeight, setCollapseContentHeight] = useState(0)
    const collapseContentRef = useRef()

    useEffect(() => {

        if(showCollapse?.show){
            
            if(collapseContentRef.current){
                setCollapseContentHeight(collapseContentRef.current.scrollHeight)
            }

        }

        window.addEventListener('resize', debounceUpdateCollapseHeight)

        return () => {
            window.removeEventListener('resize', debounceUpdateCollapseHeight)
        }

    }, [])

    // --------------------------------------
    // for updating height when resize page
    const updateCollapseHeight = () => {
        
        if(collapseContentRef.current){
            setCollapseContentHeight(collapseContentRef.current.scrollHeight)
        }

    }
    const debounceUpdateCollapseHeight = debounce(updateCollapseHeight, 400)
    // --------------------------------------

    const toggleShowCollapse = () => {
        
        if(showCollapse.show){

            dispatch({
                type: SET_SHOW_DESCRIPTION_COLLAPSE_SINGLE_CASE,
                payload: {
                    show: false,
                    loading: true
                }
            })

            setTimeout(() => {
                
                dispatch({
                    type: SET_SHOW_DESCRIPTION_COLLAPSE_SINGLE_CASE,
                    payload: {
                        show: false,
                        loading: false
                    }
                })

            }, 500);

            setCollapseContentHeight(0)
        }
        else{

            dispatch({
                type: SET_SHOW_DESCRIPTION_COLLAPSE_SINGLE_CASE,
                payload: {
                    show: true,
                    loading: false
                }
            })

            if(collapseContentRef.current){
                setCollapseContentHeight(collapseContentRef.current.scrollHeight)
            }

        }

    }

    return (
        <div className={`bg-white shadow-custom-md rounded-xl h-fit`}>
            
            <button type="button" onClick={(toggleShowCollapse)} className='w-full flex items-center justify-between text-gray-800 cursor-pointer py-4 px-4 hover:text-black duration-300 group outline-none focus:outline-none'>

                <span className="flex items-center gap-1.5 font-medium text-lg"> 
                    خلاصه متن پرونده
                </span>

                <div className="flex items-center justify-center">

                    <ReactSVG 
                        src={`${process.env.PUBLIC_URL}/assets/images/icons/down.svg`}
                        className={`w-5 text-gray-800 ${!showCollapse.show ? 'rotate-0' : 'rotate-180'} duration-500`}
                    />
               
                </div>

            </button>

            <div style={ { height: showCollapse.show ? `${collapseContentHeight}px` : '0px' } } className={`collapse-content overflow-hidden`}>

                <div ref={collapseContentRef} className={`p-4 flex flex-col gap-6 ${showCollapse.show ? '' : 'border-none'} border-t duration-300`}>

                    <p className="text-gray-600 text-justify leading-8">
                        {description ? description : 'بدون شرح'}
                    </p>

                </div>

            </div>
        
        </div>
    )

}

export default CaseContentInfoDescription