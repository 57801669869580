import { ReactSVG } from "react-svg"

import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Autoplay, Navigation, Pagination } from 'swiper';

import 'swiper/swiper.min.css';
import 'swiper/components/navigation/navigation.min.css';
import 'swiper/components/pagination/pagination.min.css';

import HomeSliderItem from "./Item"
import { useSelector } from "react-redux";

SwiperCore.use([Navigation, Pagination, Autoplay]);

const HomeSlider = ({items}) => {

    const loading = useSelector(state => state.home.loading)

    return (
        <section className="mb-8">

            <Swiper 
                className="group banner swiper mr-0 rounded-2xl"
                spaceBetween={5}
                loop={true}
                slidesPerView={1}
                autoplay={ {delay: 5000, disableOnInteraction: false, pauseOnMouseEnter: true} }
                navigation={ {nextEl: '.banner .swiper-button-next', prevEl: '.banner .swiper-button-prev'} }
                pagination={ {el: ".banner .swiper-pagination", clickable: true} }
            >

                <div className="md:min-h-[320px] min-h-[220px] bg-white h-full border border-gray-200 shadow-custom-md rounded-2xl relative">
                    
                    { items && 
                    
                        items.map(item => 
                            
                            <SwiperSlide
                                className="h-auto rounded-2xl overflow-hidden"
                                key={item.caption + item.image}
                            >

                                <HomeSliderItem 
                                    image={item.image}
                                    link={item.link}
                                    caption={item.caption}
                                />

                            </SwiperSlide>
                            
                        )

                    }

                    { loading &&
                        <div className="w-full md:min-h-[320px] min-h-[220px] flex items-center justify-center">

                            <ReactSVG 
                                src={`${process.env.PUBLIC_URL}/assets/images/icons/loading-dot.svg`}
                                className="text-gray-400 w-20"
                            />

                        </div>
                    }

                </div>

                <div className="static swiper-pagination mt-3"></div>

                <div className="group-hover:opacity-100 opacity-0 duration-75">

                    <div className="swiper-button-next shadow-custom-md after:hidden duration-150 flex items-center justify-center rounded-lg w-8 h-8 bg-white/80 -translate-y-1/2" aria-disabled="false">
                    
                        <ReactSVG 
                            src={`${process.env.PUBLIC_URL}/assets/images/icons/left.svg`}
                            className="text-gray-600 w-1/3"
                        />

                    </div>
                    
                    <div className="swiper-button-prev shadow-custom-md after:hidden duration-150 flex items-center justify-center rounded-lg w-8 h-8 bg-white/80 -translate-y-1/2" aria-disabled="true">

                        <ReactSVG 
                            src={`${process.env.PUBLIC_URL}/assets/images/icons/right.svg`}
                            className="text-gray-600 w-1/3"
                        />
                        
                    </div>

                </div>

            </Swiper>

        </section>
    )

}

export default HomeSlider