const AddEventFormSuggestItem = (props) => {

    const addToTitle = () => {
        props.onAddToTitle(props.text)
    }

    return (
        <div>
            
            <input onChange={addToTitle} id={props.text} type="radio" name="title-event-suggest" value={props.text} className="hidden peer" checked={props.isActivePrefix.includes(props.text)}/>
            <label htmlFor={props.text} className="bg-gray-200 outline outline-2 outline-gray-200/20 peer-checked:outline-primary/20 px-3.5 py-1 rounded-2xl peer-checked:bg-primary peer-checked:text-white cursor-pointer duration-300">{props.text}</label>

        </div>
    )

}

export default AddEventFormSuggestItem