import { Link, useParams } from "react-router-dom"
import { ReactSVG } from "react-svg"
import { calculateLeftTime } from "../../../../../../utils/time"
import { useCookies } from "react-cookie"
import { useDispatch } from "react-redux"
import { deleteFileFromCase } from "../../../../../../redux/action/cases/casesAction"
import ConfirmModal from "../../../../../UI/ConfirmModal"
import { useState } from "react"

const CaseContentFilesItem = ({info, dontShowAction = false, ...props}) => {

    const fileType = () => {
        if(info.kind === 'image')
            return 'تصویر سند یا مدرک'
        else
            return 'ابلاغیه'
    }

    const putTime = () => {
        return calculateLeftTime(info.created_at)
    }

    const putSize = () => {
    
        const size = (info.size / 100000) * 100
        
        if(size < 500) {
            return size.toFixed(0) + ' کیلوبایت '
        }
    
        else 
            return (size / 1000).toFixed(2) + ' مگابایت '
    
    }

    return (
        <li className="bg-white items-start shadow-custom-md border border-primary/10 rounded-xl p-3 gap-4 flex flex-col relative before:absolute before:top-0 before:w-11/12 before:h-0.5 before:bg-primary before:mx-auto before:left-0 before:right-0 before:rounded-md">

            <div className="flex flex-wrap gap-4 items-center justify-between w-full">

                <div className="flex items-start justify-between flex-wrap mt-2 gap-3">
                    
                    { info.kind === 'image' ? 

                        <div className="flex-shrink-0 w-12 h-12 flex items-center justify-center bg-white rounded-2xl text-white outline outline-4 outline-primary/10">
                            
                            <img className="rounded-xl w-full h-full object-cover" src={info.url} alt="" />

                        </div>

                        :

                        <div className="flex-shrink-0 w-12 h-12 flex items-center justify-center bg-primary rounded-2xl text-white outline outline-4 outline-primary/20">
                            <ReactSVG 
                                className="w-5"
                                src={`${process.env.PUBLIC_URL}/assets/images/icons/file.svg`}
                            />
                        </div>

                    }

                    <div className="flex flex-col justify-between gap-2">

                        <h3 className="text-gray-900 font-medium text-sm"> {info.caption ? info.caption : 'بدون عنوان'} </h3>

                        <div className="flex items-center gap-1 text-gray-500">

                            <svg className="w-3 h-3 mb-0.5" viewBox="0 0 24 24" fill="none">
                                <path d="M2.00162 10C2.00052 10.1027 2 10.2067 2 10.312V15.688C2 16.8044 2.05852 17.7698 2.23866 18.5804C2.42133 19.4024 2.74209 20.1251 3.30848 20.6915C3.87488 21.2579 4.59764 21.5787 5.41959 21.7613C6.23018 21.9415 7.19557 22 8.312 22H15.688C16.8044 22 17.7698 21.9415 18.5804 21.7613C19.4024 21.5787 20.1251 21.2579 20.6915 20.6915C21.2579 20.1251 21.5787 19.4024 21.7613 18.5804C21.9415 17.7698 22 16.8044 22 15.688V10.312C22 10.2067 21.9995 10.1027 21.9984 10H2.00162Z" fill="currentColor"></path>
                                <path d="M4.99991 4.34708V3C4.99991 2.44772 5.44762 2 5.99991 2C6.55219 2 6.99991 2.44772 6.99991 3V4.03477C7.41104 4.01008 7.84846 4 8.31191 4H15.6879C16.1514 4 16.5888 4.01008 16.9999 4.03477V3C16.9999 2.44772 17.4476 2 17.9999 2C18.5522 2 18.9999 2.44772 18.9999 3V4.34708C19.649 4.54034 20.2235 4.8406 20.6914 5.30848C21.2578 5.87488 21.5786 6.59764 21.7612 7.41959C21.8024 7.60498 21.8373 7.79846 21.8665 8H2.1333C2.16253 7.79846 2.19737 7.60498 2.23857 7.41959C2.42124 6.59764 2.742 5.87488 3.30839 5.30848C3.77627 4.8406 4.35086 4.54034 4.99991 4.34708Z" fill="currentColor"></path>
                            </svg>

                            <span className="text-xs"> { putTime() } </span>

                        </div>

                    </div>
                    
                </div>

                <div className="flex flex-col gap-2 justify-between items-end">
                
                    <span className="text-xs px-3 py-1 bg-third text-white w-fit rounded-md"> { !info.context ? fileType() : info.context} </span>
                
                    <span className="text-xs text-gray-500"> { putSize() } </span>

                </div>


            </div>

            { info.description && 
                <>
                    <hr className="w-full mt-auto" />

                    <p className="text-sm text-justify text-gray-500 leading-7"> {info.description} </p>
                </>    
            }
            
            { !dontShowAction && 
                
                <>
                    
                    <hr className="w-full mt-auto" />   
                    
                    <div className="flex items-center justify-between w-full">

                        <div className="flex items-center gap-4 flex-wrap">

                        </div>

                        <Link to={info.url} target="_blank" download={true} className="hover:bg-green-50 duration-300 text-green-500 px-6 py-0.5 rounded-md border border-green-500">
                                
                            <ReactSVG 
                                className="w-5"
                                src={`${process.env.PUBLIC_URL}/assets/images/icons/download.svg`}
                            />

                        </Link>

                    </div>

                </>
            }

        </li>
    )

}

export default CaseContentFilesItem