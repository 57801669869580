import { useEffect } from "react"
import { ReactSVG } from "react-svg"

const WalletActionButtonAddBalanceModalPriceField = ({price, onSetPrice, shouldIncreasePrice}) => {

    useEffect(() => {

        if(shouldIncreasePrice){

            onSetPrice( shouldIncreasePrice >= 1 ? `${shouldIncreasePrice.toLocaleString('fa')}` : '' )
        }

    }, [])

    const updatePriceField = (event) => {

        let value = event.target.value.split('٬').join('').split('')

        value = value.map(item => {
            item = item.replace('۰', '0')
            item = item.replace('۱', '1')
            item = item.replace('۲', '2')
            item = item.replace('۳', '3')
            item = item.replace('۴', '4')
            item = item.replace('۵', '5')
            item = item.replace('۶', '6')
            item = item.replace('۷', '7')
            item = item.replace('۸', '8')
            item = item.replace('۹', '9')
            return item
        }).join('')

        if( isNaN(value) || event.target.value === ' ' ) return

        value = Number(value)

        onSetPrice( value >= 1 ? `${value.toLocaleString('fa')}` : '' )

    }

    const add = () => {

        let value = price.split('٬').join('').split('')
        
        value = value.map(item => {
            item = item.replace('۰', '0')
            item = item.replace('۱', '1')
            item = item.replace('۲', '2')
            item = item.replace('۳', '3')
            item = item.replace('۴', '4')
            item = item.replace('۵', '5')
            item = item.replace('۶', '6')
            item = item.replace('۷', '7')
            item = item.replace('۸', '8')
            item = item.replace('۹', '9')
            return item
        }).join('')

        value = Number(value)

        value += 1000

        onSetPrice( value >= 1 ? `${value.toLocaleString('fa')}` : '' )
    }

    const subtract = () => {
        let value = price.split('٬').join('').split('')
        
        value = value.map(item => {
            item = item.replace('۰', '0')
            item = item.replace('۱', '1')
            item = item.replace('۲', '2')
            item = item.replace('۳', '3')
            item = item.replace('۴', '4')
            item = item.replace('۵', '5')
            item = item.replace('۶', '6')
            item = item.replace('۷', '7')
            item = item.replace('۸', '8')
            item = item.replace('۹', '9')
            return item
        }).join('')

        value = Number(value)

        value -= 1000

        onSetPrice( value >= 1 ? `${value.toLocaleString('fa')}` : '' )
    }

    return (
        <div className="flex items-center justify-between gap-2">

            <button onClick={add} className="group w-10 h-10 border border-gray-300 rounded-md flex-shrink-0 flex items-center justify-center hover:border-primary hover:text-primary duration-300 transition-colors">
                
                <ReactSVG src={`${process.env.PUBLIC_URL}/assets/images/icons/add.svg`} className="w-6" />

            </button>

            <div className="w-full relative">
            
                <input onInput={updatePriceField} value={price} dir="rtl" type="tel" className="py-3 text-lg font-medium placeholder:font-normal text-center border border-gray-300 placeholder:text-gray-400 px-3 w-full rounded-lg !outline-none" placeholder="مبلغ واریزی را وارد کنید" />

                { price.length !== 0  &&
                    <span className="absolute text-gray-500 right-2 -top-2 px-2 h-fit text-xs bg-white"> مبلغ دلخواه‌ (تومان) </span>
                }

            </div>

            <button onClick={subtract} className="group w-10 h-10 border border-gray-300 rounded-md flex-shrink-0 flex items-center justify-center hover:border-primary hover:text-primary duration-300 transition-colors">
                
                <ReactSVG src={`${process.env.PUBLIC_URL}/assets/images/icons/subtract.svg`} className="w-2.5" />

            </button>

        </div>
    )

}

export default WalletActionButtonAddBalanceModalPriceField