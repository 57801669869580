export const SET_LOADING_NOTES = 'SET_LOADING_NOTES'
export const SET_FAILURE_NOTES = 'SET_FAILURE_NOTES'
export const SET_NOTES = 'SET_NOTES'
export const SET_PAGINATION_NOTES = 'SET_PAGINATION_NOTES'

export const SET_FILTER_NOTES = 'SET_FILTER_NOTES'
export const SET_FILTER_ERROR_NOTES = 'SET_FILTER_ERROR_NOTES'
export const ADD_ITEMS_TO_FILTER_NOTES = 'ADD_ITEMS_TO_FILTER_NOTES'

export const SET_LOAD_MORE_LOADING_NOTES = 'SET_LOAD_MORE_LOADING_NOTES'
export const INCRAESE_NOTES = 'INCRAESE_NOTES'

export const SET_DELETE_ERROR_NOTES = 'SET_DELETE_ERROR_NOTES'
export const SET_LOADING_ERROR_NOTES = 'SET_LOADING_ERROR_NOTES'
export const REMOVE_NOTE_FROM_NOTES = 'REMOVE_NOTE_FROM_NOTES'
export const REMOVE_LIST_OF_NOTES_FROM_NOTES = 'REMOVE_LIST_OF_NOTES_FROM_NOTES'


export const SET_NOTE_INFO = 'SET_NOTE_INFO'
export const RESET_NOTE_INFO = 'RESET_NOTE_INFO'
export const SET_NOTE_INFO_BODY = 'SET_NOTE_INFO_BODY'
export const SET_NOTE_INFO_COLOR = 'SET_NOTE_INFO_COLOR'
export const SET_NOTE_INFO_LAW_SUIT = 'SET_NOTE_INFO_LAW_SUIT'
export const SET_NOTE_INFO_SUBJECT = 'SET_NOTE_INFO_SUBJECT'

export const ADD_ITEM_TO_NOTES = 'ADD_ITEM_TO_NOTES'
export const SET_NOTE_LOADING = 'SET_NOTE_LOADING'
export const SET_NOTE_ERROR = 'SET_NOTE_ERROR'
export const EDIT_ITEM_FROM_NOTES = 'EDIT_ITEM_FROM_NOTES'

export const RESTE_ALL_NOTES_DATA_APP = 'RESTE_ALL_NOTES_DATA_APP'