import { ReactSVG } from "react-svg"
import CasesList from "../../components/Cases/List"
import CasesSearch from "../../components/Cases/Search"
import Header from "../../components/Header"
import HeaderAddBtn from "../../components/Header/AddBtn"
import HeaderSearchBtn from "../../components/Header/Search/Btn"
import { useDispatch, useSelector } from "react-redux"
import { SHOW_FILTER_SECTION_CASES } from "../../redux/action/cases/casesType"
import CasesFilterSection from "../../components/Cases/FilterSection"
import { getCountFilteredApplied } from "../../redux/selector/cases"
import ToastCustom from "../../components/UI/ToastCustom"
import ConfirmModal from "../../components/UI/ConfirmModal"
import SuccessBoxCases from "../../components/Cases/List/SuccessBox"
import LoadingDot from "../../components/UI/LoadingDot"
import { useEffect } from "react"

const Cases = () => {

    const dispatch = useDispatch()
    const showFilterSection = useSelector(state => state.cases.showFilterSection)
    const filterCount = useSelector( state => getCountFilteredApplied(state.cases.fieldsFilter) )
    const successMessageArchiveLike = useSelector(state => state.cases.successArchiveLike)
    const archiveLikeLoading = useSelector(state => state.cases.archiveLikeLoading)

    useEffect(() => {

        window.scrollTo({top: 0})

        return () => {
            if(document.body.classList.contains('modal-opened'))
                document.body.classList.remove('modal-opened')
        }

    }, [])

    const comeShowFilterSection = () => {
        
        dispatch({
            type: SHOW_FILTER_SECTION_CASES,
            payload: true
        })

        document.body.classList.add('modal-opened')

    }

    return (
        <>
            
            <Header 
                title={'پرونده‌ها'}
                backBtn={false}
                inMobileTitleBeHidden={true}
            >

                <HeaderSearchBtn />

                <button onClick={comeShowFilterSection} className="w-7 h-8 flex items-center justify-center relative">

                    <ReactSVG 
                        className="w-6 text-gray-700"
                        src={`${process.env.PUBLIC_URL}/assets/images/icons/filter.svg`}
                    />
                    
                    { filterCount >= 1 && 
                        <div className="absolute top-0 left-0 bg-rose-600 text-white w-4 h-4 text-xs rounded-full font-medium flex items-center justify-center">
                            <span className="mt-0.5"> { filterCount.toLocaleString('fa') } </span>
                        </div>
                    }

                </button>

            </Header>

            <main className="main-content"> 

                <CasesSearch />

                <CasesList />

                {showFilterSection &&
                    <CasesFilterSection />
                }

                { ( (successMessageArchiveLike && successMessageArchiveLike.length <= 40) ) &&

                    <ToastCustom>
    
                        <div className="flex items-center gap-2">
                            <ReactSVG
                                className="text-green-600 4xm:w-8 w-6"
                                src={`${process.env.PUBLIC_URL}/assets/images/icons/tick.svg`}
                            />

                            { successMessageArchiveLike }

                        </div>

                    </ToastCustom>

                }
                
                { archiveLikeLoading && <LoadingDot /> }

            </main>

        </>
    )

}

export default Cases