import { ReactSVG } from "react-svg"

const Input = ({type = 'text', max, className, labelClass, label, id, value = '', deactive, labelSize = 'sm', labelColor = 'text-gray-500', isValid = true, icon, loading, showCloseBtn, closeBtnClicked, ...props}) => {

    let iconElement;

    // icons name is exist in README.md file and icons exist in assets/images files.
    if(icon){   
        iconElement = <ReactSVG src={`${process.env.PUBLIC_URL}/assets/images/icons/${icon}.svg`} className={`w-5 h-5 text-primary absolute right-3 top-0 bottom-0 my-auto peer-focus:!text-primary duration-300 ${!isValid && 'text-rose-500'} ${loading && '!text-gray-400'}`} />
    }

    const input = (
        <div className={`relative`}>
                
            <input 
                type={type} 
                id={id}
                value={value}
                readOnly={deactive}
                className={`py-3 px-4 peer w-full focus:outline-none shadow-custom-md border border-gray-200/70 focus:!border-primary duration-300 transition-colors rounded-xl pl-16 appearance-none ${!isValid && 'border-rose-500'} ${icon && 'pr-10'} ${className}`}
                {...props}
            />

            { loading && <ReactSVG src={`${process.env.PUBLIC_URL}/assets/images/icons/loading-dot.svg`} className={`absolute top-1/2 -translate-y-1/2 right-4 ${icon && 'right-12'} w-10 text-gray-400 group-focus-within:text-primary duration-300`} /> }

            { icon && iconElement }

            { max && <span className="absolute left-2.5 bottom-1/2 translate-y-[calc(50%+2px)] text-sm text-gray-400"> {value.length}/{max}</span> }

        </div>
    )

    if(!label){
        return input
    }
    else if(label){
        return (
            <div className="flex flex-col gap-2.5 group cursor-pointer">

                <div className="flex items-center justify-between gap-3">
                    
                    <label 
                        htmlFor={id} 
                        className={`${labelSize === 'sm' ? 'text-sm' : 'text-base'} ${labelColor} group-focus-within:text-primary duration-300 cursor-pointer ${labelClass ? labelClass : ''}`}
                    >
                        { label } :
                    </label>
                    
                
                    { showCloseBtn && 
                        <button onClick={closeBtnClicked} className="w-12 flex items-center justify-end outline-none">
                            <ReactSVG 
                                className="w-5 text-rose-600"
                                src={`${process.env.PUBLIC_URL}/assets/images/icons/times.svg`}
                            />
                        </button>
                    }

                </div>

                { input }

            </div>
        )
    }

}

export default Input