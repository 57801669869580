import CaseListItem from "../Item"

const CasesFiltered = (props) => {

    return (
        <>

            <section>

                <div className="flex mb-6 items-center justify-between flex-wrap gap-2 rounded-2xl shadow-custom-md border-[3px] border-white p-4">
                            
                    <h2 className="text-base font-medium"> 
                        <span> جستجو پرونده </span>
                        {/* <span> با فیلترهای اعمال شده </span> */}
                    </h2>

                    { props.list.length >= 1 && 
                        <span className="text-sm flex items-center gap-1"> ( <span className="font-medium">{Number(props.list.length).toLocaleString('fa')}</span> پرونده) </span>
                    }
                            
                </div>

                { props.list.length === 0 && 
                    'پرونده مورد نظر یافت نشد'
                }

                <ul className="grid md:grid-cols-2 grid-cols-1 gap-6">
                
                    { props.list.map((item, index) => 

                        <CaseListItem 
                            key={item.id}

                            id={item.id}
                            title={item.title}
                            mobile={item.code}
                            name={item?.lawyer?.name}

                            code={item.code}
                            archive_code={item.archive_code}
                            branch={item.branch}
                            step={item.step}
                            state={item.state}
                            category={item.category}

                            files={item.files}
                            notes={item.notes}
                            conversations={item.conversations}
                            events={item.events}
                        />   

                    )}

                </ul>

            </section>

        </>
    )

}

export default CasesFiltered