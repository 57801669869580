import { useEffect, useState } from "react"
import { ReactSVG } from "react-svg"
import ModalBottom from "../../../../UI/ModalBottom"
import AddEventFormTypeList from "./List"
import AddEventFormCasesList from "./List"
import AddEventFormCasesSearch from "./Search"

const AddEventFormCasesField = (props) => {

    const [showModal, setShowModal] = useState(false)
    const [showingModal, setShowingModal] = useState(false)
    const [filteredList, setFilteredList] = useState({
        searched: false,
        list: []
    })

    useEffect(() => {

        return () => {
            if(document.body.classList.contains('modal-opened'))
                document.body.classList.remove('modal-opened')
        }

    }, [])

    const openModal = () => {
        setShowModal(true)

        document.body.classList.add('modal-opened')
    }

    const hideModal= () => {
        setShowingModal(false)

        setTimeout(() => {
            setShowModal(false)
        }, 300);

        document.body.classList.remove('modal-opened')
    }

    const resetDataAndHideModal = () => {
        hideModal()

        props.onUpdateCaseInfoField(null, '')
    }

    const restoreDataAndHideModal = (event) => {
        event.preventDefault()

        hideModal()

        props.onUpdateCaseInfoField(props.mainCase.id, props.mainCase.text)
    }

    const selectedCase = (id, text) => {
        props.onUpdateCaseInfoField(id, text)

        hideModal()
    }

    return (
        <>
            <div className="flex flex-col gap-2.5">

                <span className="text-gray-500 text-sm"> پرونده مرتبط : </span>

                <button onClick={ openModal } type="button" className="flex items-center justify-between gap-4 flex-wrap bg-white p-3 text-right text-gray-800 rounded-xl shadow-custom-md">

                    <div className="flex items-center gap-3">
                        
                        <ReactSVG src={`${process.env.PUBLIC_URL}/assets/images/icons/folder.svg`} className={`w-5 text-primary ${(props.loading) && '!text-gray-400' }`} />

                        { !props.loading ? props.caseInfo?.id ? props?.caseInfo?.text : <span className="text-gray-500">انتخاب نشده</span>
                            :
                            <ReactSVG src={`${process.env.PUBLIC_URL}/assets/images/icons/loading-dot.svg`} className="w-10 py-[7px] text-gray-400" /> 
                        }
                    
                    </div>

                    <div>
                        <ReactSVG src={`${process.env.PUBLIC_URL}/assets/images/icons/down.svg`} className="w-5" />
                    </div>

                </button>

            </div>
            
            { showModal &&
                <ModalBottom
                    show={showModal}
                    onSetShow={setShowModal}
                    className="overflow-y-auto no-scrollbar"
                    showing={showingModal}
                    onSetShowing={setShowingModal}
                >
                    
                    <div className="sticky top-0 left-0 flex flex-col bg-white w-full z-[1] border-b border-b-slate-200">

                        <div className="py-2 bg-white z-[1] mb-2">
                            <div className="mx-auto w-20 h-2 bg-gray-200 rounded-2xl"></div>
                        </div>

                        <div className="flex items-center justify-between gap-4 mb-4">

                            <button onClick={hideModal} type="button" className="w-10 group">
                                
                                <ReactSVG src={`${process.env.PUBLIC_URL}/assets/images/icons/back.svg`} className="w-5 text-gray-600 hover:text-primary duration-300 rotate-180 mr-1.5 group-disabled:text-gray-400 group-disabled:opacity-70" />

                            </button>

                            <div className="flex items-center gap-0.5">

                                <span className="font-medium flex items-center gap-1.5">
                                    <ReactSVG src={`${process.env.PUBLIC_URL}/assets/images/icons/folder.svg`} className="w-[18px] text-gray-500" />

                                    { props.caseInfo?.id ? props.caseInfo?.text : 'انتخاب نشده' }
                                </span>

                                { props.caseInfo?.id && ((props.mainCase && props.caseInfo?.id === props.mainCase.id) || !props.mainCase) ?
                                    <button onClick={resetDataAndHideModal} className="h-full focus:outline-none outline-none w-8 flex justify-end">
                                        <ReactSVG 
                                            src={`${process.env.PUBLIC_URL}/assets/images/icons/delete.svg`}
                                            className="w-5 text-rose-600"
                                        />
                                    </button>
                                    :
                                    (props.mainCase && props.caseInfo?.id !== props.mainCase) &&
                                        <button onClick={restoreDataAndHideModal} type="button" className="h-full focus:outline-none outline-none w-8 flex justify-end">
                                            <ReactSVG 
                                                src={`${process.env.PUBLIC_URL}/assets/images/icons/turn-back.svg`}
                                                className="w-4 text-primary"
                                            />
                                        </button>
                                }

                            </div>

                        </div>

                        <AddEventFormCasesSearch 
                            onSetFilteredList={setFilteredList}
                        />

                    </div>

                    <AddEventFormCasesList 
                        onSelectCase={selectedCase}
                        filteredList={filteredList}
                    />

                </ModalBottom>
            }
        </>
    )

}

export default AddEventFormCasesField