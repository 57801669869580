import { useSelector } from "react-redux"
import Header from "../../components/Header"
import TariffsItems from "../../components/Tariffs"
import { useEffect } from "react"

const Tariffs = () => {

    useEffect(() => {

        window.scrollTo({top: 0})

    }, [])

    return (
        <>
            <Header 
                title={'تعرفه‌ها'}
                backBtn={true}
            >

            </Header>

            <main className="main-content">

                <TariffsItems />

            </main>
        </>
    )

}

export default Tariffs