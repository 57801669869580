import { useSelector } from "react-redux"
import MessageContentFile from "./File"
import MessageContentImage from "./Image"
import MessageContentText from "./Text"
import { useLocation, useParams } from "react-router-dom"
import { ReactSVG } from "react-svg"

const MessageContent = () => {

    const chatMessages = useSelector(state => state.messages.chat.messages)
    const chatMessagesByBaseDate = useSelector(state => state.messages.chat.messagesSortByDate)
    const receiver = useSelector(state => state.messages.chat.receiver)
    const loading = useSelector(state => state.messages.chat.loading)
    const error = useSelector(state => state.messages.chat.error)
    const filtered = useSelector(state => state.messages.chat.filtered)

    const location = useLocation()

    const params = useParams()

    const  generateRandomString = (length) => {
        const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        let result = '';
        const charactersLength = characters.length;
        for (let i = 0; i < length; i++) {
            result += characters.charAt(Math.floor(Math.random() * charactersLength));
        }
        return result;
    }

    const handleShowMessage = (item) => {
        
        if(item.type === 'text'){

            return (
                <MessageContentText 
                    key={ 'txt-' + `-${generateRandomString(4)}-` + item.id}
                    self={params.id != item.sender_id}
                    body={item.body}
                    name={location?.state?.name ? location?.state?.name : receiver?.name ? receiver?.name : null}
                    seen={item.seen}
                    time={new Date(item.created_at)}
                    id={item.id}
                    status={item?.status}
                />
            )

        }

        else if(item.type === 'file' && item.file.kind === 'image'){
            return (
                <MessageContentImage 
                    key={ 'img-' + `-${generateRandomString(4)}-` + item.id}
                    self={params.id != item.sender_id}
                    name={location?.state?.name ? location?.state?.name : receiver?.name ? receiver?.name : null}
                    seen={item.seen}
                    time={new Date(item.created_at)}
                    id={item.id}
                    src={item.file.src}
                    sendingStatus={item.status}

                    item={item}
                />
            )
        }

        else if(item.type === 'file'){
            return (
                <MessageContentFile 
                    key={'file-' + `-${generateRandomString(4)}-` + item.id}
                    self={params.id != item.sender_id}
                    name={location?.state?.name ? location?.state?.name : receiver?.name ? receiver?.name : null}
                    seen={item.seen}
                    time={new Date(item.created_at)}
                    id={item.id}
                    src={item.file.src}
                    sendingStatus={item.status}
                    caption={item.file.caption ? item.file.caption : item.body ? item.body : 'فایل'}

                    item={item}
                />
            )
        }

    }

    if(error){

        return (
            <>
                <section>

                    <div className="h-20 flex items-center gap-2.5 flex-wrap justify-center"> 

                        <ReactSVG
                            className="text-rose-500/90 4xm:w-8 w-6"
                            src={`${process.env.PUBLIC_URL}/assets/images/icons/info-box.svg`}
                        />

                        <span className="4xm:text-base text-sm text-gray-600"> {error} </span>
                        
                    </div>

                </section>
            </>
        )

    }

    else if(filtered.searchedValue){

        return (
            <>
                <section>

                    { filtered.list.length <= 0 && <p className="text-gray-600 flex items-center"> هیچ پیامی یافت نشد </p> }
    
                    <ul className="flex flex-col gap-5"> 

                        { filtered.list.map(item => 
                            
                            handleShowMessage(item)
                            
                        )}

                    </ul>

                </section>
            </>
        )

    }

    else if(receiver.id == params.id){

        return (
            <>
                <section>

                    {  Object?.keys(chatMessagesByBaseDate)?.length >= 1 && Object?.keys(chatMessagesByBaseDate)?.sort((a, b) => a.localeCompare(b, 'fa-IR'))?.map(item => 
                        
                        <div key={item} className="select-none">
                            
                            <div className="flex relative items-center justify-center my-12 text-gray-500 text-opacity-90">
                                
                                <hr className="w-full h-2" /> 
                                
                                <span className="absolute left-0 right-0 top-0 -translate-y-2.5 mx-auto px-6 bg-custom-gray block w-fit"> {item} </span>

                            </div>

                            <ul className="flex flex-col gap-5"> 
    
                                { chatMessagesByBaseDate[item].map(chat => 
                                    
                                    handleShowMessage(chat)
                                    
                                )}
            
                            </ul>

                        </div>

                    )}

                    {/* <ul className="flex flex-col gap-5"> 
    
                        { chatMessages.map(item => 
                            
                            handleShowMessage(item)
                            
                        )}
    
                    </ul> */}
    
                </section>
            </>
        )

    }

    else if(loading){

        return (
            <>
                <section>

                    <div className="h-20 flex items-center justify-center"> 

                        <ReactSVG
                            className="w-8 text-primary"
                            src={`${process.env.PUBLIC_URL}/assets/images/icons/loading-dot.svg`}
                        />
                        
                    </div>

                </section>
            </>
        )

    }

}

export default MessageContent