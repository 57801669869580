import { useEffect, useState } from "react"
import { ReactSVG } from "react-svg"

import WalletActionButtonAddBalanceModal from "./Modal"
import { useLocation } from "react-router-dom"
import { getPeymentedStatus } from "../../../../utils/paymented"

const WalletActionButtonAddBalance = () => {
    
    const [showPayModal, setShowPayModal] = useState(false)
    const [showingPayModal, setShowingPayModal] = useState(false)
    const [shouldIncreasePrice, setShouldIncreasePrice] = useState()

    const location = useLocation()

    useEffect(() => {

        checkShouldIncreaseWallet()

        return () => {
            if(document.body.classList.contains('modal-opened'))
                document.body.classList.remove('modal-opened')
        }

    }, [])

    const checkShouldIncreaseWallet = () => {
        const paymentStatus =  getPeymentedStatus()

        if(!location.state?.increaseToWallet || paymentStatus) return

        setShowingPayModal(true)
        setShowPayModal(true)
        
        setShouldIncreasePrice(location.state?.price)
    }

    const showModal = () => {

        document.body.classList.add('modal-opened')
        
        setShowPayModal(true)

    }

    return (
        <>
            <button onClick={showModal} className="bg-white shadow-custom-lg rounded-xl p-4 flex items-center flex-col justify-center gap-4 !outline-none">

                <div className="bg-white shadow-custom-md border-custom-gray border-2 w-14 h-14 rounded-full flex items-center justify-center">

                    <ReactSVG
                        className="text-primary w-8"
                        src={`${process.env.PUBLIC_URL}/assets/images/icons/wallet-add.svg`}
                    />

                </div>

            <span className="font-medium"> افزایش موجودی </span>

            </button>

            { showPayModal &&
                
                <WalletActionButtonAddBalanceModal 
                    showPayModal={showPayModal}
                    showingPayModal={showingPayModal}

                    onSetShowPayModal={setShowPayModal}
                    onSetShowingPayModal={setShowingPayModal}

                    shouldIncreasePrice={shouldIncreasePrice}
                    OnSetShouldIncreasePrice={setShouldIncreasePrice}
                />

            }

        </>
    )

}

export default WalletActionButtonAddBalance