import { DELETE_BASIC_LOCATION_DATA, FAILURE_LOCATION, REQUEST_LOCATION, SET_SEARCH_LOCATION, SUCCESS_LOCATION, DELETE_CHOOSE_LOCATION, SET_CHOOSE_PROVINCE, SET_CHOOSE_CITY } from '../../action/location/locationType'

const initialState = {
    search: '',
    list: [],

    loading: false,
    error: null,

    chooseProvince: {},
    chooseCity: {}
}

const locationReducer = (state = initialState, action) => {

    switch(action.type){

        case SET_SEARCH_LOCATION :
            return {
                ...state,
                search: action.payload
            }

        case DELETE_BASIC_LOCATION_DATA :
            return {
                ...state,
                list: [],
                error: null,
            }

        case REQUEST_LOCATION :
            return {
                ...state,
                loading: true,
                error: null
            }

        case FAILURE_LOCATION :
            return {
                ...state,
                loading: false,
                error: action.payload
            }

        case SUCCESS_LOCATION :
            return {
                ...state,
                loading: false,
                error: null,
                list: action.payload
            }

        case DELETE_CHOOSE_LOCATION :
            return {
                ...state,
                chooseCity: {},
                chooseProvince: {},
                search: ''
            }

        case SET_CHOOSE_PROVINCE :
            return {
                ...state,
                chooseProvince: action.payload
            }

        case SET_CHOOSE_CITY :
            return {
                ...state,
                chooseCity: action.payload
            }

        default: 
            return state

    }

}

export default locationReducer