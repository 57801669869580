// for searching locations
export const getCountFilteredApplied = (fields) => {

    let count = 0

    Object.keys(fields).map(item => {
        
        if(fields[item].applied)
            count += 1

    })

    return count
}