import { useState } from "react"
import CalendarTitleDays from "./TitleDays"

const CalendarTitleSection = () => {

    const [daysOfWeek] = useState([
        {longName: 'شنبه', smallName: 'ش'},
        {longName: 'یکشنبه', smallName: 'ی'},
        {longName: 'دوشنبه', smallName: 'د'},
        {longName: 'سه‌شنبه', smallName: 'س'},
        {longName: 'چهارشنبه', smallName: 'چ'},
        {longName: 'پنجشنبه', smallName: 'پ'},
        {longName: 'جمعه', smallName: 'ج', dayOff: true},
    ])

    return (
        <>
            { daysOfWeek.map((item, index) => 
                <CalendarTitleDays 
                    key={index}
                    longName={item.longName}
                    smallName={item.smallName}
                    dayOff={item.dayOff}
                />  
            )}
        </>
    )

}

export default CalendarTitleSection