import { useSelector } from "react-redux"
import VerifyTitleTimer from "./Timer"

const VerifyTitle = () => {

    const mobile = useSelector(state => state.auth.mobile)

    return (
        <div className="mb-4 flex items-center justify-center flex-col">
           
            <VerifyTitleTimer />

            <h1 className="font-medium text-gray-800 mb-3 text-lg leading-8"> کد ورود شما برایتان پیامک شده است!</h1>
           
            { mobile &&
                <div className="mb-3 flex items-center justify-center text-gray-500 gap-3 font-medium">
                    <span>به شماره:</span>
                    <div className="bg-gray-100 text-gray-500 rounded-lg px-2 py-0.5 border border-gray-100 flex items-center gap-2">
                        <span className="mt-1 inline-block">{mobile.slice(7)} </span>   
                        <span className="mt-1 inline-block">{mobile.slice(4, 7)} </span>   
                        <span className="mt-1 inline-block">{mobile.slice(0, 4)} </span>   
                    </div> 
                </div> 
            }

            <p className="text-gray-600"> لطفا کد ارسال شده را در کادر زیر وارد نمایید </p>

        </div>
    )

}

export default VerifyTitle