import { useState } from "react"

const SelectPositionList = (props) => {

    const [positions, setPositions] = useState([
        'موکل',
        'وکیل',
        'قاضی',
        'دادستان',
        'نماینده حقوقی',
        'کارآموز وکالت',
        'بازپرس',
        'دادیار'
    ])

    return (
        <ul className="mt-4">

            {positions.map(item => 
                <li
                    key={'position_'+item}
                    className="text-gray-600 cursor-pointer py-4 first:pt-0 last:pb-0 border-b last:border-b-0 border-b-gray-200 flex items-center justify-between"
                    onClick={() => props.onSelectPosition(item)}
                >
                    {item}

                    <span className="text-sm text-primary"> انتخاب </span>
                </li>    
            )}

        </ul>
    )

}

export default SelectPositionList