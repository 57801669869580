import { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import { Link } from "react-router-dom"

import Button from "../../../../UI/Button"
import OtpInput from "../../../../UI/OtpInput"

const VerifyForm  = (props) => {

    const loading = useSelector(state => state.auth.loading)
    const [otp, setOtp] = useState('')
    const [requestedServer, setRequestedServer] = useState(false)

    useEffect(() => {

        window.scrollTo({top: 0})

    }, [])

    const updateOtpValue = (value) => {

        setOtp(value)

        // if otp is fill and its my first request come do automat
        if(value.length === 5 && !requestedServer){
            setRequestedServer(true)
            props.onSubmit(value)
        }

    }

    const submitForm = (event) => {
        event.preventDefault()

        if(otp.length !== 5) return;

        props.onSubmit(otp)
    }

    return (
        <form onSubmit={submitForm} className="flex flex-col">

            <OtpInput 
                inputCount={5}
                onUpdateValues={updateOtpValue}
            />

            <p className="text-gray-500 text-sm mt-8 mb-8">
                شماره را اشتباه وارد کردم! 
                <Link to={'/auth/login'} className="text-primary"> ویرایش شماره </Link>
            </p>

            <Button onClick={submitForm} className="w-full" disabled={otp.length !== 5}>
                { !loading ? 'ورود' :     
                    <svg className='w-6 h-6 fill-white animate-spin' viewBox="0 0 1024 1024">
                        <path d="M988 548c-19.9 0-36-16.1-36-36 0-59.4-11.6-117-34.6-171.3a440.45 440.45 0 0 0-94.3-139.9 437.71 437.71 0 0 0-139.9-94.3C629 83.6 571.4 72 512 72c-19.9 0-36-16.1-36-36s16.1-36 36-36c69.1 0 136.2 13.5 199.3 40.3C772.3 66 827 103 874 150c47 47 83.9 101.8 109.7 162.7 26.7 63.1 40.2 130.2 40.2 199.3.1 19.9-16 36-35.9 36z" />
                    </svg> 
                }
            </Button>

        </form>
    )

}

export default VerifyForm