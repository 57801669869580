import { useState } from "react"
import { ReactSVG } from "react-svg"

const LoadingDot = () => {

    const [show, setShow] = useState(true)

    const hideLoading = () => {
        setShow(false)
    }

    if(show)
        return (
            <>
                <div onClick={hideLoading} className="fixed top-0 left-0 w-full h-full flex items-center justify-center bg-black/50 z-50">

                    <div className="w-24 flex items-center justify-center p-2 py-3 bg-white rounded-2xl">
                        <ReactSVG src={`${process.env.PUBLIC_URL}/assets/images/icons/loading-dot.svg`} className="w-14 text-primary/70" />
                    </div>

                </div>
            </>
        )

}

export default LoadingDot