import { useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import { format, add, parse, sub } from "date-fns-jalali"

import AddEventFormCustomTimeListColck from "./Clock"
import moment from "jalali-moment"

const AddEventFormCustomTimeList = (props) => {

    const [options] = useState([
        {name: 'moment', text: 'در لحظه'},
        {name: '1 hour', text: 'یک ساعت قبل'},
        {name: '2 hours', text: 'دو ساعت قبل'},
        {name: '3 hours', text: 'سه ساعت قبل'},
        {name: '1 day', text: 'یک روز قبل'},
    ])
    const [days, setDays] = useState([])

    const [date, setDate] = useState(props.persianDate)
    const [time, setTime] = useState('00:00')

    const params = useParams()

    useEffect(() => {

        fillDays()

    }, [props.persianDate])

    const fillDays = () => {

        if(params.date){
            let current = new Date(params.date.split('-').join('/'))

            let before1Days = sub(current, {days: 1})
            let before2Days = sub(before1Days, {days: 1})

            const detailDateCurrent =  current.toLocaleDateString('en', {month: '2-digit', day: '2-digit', year: 'numeric'}) 
            const detailDate1Days = before1Days.toLocaleDateString('en', {month: '2-digit', day: '2-digit', year: 'numeric'})
            const detailDate2Days = before2Days.toLocaleDateString('en', {month: '2-digit', day: '2-digit', year: 'numeric'})

            const curretText = current.toLocaleString('fa', {weekday: 'narrow'});
            const before1Text = before1Days.toLocaleString('fa', {weekday: 'narrow'});
            const before2Text = before2Days.toLocaleString('fa', {weekday: 'narrow'});

            current = format(current, 'dd', { jalali: true })
            before1Days = format(before1Days, 'dd', { jalali: true })
            before2Days = format(before2Days, 'dd', { jalali: true })

            const dates = [
                {number: current, detail: moment( `${detailDateCurrent.slice(6)}-${detailDateCurrent.slice(0, 2)}-${detailDateCurrent.slice(3, 5)}` ), text: curretText},
                {number: before1Days, detail: moment( `${detailDate1Days.slice(6)}-${detailDate1Days.slice(0, 2)}-${detailDate1Days.slice(3, 5)}` ), text: before1Text},
                {number: before2Days, detail: moment( `${detailDate2Days.slice(6)}-${detailDate2Days.slice(0, 2)}-${detailDate2Days.slice(3, 5)}` ), text: before2Text},
            ]

            setDays(dates)
            
        }
        else {
            let current = parse(props.persianDate,  'yyyy/MM/dd', new Date())
            let before1Days = sub(current, {days: 1})
            let before2Days = sub(before1Days, {days: 1})

            const curretText = current.toLocaleString('fa', {weekday: 'narrow'});
            const before1Text = before1Days.toLocaleString('fa', {weekday: 'narrow'});
            const before2Text = before2Days.toLocaleString('fa', {weekday: 'narrow'});

            const detailDateCurrent =  current.toLocaleDateString('en', {month: '2-digit', day: '2-digit' , year: 'numeric'}) 
            const detailDate1Days = before1Days.toLocaleDateString('en', {month: '2-digit', day: '2-digit' , year: 'numeric'})
            const detailDate2Days = before2Days.toLocaleDateString('en', {month: '2-digit', day: '2-digit' , year: 'numeric'})

            current = format(current, 'dd', { jalali: true })
            before1Days = format(before1Days, 'dd', { jalali: true })
            before2Days = format(before2Days, 'dd', { jalali: true })

            const dates = [
                {number: current, detail: moment( `${detailDateCurrent.slice(6)}-${detailDateCurrent.slice(0, 2)}-${detailDateCurrent.slice(3, 5)}` ), text: curretText},
                {number: before1Days, detail: moment( `${detailDate1Days.slice(6)}-${detailDate1Days.slice(0, 2)}-${detailDate1Days.slice(3, 5)}` ), text: before1Text},
                {number: before2Days, detail: moment( `${detailDate2Days.slice(6)}-${detailDate2Days.slice(0, 2)}-${detailDate2Days.slice(3, 5)}` ), text: before2Text},
            ]

            setDays(dates)
        }

        getClock()
    }

    const getClock = () => {
        if(props.clock && !props.alaram_at.clock)
            setTime( props.clock )
        
        else if(props.alaram_at.clock)
            setTime(props.alaram_at.clock)

        if(props.alaram_at.date){
            setDate(props.alaram_at.date)
        }

    }

    const setDateEvent = (item) => {
        if(!props.persianDate) return

        const PersianDate = item.detail.format('jYYYY/jMM/jDD')
        const EnglishDate = item.detail.format('YYYY/MM/DD')

        setDate(PersianDate)
        props.onUpdateAlaramDate(PersianDate, !props.alaram_at.clock ? props.clock : props.alaram_at.clock, '', EnglishDate)
    }

    const setHourOfTime = ({hour}) => {
        const times = `${hour}:${time.split(':')[1]}` 

        setTime(times.trim())
        
        if(props.alaram_at.date)
            props.OnUpdateAlaramClock(times.trim(), '', props.alaram_at.englishDate)
        else
            props.onUpdateAlaramDate(date, times.trim(), '', props.alaram_at.englishDate)
    }

    const setMinOfTime = ({min}) => {
        const times = time.split(':')[0] + ':' + min

        setTime(times.trim())

        if(props.alaram_at.date)
            props.OnUpdateAlaramClock(times.trim(), '', props.alaram_at.englishDate)
        else
            props.onUpdateAlaramDate(date, times.trim(), '',  props.alaram_at.englishDate)
    }

    const selectEventTime = (data) => {

        // work on this.
        // 5 suggest time

        if(!params.date && !props.persianDate) return

        let current = parse(props.persianDate,  'yyyy/MM/dd', new Date())
        current = moment(current).format('yyyy/MM/DD')

        if(data.suggestTime.name === 'moment'){
            const date = new Date( current.split('-').join('/') )

            setTime( props.clock )
            setDate( moment(date).format('YYYY/MM/DD') )
            
            props.onUpdateAlaramDate( props.persianDate, props.clock, data.suggestTime.text, moment(date).format('YYYY/MM/DD'))
        }

        else if(data.suggestTime.name.includes('hour')){
            const date = format(new Date(current.split('-').join('/')), 'yyyy-MM-dd', { jalali: true })
            let englishDate = parse(date, 'yyyy-MM-dd', new Date())
            englishDate.setHours( Number(props.clock.split(':')[0]) )
            englishDate.setMinutes( Number(props.clock.split(':')[1]))

            if(data.suggestTime.name === '1 hour'){
                englishDate = sub(englishDate, {hours: 1})
            }
            else if(data.suggestTime.name === '2 hours'){
                englishDate = sub(englishDate, {hours: 2})
            }
            else if(data.suggestTime.name === '3 hours'){
                englishDate = sub(englishDate, {hours: 3})
            }

            const persianDate = moment(englishDate).format('jYYYY/jMM/jDD')

            setDate( persianDate )

            const standardClock = `${englishDate.getHours() > 9 ? englishDate.getHours() : '0' + englishDate.getHours()}:${englishDate.getMinutes() > 9 ? englishDate.getMinutes() : '0' + englishDate.getMinutes()}`

            setTime( standardClock )

            props.onUpdateAlaramDate( persianDate, standardClock, data.suggestTime.text, moment(englishDate).format('YYYY/MM/DD'))
        }

        else if(data.suggestTime.name === '1 day'){
            const date = format(new Date(current.split('-').join('/')), 'yyyy-MM-dd', { jalali: true })
            let englishDate = parse(date, 'yyyy-MM-dd', new Date())
            englishDate.setHours(props.clock.split(':')[0])
            englishDate.setMinutes(props.clock.split(':')[1])

            englishDate = sub(englishDate, {days: 1})

            let persianDate = moment(englishDate).format('jYYYY/jMM/jDD')
            setDate( persianDate )


            const standardClock = `${englishDate.getHours() > 9 ? englishDate.getHours() : '0' + englishDate.getHours()}:${englishDate.getMinutes() > 9 ? englishDate.getMinutes() : '0' + englishDate.getMinutes()}`

            setTime( standardClock )

            props.onUpdateAlaramDate( persianDate, standardClock, data.suggestTime.text, moment(englishDate).format('YYYY/MM/DD'))
        }

        props.onHideModal()
    }

    const openCustomBox = () => {
        props.onSetCustomState(!props.customState) 

        if( (!props.alaram_at.label && !props.alaram_at.englishDate) || (props.alaram_at.label && props.alaram_at.englishDate) ){
            
            if(props.alaram_at.date)
                props.OnUpdateAlaramClock( props.clock.trim(), '', days[0].detail.format('YYYY/MM/DD'))
            else
                props.onUpdateAlaramDate(date, props.clock.trim(), '',  days[0].detail.format('YYYY/MM/DD'))
            
            setTime( props.clock.trim() )
        }

    }

    if(!props.customState){
        return (
            <ul className="mt-4">
                
                { options.map(item => 
                    
                    <li
                        key={item.name}
                        className="text-gray-600 cursor-pointer py-4 first:pt-0 last:pb-0 border-b last:border-b-0 border-b-gray-200 flex items-center justify-between"
                        onClick={() => selectEventTime({suggestTime: item})}
                    >
                        {item.text}
    
                        <span className="text-sm text-primary"> انتخاب </span>
                    </li> 
    
                )}
    
                <li
                    className="text-gray-600 select-none cursor-pointer py-4 first:pt-0 last:pb-0 border-b last:border-b-0 border-b-gray-200 flex items-center justify-between"
                    onClick={openCustomBox}
                >
                    سفارشی
    
                    <span className="text-sm text-primary"> انتخاب </span>
                </li> 
    
            </ul>
        )
    }

    return (
        <div className="mt-4">

            <h4 className="mb-4 font-medium"> تنظیم تاریخ هشدار:  </h4>

            <ul className="flex flex-wrap gap-8 mb-8">
                
            
                { days.map(item => 
                    <li
                        key={item.number + '-day'}
                        className={` cursor-pointer w-14 h-14 flex-col rounded-xl flex items-center justify-between duration-300 bg-gray-100 text-center border border-gray-300 ${Number(item.number) === Number(date.split('/')[2]) ? 'bg-primary text-white' : 'bg-gray-100 text-gray-600'}`}
                        onClick={ () => setDateEvent(item) }
                    >
                        <span className={`text-sm pt-1 ${item.text === 'ج' && 'text-rose-500'}`}> {item.text} </span>
                        <span className="text-center w-full h-full flex items-center justify-center mt-1"> {item.number} </span>
    
                    </li> 
                )}

            </ul>

            <hr className="mb-8" />

            <div className="mb-4 font-medium flex items-center flex-wrap gap-4 justify-between">

                <h4 className=""> تنظیم ساعت هشدار:  </h4>

                <div className="flex items-center gap-2 text-primary font-bold">
                    <span>{time.split(':')[1]}</span>
                    <span>:</span>
                    <span>{time.split(':')[0]}</span>
                </div>

            </div>

            <AddEventFormCustomTimeListColck 
                onSetHourOfTime={setHourOfTime}
                onSetMinOfTime={setMinOfTime}
                time={time}
            />

        </div>
    )

}

export default AddEventFormCustomTimeList