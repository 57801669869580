import { useEffect, useRef, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { RESET_FILEDS_OF_FILTER, SET_FILEDS_OF_FILTER, SET_FILTER_FOR_CASES, SHOW_FILTER_SECTION_CASES } from "../../../redux/action/cases/casesType"
import { ReactSVG } from "react-svg"
import ModalBottom from "../../UI/ModalBottom"
import CasesFilterSectionContent from "./Content"
import { getCountFilteredApplied } from "../../../redux/selector/cases"
import CasesFilterSectionCategoryList from "./Content/Category/List"
import CasesFilterSectionJudicialList from "./Content/Judicial/List"
import CasesFilterSectionStepList from "./Content/Step/List"
import CasesFilterSectionStateList from "./Content/State/List"

const CasesFilterSection = (props) => {

    const dispatch = useDispatch()
    const showFilterSection = useSelector(state => state.cases.showFilterSection)
    const filterSectionLoading = useSelector(state => state.cases.filterSectionLoading)
    const filterCount = useSelector( state => getCountFilteredApplied(state.cases.fieldsFilter) )
    const allCases = useSelector( state => state.cases.allCases)

    const [activeItemState, setActiveItemState] = useState(null)
    const [subActiveItemState, setSubActiveItemState] = useState(false)
    const [showingModal, setShowingModal] = useState(false)

    const modalRef = useRef()

    useEffect(() => {

        return () => {
            if(document.body.classList.contains('modal-opened'))
                document.body.classList.remove('modal-opened')
        }

    }, [])

    const setShowModal = (value) => {

        dispatch({
            type: SHOW_FILTER_SECTION_CASES,
            payload: value
        })

        if(!value){
            document.body.classList.remove('modal-opened')
        }

    }

    const hideModal = () => {
        setShowingModal(false)

        setTimeout(() => {
            
            dispatch({
                type: SHOW_FILTER_SECTION_CASES,
                payload: false
            })
            
        }, 300);

        document.body.classList.remove('modal-opened')
    }

    const resetDataAndHideModal = () => {
        hideModal()

        // do reset work
        dispatch({
            type: RESET_FILEDS_OF_FILTER,
        })
        
        dispatch({
            type: SET_FILTER_FOR_CASES,
            payload: allCases
        })

    }

    const changeActiveItemState = (value) => {
        
        modalRef.current.scrollTo({top: 0})
    
        setActiveItemState(value)

    }

    const backModal = () => {
        if(!activeItemState){
            hideModal()
        }
        else if(!subActiveItemState){
            setActiveItemState(null)
        }
        else if(subActiveItemState){
            setSubActiveItemState(false)
        }
    }

    return (
        <ModalBottom
            show={showFilterSection}
            onSetShow={setShowModal}
            className="overflow-y-auto no-scrollbar"
            showing={showingModal}
            onSetShowing={setShowingModal}
            refEl={modalRef}
        >
            
            <div className="sticky top-0 left-0 flex flex-col bg-white w-full z-[2] border-b border-b-slate-200">

                <div className="py-2 bg-white z-[1] mb-2">
                    <div className="mx-auto w-20 h-2 bg-gray-200 rounded-2xl"></div>
                </div>

                <div className="flex items-center justify-between gap-4 mb-4">
                        
                    <button onClick={backModal} type="button" className="w-10 group">
                        
                        <ReactSVG src={`${process.env.PUBLIC_URL}/assets/images/icons/back.svg`} className="w-5 text-gray-600 hover:text-primary duration-300 rotate-180 mr-1.5 group-disabled:text-gray-400 group-disabled:opacity-70" />

                    </button>

                    <div className="flex items-center gap-0.5">

                        <span className="font-medium flex items-center gap-1.5">
                            
                            <ReactSVG src={`${process.env.PUBLIC_URL}/assets/images/icons/filter.svg`} className="w-[18px] text-gray-700" />

                            { filterSectionLoading ? 
                                
                                <ReactSVG src={`${process.env.PUBLIC_URL}/assets/images/icons/loading-dot.svg`} className="w-9 text-gray-700" />
                                
                                :

                                filterCount ? <div> <span className="text-primary"> {filterCount} </span> فیلتر اعمال شده </div> : 'انتخاب نشده'
                            }

                        </span>

                        { filterCount >= 1 && !filterSectionLoading &&
                            
                            <button type="button" onClick={resetDataAndHideModal} className="h-full focus:outline-none outline-none w-8 flex justify-end">
                                <ReactSVG 
                                    src={`${process.env.PUBLIC_URL}/assets/images/icons/delete.svg`}
                                    className="w-5 text-rose-600"
                                />
                            </button>

                        }

                    </div>

                </div>

            </div>

            { !activeItemState && 
                
                <CasesFilterSectionContent 
                    count={filterCount}
                    onSetActiveItemState={changeActiveItemState}
                />

            }

            { activeItemState === 'categories' &&

                <CasesFilterSectionCategoryList 
                    onSetSubActiveItemState={setSubActiveItemState}
                    onSetActiveItemState={setActiveItemState}
                    subActiveItemState={subActiveItemState}
                />
                
            }

            { activeItemState === 'jurisdiction' && 
                
                <CasesFilterSectionJudicialList 
                    onSetSubActiveItemState={setSubActiveItemState}
                    onSetActiveItemState={setActiveItemState}
                    subActiveItemState={subActiveItemState}
                />  

            }

            { activeItemState === 'step' && 
                
                <CasesFilterSectionStepList 
                    onSetSubActiveItemState={setSubActiveItemState}
                    onSetActiveItemState={changeActiveItemState}
                    subActiveItemState={subActiveItemState}
                />  

            }

            { activeItemState === 'state' && 
                
                <CasesFilterSectionStateList 
                    onSetSubActiveItemState={setSubActiveItemState}
                    onSetActiveItemState={changeActiveItemState}
                    subActiveItemState={subActiveItemState}
                />  

            }

        </ModalBottom>
    )

}

export default CasesFilterSection