import { useState } from 'react'
import Button from '../Button'
import Input from '../Input'

const Form = (props) => {

    const [valid, setValid] = useState(false)

    const updateField = (event) => {
        const field = props.fields.find(item => item.id === event.target.id)
        
        if(event.target.value.length <= field.max){            

            field.value = event.target.value

            props.onSetFields([...props.fields])
            
            validateFields()
        }
        
    }

    const validateFields = () => {
        let valid = true

        props.fields.map(item => {
            
            const { length, min, required } = item.validate
            
            if(!required && item.value === '')
                return            
            
            if(length && item.value.length !== length){
                valid = false
                return
            }
    
            if(min && item.value.length < min){
                valid = false
                return
            }

        })

        setValid(valid)
    }

    const submitForm = (event) => {
        event.preventDefault()

        if(!valid) return

        props.onSubmit()
    }

    // ---- render -----
    const renderFields = () => {
        const fields = props.fields

        if(fields.length >= 1){
            return fields.map(item => 
                <Input 
                    key={item.id}
                    id={item.id}
                    label={item.label}
                    count={item.count}
                    max={item.max}
                    value={item.value}
                    placeholder={item.placeholder}
                    onChange={updateField}
                />
            )
        }

    }

    return (
        <form onSubmit={submitForm} className={`flex flex-col gap-5 ${props.className}`}>

            { renderFields() }

            <Button 
                disabled={!valid}
            > 
                { !props.loading ? props.buttonText :     
                    <svg className='w-6 h-6 fill-white animate-spin' viewBox="0 0 1024 1024">
                        <path d="M988 548c-19.9 0-36-16.1-36-36 0-59.4-11.6-117-34.6-171.3a440.45 440.45 0 0 0-94.3-139.9 437.71 437.71 0 0 0-139.9-94.3C629 83.6 571.4 72 512 72c-19.9 0-36-16.1-36-36s16.1-36 36-36c69.1 0 136.2 13.5 199.3 40.3C772.3 66 827 103 874 150c47 47 83.9 101.8 109.7 162.7 26.7 63.1 40.2 130.2 40.2 199.3.1 19.9-16 36-35.9 36z" />
                    </svg> 
                } 
            </Button>

        </form>
    )

}

export default Form