export const SET_LOADING_MESSAGES = 'SET_LOADING_MESSAGES'
export const UNSET_LOADING_MESSAGES = 'UNSET_LOADING_MESSAGES'

export const SET_FILTER_MESSEAGES = 'SET_FILTER_MESSEAGES'

export const SET_FAILURE_MESSAGES = 'SET_FAILURE_MESSAGES'

export const SET_LIST_MESSAGES = 'SET_LIST_MESSAGES'
export const ADD_TO_LIST_MESSAGES = 'ADD_TO_LIST_MESSAGES'

export const SET_LOADING_DELETE = 'SET_LOADING_DELETE'
export const SET_FAILURE_DELETE = 'SET_FAILURE_DELETE'
export const SET_SUCCESS_DELETE = 'SET_SUCCESS_DELETE'

export const SEEN_MESSAGE_ITEM_COUNT = 'SEEN_MESSAGE_ITEM_COUNT'

export const RESTE_ALL_MESSAGES_LIST_DATA_APP = 'RESTE_ALL_MESSAGES_LIST_DATA_APP'