import { SET_MENU_LIST } from "./mainType"

export const getMainMenuList = () => {

    return (dispatch) => {
        
        // manual set - but in future this load --> dynamic
        const menu = [
            {
                id: 'cases-menu-item',
                text: 'پرونده‌ها',
                icon: process.env.PUBLIC_URL + '/assets/images/icons/cases.svg',
                path: '/law-suits'
            },
            {
                id: 'events-menu-item',
                text: 'رویداد‌ها',
                icon: process.env.PUBLIC_URL + '/assets/images/icons/events.svg',
                path: '/events'
            },
            {
                id: 'home-menu-item',
                text: 'خانه',
                icon: process.env.PUBLIC_URL + '/assets/images/icons/home.svg',
                path: '/'
            },
            {
                id: 'notes-menu-item',
                text: 'یادداشت‌ها',
                icon: process.env.PUBLIC_URL + '/assets/images/icons/notes.svg',
                path: '/notes'
            },
            {
                id: 'messages-menu-item',
                text: 'گفتگو',
                icon: process.env.PUBLIC_URL + '/assets/images/icons/messages.svg',
                path: '/messages'
            }
        ]

        dispatch({
            type: SET_MENU_LIST,
            payload: menu
        })

    }

}