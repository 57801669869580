import { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { ReactSVG } from "react-svg"

import { SET_EVENTS_CALENDAR_STATE } from "../../redux/action/events/eventsType"

import Header from "../../components/Header"
import HeaderSearchBtn from "../../components/Header/Search/Btn"
import EventsLayout from "../../components/Events/Layout"
import { useLocation } from "react-router-dom"

const Events = () => {

    const dispatch = useDispatch()
    const calendarState = useSelector(state => state.events.eventsCalendarState)

    const location = useLocation()

    useEffect(() => {

        setCalendarStateByBaseLocaleData()

        window.scrollTo({top: 0})

    }, [])

    const setCalendarStateByBaseLocaleData = () => {
        let data = localStorage.getItem('eventsCalenarState')

        if(data){
            data = JSON.parse(data) 

            if(data !== calendarState){
                
                dispatch({
                    type: SET_EVENTS_CALENDAR_STATE,
                    payload: data
                })

            }

        }
        else{
            localStorage.setItem('eventsCalenarState', true)
        }

    }

    const changeCalendarState = () => {
        
        dispatch({
            type: SET_EVENTS_CALENDAR_STATE,
            payload: !calendarState
        })

        localStorage.setItem('eventsCalenarState', !calendarState)
    }

    return (
        <>
            <Header 
                title={!location.state?.forAddCase ? 'رویداد‌ها' : 'انتخاب تاریخ رویداد'}
                backBtn={!location.state?.forAddCase ? false : true}
            >

                { !location.state?.forAddCase && 
                    <>
                        <HeaderSearchBtn />
                        
                        <button onClick={changeCalendarState} className="header-btn">

                            { !calendarState ? 
                            
                                <ReactSVG src={`${process.env.PUBLIC_URL}/assets/images/icons/calendar-3.svg`} className="w-5" />
                                :

                                <ReactSVG src={`${process.env.PUBLIC_URL}/assets/images/icons/list.svg`} className="w-[22px]" />
                            }

                        </button>
                    </>
                }


            </Header>

            <main className="main-content">

                <EventsLayout 
                    calendar={calendarState}
                />

            </main>
        </>
    )

}

export default Events