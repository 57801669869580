export const SET_EVENTS_CALENDAR_STATE = 'SET_EVENTS_CALENDAR_STATE'

export const SET_LOADING_EVENTS = 'SET_LOADING_EVENTS'
export const SET_FAILURE_EVENTS = 'SET_FAILURE_EVENTS'
export const SET_EVENTS_LIST = 'SET_EVENTS_LIST'
export const SET_EVENTS_LIST_SORTED = 'SET_EVENTS_LIST_SORTED'
export const SET_PAGINATION_EVENTS_LIST = 'SET_PAGINATION_EVENTS_LIST'
export const SET_LOADMORE_LOADING_EVENTS = 'SET_LOADMORE_LOADING_EVENTS'
export const SET_LOADMORE_ERROR_EVENTS = 'SET_LOADMORE_ERROR_EVENTS'
export const ADD_NEW_EVENTS_TO_LIST = 'ADD_NEW_EVENTS_TO_LIST'
export const ADD_ITEM_TO_LIST_EVENTS = 'ADD_ITEM_TO_LIST_EVENTS'

export const SET_DATE_EVENTS = 'SET_DATE_EVENTS'
export const SET_INITIAL_DATE_EVENTS = 'SET_INITIAL_DATE_EVENTS'
export const SET_START_END_DATE_EVENTS = 'SET_START_END_DATE_EVENTS'

export const SET_CALENDAR_ITEMS  = 'SET_CALENDAR_ITEMS'
export const SET_LOADING_CALENDAR = 'SET_LOADING_CALENDAR'
export const SET_FAILURE_CALENDAR = 'SET_FAILURE_CALENDAR'
export const ADD_TO_CALENDAR_ITEMS = 'ADD_TO_CALENDAR_ITEMS'

export const SET_LOADING_CALENDAR_DATE_EVENTS = 'SET_LOADING_CALENDAR_DATE_EVENTS'
export const SET_FAILURE_CALENDAR_DATE_EVENTS = 'SET_FAILURE_CALENDAR_DATE_EVENTS'
export const SET_DAY_EVENTS_TO_EVENTS_CALENDAR = 'SET_DAY_EVENTS_TO_EVENTS_CALENDAR'
export const ADD_NEW_EVENTS_TO_DATE_CALENDAR = 'ADD_NEW_EVENTS_TO_DATE_CALENDAR'

export const SET_FILTER_EVENTS = 'SET_FILTER_EVENTS'
export const SET_FILTER_ERROR_EVENTS = 'SET_FILTER_ERROR_EVENTS'
export const SET_FILTER_SORT_EVENTS = 'SET_FILTER_SORT_EVENTS'
export const ADD_EVENTS_TO_FILTER_EVENTS = 'ADD_EVENTS_TO_FILTER_EVENTS'

export const SET_CAN_I_SEND_DATA_EVENTS = 'SET_CAN_I_SEND_DATA_EVENTS'
export const SET_ENTERED_DATA_EVENTS = 'SET_ENTERED_DATA_EVENTS'
export const SET_FIELDS_EVENTS = 'SET_FIELDS_EVENTS'
export const SET_HAVE_CHANGE_IN_STORAGE = 'SET_HAVE_CHANGE_IN_STORAGE'

export const DELETE_ITEM_EVENT = 'DELETE_ITEM_EVENT'
export const DELETE_ITEM_EVENT_DAY = 'DELETE_ITEM_EVENT_DAY'
export const SET_DELETE_ITEM_STATUS_EVENTS = 'SET_DELETE_ITEM_STATUS_EVENTS'

export const SET_EVENT_DATA_LOADING = 'SET_EVENT_DATA_LOADING'
export const SET_EVENT_DATA_VALUES = 'SET_EVENT_DATA_VALUES'
export const SET_EVENT_DATA_ERROR = 'SET_EVENT_DATA_ERROR'
export const EDIT_ITEM_EVENT = 'EDIT_ITEM_EVENT'

export const SET_SERVER_DATE_OBJECT = 'SET_SERVER_DATE_OBJECT'
export const SET_COMPUTER_DIFFERRNTTIME = 'SET_COMPUTER_DIFFERRNTTIME'

export const RESTE_ALL_EVENTS_DATA_APP = 'RESTE_ALL_EVENTS_DATA_APP'