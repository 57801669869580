import { useEffect, useState } from "react"
import { useLocation, useNavigate } from "react-router-dom"
import { ReactSVG } from "react-svg"

const Offline = () => {

    const [loading, setLoading] = useState()
    const [inProgress, setInProgress] = useState(false)

    const location = useLocation()
    const navigate = useNavigate()

    useEffect(() => {

        if(navigator.onLine){
            navigate( location?.state?.url ? location.state.url : '/', {replace: true, state: {dontBack: true}})
        }

    }, [])

    const backToOnlineStatus = () => {

        if(inProgress)
            return

        setInProgress(true)
        setLoading(true)

        let tryAgain = setInterval(() => {
            
            if(navigator.onLine){

                navigate( location?.state?.url ? location.state.url : '/', {replace: true, state: {dontBack: true}})
                
                setLoading(false)
                setInProgress(false)
            }

        }, 300);

        setTimeout(() => {

            setLoading(false)
            setInProgress(false)
            clearInterval(tryAgain)
            tryAgain = null

        }, 1000)

    }

    return (
        <div className="fixed flex items-center flex-col gap-6 justify-center left-0 top-0 w-full h-screen bg-primary">

            <div className="w-20 h-20 flex items-center justify-center rounded-xl ">
                <img className="" src={`${process.env.PUBLIC_URL}/assets/images/logo/logo.png`} alt="" />
            </div>

            <h1 className="font-medium text-2xl mb-20 text-white drop-shadow-2xl shadow-second"> وکیل‌سان </h1>


            <div className="absolute flex-col gap-4 flex items-center justify-center left-0 w-full bottom-20">

                <span className="text-gray-100"> خطا در اتصال به اینترنت... </span>

            </div>

            <button onClick={backToOnlineStatus} disabled={inProgress} className="absolute bottom-0 w-full h-12 bg-second text-white flex justify-center items-center gap-2 !outline-none">
                    
                تلاش مجدد

                <ReactSVG
                    className={`w-4 !duration-100 ${loading ? 'animate-spin' : ''}`}
                    src={`${process.env.PUBLIC_URL}/assets/images/icons/turn-back.svg`}
                />

            </button>

        </div>
    )

}

export default Offline