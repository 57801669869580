import { useLocation, useNavigate } from "react-router-dom"
import { format, parse } from "date-fns-jalali";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import moment from "jalali-moment";

const CalendarListItem = (props) => {

    const severTimeObj = useSelector(state => state.events.serverDateObject)
    const computerDifferentTime = useSelector(state => state.events.computerDifferentTime)

    const [thisMonth, setThisMonth] = useState()
    const [thisDay, setThisDay] = useState()

    const location = useLocation()

    const navigate = useNavigate()

    useEffect(() => {

        if(props.date){

            if( severTimeObj?.year ){
        
                const time = new Date(severTimeObj?.year, severTimeObj.month, severTimeObj.day, severTimeObj.hours + 3, severTimeObj.mins + 30, severTimeObj.seconds)

                setThisMonth( time.getMonth() ==  new Date(new Date(props.date) + computerDifferentTime).getMonth() )
                setThisDay( time.getDate() ==  severTimeObj.day )
            }
            else {
                setThisMonth( new Date().getMonth() ==  props.date.getMonth() )
                setThisDay( new Date().getDate() ==  props.today )        
            }

        }

    }, [props.date, severTimeObj])
    
    if(!props.text){
        return (<div></div>)
    }

    const goToEeventPage = (event) => {
        let persianDay = format(props.date, 'yyyy-MM-', { jalali: true })
        
        if(Number(props.text) >= 10)
            persianDay += props.text
        else
            persianDay += '0'+props.text
            
        const date = parse(persianDay, 'yyyy-MM-dd', new Date())

        const year = date.getFullYear()
        const month = date.getMonth()
        const day = date.getDate()

        const link = `/events/date/${year}-${Number(month) <= 8 ? '0' + (Number(month) + 1) : month + 1}-${day <= 9 ? '0' + day : day}`

        if(!location?.state?.forAddCase)
            navigate(link)
        else{
            navigate(`${link}/add`, {state: location.state})
            window.scrollTo({top: 0})
        }
    }

    return (
        <div onClick={goToEeventPage} className={`${(props.text + props.prefixDays === 6 ) || (props.text + props.prefixDays === 13 ) || (props.text + props.prefixDays === 20 ) || (props.text + props.prefixDays === 27 ) || (props.text + props.prefixDays === 34 ) || (props.text + props.prefixDays === 41 ) || (props.holidy) ? 'text-rose-600 bg-rose-50 border-rose-200/70' : ''} relative select-none flex items-center justify-center md:w-12 md:h-12 xm:w-10 xm:h-10 2xm:w-9 2xm:h-9 4xm:w-9 4xm:h-9 5xm:w-8 5xm:h-8 w-7 h-7 ${ (props.text != props.today) ? 'bg-gray-200' : (thisDay && !thisMonth) ? `!border-primary !bg-white !text-primary` : (props.toYear == props?.date?.getFullYear() && (thisDay && thisMonth)) ? '!bg-primary !text-white !border-primary' : '!border-primary !text-primary'} border border-gray-300 shadow-custom-md rounded-md cursor-pointer mx-auto`}>
            <span className="translate-y-0.5 4xm:text-base text-sm">
                {props.text}
            </span>

            {props.count >= 1 &&
                <div className={`absolute md:-bottom-1.5 -bottom-2 -right-1.5 bg-rose-500 text-white md:w-6 md:h-6 xm:w-5 xm:h-5 2xm:w-5 2xm:h-5 ${props.count && props.count >= 10 && '5xm:w-[18px] 5xm:h-[18px]'} w-4 h-4 flex items-center justify-center rounded-full text-xs`}>
                    <span className="2xm:translate-y-[0.75px] translate-y-0.5 font-medium"> {props.count} </span>
                </div>
            }

        </div>
    )

}

export default CalendarListItem