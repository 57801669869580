const Button = ({className, ...props}) => {

    return (
        <button {...props} className={`btn disabled:!bg-opacity-50 relative before:left-0 before:top-0 before:h-full before:absolute before:w-0 before:bg-white/[0.07] hover:before:w-full before:duration-300 ${className}`}>
            {props.children}
        </button>
    )

}

export default Button