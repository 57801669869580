const LoginTitle = () => {

    return (
        <div className="mb-10 flex items-center justify-center flex-col">
           
            <h1 className="font-medium text-gray-800 mb-3 text-lg"> ورود موکل </h1>
           
            <p className="text-gray-600"> لطفا شماره موبایل خود را برای ارسال کد فعالسازی وارد کنید </p>
            
        </div>
    )

}

export default LoginTitle