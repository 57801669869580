const WelcomeTitle = () => {

    return (
        <div className="mb-6 text-center">

            <h1 className="font-medium text-gray-800 mb-3 text-lg"> به جمع ما خوش اومدی </h1>
           
            <p className="text-gray-600"> لطفا اطلاعات خواسته شده را تکمیل کنید </p>

        </div>
    )

}

export default WelcomeTitle