import { useState } from "react"
import CaseFinancialsItems from "./Items"
import { useSelector } from "react-redux"

import LoadingDot from "../../../../UI/LoadingDot"
import ToastCustom from "../../../../UI/ToastCustom"
import { ReactSVG } from "react-svg"


const CaseFinancials = ({financials, financials_total_income, financials_total_expense, money, data, ...props}) => {

    const deleteLoading = useSelector(state => state.finanicalCase.deleteLoading)
    const errorMessage = useSelector(state => state.finanicalCase.errorMessage)
    const successMessage = useSelector(state => state.finanicalCase.successMessage)

    const [activeTab, setActiveTab] = useState(0)

    return (
        <>

            <h3 className="font-medium text-lg mb-8">
                لیست تراکنش‌ها
            </h3>            

            <CaseFinancialsItems 
                activeTab={activeTab}
                financials={financials}
            /> 

            { deleteLoading && <LoadingDot /> }

            { (errorMessage)  && 
                
                <ToastCustom>
                    
                    <div className="flex items-center gap-2">
                        <ReactSVG
                            className="text-rose-500/90 4xm:w-8 w-6"
                            src={`${process.env.PUBLIC_URL}/assets/images/icons/info-box.svg`}
                        />

                        { errorMessage }

                    </div>

                </ToastCustom>

            }

            { successMessage && 

                <ToastCustom>
                                        
                    <div className="flex items-center gap-2">
                        
                        <ReactSVG
                            className="text-green-600 4xm:w-8 w-6"
                            src={`${process.env.PUBLIC_URL}/assets/images/icons/tick.svg`}
                        />

                        { successMessage }

                    </div>

                </ToastCustom>

            }

        </>
    )

}

export default CaseFinancials