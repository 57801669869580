import { memo, useEffect, useRef, useState } from "react"
import { ReactSVG } from "react-svg"
import { useCookies } from "react-cookie"
import { connect } from "react-redux"

import { DELETE_BASIC_LOCATION_DATA, SET_CHOOSE_PROVINCE } from "../../../redux/action/location/locationType"
import { SET_USER_LOCATION } from "../../../redux/action/profile/profileType"
import { getLocation } from "../../../redux/action/location/locationAction"

import ModalBottom from "../../UI/ModalBottom"
import SelectCitySearch from "./Search"
import SelectCityList from "./List"


const SelectCity = (props) => {

    const [showCityModal, setShowCityModal] = useState(false)
    const [showingCity, setShowingCity] = useState(false)

    const modalRef = useRef()
    
    const [cookies] = useCookies(['authToken'])

    useEffect(() => {
    
        if(props.list.length <= 0){
            props.getLocations(cookies.authToken)
        }

        if(props.location.city && props.location.city?.name !== props.profile.city?.name){
            props.resetTheProfileLocation({
                city: props.profile.city,
                province: props.profile.city
            })
        }

        return () => {
            // delete search, list, error, ... fields.
            props.deleteLocationsData()

            if(document.body.classList.contains('modal-opened'))
                document.body.classList.remove('modal-opened')
        }

    }, [])

    useEffect(() => {

        scrollToTopWhenProvinceSelected()

    }, [props.chooseProvince])

    useEffect(() => {

        closeModalWhenSelectedCity()

    }, [props.chooseCity])

    const openModal = () => {
        setShowCityModal(true)

        document.body.classList.add('modal-opened')
    }

    const backAction = () => {
        
        if(props.chooseProvince.name){
            props.deleteProvince()
        }

    }

    const scrollToTopWhenProvinceSelected = () => {
        if(props.chooseProvince.name){
            modalRef.current.scrollTo({top: 0})
        }
    }
    
    const closeModalWhenSelectedCity = () => {
        
        if(props.chooseCity.name){
            // come hide modal
            setShowingCity(false)

            setTimeout(() => {
                setShowCityModal(false)
            }, 300)

            document.body.classList.remove('modal-opened')
        }

    }

    return (
        <>
            <div className="flex flex-col gap-2.5">

                <span className={`text-gray-500 ${props.labelSize === 'sm' && 'text-sm'}`}> انتخاب شهر : </span>

                <button onClick={ openModal } type="button" className="flex items-center justify-between gap-4 flex-wrap bg-white p-3 text-right text-gray-800 rounded-xl shadow-custom-md">

                    <div className="flex items-center gap-3">
                    
                        <ReactSVG src="../assets/images/icons/location.svg" className={`w-5 text-primary ${(props.loading) && '!text-gray-400' }`} />

                        { !props.loading ? props.location?.city ? props.location?.city?.name : 'انتخاب نشده' 
                            :
                            <ReactSVG src="../assets/images/icons/loading-dot.svg" className="w-10 py-[7px] text-gray-400" /> 
                        }
                    
                    </div>

                    <div>
                        <ReactSVG src="../assets/images/icons/down.svg" className="w-5" />
                    </div>

                </button>

            </div>
            
            { showCityModal &&
                <ModalBottom
                    show={showCityModal}
                    onSetShow={setShowCityModal}
                    className="overflow-y-auto no-scrollbar"
                    refEl={modalRef}
                    showing={showingCity}
                    onSetShowing={setShowingCity}
                >
                    
                    <div className="sticky top-0 left-0 flex flex-col pb-4 bg-white w-full z-[1] border-b border-b-slate-200">

                        <div className="py-2 bg-white z-[1] mb-2">
                            <div className="mx-auto w-20 h-2 bg-gray-200 rounded-2xl"></div>
                        </div>

                        {/* back action --> btn */}
                        <div className="flex items-center justify-between gap-4 mb-4">

                            <button onClick={backAction} type="button" className="w-10 group" disabled={!props.chooseProvince.name}>
                                
                                <ReactSVG src="../assets/images/icons/back.svg" className="w-5 text-gray-600 hover:text-primary duration-300 rotate-180 mr-1.5 group-disabled:text-gray-400 group-disabled:opacity-70" />

                            </button>

                            <span className="font-medium flex items-center gap-1">
                                <ReactSVG src="../assets/images/icons/location.svg" className="w-4 text-gray-400" />
                                { !props.loading && props.location?.city ? props.location?.city?.name : 'انتخاب نشده' }
                            </span>

                        </div>

                        <SelectCitySearch />

                    </div>

                    <SelectCityList 
                        onUpdateLocation={props.onUpdateLocation}
                    />

                </ModalBottom>
            }
        </>
    )

}

const mapStateToProps = (state) => {

    return {
        chooseProvince: state.location.chooseProvince,
        chooseCity: state.location.chooseCity,
        location: state.profile.userLocation,
        list: state.location.list,
        // loadingList: state.location.loading,
        profile: state.profile.profile
    }

}

const mapDispatchToProps = (dispatch) => {

    return {
        deleteLocationsData: () => {
            dispatch({
                type: DELETE_BASIC_LOCATION_DATA
            })
        },

        deleteProvince: () => {
            
            dispatch({
                type: SET_CHOOSE_PROVINCE,
                payload: {}
            })

        },

        getLocations: async (authToken) => {
            await dispatch( await getLocation(authToken) )
        },

        resetTheProfileLocation: (initalLocation) => {
            
            dispatch({
                type: SET_USER_LOCATION,
                payload: initalLocation
            })

        }

    }

}

const SelectCityMemo = memo(connect(mapStateToProps, mapDispatchToProps)(SelectCity))

export default SelectCityMemo