import { useEffect, useState } from "react"
import { useCookies } from "react-cookie"
import { useDispatch, useSelector } from "react-redux"
import { getContactsList } from "../../../redux/action/contacts/contactsAction"
import { ReactSVG } from "react-svg"
import MessageContactItem from "./Item"
import { SET_CONTACTS } from "../../../redux/action/contacts/typeContacts"

const MessagesContactList = () => {

    const dispatch = useDispatch()
    const contacts = useSelector(state => state.contacts.contacts)
    const error = useSelector(state => state.contacts.error)
    const loading = useSelector(state => state.contacts.loading)
    const filtered = useSelector(state => state.contacts.filtered)
    const getedContacts = useSelector(state => state.contacts.getedContacts)

    const [showOnlyHaveAppContacts, setShowOnlyHaveAppContacts] = useState([])
    const [showOnlyNotHaveAppContacts, setShowOnlyNotHaveAppContacts] = useState([])

    const [cookies] = useCookies(['authToken'])

    useEffect(() => {

        if(contacts.length <= 0 || !getedContacts){
            getContacts()
        }
        else {
            const haveContacts = contacts.filter(item => item.user?.last_login)
            setShowOnlyHaveAppContacts(haveContacts)
            
            const notHaveContacts = contacts.filter(item => (!item.user || !item?.user?.last_login) )
            setShowOnlyNotHaveAppContacts(notHaveContacts)
        }

    }, [])

    const getContacts = async () => {
        
        if(!loading) {
            const data =  await dispatch( await getContactsList(cookies.authToken) )
            
            if(data)
                sortContactsByBaseIsUser(data)
        }

    }

    const sortContactsByBaseIsUser = (contacts) => {
        
        contacts = contacts.sort((a, b) => {
            if (a?.user?.last_login && !b?.user?.last_login) {
                return -1; // a قبلی از b قرار می‌گیرد
            } else if (!a?.user?.last_login && b?.user?.last_login) {
                return 1; // b قبلی از a قرار می‌گیرد
            } else {
                return 0; // ترتیب مانند قبلی باقی می‌ماند
            }
        })

        dispatch({
            type: SET_CONTACTS,
            payload: [...contacts]
        })

        const haveContacts = contacts.filter(item => item?.user?.last_login)
        setShowOnlyHaveAppContacts(haveContacts)
        
        const notHaveContacts = contacts.filter(item => (!item.user || !item?.user?.last_login))
        setShowOnlyNotHaveAppContacts(notHaveContacts)

    }   

    // ==== Render ====
    if(loading){
        return (
            <section className="flex items-center justify-center h-20">
                <ReactSVG 
                    src={`${process.env.PUBLIC_URL}/assets/images/icons/loading-dot.svg`}
                    className="w-10 text-second"
                />
            </section>
        )
    }

    if(error) {
        return (
            <section className="flex items-center gap-2">

                <ReactSVG src={`${process.env.PUBLIC_URL}/assets/images/icons/info-box.svg`} className="w-6 text-rose-500" />

                <p className="text-gray-700 leading-7"> {error} </p>

            </section>
        )
    }

    if(filtered.searchedValue){
        return (
            <>
                <section>

                    { filtered.list.length <= 0 && <p className="text-gray-600 flex items-center"> در جستجو شما هیچ مخاطبی یافت نشد </p> }

                    <ul className="grid md:grid-cols-2 grid-cols-1 gap-4">

                        {filtered.list.map(item => 
                        
                            <MessageContactItem 
                                key={item.id}
                                id={item?.user?.id ? item.user.id : item.id}
                                name={item.name}
                                mobile={item.mobile}
                                image={item?.user?.image}
                                isUser={(item?.user && item?.user?.last_login) ? true : false}
                            />

                        )}
                
                    </ul>

                </section>
            </>
        )
    }

    return (
        <section>

            { contacts.length <= 0 ? 
                <p className="text-gray-600 flex items-center"> هنوز مخاطبی اضافه نکردید </p> 
                :       
                <div className="flex mb-6 items-center flex-wrap gap-2 rounded-2xl shadow-custom-md border-[3px] border-white p-4">
                    <h2 className="text-lg font-medium"> انتخاب کاربر</h2><span className="text-sm"> (در مجموع <span className="font-medium">{contacts.length}</span> مخاطب) </span>
                </div>
            }

            {showOnlyHaveAppContacts.length >= 1 && 
                <ul className="grid md:grid-cols-2 grid-cols-1 gap-4">

                    {showOnlyHaveAppContacts.map(item => 
                
                        <MessageContactItem 
                            key={item.id}
                            id={item?.user?.id ? item.user.id : item.id}
                            name={item.name}
                            mobile={item.mobile}
                            image={item?.user?.image}
                            isUser={(item?.user && item?.user?.last_login) ? true : false}
                        />

                    )}
            
                </ul>
            }

            {showOnlyHaveAppContacts.length >= 1 && showOnlyNotHaveAppContacts.length >= 1 && <hr className="my-7" /> }

            {showOnlyNotHaveAppContacts.length >= 1 && 
                <ul className="grid md:grid-cols-2 grid-cols-1 gap-4">
                    
                    {showOnlyNotHaveAppContacts.map(item => 
                        
                        <MessageContactItem 
                            key={item.id}
                            id={item.id}
                            name={item.name}
                            mobile={item.mobile}
                            image={item?.user?.image}
                            isUser={(item?.user && item?.user?.last_login) ? true : false}
                        />

                    )}

                </ul>
            }

        </section>
    )

}

export default MessagesContactList