export const calculateLeftTime = (time) => {

    const now = new Date()
    time = new Date(time)
        
    const difference = now - time

    const countDaysDifference = difference / (1000 * 60 * 60 * 24)

    if(countDaysDifference >= 30){
        return time.toLocaleString('fa', {
            year: 'numeric', month: '2-digit', day: '2-digit'
        })
    }
    else if(countDaysDifference >= 7){
        const weekPassed = Math.floor(countDaysDifference / 7).toLocaleString('fa')

        return `${weekPassed} هفته پیش`
    }
    else if(countDaysDifference >= 1){
        const days = Math.floor(countDaysDifference).toLocaleString('fa')
        
        return `${days} روز پیش`
    }
    else {
        const hoursPassed = Math.floor(difference / (1000*60*60))

        if(hoursPassed == 0){
            const min = Math.floor(difference / (1000*60))

            if(min === 0)
                return `${'همین حالا'}`

            return `${min.toLocaleString('fa')} دقیقه پیش`
        }

        return `${hoursPassed.toLocaleString('fa')} ساعت پیش`
    }

}

export const getCurrentTimeInIran = (lang) => {
        
    const options = {
        timeZone: 'Asia/Tehran',
        hour12: false,
        weekday: 'long',
        year: 'numeric',
        month: 'long',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        second: 'numeric',
    };
  
    const iranDateTime = new Intl.DateTimeFormat(lang, options).format(new Date());
    
    return iranDateTime;
}

export const getStartEndDate = (persianDate) => {
        
    const month = persianDate.slice(5, 7)
    let monthNumber = Number(month)
    let year = Number( persianDate.slice(0, 4) )

    // if current month be bigger than 10
    if(monthNumber){
        monthNumber = monthNumber - 7
    }
    else{
        
        monthNumber = Number( persianDate.slice(5, 6) )

        if(monthNumber <= 7){
            year = year - 1
        }

        const difference = (monthNumber - 7);

        if(difference > 0){
            monthNumber =  difference
        }
        else {
            monthNumber =  12 + difference
        }

    }

    let startDate = `${ year }/${monthNumber}/1`
    let endDate = `${(Number(persianDate.slice(0, 4)) + 1)}${persianDate.slice(4)}1`

    return {
        startDate,
        endDate,
        monthNumber,
        year
    }
}

export const calculateLeftDaysFromActivePlan = (expireDate, now) => {

    if(expireDate && expireDate !== 0){

        const expireDateExploded = expireDate?.split('-')
        const expireClockExploded = expireDate?.split(' ')[1]?.split(':')
    
        const expireTime = new Date( expireDateExploded[0], Number( expireDateExploded[1]) - 1, expireDateExploded[2].slice(0, 2), expireClockExploded[0], expireClockExploded[1], expireClockExploded[2] )?.getTime()
    
        if(expireTime){
    
            now = new Date( now )?.getTime()
    
            const leftDays =  Math.ceil((expireTime - now)  / (1000 * 60 * 60 * 24))
    
            if(leftDays >= 1)
                return leftDays
            else
                return 0
    
        }

    }

    else
        return 0

}