import { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"

import { HIDE_SEARCH, SET_CASES_SEARCH } from "../../../redux/action/search/searchType"
import { SET_FILTER_CASES, SET_LOADING_LIST_CASES } from "../../../redux/action/cases/casesType"

import { searchInList } from "../../../utils/search"

import SearchBox from "../../Header/Search"


const CasesSearch = () => {

    const dispatch = useDispatch()
    const showSearch = useSelector(state => state.search.showSearch)
    const searchValue = useSelector(state => state.search.casesSearch)
    const filtered = useSelector(state => state.cases.filtered)
    const cases = useSelector(state => state.cases.cases)

    useEffect(() => {

        if(!searchValue && showSearch){
            // close the search state
            hideSearch()
        }   

    }, [])

    useEffect(() => {
    
        // dont send request to server when not in search state
        if(searchValue === '' || !showSearch ||  filtered.searchedValue === searchValue) return
        if(cases.length <= 0) return

        // fake loading - for watching better user.
        dispatch({
            type: SET_LOADING_LIST_CASES,
            payload: true
        })

        const timeout = setTimeout(async () => {

            // search in server
            // await dispatch( await searchInContacts(searchValue, cookies.authToken) )

            // search in front
            doSearch()

        }, 600)

        return () => {
            clearTimeout(timeout)
        }

    }, [searchValue])

    const updateSearchField = (event) => {

        if(event.target.value === ''){
            clearSearchValue()
            return
        }

        dispatch({
            type: SET_CASES_SEARCH,
            payload: event.target.value
        })

    }

    const clearSearchValue = () => {
        
        dispatch({
            type: SET_CASES_SEARCH,
            payload: ''
        })
        
        // if exist search-value come show fake loading
        if(searchValue){
            dispatch({
                type: SET_FILTER_CASES,
                payload: {
                    value: '',
                    list: []
                }
            })

            // set fake loading
            dispatch({
                type: SET_LOADING_LIST_CASES,
                payload: true
            })
    
            setTimeout(() => {
                
                dispatch({
                    type: SET_LOADING_LIST_CASES,
                    payload: false
                })
    
            }, 250)
        }

    }

    const hideSearch = () => {
        
        // hide search
        dispatch({
            type: HIDE_SEARCH
        })

        clearSearchValue()

    }

    const doSearch = () => {
        const filtered = searchInList(cases, searchValue, ['title', 'code', 'branch', 'step', 'state', 'archive_code'])

        dispatch({
            type: SET_FILTER_CASES,
            payload: {
                value: searchValue,
                list: filtered
            }
        })        
    }

    return (
        <SearchBox 
            value={searchValue}
            onUpdate={ updateSearchField }
            onClear={clearSearchValue}
        />
    )

}

export default CasesSearch