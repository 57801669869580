import { useEffect, useState } from "react"
import { Link } from "react-router-dom"
import { ReactSVG } from "react-svg"

const SideMenuUserInfoPlanStatus = (props) => {

    const [leftDays, setLeftDays] = useState()

    useEffect(() => {

        if(!leftDays && (!props.planLeftDays || props.planLeftDays === 0) ){
            caluclateLeftPlan()
        }
        else {
            setLeftDays( props.planLeftDays )
        }

    }, [props.loaded, props.loading, props.planLeftDays])

    const caluclateLeftPlan = () => {

        if(props.activePlan && props.activePlan[0] && props.activePlan[0]?.id){
        
            const expireTime = new Date( props.activePlan[0]?.pivot?.expire_date )?.getTime()

            if(expireTime){

                const now = new Date( props.now )?.getTime()

                const leftDays =  Math.ceil((expireTime - now)  / (1000 * 60 * 60 * 24))

                if(leftDays >= 1)
                    setLeftDays(leftDays)
                else
                    setLeftDays(0)

            }

            else
                setLeftDays(0)
        
        }

        else
            setLeftDays(0)
    }

    if( (leftDays && leftDays !== 0) || props.loading){
        
        return (
            <Link to="/plans" onClick={props.onHideMenu} className="text-sm text-gray-100 flex items-center gap-1.5">
                
                <span> اشتراک باقی‌مانده: </span>
        
                { props.loading ?
                    <ReactSVG src={`${process.env.PUBLIC_URL}/assets/images/icons/loading-dot.svg`} className="w-8" />
                    :
                    <>
                        <span className="text-white font-medium lg:text-lg text-base"> {Number(leftDays).toLocaleString('fa')} </span>
                        <span> روز </span>
                    </>
                }

            </Link>
        )

    }
    else {

        return (
            <Link to="/plans" onClick={props.onHideMenu} className="text-sm text-gray-100 flex items-center gap-1.5">
            
                <span className="mt-0.5 block"> اشتراک ندارید </span>
            
            </Link>
        )

    }
}

export default SideMenuUserInfoPlanStatus