import { SET_LOADING_CHAT, SET_FILTER_CHAT, UNSET_LOADING_CHAT, SET_FAILURE_CHAT, SET_LIST_CHAT, SET_RECEIVER_CHAT, ADD_ITEM_TO_CHATMESSAGES_LIST, ADD_TEXT_MESSAGE_ITEM_TO_CHAT, SET_SENDING_MESSAGE_FAIL_CHAT, SET_PAGINATION_IN_CHAT, ADD_MORE_MESSAGE_TO_MESSAGES_IN_CHAT, UPDATE_ITEM_TO_CHATMESSAGES_LIST, UPDATE_LIST_CHAT, DELETE_MESSAGE_ITEM_FROM_CHAT, DELETE_ALL_MESSAGES_IN_CHAT, SET_LOADEDCHATLIST, DELETE_ITEM_LOADEDCHATLIST, SET_INVITE_CHAT, RESTE_ALL_MESSAGES_CHAT_DATA_APP, SET_SUCCESS_FILE_IN_CHAT_USER, ADD_FILE_TO_CHAT_USER, SET_ERROR_FOR_FILE_IN_CHAT_USER, SET_LOADING_FILE_IN_CHAT_USER, REMOVE_ERROR_CHAT_FILE, CHANGE_STATUS_TEXT_MESSAGE_ITEM_IN_CHAT, SET_LIST_CHAT_WITH_SORTED_DATE, SVAE_SCROLL_POINT_USER_IN_CHAT_PAGE, SVAE_MESSAGE_INPUT_USER_IN_CHAT_PAGE, SVAE_HAVE_NEW_MESSAGE_IN_CHAT_PAGE, SET_ERROR_CHAT_FILE } from '../../../action/messages/chat/chatType'
import { RESTE_ALL_MESSAGES_LIST_DATA_APP } from '../../../action/messages/list/listType'

const initalState = {
    messages: [],
    messagesSortByDate: {},

    receiver: {},
    
    filtered: {
        searchedValue: '',
        list: []
    },

    loading: false,
    error: null,

    loaded: false,

    loadedChatList: {},

    sendingMessageWasFail: null,

    pagination: {},

    chatFileError: null,
    
    loadedChatListExpendData: []
}

const messagesChatReducer = (state = initalState, action) => {

    switch(action.type){

        case SET_LOADING_CHAT :
            return {
                ...state,
                loading: true,
            }

        case SET_FAILURE_CHAT :
            return {
                ...state,
                loading: false,
                error: action.payload
            }
        
        case UNSET_LOADING_CHAT :
            return {
                ...state,
                loading: false,
            }

        case SET_FILTER_CHAT :
            return {
                ...state,
                loading: false,
                filtered: {
                    searchedValue: action.payload.value,
                    list: action.payload.list
                }
            }
            
        case SET_LIST_CHAT :
            return {
                ...state,
                messages: action.payload,
                loading: false,
                loaded: true,
                error: null
            }

        case SET_LIST_CHAT_WITH_SORTED_DATE :
            return {
                ...state,
                messagesSortByDate: action.payload
            }

        case UPDATE_LIST_CHAT :
            return {
                ...state,
                messages: [
                    ...state.loadedChatList[action.payload.id].data.moreMessages,
                    ...action.payload.messages
                ],
                messagesSortByDate: {
                    ...state?.messagesSortByDate,
                    ...action?.payload?.messagesSortByDate
                },
                loading: false,
                loaded: true,
                error: null,
            }

        case SET_RECEIVER_CHAT :
            return {
                ...state,
                receiver: action.payload,
                loading: false,
                loaded: true,
                error: null
            }

        case ADD_ITEM_TO_CHATMESSAGES_LIST :
            return {
                ...state,
                loadedChatList: {
                    ...state.loadedChatList,
                    [action.payload.id]: {
                        loadedTime: action.payload.loadedTime,
                        pagination: action.payload.pagination,
                        data: {
                            ...action.payload.data,
                            moreMessages: []
                        }
                    }
                }
            }

        case UPDATE_ITEM_TO_CHATMESSAGES_LIST :
            return {
                ...state,
                loadedChatList: {
                    ...state.loadedChatList,
                    [action.payload.id]: {
                        loadedTime: action.payload.loadedTime,
                        data: {
                            ...action.payload.data,
                            moreMessages: state.loadedChatList[action.payload.id].data.moreMessages
                        },
                        pagination: state.loadedChatList[action.payload.id].pagination
                    }
                }
            }
        
        case ADD_TEXT_MESSAGE_ITEM_TO_CHAT :
            return {
                ...state,
                messages: [
                    ...state.messages,
                    {
                        body: action.payload.body,
                        id: action.payload.id,
                        seen: false,
                        receiver_id: action.payload.receiver_id,
                        type: 'text',
                        file: null,
                        status: action.payload.status,
                        created_at: new Date(),
                    }
                ],
                messagesSortByDate: {
                    ...state.messagesSortByDate,
                    [action.payload.date] : [
                        ...state.messagesSortByDate[action.payload.date] || [],
                        {
                            body: action.payload.body,
                            id: action.payload.id,
                            seen: false,
                            receiver_id: action.payload.receiver_id,
                            type: 'text',
                            file: null,
                            status: action.payload.status,
                            created_at: new Date()
                        }
                    ],
                },
                loadedChatList: {
                    ...state.loadedChatList,
                    [action.payload.receiver_id]: {
                        loadedTime: state.loadedChatList[action.payload.receiver_id].loadedTime,
                        data : {
                            receiver: state.loadedChatList[action.payload.receiver_id].data.receiver,
                            messages: [
                                ...state.loadedChatList[action.payload.receiver_id].data.messages,
                                {
                                    body: action.payload.body,
                                    id: Math.floor( Math.random() * 1000 ),
                                    seen: false,
                                    receiver_id: action.payload.receiver_id,
                                    type: 'text',
                                    file: null,
                                    created_at: new Date()
                                }
                            ],
                            moreMessages: state.loadedChatList[action.payload.receiver_id].data.moreMessages,
                            messagesSortByDate: {
                                ...state.loadedChatList[action.payload.receiver_id]?.data?.messagesSortByDate || {} ,
                                [action.payload.date] : [
                                    ...state.loadedChatList[action.payload.receiver_id]?.data?.messagesSortByDate[action.payload.date] || [],
                                    {
                                        body: action.payload.body,
                                        id: action.payload.id,
                                        seen: false,
                                        receiver_id: action.payload.receiver_id,
                                        type: 'text',
                                        file: null,
                                        status: action.payload.status,
                                        created_at: new Date()
                                    }
                                ]

                            }
                        },
                        pagination: state.loadedChatList[action.payload.receiver_id].pagination
                    }   
                }
            }

        case CHANGE_STATUS_TEXT_MESSAGE_ITEM_IN_CHAT :
            return {
                ...state,
                messages: state?.messages?.map(item => {
                    if(item.id == action.payload?.fakeId){
                        return {
                            ...item,
                            id: action.payload.id,
                            status: action.payload?.status,
                            receiver_id: action.payload.receiver_id,
                            created_at: new Date(),
                        }
                    }
                    return item
                }),
                messagesSortByDate: {
                    ...state.messagesSortByDate,
                    [action.payload.date] : [
                        ...state.messagesSortByDate[action.payload.date]?.map(item => {
                    
                            if(item.id == action.payload.fakeId){
                                return {
                                    ...item,
                                    id: action.payload.id,
                                    status: action.payload.status,
                                    created_at: new Date()
                                }
                            }
                            return item
                        })
                    ]
                },
                loadedChatList: {
                    ...state.loadedChatList,
                    [action.payload.receiver_id]: {
                        loadedTime: state.loadedChatList[action.payload.receiver_id].loadedTime,
                        data : {
                            messagesSortByDate: {
                                ...state.loadedChatList[action.payload.receiver_id].data?.messagesSortByDate,
                                [action.payload.date] : [
                                    ...state.loadedChatList[action.payload.receiver_id]?.data?.messagesSortByDate[action.payload.date]?.map(item => {
                                
                                        if(item.id == action.payload.fakeId){
                                            return {
                                                ...item,
                                                id: action.payload.id,
                                                status: action.payload.status,
                                                created_at: new Date()
                                            }
                                        }
                                        return item
                                    })
                                ]
                            },
                            receiver: state.loadedChatList[action.payload.receiver_id].data.receiver,
                            messages: state.loadedChatList[action.payload.receiver_id]?.data?.messages.map(item => {
                                
                                if(item.id == action.payload.fakeId){
                                    return {
                                        ...item,
                                        id: action.payload.id,
                                        status: action.payload?.status,
                                        created_at: new Date(),
                                    }
                                }
                                return item
                            }),
                            moreMessages: state.loadedChatList[action.payload.receiver_id].data.moreMessages
                        },
                        pagination: state.loadedChatList[action.payload.receiver_id].pagination
                    }   
                }
            }

        case SVAE_SCROLL_POINT_USER_IN_CHAT_PAGE :
            return {
                ...state,
                loadedChatListExpendData: {
                    ...state.loadedChatListExpendData,
                    [action.payload.userId]: {
                        scrollPoint: action.payload.scrollPoint,
                        haveNewMessage: state.loadedChatListExpendData[action.payload.userId]?.haveNewMessage ? state.loadedChatListExpendData[action.payload.userId]?.haveNewMessage : false,
                        inputText: state.loadedChatListExpendData[action.payload.userId]?.inputText ? state.loadedChatListExpendData[action.payload.userId]?.inputText : '',
                    }   
                }
            }

        case SVAE_MESSAGE_INPUT_USER_IN_CHAT_PAGE :
            return {
                ...state,
                loadedChatListExpendData: {
                    ...state.loadedChatListExpendData,
                    [action.payload.userId]: {
                        ...state.loadedChatListExpendData[action.payload.userId],
                        inputText: action.payload.inputText
                    }   
                }
            }

        case SVAE_HAVE_NEW_MESSAGE_IN_CHAT_PAGE :
            return {
                ...state,
                loadedChatListExpendData: {
                    ...state.loadedChatListExpendData,
                    [action.payload.userId]: {
                        ...state.loadedChatListExpendData[action.payload.userId],
                        haveNewMessage: action.payload.haveNewMessage
                    }   
                }
            }

        case SET_SENDING_MESSAGE_FAIL_CHAT: 
            return {
                ...state,
                sendingMessageWasFail: action.payload
            }

        case SET_PAGINATION_IN_CHAT :
            return {
                ...state,
                pagination: action.payload
            }

        case ADD_MORE_MESSAGE_TO_MESSAGES_IN_CHAT :
            return {
                ...state,
                messages: [
                    ...action.payload.messages,
                    ...state.messages
                ],
                messagesSortByDate: {
                    ... Object.keys({...action.payload?.messagesSortByDate, ...state?.messagesSortByDate}).reduce((result, key) => {
                         if (action.payload?.messagesSortByDate[key] !== undefined && state?.messagesSortByDate[key] !== undefined) {
                             if (typeof action.payload?.messagesSortByDate[key] === 'number' && typeof state?.messagesSortByDate[key] === 'number') {
                                 result[key] = action.payload?.messagesSortByDate[key] + state?.messagesSortByDate[key];
                             } else if (typeof action.payload?.messagesSortByDate[key] === 'string' && typeof state?.messagesSortByDate[key] === 'string') {
                                 result[key] = action.payload?.messagesSortByDate[key] + state?.messagesSortByDate[key];
                             } else if (Array.isArray(action.payload?.messagesSortByDate[key]) && Array.isArray(state?.messagesSortByDate[key])) {
                                 result[key] = [...action.payload?.messagesSortByDate[key], ...state?.messagesSortByDate[key]];
                             } else {
                                 result[key] = [action.payload?.messagesSortByDate[key], state?.messagesSortByDate[key]];
                             }
                         } else {
                             result[key] = action.payload?.messagesSortByDate[key] !== undefined ? action.payload?.messagesSortByDate[key] : state?.messagesSortByDate[key];
                         }
                         return result;
                     }, {}),
                 },
                loadedChatList: {
                    ...state.loadedChatList,
                    [action.payload.id]: {
                        loadedTime: state.loadedChatList[action.payload.id].loadedTime,
                        data : {
                            receiver: state.loadedChatList[action.payload.id].data.receiver,
                            messages: state.loadedChatList[action.payload.id].data.messages,
                            messagesSortByDate: state.loadedChatList[action.payload.id].data?.messagesSortByDate,
                            moreMessages: [
                                ...action.payload.messages,
                                ...state.loadedChatList[action.payload.id].data.moreMessages
                            ]
                        },
                        pagination: action.payload.pagination
                    }   
                },
                pagination: action.payload.pagination
            }

        case DELETE_MESSAGE_ITEM_FROM_CHAT :
            return {
                ...state,
                messages: state.messages.filter(item => item.id != action.payload.messageId),
                messagesSortByDate: {
                    ...state.messagesSortByDate || {},
                    [action.payload.date] : state.messagesSortByDate[action.payload.date].filter(item => item.id != action.payload.messageId)
                },
                loadedChatList: {
                    ...state.loadedChatList,
                    [action.payload.userId]: {
                        loadedTime: state.loadedChatList[action.payload.userId].loadedTime,
                        pagination: state.loadedChatList[action.payload.userId].pagination,
                        data : {
                            receiver: state.loadedChatList[action.payload.userId].data.receiver,
                            messages: state.loadedChatList[action.payload.userId].data.messages.filter(item => item.id != action.payload.messageId),
                            moreMessages: state.loadedChatList[action.payload.userId].data.moreMessages.filter(item => item.id != action.payload.messageId),
                            messagesSortByDate: {
                                ...state.loadedChatList[action.payload.userId].data.messagesSortByDate || {},
                                [action.payload.date] : state.messagesSortByDate[action.payload.date].filter(item => item.id != action.payload.messageId)
                            },
                        }
                    }
                }
            }

        case DELETE_ALL_MESSAGES_IN_CHAT :
            return {
                ...state,
                messages: [],
                messagesSortByDate: {},
                pagination: {},
            }

        case DELETE_ITEM_LOADEDCHATLIST :
            return {
                ...state,
                loadedChatList: {
                    ...state.loadedChatList,
                    [action.payload]: {
                        loadedTime: state.loadedChatList[action.payload].loadedTime,
                        data : {
                            receiver: state.loadedChatList[action.payload].data.receiver,
                            messages: [],
                            moreMessages: [],
                            messagesSortByDate: {}
                        },
                        pagination: {}
                    }
                }
            }

        case RESTE_ALL_MESSAGES_CHAT_DATA_APP :
            return {
                ...state,
                ...initalState
            }



        case ADD_FILE_TO_CHAT_USER :
            return {
                ...state,
                messages: [
                    ...state.messages,
                    action.payload
                ],
                messagesSortByDate: {
                    ...state.messagesSortByDate,
                    [action.payload.date] : [
                        ...state.messagesSortByDate[action.payload.date] || [],
                        action.payload
                    ],
                },
                loadedChatList: {
                    ...state.loadedChatList,
                    [action.payload.receiver_id]: {
                        loadedTime: state.loadedChatList[action.payload.receiver_id].loadedTime,
                        data : {
                            receiver: state.loadedChatList[action.payload.receiver_id].data.receiver,
                            messages: [
                                ...state.loadedChatList[action.payload.receiver_id].data.messages,
                                action.payload
                            ],
                            moreMessages: state.loadedChatList[action.payload.receiver_id].data.moreMessages,
                            messagesSortByDate: {
                                ...state.loadedChatList[action.payload.receiver_id]?.data?.messagesSortByDate || {} ,
                                [action.payload.date] : [
                                    ...state.loadedChatList[action.payload.receiver_id]?.data?.messagesSortByDate[action.payload.date] || [],
                                    action.payload
                                ]

                            }
                        },
                        pagination: state.loadedChatList[action.payload.receiver_id].pagination
                    }   
                }
            }
        
        case SET_ERROR_FOR_FILE_IN_CHAT_USER :
            return {
                ...state,
                messages: state.messages.map(item => {
                    if(item?.file && item?.file?.id == action.payload.fileId)
                        return {
                            ...item,
                            status: 'error'
                        }

                    return item
                }),
                messagesSortByDate: {
                    ...state.messagesSortByDate,
                    [action.payload.date] : [
                        ...state.messagesSortByDate[action.payload.date].map(item => {
                            if(item?.file && item?.file?.id == action.payload.fileId)
                                return {
                                    ...item,
                                    status: 'error'
                                }
        
                            return item
                        }),
                    ],
                },
                loadedChatList: {
                    ...state.loadedChatList,
                    [action.payload.receiver_id]: {
                        loadedTime: state.loadedChatList[action.payload.receiver_id].loadedTime,
                        data : {
                            receiver: state.loadedChatList[action.payload.receiver_id].data.receiver,
                            messages: state.messages.map(item => {
                                if(item?.file && item?.file?.id == action.payload.fileId)
                                    return {
                                        ...item,
                                        status: 'error'
                                    }
            
                                return item
                            }),
                            moreMessages: state.loadedChatList[action.payload.receiver_id].data.moreMessages,
                            messagesSortByDate : {
                                ...state.loadedChatList[action.payload.receiver_id].data.messagesSortByDate || {},
                                [action.payload.date]: state.loadedChatList[action.payload.receiver_id].data.messagesSortByDate[action.payload.date].map(item => {
                                    if(item?.file && item?.file?.id == action.payload.fileId)
                                        return {
                                            ...item,
                                            status: 'error'
                                        }
                
                                    return item
                                })
                            }
                        },
                        pagination: state.loadedChatList[action.payload.receiver_id].pagination
                    } 
                },
                chatFileError: action.payload.message
            }

        case SET_SUCCESS_FILE_IN_CHAT_USER :
            return {
                ...state,
                messages: state.messages.map(item => {
                    if(item?.file && item?.file?.id == action.payload.fileId)
                        return {
                            ...item,
                            id: action.payload.messageId,
                            status: 'success'
                        }

                    return item
                }),
                messagesSortByDate: {
                    ...state.messagesSortByDate || {},
                    [action.payload.date] : [
                        ...state.messagesSortByDate[action.payload.date].map(item => {
                            if(item?.file && item?.file?.id == action.payload.fileId)
                                return {
                                    ...item,
                                    id: action.payload.messageId,
                                    status: 'success'
                                }
        
                            return item
                        }),
                    ],
                },
                loadedChatList: {
                    ...state.loadedChatList,
                    [action.payload.receiver_id]: {
                        loadedTime: state.loadedChatList[action.payload.receiver_id].loadedTime,
                        data : {
                            receiver: state.loadedChatList[action.payload.receiver_id].data.receiver,
                            messages: state.messages.map(item => {
                                if(item?.file && item?.file?.id == action.payload.fileId)
                                    return {
                                        ...item,
                                        id: action.payload.messageId,
                                        status: 'success'
                                    }
            
                                return item
                            }),
                            moreMessages: state.loadedChatList[action.payload.receiver_id].data.moreMessages,
                            messagesSortByDate : {
                                ...state.loadedChatList[action.payload.receiver_id].data.messagesSortByDate || {},
                                [action.payload.date]: state.loadedChatList[action.payload.receiver_id].data.messagesSortByDate[action.payload.date].map(item => {
                                    if(item?.file && item?.file?.id == action.payload.fileId)
                                        return {
                                            ...item,
                                            id: action.payload.messageId,
                                            status: 'success'
                                        }
                
                                    return item
                                })
                            }
                        },
                        pagination: state.loadedChatList[action.payload.receiver_id].pagination
                    } 
                },
                chatFileError: action.payload.message
            }

        case SET_LOADING_FILE_IN_CHAT_USER :
            return {
                ...state,
                messages: state.messages.map(item => {
                    if(item?.file && item?.file?.id == action.payload.fileId)
                        return {
                            ...item,
                            status: 'loading'
                        }

                    return item
                }),
                messagesSortByDate: {
                    ...state.messagesSortByDate || {},
                    [action.payload.date] : [
                        ...state.messagesSortByDate[action.payload.date].map(item => {
                            if(item?.file && item?.file?.id == action.payload.fileId)
                                return {
                                    ...item,
                                    status: 'loading'
                                }
        
                            return item
                        }),
                    ],
                },
                loadedChatList: {
                    ...state.loadedChatList,
                    [action.payload.receiver_id]: {
                        loadedTime: state.loadedChatList[action.payload.receiver_id].loadedTime,
                        data : {
                            receiver: state.loadedChatList[action.payload.receiver_id].data.receiver,
                            messages: state.messages.map(item => {
                                if(item?.file && item?.file?.id == action.payload.fileId)
                                    return {
                                        ...item,
                                        status: 'loading'
                                    }
            
                                return item
                            }),
                            moreMessages: state.loadedChatList[action.payload.receiver_id].data.moreMessages,
                            messagesSortByDate : {
                                ...state.loadedChatList[action.payload.receiver_id].data.messagesSortByDate || {},
                                [action.payload.date]: state.loadedChatList[action.payload.receiver_id].data.messagesSortByDate[action.payload.date].map(item => {
                                    if(item?.file && item?.file?.id == action.payload.fileId)
                                        return {
                                            ...item,
                                            status: 'loading'
                                        }
                
                                    return item
                                })
                            }
                        },
                        pagination: state.loadedChatList[action.payload.receiver_id].pagination
                    } 
                },
            }

        case REMOVE_ERROR_CHAT_FILE :
            return {
                ...state,
                chatFileError: null
            }

        case SET_ERROR_CHAT_FILE :
            return {
                ...state,
                chatFileError: action.payload
            }

        default :
            return state

    }

}

export default messagesChatReducer