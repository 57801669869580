import { SET_MENU_LIST } from '../../../action/menu/main/mainType'

const initialState = {
    menuList: []
}

const mainMenuReducer = (state = initialState, action) => {

    switch(action.type){

        case SET_MENU_LIST :
            return {
                ...state,
                menuList: action.payload
            }

        default :
            return state

    }

}

export default mainMenuReducer