import { useEffect } from "react"

import ServiceWorkerAppInstall from "./Install"

const ServiceWorkerApp = () => {

    useEffect(() => {

        if(navigator.serviceWorker){

            navigator.serviceWorker.register('/sw.js')
            .then((e) => {
            
            })
            .catch(() => {

            })

        }

    }, [])

    return (
        <>
            
            <ServiceWorkerAppInstall />

        </>
    )

}

export default ServiceWorkerApp