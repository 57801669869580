import { useEffect, useState } from "react"
import { useCookies } from "react-cookie"
import { useDispatch, useSelector } from "react-redux"
import { getBaseDataLawyer } from "../../../../../../redux/action/baseData/baseAction"
import { SET_FILEDS_OF_FILTER } from "../../../../../../redux/action/cases/casesType"
import { ReactSVG } from "react-svg"
import { getBaseDataInfoFromLocaleStorage, saveBaseDataInfoToLocaleStorage } from "../../../../../../utils/base-data"
import { SET_SUCCESS_BASEDATA } from "../../../../../../redux/action/baseData/baseType"

const CasesFilterSectionStateList = (props) => {

    const dispatch = useDispatch()
    const baseDataState = useSelector(state => state.baseData.info?.misc?.lawsuit_state)
    const baseDataLoading = useSelector(state => state.baseData.loading)
    const baseDataError = useSelector(state => state.baseData.error)
    const baseDataLoaded = useSelector(state => state.baseData.loaded)
    const fields = useSelector(state => state.cases.fieldsFilter)

    const [showChilds, setShowChilds] = useState(null)

    const [cookies] = useCookies(['authToken'])

    useEffect(() => {

        getStateFromBaseData()

    }, [])

    const getStateFromBaseData = async () => {

        if(baseDataLoaded) return

        if(baseDataLoading) return

        const baseData = getBaseDataInfoFromLocaleStorage()

        if(!baseData){
            const data = await dispatch( await getBaseDataLawyer(cookies.authToken) )

            if(data){
                saveBaseDataInfoToLocaleStorage(data)
            }

        }
        else {

            dispatch({
                type: SET_SUCCESS_BASEDATA,
                payload: baseData
            })

        }

    }

    const updateStepField = (value) => {

        dispatch({
            type: SET_FILEDS_OF_FILTER,
            payload: {
                ...fields,
                state: {
                    value,
                    applied: value.length >= 1 ? true : false
                }
            }
        })
    }

    const selectedState = (value) => {
        setShowChilds(null)

        props.onSetSubActiveItemState(false)
        props.onSetActiveItemState('')

        if(value)
            updateStepField(value)
    }

    if(baseDataLoading){
        return (
            <div className="flex items-center justify-center h-20">  
                <ReactSVG src={`${process.env.PUBLIC_URL}/assets/images/icons/loading-dot.svg`} className="w-10 text-gray-500" />
            </div>
        )
    }

    else if(baseDataError){
        return (
            <div className="flex items-center justify-center h-20 text-gray-600">
                {baseDataError}
            </div>
        )
    }

    return (
        <div className="mt-4">
            
            <ul className="mt-4">

                {baseDataState?.map(item => 
                    <li
                        key={item}
                        className="text-gray-600 cursor-pointer py-4 first:pt-0 last:pb-0 border-b last:border-b-0 border-b-gray-200 flex items-center justify-between"
                        onClick={() => selectedState(item) }
                    >
                        {item}

                        <span className="text-sm text-primary"> انتخاب </span>

                    </li>    
                )}

            </ul>

        </div>
    )

}

export default CasesFilterSectionStateList