import { useEffect, useState } from "react"

const DropdownModal = (props) => {

    const [show, setShow] = useState(false)

    useEffect(() => {
        
        setShow(true) 

    }, [])

    const hide = () => {
        setShow(false)

        setTimeout(() => {
            props.onSetShow(false)
        }, 300);

        document.body.classList.remove('modal-opened')
    }

    return (
        <>
            <div className={`xm:w-56 w-48 bg-white z-40 absolute right-3 top-full rounded-xl 5xm:text-sm xm:text-base text-xs shadow-custom-lg border-2 p-0 border-gray-200 ${show ? 'opacity-100' : 'opacity-0'} duration-300 transition-opacity ${props.classList}`}>

                {props.children}

            </div>
            
            <div onClick={hide} className={`backdrop bg-transparent ${props.backClass && props.backClass}`}></div>

        </>
    )

}

export default DropdownModal