const WalletDiscount = () => {

    return (
        <>

            {/* <div className="flex justify-between items-center gap-5 bg-gradient-to-br outline outline-4 mx-1 outline-third/20 from-third to-four rounded-2xl py-6 lg:p-6 md:p-5 p-4 mb-12">
                
                <div className="flex flex-col gap-2">
                    <span className="font-medium text-gray-100"> همین الان کیف‌ پولتو شارژ کن </span>
                    
                    <div className="flex items-center gap-2">
                        <span className="text-white text-lg font-bold"> ۱۰ درصد از ما هدیه بگیر </span>
                    </div>

                </div>
                
                <div className="mr-auto">
                    
                    <img className="w-20" src={`/assets/images/pictures/discount-wallet-03.svg`} alt="" />

                </div>

            </div>
     */}
            <div className="flex justify-between items-center gap-5 bg-gradient-to-br outline-4 mx-1 outline-third/20 from-white to-white shadow-custom-sm rounded-2xl py-6 lg:p-6 md:p-5 p-4 mb-12">
                        
                <div className="flex flex-col gap-2">
                    <span className="font-medium text-gray-700"> همین الان کیف‌ پولتو شارژ کن </span>
                    
                    <div className="text-primary text-lg font-bold"> <span className="text-2xl">۱۰</span> % از ما هدیه بگیر </div>
        
                </div>
                
                <div className="mr-auto">
                    
                    <img className="w-20" src={`/assets/images/pictures/discount-wallet-03.svg`} alt="" />
        
                </div>
        
            </div>

        </>
    )

    // <div className="flex justify-between items-center gap-5 bg-gradient-to-br outline outline-4 mx-1 outline-third/20 from-third to-four rounded-2xl py-6 lg:p-6 md:p-5 p-4 mb-12">
                
    //             <div className="flex flex-col gap-2">
    //                 <span className="font-medium text-gray-100"> همین الان کیف‌ پولتو شارژ کن </span>
                    
    //                 <div className="flex items-center gap-2">
    //                     <span className="text-white text-lg font-bold"> ۱۰ درصد از ما هدیه بگیر </span>
    //                 </div>

    //             </div>
                
    //             <div className="mr-auto">
                    
    //                 <img className="w-20" src={`/assets/images/pictures/discount-wallet-03.svg`} alt="" />

    //             </div>

    //         </div>

    // <div className="flex justify-between items-center gap-5 bg-gradient-to-br  outline-4 mx-1 outline-third/20 from-white to-white shadow-custom-md rounded-2xl py-6 lg:p-6 md:p-5 p-4 mb-12">
                
    //     <div className="flex flex-col gap-2">
    //         <span className="font-medium text-gray-700"> همین الان کیف‌ پولتو شارژ کن </span>
            
    //         <div className="flex items-center gap-2">
    //             <span className="text-primary text-lg font-bold"> ۱۰ درصد از ما هدیه بگیر </span>
    //         </div>

    //     </div>
        
    //     <div className="mr-auto">
            
    //         <img className="w-20" src={`/assets/images/pictures/discount-wallet-03.svg`} alt="" />

    //     </div>

    // </div>

}

export default WalletDiscount 