import { useState } from "react"
import data from '@emoji-mart/data'
import Picker from '@emoji-mart/react'

import SendMessageBtn from "./Btn"
import SendMessageEmoji from "./Empoji"
import SendMessageFile from "./File"
import SendMessageInput from "./Input"
import ToastCustom from "../../../UI/ToastCustom"
import { ReactSVG } from "react-svg"
import { useSelector } from "react-redux"

const SendMessageFooter = (props) => {

    const sendingError = useSelector(state => state.messages.chat.sendingMessageWasFail)

    const [showEmoji, setShowEmoji] = useState('')
    const [lastEmoji, setLastEmoji] = useState('')

    const [textInput, setTextInput] = useState('')
    const [selectTextInputStart, setSelectTextInputStart] = useState(0)
    const [selectTextInputEnd, setSelectTextInputEnd] = useState(0)

    const handleShowEmoji = () => {
        setShowEmoji(!showEmoji)
    }

    const addEmoji = (event) => {
        const emoji = event.native

        setLastEmoji(emoji)

        const textStart = textInput.slice(0, selectTextInputStart)
        const textEnd = textInput.slice(selectTextInputEnd)

        setTextInput( textStart + emoji + textEnd )

    }
    
    const hideEmoji = () => {
        setShowEmoji(false)

        document.body.classList.remove('modal-opened')
    }

    const addMyMessage = (event) => {
        event.preventDefault()

        // send message
        if(textInput.length >= 1){

            hideEmoji()
        }

    }

    return (
        <footer className="lg:w-3/4 w-full fixed bottom-0 left-0 lg:p-4 pt-4 pb-4 select-none bg-custom-gray z-[1]">
            
            <div className="w-full lg:px-0 px-3 relative">

                <form onSubmit={addMyMessage} action="" className="flex items-end gap-4 relative">

                    <SendMessageBtn 
                        body={textInput}
                        onResetTextValue={setTextInput}
                        invite={props.invite}
                    />

                    <SendMessageInput 
                        value={textInput}
                        onUpdateTextInput={setTextInput}
                        onSetSelectTextInputStart={setSelectTextInputStart}
                        onSetSelectTextInputEnd={setSelectTextInputEnd}
                        lastEmoji={lastEmoji}
                        onHideEmoji={hideEmoji}
                        showEmoji={showEmoji}
                    />

                    <SendMessageEmoji 
                        showEmoji={showEmoji}
                        onHandleShowEmoji={handleShowEmoji}
                    />
                    
                    <SendMessageFile /> 
                    
                </form>

                { showEmoji && 
                    <Picker theme="light"  previewPosition={'none'} locale={'fa'} data={data} onEmojiSelect={addEmoji} searchPosition={'none'} />
                }

            </div>

            { sendingError && 
                <ToastCustom className="lg:-translate-x-2/3">
                    
                    <div className="flex items-center gap-2">
                        <ReactSVG
                            className="text-rose-500/90 4xm:w-8 w-6"
                            src={`${process.env.PUBLIC_URL}/assets/images/icons/info-box.svg`}
                        />

                        {sendingError}
                    </div>

                </ToastCustom>
            }

        </footer>
    )

}

export default SendMessageFooter