import { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { getBaseDataLawyer } from "../../redux/action/baseData/baseAction"
import { ReactSVG } from "react-svg"
import TariffsItem from "./Item"
import { getBaseDataInfoFromLocaleStorage, saveBaseDataInfoToLocaleStorage } from "../../utils/base-data"
import { SET_SUCCESS_BASEDATA } from "../../redux/action/baseData/baseType"
import { useCookies } from "react-cookie"

const TariffsItems = () => {

    const dispatch = useDispatch()
    const tariffs = useSelector(state => state.baseData.info?.misc?.fees)
    const loaded = useSelector(state => state.baseData.loaded)
    const loading = useSelector(state => state.baseData.loading)
    const error = useSelector(state => state.baseData.error)

    const [cookies] = useCookies(['authToken'])

    useEffect(() => {

        if(!loaded){
            
            getFees()

        }

    }, [])

    const getFees = async () => {

        const baseData = getBaseDataInfoFromLocaleStorage()

        if(!baseData){
            const data = await dispatch( await getBaseDataLawyer(cookies.authToken) )

            if(data){
                saveBaseDataInfoToLocaleStorage(data)
            }

        }
        else {

            dispatch({
                type: SET_SUCCESS_BASEDATA,
                payload: baseData
            })

        }

    }

    if(loading){
        
        return (
            <section className="flex items-center justify-center h-20">
                <ReactSVG 
                    src={`${process.env.PUBLIC_URL}/assets/images/icons/loading-dot.svg`}
                    className="w-10 text-second"
                />
            </section>
        )

    }

    if(error){
        return (
            <section className="flex items-center gap-2">

                <ReactSVG src={`${process.env.PUBLIC_URL}/assets/images/icons/info-box.svg`} className="w-6 text-rose-500" />

                <p className="text-gray-700 leading-7"> {error} </p>

            </section>
        )
    }

    return (
        <section>

            <ul className="grid md:grid-cols-2 grid-cols-1 gap-4">
                
                { tariffs && tariffs.map((item, index) =>
                     
                    <TariffsItem 
                        key={item.name+index}
                        name={item.name}
                        price={item.price}
                        description={item.description}
                    />
                    
                )}

            </ul>

        </section>
    )
    
}

export default TariffsItems