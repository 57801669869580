import { ReactSVG } from "react-svg"

import Button from "../../../UI/Button"
import { useCookies } from "react-cookie"
import { useDispatch, useSelector } from "react-redux"
import { deleteEventFromList } from "../../../../redux/action/events/eventsAction"
import { DELETE_EVENT_FROM_HOME_INFO } from "../../../../redux/action/home/homeType"
import { DELETE_EVENT_FROM_SINGLE_CASE } from "../../../../redux/action/cases/casesType"

const DeleteHeader = (props) => {

    const dispatch = useDispatch()
    const homeInfoLoaded = useSelector(state => state.home.loaded)
    const singlesCase = useSelector(state => state.cases.singlesCase)

    const [cookies] = useCookies(['authToken'])

    const destroyDeleteMode = () => {
        props.onSetSelectedItemDelete(null)
    }

    const doDelete = async (event) => {
        event.stopPropagation()

        const data = await dispatch( await deleteEventFromList(props.selectedItemId, props.date ? props.date : null, cookies.authToken) )

        if(data){

            // remove from home-page
            if(homeInfoLoaded){

                dispatch({
                    type: DELETE_EVENT_FROM_HOME_INFO,
                    payload: props.id
                })

            }

            if(props.events && props.events.length >= 1){

                let item = props.events?.filter(item => item.id == props.selectedItemId)
    
                // item-finded
                if(item && item[0]){
    
                    // remove of single-case
                    if(item[0]?.law_suit && item[0]?.law_suit?.id && singlesCase[item[0]?.law_suit?.id]){
       
                        dispatch({
                            type: DELETE_EVENT_FROM_SINGLE_CASE,
                            payload: {
                                id: item[0]?.law_suit?.id,
                                eventId: props.selectedItemId
                            }
                        })
        
                    }
    
                }

            }
            else if(props?.law_suit_id){

                dispatch({
                    type: DELETE_EVENT_FROM_SINGLE_CASE,
                    payload: {
                        id: props.law_suit_id,
                        eventId: props.selectedItemId
                    }
                })

            }

        }

        props.onSetSelectedItemDelete(null)

        document.body.classList.remove('modal-opened')
    }

    return (
        <>
            <header className="fixed lg:pt-4 bg-custom-gray py-3 top-0 left-0 lg:w-3/4 lg:px-4 px-3 w-full z-[3]">

                <div className="bg-white border border-gray-200 flex items-center flex-wrap justify-between rounded-2xl shadow-custom-lg p-4">

                    <button onClick={destroyDeleteMode} className="text-gray-600 h-full">
                        <ReactSVG 
                            className="w-8 h-8"
                            src={`${process.env.PUBLIC_URL}/assets/images/icons/times.svg`}
                        />
                    </button>

                    <div className="flex items-center gap-4">

                        <Button  
                            className={'profile-btn bg-rose-600 py-1 flex gap-1 pr-3 pl-4'}
                            onClick={doDelete}
                        >
                            <ReactSVG
                                src={`${process.env.PUBLIC_URL}/assets/images/icons/delete.svg`}
                                className="w-4"
                            />
                            حذف     
                        </Button>

                    </div>

                </div>

            </header>

        </>
    )

}

export default DeleteHeader