import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { ReactSVG } from "react-svg"
import { getMessages, getMessagesWithoutLoading } from "../../../redux/action/messages/list/listAction"

import MessagesListItem from "./Item"
import { useCookies } from "react-cookie"
import DeleteHeader from "./DeleteHeader"
import { useNavigate } from "react-router-dom"

const MessagesList = () => {

    const dispatch = useDispatch()
    const messages = useSelector(state => state.messages.list.messages)
    const filtered = useSelector(state => state.messages.list.filtered)
    const loaded = useSelector(state => state.messages.list.loaded)
    const [selectedItemDelete, setSelectedItemDelete] = useState(null)

    const loading = useSelector(state => state.messages.list.loading)
    const error = useSelector(state => state.messages.list.error)

    const navigate = useNavigate()

    const [cookies, ,removeCookie] = useCookies(['authToken'])

    useEffect(() => {

        if(messages.length <= 0 && !loading && !loaded){
            getMessagesList()

            const date = new Date()
            localStorage.setItem('last_load_messages', date.getTime())
        }
        else {
            getMessageWithoutLoading()
        }

        let timeoutForGettingNewMessages;
        
        timeoutForGettingNewMessages = setInterval(() => {

            getMessageWithoutLoading()

        }, 30000);

        return () => {
            
            if(timeoutForGettingNewMessages){
                clearInterval(timeoutForGettingNewMessages)
            }

        }

    }, [])

    useEffect(() => {

        if(error){

            if(error === 'ابتدا وارد حساب کاربری خود شوید.'){
                // come go to authPage and remove cookies
                removeCookie('authToken')                

                navigate('/auth/login', {replace: true})
            }     

        }

    }, [error])

    const getMessagesList = async () => {
        await dispatch( await getMessages(cookies.authToken) )
    }

    const getMessageWithoutLoading = async () => {
        const time = localStorage.getItem('last_load_messages');
        
        if(time){
            const now = new Date().getTime()
            
            const leftTime = ( (now - time) / 1000 / 60 );
            
            if(leftTime >= 0.45){
                const data = await dispatch( await getMessagesWithoutLoading(cookies.authToken) )
            }

        }
    }

    // ====== Render ======

    if(loading){

        return (
            <section className="flex items-center justify-center h-20">
                <ReactSVG 
                    src="../assets/images/icons/loading-dot.svg"
                    className="w-10 text-second"
                />
            </section>
        )

    }

    if(error) {
        return (
            <section className="flex items-center gap-2">

                <ReactSVG src="../assets/images/icons/info-box.svg" className="w-6 text-rose-500" />

                <p className="text-gray-700 leading-7"> {error} </p>

            </section>
        )
    }

    if(filtered.searchedValue){
        return (
            <>
                { selectedItemDelete &&
                    <DeleteHeader 
                        selectedItemId={selectedItemDelete}
                        onSetSelectedItemDelete={setSelectedItemDelete}
                    />
                 }

                <section>

                    <div className="flex mb-6 items-center flex-wrap gap-2 rounded-2xl shadow-custom-md border-[3px] border-white p-4">

                        <h2 className="text-lg font-medium"> نتیجه جستجو:‌ {filtered.searchedValue} </h2>

                        <span className="text-sm mt-0.5"> (<span className="font-medium">{filtered.list.length}</span> پیام یافت شده) </span>
                            
                    </div>

                    { filtered.list.length <= 0 && <p className="text-gray-600 flex items-center"> هیچ پیامی یافت نشد </p> }

                    <ul className="flex flex-col gap-5">

                        { filtered.list.map(item => 

                            <MessagesListItem 
                                key={item.id}
                                message={item}
                                selectedItemDelete={selectedItemDelete}
                                onSetSelectedItemDelete={setSelectedItemDelete}
                            />

                        )}

                    </ul>

                </section>
            </>
        )
    }

    return (
        <>
            { selectedItemDelete &&
                <DeleteHeader 
                    selectedItemId={selectedItemDelete}
                    onSetSelectedItemDelete={setSelectedItemDelete}
                />
            }
            <section>

                { messages.length <= 0 && <p className="text-gray-600 text-center h-20 flex items-center justify-center  rounded-2xl shadow-custom-md border-[3px] border-white p-4"> هنوز پیامی اضافه نشده است </p> }

                <ul className="flex flex-col gap-5">

                    { messages.map(item => 

                        <MessagesListItem 
                            key={item.id}
                            message={item}
                            selectedItemDelete={selectedItemDelete}
                            onSetSelectedItemDelete={setSelectedItemDelete}
                        />

                    )}

                </ul>

            </section>
        </>
    )

}

export default MessagesList