import { ReactSVG } from "react-svg"

const ContactProfileInfoItem = (props) => {

    return (
        <div className="flex items-center gap-4">

            <ReactSVG 
                src={`${process.env.PUBLIC_URL}/assets/images/icons/${props.icon}.svg`}
                className="w-6 text-primary"
            />

            <div className="flex items-start flex-col gap-2">

                <span className="text-gray-500 text-sm"> {props.label} </span>

                { props.loading ? 
                    <div className="h-6 flex items-center">
                        <ReactSVG 
                            src={`${process.env.PUBLIC_URL}/assets/images/icons/loading-dot.svg`}
                            className="w-7 text-gray-600"
                        />
                    </div>
                :

                    !props.phoneNumber ? 
                        props.data ? props.data : '-'
                        :
                        <div dir="ltr" className="flex items-center gap-1.5"> 
                            { props.data ?
                                <>
                                    <span> {props?.data?.slice(0, 4)} </span>
                                    <span> {props?.data?.slice(4, 7)} </span>
                                    <span> {props?.data?.slice(7)} </span>
                                </>
                                :
                                <span>-</span>
                            }
                        </div>
                }
            </div>

        </div>
    )

}

export default ContactProfileInfoItem