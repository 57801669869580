import { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"

import { getHomeInfo } from "../../redux/action/home/homeAction"
import { useCookies } from "react-cookie"
import HomeSlider from "./Slider"
import HomeStatus from "./Status"
import HomeFollowSocial from "./FollowSocial"
import HomeNotes from "./Notes"
import HomeCases from "./Cases"
import HomeEvents from "./Events"
import { useNavigate } from "react-router-dom"
import HomeInstall from "./Install"

const HomeContent = () => {

    const dispatch = useDispatch()
    const homeInfo = useSelector((state) => state.home.info)
    const error = useSelector(state => state.home.error)
    const loaded = useSelector(state => state.home.loaded)


    const navigate = useNavigate()

    const [cookies, ,removeCookie] = useCookies(['authToken'])

    useEffect(() => {

        if((!homeInfo?.stats || !homeInfo?.notes) && !loaded)
            getInfo()

    }, [])

    useEffect(() => {

        if(error){

            if(error === 'ابتدا وارد حساب کاربری خود شوید.' || error === 'دسترسی مجاز نیست.'){
                // come go to authPage and remove cookies
                removeCookie('authToken')                

                navigate('/auth/login', {replace: true})
            }     

        }

    }, [error])

    const getInfo = async () => {
        await dispatch( await getHomeInfo(cookies.authToken) )
    }

    return (
        <>

            <HomeSlider 
                items={homeInfo.slider}
            />

            <HomeInstall />

            <HomeCases 
                items={homeInfo.law_suits}
            />

            <HomeStatus 
                items={homeInfo.stats}
            />

            <HomeEvents 
                items={homeInfo.events}
            />

            <HomeNotes 
                items={homeInfo.notes}
            />

            <HomeFollowSocial />

        </>
    )
    
}

export default HomeContent