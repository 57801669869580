import { differenceInDays, endOfMonth, format, setYear, startOfMonth } from 'date-fns-jalali'

import CalendarListItem from "./Item"
import { useEffect, useState } from "react"
import { useSelector } from 'react-redux'

const CalendarBodyList = (props) => {

    const initialDate = useSelector(state => state.events.initialDate)
    const calendarItems = useSelector(state => state.events.calendarItems)
    const differentServerDate = useSelector(state => state.events.computerDifferentTime)

    const [today, setToday] = useState()
    const [toYear, setToYear] = useState()
    const [toYearPersian, setToYearPersian] = useState()
    const [toMonthPersian, setToMonthPersian] = useState()
    const [persianDate, setPersianDate] = useState()
    const [differenceDate, setDifferenceDate] = useState()
    const [eventsData, setEventsData] = useState({})

    const [prefixDays, setPrefixDays] = useState()

    const [holidays, setHolidays] = useState({
        "۱۴۰۳": {
            "1": "1,2,4,12,13,22,23",
            "2": "15",
            "3": "14,15,28",
            "4": "5,25,26",
            "5": "",
            "6": "4,12,14,31",
            "7": "",
            "8": "",
            "9": "15",
            "10": "25",
            "11": "9,22",
            "12": "29,30"
        }
    })

    const [toMonthHolidays, setToMonthHolidays] = useState([])

    useEffect(() => {

        if(props.now){

            const start = startOfMonth( props.now )
            const end = endOfMonth( props.now )
            setDifferenceDate( differenceInDays(end, start) )        
            setPrefixDays( start.getDay() )

            let persianDay = format(new Date(props.now), 'dd', { jalali: true })
            setToday( persianDay )

            if(initialDate)
                setToYear( initialDate.getFullYear() )
            
            let persianTime = format(props.now, 'yyyy/MM/dd', { jalali: true })
            persianTime = persianTime.split('/')
            const year = Number(persianTime[0]).toLocaleString('fa').replace('٬', '')
            const month = Number(persianTime[1]).toLocaleString('fa')
            const day = Number(persianTime[2]).toLocaleString('fa')
            persianTime = [year, month, day]
            setPersianDate( persianTime )

            // exist events data or no
            if(persianTime && calendarItems && calendarItems[persianTime[0]]){
                
                if( calendarItems[persianTime[0]][persianTime[1]] ){
                    setEventsData( calendarItems[persianTime[0]][persianTime[1]] )
                }
                else{
                    setEventsData({})
                }
        
            }
            else{
                setEventsData({})
            }

            let nowFa
            let nowEn
            
            if(differenceDate){
                nowFa = new Date( new Date(props.now).getTime() + differentServerDate ).toLocaleDateString('fa')
                nowEn = format(new Date(props.now).getTime() + differentServerDate, 'yyyy/M/d', { jalali: true })
            }
            else {
                nowFa = new Date(props.now).toLocaleDateString('fa')
                nowEn = format(new Date(props.now).getTime(), 'yyyy/M/d', { jalali: true })
            }

            setToYearPersian(nowFa.split('/')[0])
            setToMonthPersian(nowEn.split('/')[1])

            if(holidays[nowFa.split('/')[0]] && holidays[nowFa.split('/')[0]][nowEn.split('/')[1]]){
                setToMonthHolidays( holidays[nowFa.split('/')[0]][nowEn.split('/')[1]].split(',') )
            }
            else {
                setToMonthHolidays([])
            }

        }

    }, [props.now, calendarItems])

    return (
        <>      
            { prefixDays != 6 && 
                Array.from({length: prefixDays + 1}).map((item, index) =>  
                    
                    <CalendarListItem 
                        key={index + '-s'}
                    />

                )
            }

            { Array.from({length: differenceDate + 1}).map((item, index) => 
                
                <CalendarListItem 
                    key={index}
                    text={index + 1}
                    count={eventsData ? eventsData[(index+1).toLocaleString('fa')] : 0}
                    date={props.now}
                    today={today}
                    toYear={toYear}
                    prefixDays={prefixDays}

                    holidy={ toMonthHolidays && toMonthHolidays.length >= 1 && toMonthHolidays?.includes(String(index + 1)) }
                />
                
            )}

        </>
    )

}

export default CalendarBodyList