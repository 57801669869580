import { debounce } from "lodash"
import { useEffect, useRef, useState } from "react"
import { ReactSVG } from "react-svg"
import PlanItemPayment from "./Payment"
import { useDispatch, useSelector } from "react-redux"
import { getFreePlan, getPlanItems } from "../../../../redux/action/plans/action"
import { useCookies } from "react-cookie"
import { getProfileInfo } from "../../../../redux/action/profile/profileAction"

const PlanItem = ({id, title, real_price, discounted_price, discount, duration_month, activePaymentItem = null, onToggleActivePaymentItem = () => {}, onSetFreePlanSuccess}) => {

    const dispatch = useDispatch()
    const freePlanLoading = useSelector(state => state.plans.freePlanLoading)

    const [showCollapse, setShowCollapse] = useState({
        show: false,
        loading: false
    })

    const [collapseContentHeight, setCollapseContentHeight] = useState(0)
    const collapseContentRef = useRef()

    const [cookies] = useCookies(['authToken'])

    useEffect(() => {

        window.addEventListener('resize', debounceUpdateCollapseHeight)

        return () => {
            window.removeEventListener('resize', debounceUpdateCollapseHeight)
        }

    }, [])

    useEffect(() => {

        let time;

        if(activePaymentItem != id && showCollapse.show){
            setShowCollapse({
                show: false,
                loading: true
            })

            time = setTimeout(() => {
                
                setShowCollapse({
                    show: false,
                    loading: false
                })

            }, 500);

            setCollapseContentHeight(0)
        }
        else {

            setShowCollapse({
                show: true,
                loading: false
            })

            if(collapseContentRef.current){
                setCollapseContentHeight(collapseContentRef.current.scrollHeight)
            }

        }

        return () => {
            if(time)
                clearTimeout(time)
        }

    }, [activePaymentItem])

    // --------------------------------------
    // for updating height when resize page
    const updateCollapseHeight = () => {
        
        if(collapseContentRef.current){
            setCollapseContentHeight(collapseContentRef.current.scrollHeight)
        }

    }
    const debounceUpdateCollapseHeight = debounce(updateCollapseHeight, 400)
    // --------------------------------------

    const toggleShowCollapse = () => {
        
        onToggleActivePaymentItem(id)
    }

    const enableFreePlan = async () => {

        if(freePlanLoading) return

        // send request to server     
        const success = await dispatch( await getFreePlan(cookies.authToken) )

        if(success){
            
            // show success message
            onSetFreePlanSuccess('با موفقیت فعالسازی شد')

            setTimeout(() => {
                onSetFreePlanSuccess(null)
            }, 1000);

            // update profile
            setTimeout(async () => {

                await dispatch( await getProfileInfo(cookies.authToken) )

            }, 2000)

        }
        
    }

    const chooseIcon = () => {

        if(duration_month === 1){
            return '1month.svg'
        }
        else if(duration_month === 3){
            return '3month.svg'
        }
        else if(duration_month === 6){
            return '6month.svg'
        }
        else if(duration_month === 12){
            return '12month.png'
        }
        else {
            return '1month.svg'
        }

    }

    return (
        <li className="bg-white rounded-xl shadow-custom-md h-fit">
            
            <div onClick={discounted_price !== 0 ? toggleShowCollapse : () => {}} className="group bg-white rounded-xl relative flex cursor-pointer items-center justify-between flex-wrap gap-4 p-4 py-8 w-full">
                        
                <div className="flex items-center lg:gap-4 md:gap-2.5 4xm:gap-4 gap-2">

                    <img 
                        src={`${process.env.PUBLIC_URL}/assets/images/pictures/${chooseIcon()}`} 
                        className="lg:w-14 md:w-12 3xm:w-14 w-11" 
                        alt=""
                    />
                    
                    <span className="font-medium 3xm:text-base text-sm"> {title} </span>
                
                </div>

                <div className="flex items-center gap-2 mr-auto 45xm:py-0 py-2">

                    <div className="flex items-center lg:gap-3 md:gap-1.5 4xm:gap-3 45xm:gap-1.5 45xm:flex-row flex-col mr-auto">

                        {  discount >= 1 && discount <= 99 &&
                            <span className="line-through lg:text-base md:text-sm 45xm:text-gray-600 text-gray-400 3xm:text-base text-sm"> {Number(real_price).toLocaleString('fa')} تومان</span>
                        }

                        <button onClick={discounted_price === 0 ? enableFreePlan : () => {}} className={`flex items-center lg:text-base md:text-sm gap-1 45xm:border text-[#19ab56] 3xm:text-base text-sm 45xm:border-[#1cb56180] ${freePlanLoading && discount === 100 && '!bg-[#52b47b]'} ${discount >= 1 && '45xm:bg-[#19ab56] 45xm:text-white'} 45xm:group-hover:bg-[#19ab56] 45xm:group-hover:text-white rounded-lg lg:px-4 md:px-2 3xm:px-4 4xm:px-3 45xm:px-1 45xm:py-1.5 45xm:font-medium font-bold duration-150 transition-colors`}>

                            { discount === 100 ?
                                <div className="flex items-center justify-center gap-2">
                                    <span>فعالسازی</span>
                                    
                                    { freePlanLoading && 
                                        <ReactSVG 
                                            src={`${process.env.PUBLIC_URL}/assets/images/icons/loading-dot.svg`} 
                                            className={`w-2.5 scale-150`}
                                        />
                                    }
                                </div>
                                :
                                <>
                                    <span> {discount >= 1 ? Number(discounted_price).toLocaleString('fa') : Number(real_price).toLocaleString('fa')} </span>
                                    <span>تومان</span>
                                </>
                            }

                        </button>

                    </div>

                    <ReactSVG 
                        src={`${process.env.PUBLIC_URL}/assets/images/icons/up.svg`} 
                        className={`w-4.5 ${(showCollapse.show && activePaymentItem == id ? '-rotate-180' : '-rotate-90')} duration-300 transition-transform -rotate-90 text-[#19ab56] 45xm:hidden`}
                    />

                </div>
                
                {
                    discount >= 1 && discount < 100 ?
                        <span className="absolute top-0 bg-gradient-to-r from-[#e4156c] to-[#c11756] text-white text-sm px-2.5 py-1 rounded-b-full right-6 font-medium"> {Number(discount).toLocaleString('fa')}٪ </span>
                    :
                    discount === 100 &&
                        <img className="w-32 absolute -top-1.5" src={`${process.env.PUBLIC_URL}/assets/images/pictures/plan-gift-title-2.png`} alt="" />
                }

            </div>

            <div style={ { height: (showCollapse.show && activePaymentItem == id) ? `${collapseContentHeight}px` : '0px' } } className={`collapse-content overflow-hidden`}>
                
                <div ref={collapseContentRef} className={`p-4 flex flex-col gap-6 ${showCollapse.show && activePaymentItem == id ? '' : 'border-none'} border-t duration-300`}>

                    <PlanItemPayment 
                        id={id}
                        title={title}
                        price={discounted_price}

                        onToggleActivePaymentItem={onToggleActivePaymentItem}
                    />

                </div>

            </div>

        </li>
    )

}

export default PlanItem