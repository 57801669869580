import { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { ReactSVG } from "react-svg"
import { getCasesLawyer } from "../../../../../../redux/action/cases/casesAction"
import { useCookies } from "react-cookie"

const AddEventFormCasesList = (props) => {

    const dispatch = useDispatch()
    const cases = useSelector(state => state.cases.cases)
    const loading = useSelector(state => state.cases.loading)
    const error = useSelector(state => state.cases.error)
    const loaded = useSelector(state => state.cases.loaded)

    const [cookies] = useCookies(['authToken'])

    useEffect(() => {

        getCases()

    }, [])

    const getCases = async () => {
        if(loaded) return

        await dispatch( await getCasesLawyer(cookies.authToken) )
    }

    if(loading){
        return (
            <div className="flex items-center justify-center h-20">  
                <ReactSVG src={`${process.env.PUBLIC_URL}/assets/images/icons/loading-dot.svg`} className="w-10 text-gray-500" />
            </div>
        )
    }

    else if(error){
        return (
            <div className="flex items-center justify-center h-20 text-gray-600">
                {error}
            </div>
        )
    }

    else if (props.filteredList.searched){
        return (
            <>
                { props.filteredList.list.length === 0 &&
                    <div className="flex items-center justify-center h-20 text-gray-600">
                        هیچ پرونده ای یافت نشد
                    </div>
                }

                <ul className="mt-4">

                    {props.filteredList?.list?.map(item => 
                        <li
                            key={'position_'+item.title}
                            className="text-gray-600 cursor-pointer py-4 first:pt-0 last:pb-0 border-b last:border-b-0 border-b-gray-200 flex items-center justify-between"
                            onClick={() => props.onSelectCase(item.id, item.title)}
                        >
                            {item.title}

                            <span className="text-sm text-primary"> انتخاب </span>
                        </li>    
                    )}

                </ul>
            </>
        )
    }

    else if(cases.length >= 1)
        return (
            <ul className="mt-4">

                {cases?.map(item => 
                    <li
                        key={'position_'+item.title}
                        className="text-gray-600 cursor-pointer py-4 first:pt-0 last:pb-0 border-b last:border-b-0 border-b-gray-200 flex items-center justify-between"
                        onClick={() => props.onSelectCase(item.id, item.title)}
                    >
                        {item.title}

                        <span className="text-sm text-primary"> انتخاب </span>
                    </li>    
                )}

            </ul>
        )

    return (
        <div className="flex items-center justify-center h-20 text-gray-600">
            هیچ پرونده ای ثبت نشده است
        </div>
    )

}

export default AddEventFormCasesList