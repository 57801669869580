import { Link } from "react-router-dom"
import { ReactSVG } from "react-svg"
import { getProfileColor } from "../../../../utils/messages"
import { useState } from "react"

import { useDispatch, useSelector } from "react-redux"
import { useCookies } from "react-cookie"
import { deleteCaseFromList } from "../../../../redux/action/cases/casesAction"
import { DELETE_CASE_FROM_HOME_INFO } from "../../../../redux/action/home/homeType"
import { DELETE_CASE_FROM_CONTACTS, DELETE_CASE_FROM_CONTACT_INFO_CONTACTS } from "../../../../redux/action/contacts/typeContacts"
import ConfirmModal from "../../../UI/ConfirmModal"

const CaseListItem = (props) => {

    const dispatch = useDispatch()
    const homeInfoLoaded = useSelector(state => state.home.loaded)
    const profilesList = useSelector(state => state.contacts.profilesList)
    const contactInfo = useSelector(state => state.contacts.contactInfo)

    const [showDeleteCaseModal, setShowDeleteCaseModal] = useState(false)

    const [cookies] = useCookies(['authToken'])

    const showDeleteCaseAlert = () => {
        setShowDeleteCaseModal(true)
    }

    const getColor = () => {

        const color = getProfileColor(props.mobile.slice(10, 11))
        
        return color
    }

    return (
        <li className="relative border-b pb-6">

            <Link to={`/law-suits/${props.id}`}  className="bg-white shadow-custom-md flex flex-col rounded-xl h-full">
                
                <div className="flex items-center justify-between mb-4 relative border-b border-b-gray-100">

                    <div className="flex items-center flex-wrap gap-2 mb-3 pt-4 px-4">

                        { props.image ? 
                        
                            <div className="w-6 h-6 flex items-center border border-primary/20 justify-center bg-primary/10 rounded-lg">

                                <img className="w-full h-full rounded-lg" src={props.image} alt="" />

                            </div>
                            :
                            props.name ?
                                <div className={`w-6 h-6 flex items-center justify-center profile-${getColor()} border rounded-lg`}>
                                    <span className={`text-sm-13px font-medium`}>{ props.name.slice(0, 1) }</span>
                                </div>
                            :

                            <div className="w-6 h-6 flex items-center border border-primary/20 justify-center bg-primary/10 rounded-lg">

                                <ReactSVG 
                                    className="w-3 h-3 text-primary"
                                    src={`${process.env.PUBLIC_URL}/assets/images/icons/cases.svg`}
                                />

                            </div>
                        }

                        <span className="text-sm text-gray-700 font-medium"> پرونده {props.title} </span>

                    </div>


                </div>

                <div className="flex items-center gap-2 text-gray-500 px-4 mb-4">

                    <ReactSVG 
                        className="w-5 h-5 text-primary flex-shrink-0"
                        src={`${process.env.PUBLIC_URL}/assets/images/icons/ticket.svg`}
                    />

                    <span className="text-sm-13px text-second"> شماره </span>

                    <span className="text-gray-600 text-sm-13px font-medium"> { props.code ? Number(props.code).toLocaleString('fa').split('٬').join('') : '-'} </span>

                </div>

                <div className="grid lg:grid-cols-2 md:grid-cols-1 3xm:grid-cols-2 grid-cols-1 gap-4 mb-4 px-4">

                    <div className="flex flex-col gap-4">

                        <div className="flex items-center gap-2 text-gray-500">

                            <ReactSVG 
                                className="w-4.5 h-4.5 mr-[0.8px] text-primary flex-shrink-0"
                                src={`${process.env.PUBLIC_URL}/assets/images/icons/folder.svg`}
                            />

                            <span className="text-sm-13px text-second"> نوع دعوی </span>

                            <span className="text-gray-600 text-sm-13px font-medium"> {props?.category?.name ? props?.category?.name : '-'} </span>

                        </div>

                        <div className="flex items-center gap-2 text-gray-500">

                            <ReactSVG 
                                className="w-5 h-5 flex-shrink-0 text-[#e46200]"
                                src={`${process.env.PUBLIC_URL}/assets/images/icons/lawyer.svg`}
                            />

                            <span className="text-sm-13px text-primary"> شعبه </span>

                            <span className="text-gray-600 text-sm-13px font-medium max-w-[300px] 2xm:max-w-[200px] text-ellipsis overflow-hidden whitespace-nowrap"> {props.branch ? props.branch : '-'} </span>

                        </div>

                    </div>

                    <div className="flex flex-col gap-4">

                        <div className="flex items-center gap-2 text-gray-500">

                            <ReactSVG 
                                className="w-5 h-5 text-primary flex-shrink-0"
                                src={`${process.env.PUBLIC_URL}/assets/images/icons/archive_code.svg`}
                            />

                            <span className="text-sm-13px text-second"> شماره بایگانی </span>

                            <span className="text-gray-600 text-sm-13px font-medium"> { props.archive_code ? Number(props.archive_code).toLocaleString('fa').split('٬').join('') : '-'} </span>

                        </div>

                        <div className="flex items-center gap-2 text-gray-500">

                            <ReactSVG 
                                className="w-5 h-5 flex-shrink-0 text-primary"
                                src={`${process.env.PUBLIC_URL}/assets/images/icons/court-2.svg`}
                            />

                            <span className="text-sm-13px text-second"> مرحله </span>

                            <span className="text-gray-600 text-sm-13px font-medium"> {props.step ? props.step : '-'} </span>

                        </div>

                    </div>

                </div>

                <div className="flex items-center flex-wrap justify-evenly gap-4 px-4 pb-4 border-t border-t-gray-200 pt-4 mt-auto">

                    <div className="flex items-center text-gray-600 gap-2">

                        <ReactSVG 
                            className="w-4 text-primary"
                            src={`${process.env.PUBLIC_URL}/assets/images/icons/notes.svg`}
                        />

                        <div className="flex items-center gap-1 mt-1">
                            <span className="text-sm-13px"> یادداشت‌ها </span>
                            <span className="text-sm-13px"> { props.notes ? Number(props.notes).toLocaleString('fa') : '۰' } </span>
                        </div>

                    </div>

                    <div className="flex items-center text-gray-600 gap-2">

                        <ReactSVG 
                            className="w-4 text-primary rotate-45"
                            src={`${process.env.PUBLIC_URL}/assets/images/icons/tag.svg`}
                        />

                        <div className="flex items-center gap-1 mt-1">
                            <span className="text-sm-13px"> الحاق </span>
                            <span className="text-sm-13px"> { props.files ? Number(props.files).toLocaleString('fa') : '۰' } </span>
                        </div>

                    </div>

                    <div className="flex items-center text-gray-600 gap-2">

                        <ReactSVG 
                            className="w-4 text-primary"
                            src={`${process.env.PUBLIC_URL}/assets/images/icons/message.svg`}
                        />

                        <div className="flex items-center gap-1 mt-0.5">
                            <span className="text-sm-13px"> گفتگو </span>
                            <span className="text-sm-13px"> { props.conversations ? Number(props.conversations).toLocaleString('fa') : '۰' } </span>
                        </div>

                    </div>

                    <div className="flex items-center text-gray-600 gap-2">

                        <ReactSVG 
                            className="w-4 text-primary"
                            src={`${process.env.PUBLIC_URL}/assets/images/icons/events.svg`}
                        />

                        <div className="flex items-center gap-1">
                            <span className="text-sm-13px"> رویداد </span>
                            <span className="text-sm-13px"> { props.events ? Number(props.events).toLocaleString('fa') : '۰' } </span>
                        </div>

                    </div>

                </div>

            </Link>

            { props.selectedItemDelete === props.id && 

                <div className="absolute top-3.5 right-3.5 bg-white">

                    <ReactSVG   
                        className="w-7 h-7 text-green-600 rounded-full"
                        src={`${process.env.PUBLIC_URL}/assets/images/icons/tick.svg`}
                    />

                </div>

            }

        </li>
    )

}

export default CaseListItem